import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import allPagesIcon from '../../../../controls/icons/allPages'
import firstPageIcon from '../../../../controls/icons/firstPage'
import selectPagesIcon from '../../../../controls/icons/selectPages'
import InputFormRow from '../../../../controls/InputFormRow'
import PagePicker from '../../../../components/PagePicker'
import t from './translate'
const WidthInput = styled.input`
  max-width: 65px !important;
  padding: 0;
  text-align: right;
`
const PdfToWordConfig = ({ onChange, form, isPristine, inputFiles }) => {
  const [pagePickerOpen, setPagePickerOpen] = useState(false)
  const [pageSelectionType, setPageSelectionType] = useState('all')
  const [customSelection, setCustomSelection] = useState([])
  const [jobStarted, setJobStarted] = useState(false)
  const [pageSelectionError, setPageSelectionError] = useState(undefined)
  const errors = {}
  // const validate = (form) => {
  //   const errors = {}

  //   if (!form.width) {
  //     errors.width = t('errWidthRequired')
  //   } else if (isNaN(form.width)) {
  //     errors.width = t('errWidthNaN')
  //   } else if (form.width < 100) {
  //     errors.width = t('errMinWidth')
  //   } else if (form.width > 2000) {
  //     errors.width = t('errMaxWidth')
  //   }

  //   return errors
  // }
  if (!form.width) {
    errors.width = t('errWidthRequired')
  } else if (isNaN(form.width)) {
    errors.width = t('errWidthNaN')
  } else if (form.width < 100) {
    errors.width = t('errMinWidth')
  } else if (form.width > 2000) {
    errors.width = t('errMaxWidth')
  }

  return (
    <>
      <FormSection title="Options">
        <FormRow label={t('lblPageSequence')}>
          <ButtonGroup
            name="pageSequence"
            items={[
              {
                id: 'btnAllPages',
                value: 'all',
                label: t('allPages'),
                icon: allPagesIcon,
              },
              {
                id: 'btnFirstPage',
                value: 'first',
                label: t('firstPage'),
                icon: firstPageIcon,
              },
              // {
              //   id: 'btnSelectPages',
              //   value: 'custom',
              //   label: t('customSelection'),
              //   icon: selectPagesIcon,
              //   disabled: inputFiles.length < 1,
              // },
            ]}
            type="button"
            value={form.pageSequence}
            onClick={(e) => {
              if (e.target.value === 'custom') {
                setPagePickerOpen(true)
                // onChange
              }
            }}
            onChange={onChange}
          />
        </FormRow>

        <FormRow label={t('lblImgFormat')}>
          <ButtonGroup
            name="imageFormat"
            items={[
              {
                id: 'btnImgJPG',
                value: 'jpg',
                label: t('imgJPG'),
              },
              {
                id: 'btnImgPNG',
                value: 'png',
                label: t('imgPNG'),
              },
            ]}
            value={form.imageFormat}
            type="radio"
            onChange={onChange}
          />
        </FormRow>
        <InputFormRow
          label={t('lblWidth')}
          errorMessage={isPristine ? undefined : errors.width}
        >
          <WidthInput
            type="number"
            onChange={onChange}
            name="width"
            value={form.width}
          />
          <span>Pixel</span>
        </InputFormRow>
      </FormSection>
      {pagePickerOpen && (
        <PagePicker
          selectedPages={customSelection}
          onSelect={(selection) => {
            // setTotalPages(selection.totalPages)
            setCustomSelection(selection.selectedPages)
            setPagePickerOpen(false)
          }}
          onCancel={() => {
            setPagePickerOpen(false)
          }}
        />
      )}
    </>
  )
}

export default PdfToWordConfig
