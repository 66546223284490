import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import { useNavigate } from 'react-router-dom'

import thumbnailsActions from '../../store/thumbnails/actions'
import Loader from '../../controls/Loader'

import { PageList } from './PageList'
import Toolbar from './Toolbar'
import SelectionMenu from './SelectionMenu'

import { arrayMove } from '../../utils/arrayMove'
import { colors, layout, device, media } from '../../theme'

const Main = styled.div`
  padding: ${layout.gap};
  min-height: calc(100vh - ${layout.headerHeight});
  ${media.down(device.mobile)`
    min-height: calc(100vh - ${layout.mobileHeaderHeight});
  `}
  padding-top:${layout.headerHeight};
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 ${layout.gap};
  height: ${layout.headerHeight};
  background-color: ${colors.bodyBackground};
  z-index: 2;
  border-bottom: solid 2px ${colors.grayLight};
  display: flex;
  align-items: center;

  ${media.down(device.tablet)`
    .pdf4me-logo {
      overflow: hidden;
      width: 42px;
    }
  `}
  ${media.down(device.mobile)`
    height: ${layout.mobileHeaderHeight};
  `}
`

const Styles = createGlobalStyle`
  .ct-organize-pages {
    position: static;
    z-index: 100;
    .row {
      z-index: auto;
    }
  }
  .ct-organize-pages-layout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: #fff; 
    // overflow: auto;
  }
  .sortable-item-helper {
    z-index: 1000;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 22px);
      left: calc(50% - 22px);
      width: 44px;
      height: 44px;
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iIzM5MzkzOSIgZD0iTTMzNy43ODIgNDM0LjcwNGwtNzMuMjk3IDczLjc4MmMtNC42ODYgNC42ODYtMTIuMjg0IDQuNjg2LTE2Ljk3MSAwbC03My4yOTYtNzMuNzgyYy00LjY4Ni00LjY4Ni00LjY4Ni0xMi4yODQgMC0xNi45N2w3LjA3LTcuMDdjNC42ODYtNC42ODYgMTIuMjg0LTQuNjg2IDE2Ljk3MSAwTDIzOSA0NTEuODg3aDFWMjcySDYwLjExM3YxbDQxLjIyNCA0MC43NDFjNC42ODYgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxbC03LjA3MSA3LjA3Yy00LjY4NiA0LjY4Ni0xMi4yODQgNC42ODYtMTYuOTcgMEwzLjUxNSAyNjQuNDg1Yy00LjY4Ni00LjY4Ni00LjY4Ni0xMi4yODQgMC0xNi45NzFsNzMuNzgyLTczLjI5N2M0LjY4Ni00LjY4NiAxMi4yODQtNC42ODYgMTYuOTcxIDBsNy4wNzEgNy4wNzFjNC42ODYgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxTDYwLjExMyAyMzl2MUgyNDBWNjAuMTEzaC0xbC00MC43NDEgNDEuMjI0Yy00LjY4NiA0LjY4Ni0xMi4yODQgNC42ODYtMTYuOTcxIDBsLTcuMDctNy4wNzFjLTQuNjg2LTQuNjg2LTQuNjg3LTEyLjI4NCAwLTE2Ljk3bDczLjI5Ny03My43ODJjNC42ODYtNC42ODYgMTIuMjg0LTQuNjg2IDE2Ljk3MSAwbDczLjI5NyA3My43ODJjNC42ODYgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxbC03LjA3MSA3LjA3MWMtNC42ODYgNC42ODYtMTIuMjg0IDQuNjg2LTE2Ljk3MSAwTDI3MyA2MC4xMTNoLTFWMjQwaDE3OS44ODd2LTFsLTQxLjIyNC00MC43NDFjLTQuNjg2LTQuNjg2LTQuNjg2LTEyLjI4NCAwLTE2Ljk3MWw3LjA3MS03LjA3YzQuNjg2LTQuNjg2IDEyLjI4NC00LjY4NiAxNi45NyAwbDczLjc4MiA3My4yOTdjNC42ODcgNC42ODYgNC42ODYgMTIuMjg0IDAgMTYuOTcxbC03My43ODIgNzMuMjk3Yy00LjY4NiA0LjY4Ni0xMi4yODQgNC42ODYtMTYuOTcgMGwtNy4wNzEtNy4wN2MtNC42ODYtNC42ODYtNC42ODYtMTIuMjg0IDAtMTYuOTcxTDQ1MS44ODcgMjczdi0xSDI3MnYxNzkuODg3aDFsNDAuNzQxLTQxLjIyNGM0LjY4Ni00LjY4NiAxMi4yODQtNC42ODYgMTYuOTcxIDBsNy4wNyA3LjA3MWM0LjY4NiA0LjY4NSA0LjY4NiAxMi4yODMgMCAxNi45N3oiLz48L3N2Zz4=');
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 1;
      ${media.down(device.mobile)`
        box-sizing: border-box;
        border-radius: 50%;
        border: inset 5px transparent;
        width: 28px;
        height: 28px;
        top: 8px;
        right: 8px;
        left: auto;
      `}
    }
    img {
      border: 2px solid ${colors.grayDark};
      box-shadow: 0 0 16px rgba(0,0,0,0.4);
      cursor: move;
    }
  }
`

const PageView = (props) => {
  const {
    thumbnails,
    createThumbnails,
    inputFiles,
    onStartDocProcessing,
    onCancel,
  } = props
  let trackPinchEvent = false
  let lastPinchDist
  const initialState = {
    minPageSize: 100,
    maxPageSize: 400,
    showDeletedPages: true,
    pageList: [],
    selection: [],
    screenSize: 'desktop',
    listStatus: {
      totalPageCount: 1,
      pageCount: 1,
      deletedPageCount: 0,
      totalSelected: 0,
      selected: 0,
      deletedPagesSelected: 0,
    },
    size: 220,
  }
  const navigate = useNavigate()
  const [state, setState] = useState({
    ...initialState,
    listStatus: { ...initialState.listStatus },
  })
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  useEffect(() => {
    if (fileReady) {
      createThumbnails(inputFiles[0])
    }
  }, [fileReady])
  useEffect(() => {
    const { size, maxPageSize } = state
    const maxScreenWidth = Math.floor(window.innerWidth) - 60
    const maxImageWidth =
      maxScreenWidth < maxPageSize ? maxScreenWidth : maxPageSize

    let initialSize = size
    if (maxImageWidth / 2 < initialSize) {
      initialSize = maxImageWidth / 2
    }

    setState({ ...state, maxPageSize: maxImageWidth, size: initialSize })

    handleResize()

    const rootElm = document.getElementById('root')
    rootElm.addEventListener('mousewheel', handleMouseWheel)
    rootElm.addEventListener('touchmove', handleTouchMove)
    rootElm.addEventListener('touchend', handleTouchEnd)
    rootElm.addEventListener('resize', handleResize)
    rootElm.addEventListener('keyup', handleKeypress)

    // window.p4mApp.lockBodyScroll()
    // document.querySelector('#app-container').classList.add('ct-organize-pages')

    return () => {
      const rootElm = document.getElementById('root')
      rootElm.removeEventListener('mousewheel', handleMouseWheel)
      rootElm.removeEventListener('touchmove', handleTouchMove)
      rootElm.removeEventListener('touchend', handleTouchEnd)
      rootElm.removeEventListener('keyup', handleKeypress)
      rootElm.removeEventListener('resize', handleResize)

      // window.p4mApp.unlockBodyScroll()
      // document
      //   .querySelector('#app-container')
      //   .classList.remove('ct-organize-pages')
    }
  }, [])
  useEffect(() => {
    if (thumbnails) {
      const thumbnailCount = Object.keys(thumbnails).length
      if (thumbnailCount > state.pageList.length) {
        const newList = [...state.pageList]
        for (let i = state.pageList.length + 1; i <= thumbnailCount; i++) {
          newList.push({
            nr: i,
            selected: false,
            deleted: false,
            rotation: 0,
          })
        }
        setState((prevState) => ({ ...prevState, pageList: newList }))
      }
    }
  }, [thumbnails])
  const updatePageList = (newPageList) => {
    const { showDeletedPages } = state
    const newSelection = newPageList
      .filter((page) => showDeletedPages || !page.deleted)
      .filter((page) => page.selected)
      .map((page) => page.nr)

    const newListStatus = {
      totalPageCount: newSelection.length,
      pageCount: 0,
      deletedPageCount: 0,
      totalSelected: 0,
      selected: 0,
      deletedPagesSelected: 0,
    }

    newPageList.forEach((p) => {
      if (p.deleted) {
        newListStatus.deletedPageCount += 1
        if (p.selected) {
          newListStatus.deletedPagesSelected += 1
        }
      } else {
        newListStatus.pageCount += 1
      }
      if (p.selected) {
        newListStatus.totalSelected += 1
        if (!p.deleted) {
          newListStatus.selected += 1
        }
      }
    })

    setState({
      ...state,
      pageList: newPageList,
      selection: newSelection,
      listStatus: newListStatus,
    })
  }

  const handleResize = () => {
    const { screenSize } = state
    let newScreenSize = screenSize
    const screenWidth = window.innerWidth
    if (screenWidth <= parseInt(device.mobile, 10)) {
      newScreenSize = 'mobile'
    } else if (screenWidth <= parseInt(device.tablet, 10)) {
      newScreenSize = 'tablet'
    } else {
      newScreenSize = 'desktop'
    }

    if (newScreenSize !== screenSize) {
      setState({ ...state, screenSize: newScreenSize })
    }
  }

  const handleKeypress = (e) => {
    // delete
    if (e.keyCode === 46) {
      handleDeletePage()
    }
  }

  const handleMouseWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault()
      const { minPageSize, maxPageSize, size } = state

      let newSize = size + Math.sign(e.deltaY) * -25
      newSize = Math.min(maxPageSize, newSize)
      newSize = Math.max(minPageSize, newSize)
      setState({ ...state, size: newSize })
    }
  }

  const handleTouchMove = (e) => {
    if (e.touches.length > 1) {
      e.preventDefault()
      const x1 = e.touches[0].clientX
      const y1 = e.touches[0].clientY
      const x2 = e.touches[1].clientX
      const y2 = e.touches[1].clientY
      const vx = Math.abs(x1 - x2)
      const vy = Math.abs(y1 - y2)
      const dist = Math.sqrt(vx * vx + vy * vy)

      if (!trackPinchEvent) {
        trackPinchEvent = true
        lastPinchDist = dist
      } else {
        const { minPageSize, maxPageSize, size } = state
        const delta = dist - lastPinchDist

        let newSize = size + delta
        newSize = Math.min(maxPageSize, newSize)
        newSize = Math.max(minPageSize, newSize)
        setState({ ...state, size: newSize })

        lastPinchDist = dist
      }
    }
  }

  const handleTouchEnd = () => {
    trackPinchEvent = false
  }

  const handleSortStart = (sort) => {
    const { pageList } = state
    pageList[sort.index].sorting = true
    setState({ ...state, pageList: [...pageList] })
  }

  const handleSortPage = (sort) => {
    const { pageList: tempPageList, selection } = state
    let pageList = [...tempPageList]
    delete pageList[sort.oldIndex]?.sorting

    let newPageList = []

    // move multiple pages
    if (
      selection.length > 1 &&
      pageList[sort.oldIndex].selected &&
      !pageList[sort.newIndex].selected
    ) {
      const pagesToMove = pageList.filter((page) => page.selected)
      const staticPages = pageList.filter((page) => !page.selected)

      const insertAfterNr = pageList[sort.newIndex].nr
      let insertAfterIndex = staticPages.findIndex(
        (page) => page.nr === insertAfterNr
      )

      if (sort.oldIndex < sort.newIndex) {
        insertAfterIndex += 1
      }

      newPageList = [
        ...staticPages.slice(0, insertAfterIndex),
        ...pagesToMove,
        ...staticPages.slice(insertAfterIndex),
      ]
    } else {
      newPageList = arrayMove(pageList, sort.oldIndex, sort.newIndex)
    }
    setState({ ...state, pageList: newPageList })
  }

  const handleDeletePage = (pageNr) => {
    const { pageList, selection } = state
    const pagesToDelete = {}

    if (pageNr === undefined || selection.includes(pageNr)) {
      selection.forEach((nr) => {
        pagesToDelete[nr] = true
      })
    } else {
      pagesToDelete[pageNr] = true
    }

    const newPageList = pageList.map((page) => {
      if (pagesToDelete[page.nr]) {
        return {
          ...page,
          selected: false,
          deleted: true,
        }
      }
      return page
    })

    updatePageList(newPageList)
  }

  const handleUndoDeletePage = (pageNr) => {
    const { pageList, selection } = state
    const pagesToUndoDelete = {}

    if (pageNr === undefined || selection.includes(pageNr)) {
      selection.forEach((nr) => {
        pagesToUndoDelete[nr] = true
      })
    } else {
      pagesToUndoDelete[pageNr] = true
    }

    const newPageList = pageList?.map((page) => {
      if (pagesToUndoDelete[page.nr]) {
        return {
          ...page,
          deleted: false,
        }
      }
      return page
    })

    updatePageList(newPageList)
  }

  const handleRotatePage = (pageNr, deg) => {
    const { pageList, selection } = state
    const pagesToRotate = {}

    if (pageNr === undefined || selection.includes(pageNr)) {
      selection?.forEach((nr) => {
        pagesToRotate[nr] = deg
      })
    } else {
      pagesToRotate[pageNr] = deg
    }

    const newPageList = pageList?.map((page) => {
      if (pagesToRotate[page.nr]) {
        let rotation = page.rotation + pagesToRotate[page.nr]
        if (rotation > 360) {
          rotation -= 360
        } else if (rotation < 0) {
          rotation += 360
        }
        return { ...page, rotation }
      }
      return page
    })
    setState({ ...state, pageList: newPageList })
  }

  const handleSelectPage = (pageNr, ctrlKey, shiftKey) => {
    const { pageList, selection } = state
    let newSelection = {}
    selection.forEach((s) => {
      newSelection[s] = true
    })

    if (ctrlKey) {
      if (newSelection[pageNr] === undefined) {
        newSelection[pageNr] = true
      } else {
        delete newSelection[pageNr]
      }
    } else if (shiftKey) {
      let pageIndex = -1
      let lastSelectedIndex = -1
      pageList.forEach((page, index) => {
        if (page.nr === pageNr) {
          pageIndex = index
        } else if (pageIndex > -1 && page.selected) {
          lastSelectedIndex = index
        } else if (pageIndex === -1 && page.selected) {
          lastSelectedIndex = index
        }
      })

      if (pageIndex > -1 && lastSelectedIndex > -1) {
        const from = Math.min(pageIndex, lastSelectedIndex)
        const to = Math.max(pageIndex, lastSelectedIndex)
        newSelection = {}
        pageList.forEach((page, index) => {
          if (index >= from && index <= to) {
            newSelection[page.nr] = true
          }
        })
      } else {
        newSelection = { [pageNr]: true }
      }
    } else if (selection.length > 1 || !newSelection[pageNr]) {
      newSelection = { [pageNr]: true }
    } else {
      newSelection = {}
    }

    const newPageList = pageList.map((page) => ({
      ...page,
      selected: newSelection[page.nr] !== undefined,
    }))

    updatePageList(newPageList)
  }

  const handleToggleShowDeletedPages = () => {
    const { showDeletedPages } = state
    const newShowDeletedPages = !showDeletedPages
    setState({ ...state, showDeletedPages: newShowDeletedPages }, () => {
      const { pageList } = state
      const newPageList = pageList.map((page) => ({
        ...page,
        selected: newShowDeletedPages && page.selected,
      }))

      updatePageList(newPageList)
    })
  }

  const handleSelectAll = () => {
    const { pageList } = state
    const newPageList = pageList.map((page) => ({
      ...page,
      selected: true,
    }))

    updatePageList(newPageList)
  }

  const handleToggleSelection = () => {
    const { pageList } = state
    const newPageList = pageList.map((page) => ({
      ...page,
      selected: !page.selected,
    }))

    updatePageList(newPageList)
  }

  const handleClearSelection = () => {
    const { pageList } = state
    const newPageList = pageList.map((page) => ({
      ...page,
      selected: false,
    }))
    updatePageList(newPageList)
  }

  const startDocProcessing = () => {
    const { pageList } = state
    const pages = pageList.filter((page) => !page.deleted)
    onStartDocProcessing(pages)
    navigate(-1)
  }

  const handleCancel = () => {
    // const { onCancel } = this.props
    onCancel()
  }

  return (
    <Main className="mbg">
      <Styles />
      <Header>
        {/* <Logo /> */}
        {state?.pageList && state?.pageList.length > 0 && (
          <Toolbar
            listStatus={state?.listStatus}
            screenSize={state?.screenSize}
            showDeletedPages={state?.showDeletedPages}
            onToggleShowDeletedPages={handleToggleShowDeletedPages}
            onSelectAll={handleSelectAll}
            onClearSelection={handleClearSelection}
            onToggleSelection={handleToggleSelection}
            onDeletePage={handleDeletePage}
            onUndoDeletePage={handleUndoDeletePage}
            onRotatePage={handleRotatePage}
            onStart={startDocProcessing}
            onCancel={handleCancel}
          />
        )}
      </Header>
      {state.pageList && state.pageList.length ? (
        <>
          <PageList
            thumbnails={thumbnails}
            pages={state?.pageList}
            showDeletedPages={state?.showDeletedPages}
            pageSize={state?.size}
            isMobile={state?.screenSize === 'mobile'}
            onSortStart={handleSortStart}
            onSortEnd={handleSortPage}
            onDeletePage={handleDeletePage}
            onUndoDeletePage={handleUndoDeletePage}
            onRotatePage={handleRotatePage}
            onSelectPage={handleSelectPage}
          />
          {state.screenSize === 'mobile' &&
            state.listStatus.totalSelected > 0 && (
              <SelectionMenu
                selectionCount={state.listStatus.totalSelected}
                onSelectAll={handleSelectAll}
                onClearSelection={handleClearSelection}
                onToggleSelection={handleToggleSelection}
              />
            )}
        </>
      ) : (
        <Loader />
      )}
    </Main>
  )
}

const mapStateToProps = (state) => ({
  // thumbnails: state.thumbnails.thumbnails,
  // inputFiles: state.docProcessing.inputFiles,
})

const mapDispatchToProps = {
  ...thumbnailsActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageView)
