import { css } from 'styled-components'

export const device = {
  mobile: 540,
  tablet: 800,
  labtop: 1025,
  desktop: 1130,
}

export const media = {
  up: (breakpoint) => (...args) => css`
    @media (min-width: ${breakpoint}px) {
      ${css(...args)}
    }
  `,

  down: (breakpoint) => (...args) => css`
    @media (max-width: ${breakpoint}px) {
      ${css(...args)}
    }
  `,

  between: (fromBreakpoint, toBreakpoint) => (...args) => css`
    @media (min-width: ${fromBreakpoint}px) and (max-width: ${toBreakpoint}px) {
      ${css(...args)}
    }
  `,
}
