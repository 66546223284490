import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M84.507 34.9579C84.4732 35.5609 84.3944 35.972 84.2761 36.188C84.1549 36.407 83.8028 36.566 83.2197 36.665C81.9465 36.9321 81.1042 36.464 80.693 35.2579C79.2141 30.8056 77.462 27.8413 75.4338 26.3682C72.6141 24.3251 67.6817 23.305 60.6338 23.305C57.7803 23.305 56.2169 23.605 55.9408 24.2081C55.8366 24.4091 55.7859 24.6941 55.7859 25.0631V75.1341C55.7859 77.2762 56.3014 78.7763 57.3324 79.6284C58.3634 80.4835 60.4084 81.2605 63.4704 81.9626C64.7775 82.2296 65.5155 82.4156 65.6873 82.5146C66.169 82.8147 66.3408 83.4537 66.2028 84.4228C66.0986 85.1939 65.7211 85.6109 65.0676 85.6769C64.9972 85.7099 64.293 85.6589 62.9521 85.5269C53.876 84.7558 46.3803 84.7228 40.4676 85.4279C39.0563 85.5959 38.1465 85.5959 37.7352 85.4279C37.3915 85.2599 37.169 84.8578 37.0648 84.2218C36.7887 83.1507 37.3915 82.4306 38.8704 82.0616C41.8282 81.3265 43.8225 80.4385 44.8535 79.4004C46.0225 78.2273 46.6056 76.3372 46.6056 73.7239V25.0631C46.6056 24.091 45.9859 23.524 44.7493 23.356C44.6113 23.323 43.5972 23.305 41.707 23.305C35.1746 23.305 30.3606 24.4091 27.2676 26.6202C24.9296 28.2614 22.8507 31.2736 21.0282 35.66C20.4422 37.0341 19.6366 37.4841 18.6056 37.0161C17.4366 36.749 17.0056 36.128 17.3155 35.1589C18.5183 31.5436 19.1211 26.4372 19.1211 19.8397C19.1211 19.0686 20.169 18.7536 22.2676 18.8856C29.693 19.3537 37.9099 19.5877 46.9183 19.5877C63.969 19.5877 75.0253 19.2547 80.0789 18.5826C82.0028 18.3156 83.0507 18.6156 83.2253 19.4857C84.1887 23.938 84.6169 29.0954 84.5155 34.9549L84.507 34.9579Z"
          fill="currentColor"
        />
        <path
          d="M84.507 34.9579C84.4732 35.5609 84.3944 35.972 84.2761 36.188C84.1549 36.407 83.8028 36.566 83.2197 36.665C81.9465 36.9321 81.1042 36.464 80.693 35.2579C79.2141 30.8056 77.462 27.8413 75.4338 26.3682C72.6141 24.3251 67.6817 23.305 60.6338 23.305C57.7803 23.305 56.2169 23.605 55.9408 24.2081C55.8366 24.4091 55.7859 24.6941 55.7859 25.0631V75.1341C55.7859 77.2762 56.3014 78.7763 57.3324 79.6284C58.3634 80.4835 60.4084 81.2605 63.4704 81.9626C64.7775 82.2296 65.5155 82.4156 65.6873 82.5146C66.169 82.8147 66.3408 83.4537 66.2028 84.4228C66.0986 85.1939 65.7211 85.6109 65.0676 85.6769C64.9972 85.7099 64.293 85.6589 62.9521 85.5269C53.876 84.7558 46.3803 84.7228 40.4676 85.4279C39.0563 85.5959 38.1465 85.5959 37.7352 85.4279C37.3915 85.2599 37.169 84.8578 37.0648 84.2218C36.7887 83.1507 37.3915 82.4306 38.8704 82.0616C41.8282 81.3265 43.8225 80.4385 44.8535 79.4004C46.0225 78.2273 46.6056 76.3372 46.6056 73.7239V25.0631C46.6056 24.091 45.9859 23.524 44.7493 23.356C44.6113 23.323 43.5972 23.305 41.707 23.305C35.1746 23.305 30.3606 24.4091 27.2676 26.6202C24.9296 28.2614 22.8507 31.2736 21.0282 35.66C20.4422 37.0341 19.6366 37.4841 18.6056 37.0161C17.4366 36.749 17.0056 36.128 17.3155 35.1589C18.5183 31.5436 19.1211 26.4372 19.1211 19.8397C19.1211 19.0686 20.169 18.7536 22.2676 18.8856C29.693 19.3537 37.9099 19.5877 46.9183 19.5877C63.969 19.5877 75.0253 19.2547 80.0789 18.5826C82.0028 18.3156 83.0507 18.6156 83.2253 19.4857C84.1887 23.938 84.6169 29.0954 84.5155 34.9549L84.507 34.9579Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
