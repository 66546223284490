import React from 'react'

export default function (props) {
  const { className } = props


  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 120 120">
        <path fill="#000" d="M46.505 6.827 41.952 0C31.684 8.574 20.82 17.619 16.803 20.984l8.781 20.363.79-.515c8.386-5.165 15.396-11.135 20.343-22.216 1.77-4.201 1.67-8.95-.212-11.79Z" />
        <path fill="#C71217" d="M48.862 21.745 31.988 76.778s-2.873 8.488-9.52 11.028c-6.17 2.368-14.375 5.701-14.375 5.701L0 62.631 27.547 45.41c8.892-5.295 16.277-11.532 21.315-23.696v.032Z" />
        <path fill="#000" d="M35.953 106.496 40.8 120c13.94-3.322 33.303-8.059 42.145-10.224L61.87 70.358l-1.214.514c-11.4 4.48-19.392 9.539-24.592 20.695a21.602 21.602 0 0 0-.112 15.004v-.075Z" />
        <path fill="#C71217" d="m34.041 87.945 17.28-56.33c2.299-5.874 6.26-10.85 11.34-14.242 9.104-5.905 23.641-15.637 23.641-15.637L120 38.293 58.837 65.536C47.193 70.423 39.15 75.835 34.04 87.946Z" />
      </svg>




    </div>
  )
}
