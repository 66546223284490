export const types = {
  SET_IMAGESTAMP_PROGRESS: 'SET_IMAGESTAMP_PROGRESS',
  SET_IMG_LOADING: 'SET_IMG_LOADING',
  SET_IMG_LOADED: 'SET_IMG_LOADED',
}

export default {
  setImageStampProgress: progress => ({
    type: types.SET_IMAGESTAMP_PROGRESS,
    progress,
  }),
  setImgLoading: () => ({
    type: types.SET_IMG_LOADING,
  }),
  setImgLoaded: () => ({
    type: types.SET_IMG_LOADED,
  }),
}
