import React, { useState, useEffect, useRef, useContext } from 'react'

import styled from 'styled-components'

import Layout from '../Layout'
import { OverlayLoader } from '../../../../controls/Loader'
import ToolBar from '../ToolBar'
import Annotationbar from '../Annotationbar'
import UnlockPdf from '../UnlockPdf'
import ErrorInfo from '../ErrorInfo'
import DocumentOutline from '../DocumentOutline'

import PdfViewerContext from '../../pdfViewer/PdfViewerContext'
import { fontSize, device, media } from '../../../../theme/viewer'
import defaultOptions from './defaultOptions'

const AppContent = styled.div`
  display: flex;
  flex: 1 1;

  ${media.down(device.mobile)`
    flex-direction: column-reverse;
  `}
`

const ViewerContent = styled.div`
  display: flex;
  flex: 1 1;
  ${media.down(device.mobile)`
    flex-direction: column;
  `}
`

const Viewer = styled.div`
  /*border: solid 3px lime;*/
  position: relative;
  flex: 1 1;
  background-color: transparent !important;
  // height: calc(var(--vh, 1vh) * 100);

  ${media.down(device.tablet)`
    .pwv-annotationbar {
      position: fixed;
      left: -60px;
      top: 0;
      bottom: 0;
      width: 60px;
      padding-top: 75px;
      z-index: 15;
      box-shadow: 0 0 6px rgba(0,0,0,0.15);
      border: none;
      &.open {
        left: 0;
      }

    }
    .pwv-canvas-toolbar {
      .pwv-toolbar {
        justify-content: space-between;
      }

    }
  `}
`
const MainViewer = (props) => {
  const { options, toolbar, hideAnnotationBar, hidePageNavigation, hidePageZoom } = props
  const $viewer = useRef(null)
  const [loaded, setLoaded] = useState(false)
  const [showDocumentOutline, setShowDocumentOutline] = useState(false)
  const [state, actions, createPdfViewer] = useContext(PdfViewerContext)

  useEffect(() => {
    const waitForGwt = async () => {
      await Promise.all([window.WebViewer])
      setLoaded(true)
    }
    waitForGwt()
  }, [])

  useEffect(() => {
    if (loaded) {
      window.PdfToolsPromise.then(() => {
        createPdfViewer($viewer.current, {
          ...defaultOptions,
          ...options,
        })
      })
    }
  }, [loaded])

  async function handleOnPasssword(password) {
    return await actions.openFile(state.fileBlob, password)
  }

  return (
    <>
      <Layout>
        <ToolBar
          hidePageNavigation={hidePageNavigation}
          hidePageZoom={hidePageZoom}
          hideAnnotationBar={hideAnnotationBar}
          onToggleOutline={() => {
            setShowDocumentOutline(!showDocumentOutline)
          }}
        >
          {toolbar && toolbar()}
        </ToolBar>
        <AppContent className={hideAnnotationBar ? 'hide-annotationbar' : undefined}>
          <ViewerContent>
            {!hideAnnotationBar && <Annotationbar />}
            <Viewer ref={$viewer} />
          </ViewerContent>
          {showDocumentOutline && (
            <DocumentOutline
              documentOutline={state.documentOutline}
              onNavigate={(target) => {
                actions.goTo(target)
              }}
              onClose={() => {
                setShowDocumentOutline()
              }}
            />
          )}
        </AppContent>
      </Layout>

      {!state.initialized && (
        <OverlayLoader>
          {state.viewerLoaded ? 'loading document...' : 'initialize viewer...'} {'please wait'}
        </OverlayLoader>
      )}
      {state.viewerError && <ErrorInfo error={state.viewerError} />}
      {state.passwordReqired && <UnlockPdf onPassword={handleOnPasssword} />}
    </>
  )
}

export default MainViewer
