import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M60.825 79.98c0-1.355-.596-2.5-1.334-2.5H41.16c-.738 0-1.334 1.093-1.334 2.5 0 1.353.596 2.5 1.334 2.5h18.304c.344 0 .657-.238.893-.633.29-.458.47-1.123.47-1.868Zm0-16c0-1.355-.596-2.5-1.334-2.5H41.16c-.738 0-1.334 1.093-1.334 2.5 0 1.353.596 2.5 1.334 2.5h18.304c.344 0 .657-.238.893-.633.29-.458.47-1.123.47-1.868Zm0 8c0-1.355-.596-2.5-1.334-2.5H41.16c-.738 0-1.334 1.093-1.334 2.5 0 1.353.596 2.5 1.334 2.5h18.304c.344 0 .657-.238.893-.633.29-.458.47-1.123.47-1.868Z" />
        <path fill="currentColor" fillRule="evenodd" d="M88.638 79.443v-43.38c0-1.103-.808-2.018-1.825-2.018h-8.974V25.88c0-.851-.628-1.513-1.406-1.513h-6.79v-11.35c0-1.103-.838-2.017-1.855-2.017H32.88c-1.017 0-1.854.914-1.854 2.018v11.349h-6.79c-.778 0-1.406.662-1.406 1.513v8.165h-8.974c-1.017 0-1.855.915-1.855 2.018v43.38c0 1.103.838 2.017 1.855 2.017h17.17v6.21c0 1.104.837 2.019 1.854 2.019h34.91c1.016 0 1.854-.915 1.854-2.018v-6.21h17.14c1.017 0 1.855-.915 1.855-2.018Zm-3.71-2.27h-6.82v-20.02c0-.85-.628-1.512-1.406-1.512H24.264c-.618 0-1.141.438-1.33 1.029a1.586 1.586 0 0 0-.135.642v19.86h-7.06V47.918h69.19v29.256ZM65.904 15.035h-31.08v18.916h31.08V15.035Zm-50.165 29.76v-6.683h69.19v6.684h-69.19Zm54.113 22.321h5.175v10.057h-5.175V67.116Zm0-8.291h5.175V63.9h-5.175v-5.075Zm0-31.432h5.175v6.558h-5.175v-6.558Zm-3.948 57.945h-31.08V58.825h31.08v26.513Zm-34.91-8.165H25.67V67.116h5.325v10.057Zm0-13.273H25.67v-5.075h5.325V63.9Zm0-29.95H25.67v-6.557h5.325v6.558Z" clipRule="evenodd" />
      </svg>

    </div>
  )
}
