import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
<div className={className ? className : ''}>
      <svg viewBox="0 0 74 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.529907 35.6466H35.5726V70.3535H73.4644V34.7931H36.1424V0.370728H0.529907V35.6466ZM46.057 20.3414L46.4843 19.9431L49.7892 16.9561L69.5898 29.7862L71.0427 30.725L69.9601 29.3595L55.1738 10.7828L57.7379 7.76728L58.1082 7.3121L57.51 7.28366L45.5442 6.91383H45.2308L45.2593 7.22676L46.0285 19.7725L46.057 20.3414ZM18.792 62.388L16.2279 65.4035L15.8575 65.8587L16.4558 65.8871L28.4501 66.2569H28.7635L28.735 65.944L27.9658 53.3983L27.9373 52.8009L27.51 53.1992L24.1766 56.2147L4.37606 43.3845L2.92307 42.4457L4.00569 43.8113L18.792 62.388ZM22.7521 14.3104V22.5604H13.3504V14.3104H22.7521ZM58.6496 48.4483V56.6983H49.2479V48.4483H58.6496ZM0.814808 35.3621V0.65521H35.8575V35.0776H73.1795V70.069H35.8575V35.3621H0.814808ZM46.3134 19.744L45.5442 7.19831L57.5384 7.56814L54.8319 10.7828L69.7607 29.5587L49.7892 16.5862L46.3134 19.744ZM28.4501 65.944L16.4558 65.5742L19.1624 62.3595L4.23361 43.6121L24.2051 56.5561L27.6809 53.3983L28.4501 65.944ZM23.037 14.0259H13.0655V22.8449H23.037V14.0259ZM58.9345 48.1638H48.963V56.9828H58.9345V48.1638Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.814808 0.65521V35.3621H35.8575V35.0776V0.65521H0.814808ZM13.0655 14.0259H23.037V22.8449H13.0655V14.0259Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M73.1795 35.0776H35.8575V35.3621V70.069H73.1795V35.0776ZM48.963 48.1638H58.9345V56.9828H48.963V48.1638Z" fill="currentColor" />
        <path d="M46.3134 19.744L45.5442 7.19831L57.5384 7.56814L54.8319 10.7828L69.7607 29.5587L49.7892 16.5862L46.3134 19.744Z" fill="currentColor" />
        <path d="M28.4501 65.944L16.4558 65.5742L19.1624 62.3595L4.23361 43.6121L24.2051 56.5561L27.6809 53.3983L28.4501 65.944Z" fill="currentColor" />
      </svg>


    </div>
  )
}
