import { api } from '../initFetch'

export const createFolder = async (
  FolderName,
  AbsolutePath,
  DriveId,
  ItemId,
  Token,
  TenantId
) => {
  const res = await api.post('/p4m/OneDrive/CreateFolder', {
    FolderName,
    AbsolutePath,
    DriveId,
    ItemId,
    Token,
    TenantId,
  })
  return res.json
}
