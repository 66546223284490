import styled from 'styled-components'
import { colors, device, media } from '../../../theme'

export const defaultMargin = 71
export const defaultMarginCM = 2.5
export const pageHeight = 400
export const pageWidth = 282
export const A4PageHeight = 842
export const A4PageWidth = 595
export const A4PageHeightCM = 29.7
export const A4PageWidthCM = 21
export const pageRatio = pageHeight / A4PageHeight
export const cmToPixelRatio = A4PageHeightCM / A4PageHeight

export const PageLayout = styled.div`
  // margin-top: -10px;
  padding: 10px;
  background-color: ${colors.grayDark};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // height: 420px;
  ${media.down(device.mobile)`
    align-items: auto;
    height: 400px;
  `};
`

export const Page = styled.div`
  height: ${pageHeight}px;
  width: ${pageWidth}px;
  background-color: white;
  display: flex;
  overflow: hidden;
  .imgWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &.left {
    justify-content: flex-start;
    .imgWrapper {
      align-items: flex-start;
    }
  }
  &.center {
    justify-content: center;
    .imgWrapper {
      align-items: center;
    }
  }
  &.right {
    justify-content: flex-end;
    .imgWrapper {
      align-items: flex-end;
    }
  }

  &.top {
    align-items: flex-start;
  }
  &.middle {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
    .imgWrapper {
      flex-direction: column-reverse;
    }
  }

  .stamp {
    white-space: nowrap;
  }
  .stamp.rotate_0 {
    transform: rotate(0);
  }
  .stamp.rotate_45 {
    transform: rotate(315deg);
  }
  .stamp.rotate_90 {
    transform: rotate(270deg);
  }
  .stamp.rotate_135 {
    transform: rotate(225deg);
  }
  .stamp.rotate_180 {
    transform: rotate(180deg);
  }
  .stamp.rotate_225 {
    transform: rotate(135deg);
  }
  .stamp.rotate_270 {
    transform: rotate(90deg);
  }
  .stamp.rotate_315 {
    transform: rotate(45deg);
  }

  &.overlay {
    z-index: 1;
    position: absolute;
    background-color: transparent;
  }

  .resizer {
    display: none;
  }
  &:hover,
  &:active {
    .resizer {
      display: block;
    }
  }
  ${media.down(device.mobile)`
    .resizer {
      display:block;
    }
  `}
`
