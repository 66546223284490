import React from 'react'
import styled from 'styled-components'
import { colors, device, layout, media } from '../../theme'

export const PreviewWrapper = styled.div`
  background-color: ${colors.bgLighter};
  // display: flex;
  ${media.up(device.tablet)`
    flex-grow: 1;
    order: 1;
    min-height: 250px;
    max-height: 100%;
    overflow: auto;
  `}
`
export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  //   height: 300px;
  img {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;
    box-shadow: ${layout.elevate(2)};
    border: solid 1px ${colors.bgLight};
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`
export const FileItem = styled.li`
  padding: 4px 0px;
  margin: 0px;
  animation: 0.25s ease-in 0s 1 normal none running iFEGFp;
`

export const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const PopupBody = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  ${media.up(device.tablet)`
        max-height: 270px;
        min-height: 270px;
    `}
  ${media.up(device.labtop)`
        max-height: 270px;
        min-height: 270px;
    `}
`
export const ConfigWrapper = styled.div`
  position: relative;
  position: relative;
  display: flex;
  flex-direction: column;
`
