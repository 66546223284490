import styled from 'styled-components'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  overflow: hidden;
  background-color: #eeeff2;
`

const LayoutWrapper = ({ children }) => {
  return <Layout>{children}</Layout>
}

export default LayoutWrapper
