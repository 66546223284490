import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M64.358 62.484h8.784c1.316 0 2.358.913 2.358 2.016 0 1.124-1.042 2.016-2.358 2.016h-8.784v7.468C64.358 75.11 63.29 76 62 76s-2.358-.912-2.358-2.016v-7.468h-8.785c-1.29 0-2.357-.913-2.357-2.016 0-1.125 1.042-2.016 2.358-2.016h8.784v-7.468C59.642 53.89 60.71 53 62 53s2.358.912 2.358 2.016v7.468Z" />
        <path fill="currentColor" fillRule="evenodd" d="M64.661 12H12v46.22h23.826V87.5h52.661V41.28H64.661V12Zm-7.5 5.254H19.5v35.712h16.326V41.28h21.335V17.254Zm23.826 64.992h-37.66V46.534h37.66v35.712Z" clipRule="evenodd" />
      </svg>

    </div>
  )
}
