import styled from 'styled-components'

import Icon from '../Icon'
import checkboxIcon from '../icons/checkbox'
import checkboxCheckedIcon from '../icons/checkboxChecked'
import { colors } from '../../theme'

const Root = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 0.25rem 0;
  overflow: hidden;
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: -100px;
  }
  i {
    margin-right: 0.5rem;
  }
  p {
    padding: 0;
    margin: 0;
  }
  .icon {
    margin-right: 8px;
  }
  &.disabled {
    > * {
      color: ${colors.gray} !important;
    }
    svg {
      fill: ${colors.gray};
    }
  }
`

export default (props) => {
  const { id, name, checked, disabled, onChange, right, children } = props
  return (
    <Root
      className={disabled ? 'disabled' : ''}
      id={id}
      htmlFor={`lbl_${name}`}
    >
      {!right && <Icon icon={checked ? checkboxCheckedIcon : checkboxIcon} />}
      {children}
      {right && <Icon icon={checked ? checkboxCheckedIcon : checkboxIcon} />}
      <input
        type="checkbox"
        id={`lbl_${name}`}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
    </Root>
  )
}
