export const layout = {
  maxContentWidth: '1100px',
  headerHeight: '80px',
  mobileHeaderHeight: '56px',
  logoHeight: '40px',
  logoWidth: '191px',
  logoMobileHeight: '32px',
  logoMobileWidth: '166px',
  gap: '24px',
  elevate: (f) =>
    `0 6px ${f * 8 + 8}px rgba(22, 33, 57, ${f * 0.02 + (f * 0.04) / 2})`,
}
