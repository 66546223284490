import styled from 'styled-components'

import { colors, device, media } from '../../../theme'

export const defaultMargin = 71
export const defaultMarginCM = 2.5
export const pageHeight = 400
export const pageWidth = 282
export const A4PageHeight = 842
export const A4PageWidth = 595
export const A4PageHeightCM = 29.7
export const A4PageWidthCM = 21
export const pageRatio = pageHeight / A4PageHeight
export const cmToPixelRatio = A4PageHeightCM / A4PageHeight

export const PageLayout = styled.div`
  // margin-top: -10px;
  padding: 10px;
  background-color: ${colors.grayDark};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // height: 420px;
  ${media.down(device.mobile)`
  align-items: auto;
    height: 400px;
  `};
`

export const Page = styled.div`
  // height: ${A4PageHeight}px;
  // width: ${A4PageWidth}px;
  // min-height: ${pageHeight}px;
  // min-width: ${pageWidth}px;
  height: ${pageHeight}px;
  width: ${pageWidth}px;
  // max-height: ${pageHeight}px;
  // max-width:  ${pageWidth}px;
  background-color: white;
  display: flex;
  overflow: hidden;
  .imgWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &.left {
    justify-content: flex-start;
    // .textStamp {
    //   text-align: left;
    // }
    .imgWrapper {
      align-items: flex-start;
    }
  }
  &.center {
    justify-content: center;
    // .textStamp {
    //   text-align: center;
    // }
    .imgWrapper {
      align-items: center;
    }
  }
  &.right {
    justify-content: flex-end;
    // .textStamp {
    //   text-align: right;
    // }
    .imgWrapper {
      align-items: flex-end;
    }
  }

  &.top {
    align-items: flex-start;
  }
  &.middle {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
    .imgWrapper {
      flex-direction: column-reverse;
    }
  }

  .textStamp {
    text-align: center;
    display: none;
    width: 100%;
  }
  &.editing {
    align-items: center;
    justify-content: center;
    span {
      width: 100%;
    }
    .textStamp {
      display: block;
    }
    .stamp {
      display: none;
    }
  }
  .stamp {
    white-space: nowrap;
  }
  .stamp.rotate_0 {
    transform: rotate(0);
  }
  .stamp.rotate_45 {
    transform: rotate(315deg);
  }
  .stamp.rotate_90 {
    transform: rotate(270deg);
  }
  .stamp.rotate_135 {
    transform: rotate(225deg);
  }
  .stamp.rotate_180 {
    transform: rotate(180deg);
  }
  .stamp.rotate_225 {
    transform: rotate(135deg);
  }
  .stamp.rotate_270 {
    transform: rotate(90deg);
  }
  .stamp.rotate_315 {
    transform: rotate(45deg);
  }

  &:hover {
    .changeImage {
      opacity: 0.95;
    }
  }

  &.overlay {
    z-index: 1;
    position: absolute;
    background-color: transparent;
  }
`
export const ArrowBox = styled.div`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  align-self: center;
  div {
    border: 17px solid transparent;
    margin: 10px;
  }
  &.left {
    left: 0px;
    div {
      border-left: 0px;
      border-right: 25px solid ${colors.grayLight};
    }
    &.disabled {
      div {
        border-right-color: #ebebeb40;
      }
    }
  }
  &.right {
    right: 0px;
    div {
      border-right: 0px;
      border-left: 25px solid ${colors.grayLight};
    }
    &.disabled {
      div {
        border-left-color: #ebebeb40;
      }
    }
  }
`

export const PageLayoutWrapper = styled.div`
  height: 485px;
  ${media.down(device.mobile)`
  height:auto;
  `}
`

// const DragWrapper = styled.div``

export const VerticalLine = styled.div`
  width: 0px;
  position: absolute;
  border-left: 1px dashed ${colors.gray};
`

export const HorizontalLine = styled.div`
  height: 0;
  position: absolute;
  border-top: 1px dashed ${colors.gray};
`
