import React from 'react'

import App from './App'
import { PdfViewerProvider } from '../pdfViewer/PdfViewerContext'

const Viewer = () => {
  return (
    <PdfViewerProvider>
      <App />
    </PdfViewerProvider>
  )
}

export default Viewer
