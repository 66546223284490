import { types } from './mutations'

const initalState = {
  progress: 0,
  imageUploaded: false,
  docBlobRef: '',
  imgLoading: false,
}

export default (state = initalState, action) => {
  switch (action.type) {
    case types.SET_IMAGESTAMP_PROGRESS: {
      return {
        ...state,
        progress: action.progress,
      }
    }
    case types.SET_IMG_LOADING: {
      return {
        ...state,
        imgLoading: true,
      }
    }
    case types.SET_IMG_LOADED: {
      return {
        ...state,
        imgLoading: false,
      }
    }
    // case types.SET_IMAGE_UPLOADED: {
    //   return {
    //     ...state,
    //     imageUploaded: true,
    //   }
    // }
    default:
      return state
  }
}
