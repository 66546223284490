import { types } from './mutations'

const initalState = {
  connected: false,
  status: 'closed', // 'connecting' | 'connected' | 'closing' | 'closed' | 'error';
  connectionId: undefined,
  error: undefined,
}

// tslint:disable-next-line
const reducers = (state = initalState, action) => {
  const { type } = action
  switch (type) {
    case types.HUB_CONNECTING: {
      return {
        ...state,
        connected: false,
        status: 'connecting',
        connectionId: undefined,
        error: undefined,
      }
    }
    case types.HUB_CLOSING: {
      return {
        ...state,
        connected: false,
        status: 'closing',
        connectionId: undefined,
        error: undefined,
      }
    }

    case types.HUB_CONNECTED: {
      return {
        connected: true,
        status: 'connected',
        connectionId: action.connectionId,
        error: undefined,
      }
    }

    case types.HUB_CLOSED: {
      return {
        connected: false,
        status: 'closed',
        connectionId: undefined,
        error: action.error,
      }
    }

    case types.HUB_ERROR: {
      return {
        connected: false,
        status: 'error',
        connectionId: undefined,
        error: action.error,
      }
    }

    default:
      return state
  }
}

export default reducers
