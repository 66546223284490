const t = (key) => {
  const translate = {
    lblQuality: 'Conversion Mode',
    qualityDraft: 'Standard',
    qualityHigh: 'With OCR',
    md_convertFromPdfDesc_draft:
      '**High-quality Word documents**. Ideal for PDFs that do not contain images from which text needs to be recognized.',
    md_convertFromPdfDesc_high:
      '**Best Quality Word documents**. Ideal for PDFs with images containing text that needs to be recognized. *E.g - Scanned documents or PDFs made from Photos of documents.*',
    btnStartJob: 'Start Convert',
    md_convertFromPdfDesc_high_isPro:
      '**Best Quality Word documents**. Ideal for PDFs with images containing text that needs to be recognized. *E.g - Scanned documents or PDFs made from Photos of documents.*',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
