import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { downloadOneDriveFile } from '../../service/oneDrive/downloadDriveFile'
import { errorHandler } from '../error/action'
import { isValidURL } from '../../utils/helper'
import { app } from '@microsoft/teams-js'
// import config from '../../lib/config'

// Define an async thunk for fetching recent drive files
export const downloadFile = createAsyncThunk(
  'viewer/downloadFile',
  async (
    { teamsUserCredential, itemId, driveId },
    { dispatch, rejectWithValue }
  ) => {
    // const apiClient = ApiClient(teamsUserCredential)
    // const functionName = `downloadFile?itemId=${itemId}`
    let response
    try {
      var tokenRes = await teamsUserCredential.getToken('')
      const teamsContext = await app.getContext()
      response = await downloadOneDriveFile(
        itemId,
        tokenRes.token,
        driveId,
        teamsContext.user.tenant.id
      )
      const fileUrl = response.downLoadUrl
      const valid = isValidURL(fileUrl)
      if (valid) return fileUrl
      else throw new Error(response.downLoadUrl)
    } catch (ex) {
      dispatch(errorHandler(ex))
      return rejectWithValue(ex.message)
    }
  }
)

// Create a slice to manage drive state including loading
const viewerSlice = createSlice({
  name: 'viewer',
  initialState: {
    fileUrl: '',
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle loading state when the async thunk is pending
    builder.addCase(downloadFile.pending, (state) => {
      state.isLoading = true
    })
    // Handle loading state when the async thunk is fulfilled or rejected
    builder.addCase(downloadFile.fulfilled, (state, action) => {
      state.isLoading = false
      state.fileUrl = action.payload
    })
    builder.addCase(downloadFile.rejected, (state) => {
      state.isLoading = false
      // state.recentDriveFiles = [];
    })
  },
})

export default viewerSlice.reducer
