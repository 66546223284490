import React, { useState } from 'react'
import { CardPreview } from '@fluentui/react-components'
import { MessageType, PopupScreen } from '../../utils/enums'
import { useMessage } from '../../controls/MessageBox'
import {
  CardHeader,
  CardWrapper,
  CustomIcon,
  FileUploadButton,
  FileUploadContent,
  FileUploadDescription,
  FileUploadTitle,
  InputLabel,
} from './components'
import { useJobConfig } from '../JobConfig/JobContext'
const FileUploader = ({
  multiple,
  maximumFileSize,
  maximumFileCount,
  tool,
  index,
}) => {
  const { showMessage } = useMessage()
  const [isDragOver, setIsDragOver] = useState(false)
  const { accept, mimeTypes, fileExtensions, filePickerRefs, onFileSelect } =
    useJobConfig()

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragEnter = (event, file) => {
    setIsDragOver(true)
    event.preventDefault()
    event.dataTransfer.setData('text/plain', file?.name)
  }

  const handleDragLeave = () => {
    setIsDragOver(false)
  }

  const handleDrop = (event) => {
    try {
      event.preventDefault()
      setIsDragOver(false)
      const files = []
      const items = event.dataTransfer.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          const file = items[i].getAsFile()
          if (file) {
            files.push(file)
          }
        }
      }
      if (!files?.length) throw new Error('File not found')
      onFileSelect(tool, files, true, PopupScreen.StartJob)
    } catch (error) {
      showMessage(error.message, MessageType.Error)
    }

    // const files = Array.from(event.dataTransfer.files);

    // if (files) {
    //   for (let i = 0; i < files.length; i += 1) {
    //     const file = files[i]

    //     if (!mimeTypes.includes(file.type)) {
    //       // const event = new CustomEvent('filedroprejected', {
    //       //   detail: {
    //       //     name: file.name,
    //       //     type: file.type,
    //       //     size: file.size,
    //       //     fileSizeSupported: file.size < maxFileSize,
    //       //     mimeTypeSupported: mimeTypes.includes(file.type),
    //       //   },
    //       // })
    //       // window.dispatchEvent(event)
    //     } else if (mimeTypes.includes(file.type)) {
    //       const event = new CustomEvent('filedroped', {
    //         detail: file,
    //       })
    //       window.dispatchEvent(event)
    //     }
    //   }
    // }
  }
  // const styles = useStyles();
  // const htmlElement = document.querySelector('html');
  // const language = htmlElement.getAttribute('lang');
  return (
    <CardWrapper className={`drop_card`}>
      {/* {language} */}
      <FileUploadContent
        $dragOver={isDragOver}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <CardPreview>
          <div>
            <CardHeader>
              {tool?.iconName ? (
                <CustomIcon
                  className={tool?.toolClass}
                  toolName={tool?.iconName}
                />
              ) : null}
              <FileUploadTitle>{tool?.title}</FileUploadTitle>
            </CardHeader>
            <FileUploadDescription>{tool?.description}</FileUploadDescription>
          </div>

          <FileUploadButton>
            Drag and drop, or{' '}
            <InputLabel htmlFor={tool?.title}>select a file</InputLabel>
            <input
              ref={filePickerRefs[index]}
              id={tool?.title}
              type="button"
              accept={accept}
              // multiple={multiple}
              onClick={(e) => {
                e.preventDefault()
                onFileSelect(tool, null, false, PopupScreen.Action, index)
              }}
              style={{ display: 'none' }}
            />
          </FileUploadButton>
        </CardPreview>
      </FileUploadContent>
    </CardWrapper>
  )
}

export default FileUploader
