import styled from 'styled-components'

import Icon from '../../controls/Icon'
import TrashIcon from '../../controls/icons/trash'
import { colors, fontSize, media, device } from '../../theme'

const Root = styled.div`
  font-size: ${fontSize.sm};
  background-color: ${colors.errorLighter};
  position: relative;
  user-select: none;
  ${media.down(device.mobile)`
    min-height: 54px;
  `}
`

const Error = styled.div`
  font-weight: 500;
  padding: 0 1rem;
`

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  height: 38px;

  .file-icon {
    svg {
      fill: ${colors.error};
    }
  }

  p {
    margin: 0 0 0 0.5rem;
    padding: 0;
    text-transform: uppercase;
    white-space: nowrap;
    max-width: 230px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: ${colors.error};
  }
  button {
    padding-right: -1px;
    margin: 0 0 0 auto;
    .icon {
      width: 16px;
    }
    svg {
      width: 16px;
      fill: ${colors.errorDark};
    }
  }
`

const ErrorInfo = styled.div`
  padding: 4px 0 12px 0;
  color: ${colors.errorDark};
  p {
    margin: 0;
  }
`

export default (props) => {
  const { id, name, onDeleteFile, children, isShowDelete } = props
  //   const t = useTranslation()

  return (
    <Root>
      <Error>
        <FileInfo>
          <p>{name}</p>
          {isShowDelete && (
            <button type="button" onClick={() => onDeleteFile(id)}>
              <Icon icon={TrashIcon} />
            </button>
          )}
        </FileInfo>
        <ErrorInfo>{children}</ErrorInfo>
      </Error>
    </Root>
  )
}
