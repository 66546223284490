import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  layout,
  media,
  device,
  colors,
  fontSize,
} from '../../../../theme/viewer'

const Wrapper = styled(Link)`
  display: block;
  .logo__svg {
    height: ${layout.logoHeight};
    transition: height 0.25s;
  }

  ${media.down(device.tablet)`
    width: ${layout.logoHeight};
    max-width: ${layout.logoHeight};
    min-width: ${layout.logoHeight};
    overflow: hidden;
  `}

  ${media.down(device.mobile)`
    width: ${layout.logoMobileHeight};
    max-width: ${layout.logoMobileHeight};
    min-width: ${layout.logoMobileHeight};
    overflow: hidden;
    .logo__svg {
      height: ${layout.logoMobileHeight};
      transition: height 0.25s;
    }
  `}
`

const Logo = () => {
  return (
    <Wrapper to="/home">
      <svg viewBox="0 0 542 116" className="logo__svg">
        <defs>
          <style>
            {`
              .logo__ft{fill:#2e3137;}
              .logo__fp{fill:#c71217;}
            `}
          </style>
        </defs>
        <path
          d="M 45.98,8.37 41.48,2 C 31.33,10 20.59,18.44 16.62,21.58 l 8.68,19 0.78,-0.48 c 8.29,-4.82 15.22,-10.39 20.11,-20.73 1.75,-3.92 1.65,-8.35 -0.21,-11 z"
          className="logo__ft"
        />
        <path
          d="M 48.31,22.29 31.63,73.64 c 0,0 -2.84,7.92 -9.41,10.29 -6.1,2.21 -14.21,5.32 -14.21,5.32 L 0.01,60.44 27.24,44.37 C 36.03,39.43 43.33,33.61 48.31,22.26 Z"
          className="logo__fp"
        />
        <path
          d="m 35.55,101.37 4.79,12.6 c 13.78,-3.1 32.92,-7.52 41.66,-9.54 L 61.17,67.65 59.97,68.13 C 48.7,72.31 40.8,77.03 35.66,87.44 a 19.1,19.1 0 0 0 -0.11,14 z"
          className="logo__ft"
        />
        <path
          d="M 33.66,84.06 50.74,31.5 A 28.71,28.71 0 0 1 61.95,18.21 C 70.95,12.7 85.32,3.62 85.32,3.62 L 118.63,37.73 58.17,63.15 C 46.66,67.71 38.71,72.76 33.66,84.06 Z"
          className="logo__fp"
        />
        <path
          d="M 195.62,43.68 H 157.7 c -2.73,0 -3.37,1.38 -3.37,2.89 v 19.24 h 37.58 c 2,0 3.71,-1 3.71,-3.3 z m -48.84,0 a 7.32,7.32 0 0 1 7.55,-7.54 h 48.83 v 29.67 a 7.34,7.34 0 0 1 -7.54,7.56 h -41.29 v 9.93 h -7.55 z"
          className="logo__ft"
        />
        <path
          d="m 217.89,65.9 h 37.92 c 2.72,0 3.37,-1.38 3.37,-2.89 V 43.78 H 221.6 c -2,0 -3.71,1 -3.71,3.29 z m 48.83,0 a 7.32,7.32 0 0 1 -7.54,7.54 H 210.35 V 43.78 a 7.35,7.35 0 0 1 7.54,-7.55 h 41.29 V 26.3 h 7.54 z"
          className="logo__ft"
        />
        <path
          d="m 525.58,41.97 h -37.56 v 8.4 h 41.28 v -5.26 c 0,-2.28 -1.68,-3.14 -3.72,-3.14 z m 11.27,15.93 h -48.83 v 3.24 c 0,1.81 1.41,3 3.52,3 h 45.31 v 7.55 h -48.83 c -4.62,0 -7.55,-3.08 -7.55,-7.55 V 34.42 h 48.83 c 4.56,0 7.55,2.64 7.55,7.55 z"
          className="logo__ft"
        />
        <path
          d="M 401.17,58.37 V 41.56 a 7.39,7.39 0 0 1 7.55,-7.54 h 56.76 a 7.44,7.44 0 0 1 7.54,7.54 v 29.75 h -7.54 V 45.08 c 0,-2 -1,-3.52 -3.37,-3.52 h -50.07 a 3.27,3.27 0 0 0 -3.32,3.37 v 26.38 h -7.55 z"
          className="logo__ft"
        />
        <polygon
          points="440.86,36.62 440.86,71.72 433.31,71.72 433.31,36.62 "
          className="logo__ft"
        />
        <path
          d="m 394.14,35.99 v 35.73 h -7.54 v -9.93 h -41.29 a 7.34,7.34 0 0 1 -7.54,-7.54 V 35.99 h 7.54 v 15 c 0,2.26 1.7,3.3 3.71,3.3 h 37.58 v -18.3 z"
          className="logo__fp"
        />
        <path
          d="m 275.04,50.61 v -6.83 a 7.35,7.35 0 0 1 7.54,-7.55 h 47.47 v 7.55 h -43.76 c -2,0 -3.71,1 -3.71,3.29 v 3.54 z m 7.54,22.83 h -7.54 V 58.16 h 7.54 z"
          className="logo__ft"
        />
        <polygon
          points="270.43,61.79 270.43,54.24 315.74,54.24 315.74,61.79 "
          className="logo__ft"
        />
      </svg>
    </Wrapper>
  )
}

export default Logo
