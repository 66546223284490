import styled from 'styled-components'

import { colors } from '../../theme'

import Loader from '../../controls/Loader'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.bgLight};
  width: 96px;
  height: 96px;
  .fileIcon {
    color: #91afdb;
    width: 72px;
    height: 72px;
  }
`

export default (props) => {
  const { name, thumbnail, inProgress, className = '' } = props
  function renderFileType() {
    if (name.match(/\.pdf$/i)) {
      return (
        <text x="24" y="62" className="cls-text-bjhbj">
          PDF
        </text>
      )
    }
    if (name.match(/\.doc(x)?$/i)) {
      return (
        <text x="24" y="62" className="cls-text-bjhbj">
          DOC
        </text>
      )
    }
    if (name.match(/\.ppt(x)?$/i)) {
      return (
        <text x="24" y="62" className="cls-text-bjhbj">
          PPT
        </text>
      )
    }
    if (name.match(/\.xls(x)?$/i)) {
      return (
        <text x="24" y="62" className="cls-text-bjhbj">
          XLS
        </text>
      )
    }
    if (name.match(/\.(png|jpg|jpeg)?$/i)) {
      return (
        <>
          <polygon
            className="cls-tywedd"
            points="45.3 58.42 57.4 47.93 74.79 64.02 38.85 64.02 45.3 58.42"
          />
          <path
            className="cls-tywedd"
            d="M44.14,35A5.92,5.92,0,1,1,38.23,41,5.92,5.92,0,0,1,44.14,35Z"
          />
          <path
            className="cls-tywedd"
            d="M38.85,64H25.21c0-.47,12.07-12.19,12.07-12.19l8,6.59Z"
          />
        </>
      )
    }
  }

  function renderContent() {
    if (inProgress) {
      return <Loader />
    }

    if (thumbnail) {
      return <img src={`${thumbnail}`} alt={name} />
    }

    return (
      <svg className="fileIcon" viewBox="0 0 100 100">
        <defs>
          <style>
            {`
              .cls-text-bjhbj{font: bold 24px sans-serif; fill:#2d5184;}
              .cls-tywedd{fill:#2d5184;}
              .cls-sdfsd{fill:#83b0dd;}
              .cls-rtyrty{fill:#a1cbee;}
            `}
          </style>
        </defs>
        <polygon
          className="cls-sdfsd"
          points="71.25 15.06 71.25 0.03 14.29 0.03 14.29 99.85 88 99.85 88 87.13 88 60.07 88 15.06 71.25 15.06"
        />
        <polygon
          className="cls-rtyrty"
          points="88 15.06 71.25 15.06 71.25 0.03 88 15.06"
        />
        {renderFileType()}
      </svg>
    )
  }

  return <Wrapper className={className}>{renderContent()}</Wrapper>
}
