import React from 'react'
import styled from 'styled-components'
import { Card } from '@fluentui/react-components'
import Icons from '../../assets/Icons'
export const FileUploadContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
  ${(props) =>
    props.$dragOver
      ? `
    border: 2px dashed #ccc;
    background-color: #f7f7f7;
  `
      : ''}
`
export const FileUploadButton = styled.div`
  color: var(--spectrum-global-color-gray-700);
  fontsize: '12px';
  text-align: 'left';
`
export const InputLabel = styled.label`
  color: var(--secondary);
  font-size: 12px;
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 3px;
`
export const FileUploadTitle = styled.div`
  font-size: 15px;
  color: var(--spectrum-global-color-gray-900);
  font-weight: 700 !important;
  line-height: 17px;
  margin: 0 0 2px;
  padding-right: 12px;
`
export const FileUploadDescription = styled.div`
  fontsize: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  color: #4b4b4b;
`
export const CardWrapper = styled(Card)`
  margin: auto;
  width: 300px;
  max-width: 100%;
  padding: 20px;
  min-width: 300px;
  min-height: 153px;
  height: 100%;
`
export const CustomIcon = styled(Icons)`
  height: var(--icon-size);
  width: var(--icon-size);
  min-width: var(--icon-size);
  margin-right: 12px;
  color: var(--text);
`
