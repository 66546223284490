import { errorHandler } from '../error/action'
import { app, authentication } from '@microsoft/teams-js'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import config from '../../lib/config'
import { getToken } from '../../service/auth/getToken'

const getAuthToken = async (idToken) => {
  try {
    const requestData = new URLSearchParams()
    requestData.append('client_id', config.id1)
    requestData.append('client_secret', config.id2)
    requestData.append('grant_type', config.grantType)
    requestData.append('code', idToken)
    requestData.append('redirect_uri', config.redirect_url)
    // Fetch request
    const response = await fetch(config.tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestData,
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.log(error)
  }
}

export const login = createAsyncThunk(
  'auth/login',
  async (loginCredentials, { dispatch, rejectWithValue }) => {
    const overlay = document.createElement('div')
    overlay.classList.add('overlay')
    document.body.appendChild(overlay)
    const hostUrl = config.serverHost2

    try {
      await app.initialize()

      const authUrl = `${config.login_url}?oauthRedirectMethod={oauthRedirectMethod}&authId={authId}&hostRedirectUrl=${config.redirect_url}&clientId=${config.id1}&hostUrl=${hostUrl}`

      const result = await new Promise((resolve, reject) => {
        authentication
          .authenticate({
            url: authUrl,
            isExternal: false,
            height: '752px',
            width: '600px',
          })
          .then(resolve)
          .catch((reason) => {
            document.body.removeChild(overlay)
            reject(new Error(`Authentication failed: ${reason}`))
          })
      })

      const data = await getToken(result)

      if (data?.error) {
        throw new Error(data.error)
      }

      const tokenString = JSON.stringify({
        idToken: data.id_token,
        accessToken: data.access_token,
        tokenType: data.token_type,
        expiresIn: data.expires_in,
        issuedUtc: data.issuedUtc,
      })
      localStorage.setItem('pdf4me', tokenString)
      document.body.removeChild(overlay)
      dispatch({
        type: 'LOGIN',
        data: { isAuthenticated: true },
      })
    } catch (error) {
      dispatch(errorHandler(error))
      rejectWithValue(error.message)
    }
  }
)

export const isAlive = async (dispatch) => {
  // Try to authenticate using an existing token
  try {
    const token = localStorage.getItem('pdf4me')
    if (token) {
      // const res = await client.reAuthenticate();
      // if (res.accessToken) {
      //   return res.AspNetUsers;
      // }
    }
    return false
  } catch (error) {
    dispatch(errorHandler(error))
    return false
  }
}

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('pdf4me')
    dispatch({
      type: 'LOGOUT',
      data: { isAuthenticated: false },
    })
    return true
  } catch (error) {
    dispatch(errorHandler(error))
    return false
  }
}
