import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 17V83H33.4697V58.8945V51.8047V24.0898H50.1182C54.3694 24.0898 57.7805 24.6914 60.3516 25.8945C62.9227 27.0762 64.781 28.709 65.9265 30.793C67.0975 32.877 67.683 35.2402 67.683 37.8828C67.683 40.5254 67.0975 42.8994 65.9265 45.0049C64.781 47.0889 62.9354 48.7432 60.3898 49.9678C57.8442 51.1924 54.4712 51.8047 50.2709 51.8047H33.4697V58.8945H50.5764C56.6604 58.8945 61.6499 57.96 65.5447 56.0908C69.4395 54.2002 72.3161 51.665 74.1744 48.4854C76.0581 45.3057 77 41.7715 77 37.8828C77 33.9941 76.0581 30.4707 74.1744 27.3125C72.2906 24.1543 69.3886 21.6514 65.4683 19.8037C61.5735 17.9346 56.5586 17 50.4236 17H24Z" fill="currentColor" />
        <path d="M50.2709 49.8047H35.4697V26.0898H50.1182C54.2094 26.0898 57.2968 26.6732 59.5039 27.706L59.5039 27.7061L59.5164 27.7118C61.7742 28.7495 63.2718 30.1153 64.1739 31.7564L64.1784 31.7646L64.1829 31.7727C65.1686 33.5268 65.683 35.5464 65.683 37.8828C65.683 40.219 65.1686 42.2528 64.1786 44.0328L64.1739 44.0415C63.2645 45.6959 61.7654 47.0866 59.5228 48.1655C57.3468 49.2122 54.3049 49.8047 50.2709 49.8047ZM22 83V85H24H33.4697H35.4697V83V60.8945H50.5764C56.8456 60.8945 62.1581 59.9344 66.41 57.894L66.4181 57.89C70.6034 55.8584 73.8068 53.0755 75.8982 49.4995C77.9724 45.9962 79 42.1086 79 37.8828C79 33.655 77.9713 29.774 75.892 26.288C73.7712 22.7323 70.5388 19.9837 66.3272 17.9975C62.0746 15.9582 56.7377 15 50.4236 15H24H22V17V83Z" stroke="currentColor" strokeWidth="4" />
        <path d="M100 100V0L0 0V100H100Z" stroke="none" strokeWidth="2" />
      </svg>


    </div>
  )
}
