import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'

import { useForm } from '../../hooks/useForm'
import imageIcon from '../../controls/icons/image'
import emailIcon from '../../controls/icons/email'
import textIcon from '../../controls/icons/text'
import contactIcon from '../../controls/icons/contact'
import globeIcon from '../../controls/icons/globe'
import repeatIcon from '../../controls/icons/repeate'
import downloadIcon from '../../controls/icons/download'
import Button from '../../controls/Button'
import ButtonRow from '../../controls/ButtonRow'
import FormRow from '../../controls/FormRow'
import Icon from '../../controls/Icon'
import Loader from '../../controls/Loader'
import Checkbox from '../../controls/Checkbox'
import InputField from '../../controls/InputField'

import { device, media, colors, layout } from '../../theme'
import Popup from './Popup'
import barcodeActions from '../../store/barcode/actions'
import TabHeader from './Popup/TabHeader'
import SizeForm from './SizeForm'
import Dropdown from './Popup/DropDown'
import ToggleButton from './Popup/ToggleButton'
import t from './translate'
import { useJobConfig } from '../JobConfig/JobContext'

const ImgWrapper = styled.img`
  width: 120px;
  visibility: hidden;
  align-self: center;
  justify-self: center;
  &.visible {
    visibility: visible;
  }
`

const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  box-shadow: ${layout.elevate(2)};
  background-color: ${colors.bodyBackground};
`

const ImgAction = styled.div`
  z-index: 2;
  display: flex;
  position: relative;
  opacity: 1;
  background: #fff9 9;
  /* height: 120px; */
  /* width: 120px; */
  /* display: grid; */
  grid-column: 1fr;
  align-items: center;
  justify-items: start;
  left: -88px;
  top: 90px;
  column-gap: 5px;
  ${media.down(device.mobile)`
    left: -113px;
    `}
`

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  background: #fff99;
  height: 120px;
  width: 120px;
`

const BarcodeTypeWrapper = styled.div`
  // display: flex;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-column-gap: 20px;
  padding-bottom: 10px;
  ${media.down(device.mobile)`
  grid-template-columns: 0.5fr 1fr;
`}
`

const QRcodeSubType = styled.div`
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
  ${media.down(device.mobile)`
  display: none;
  &.visible {
    display: block;
  }
  `}
`

const DisplayContact = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  ${media.down(device.mobile)`
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
`}
`

const BarCodeTypeForm = styled.div`
  height: 485px;
  padding: 0 20px;
  ${media.down(device.mobile)`
  height:auto;
  `}
`

const BarcodeForms = styled.div``

const BarcodeTypeDescWrapper = styled.div`
  align-self: flex-start;
`

const FlexDiv = styled.div`
  display: flex;
`

const ColorBox = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(prop) => prop.color};
  border: 1px solid black;
`

const FullWidth = styled.div`
  width: 100%;
`

const SketchBarcodeColor = styled.div`
  position: absolute;
  z-index: 1;
  ${media.down(device.mobile)`
  right: 5px;
  `}
  .sketch-picker {
    > div:first-child {
      padding-bottom: 50% !important;
    }
    input {
      height: auto;
    }
  }
`

const SketchBackgroundColor = styled(SketchBarcodeColor)`
  right: 5px;
`
const loadingAnimation = keyframes`
  from {
    background-position: -200% 0;
  }
  to {
    background-position: 200% 0;
  }
`

export const SkeletonButton = styled.button`
  display: inline-flex;
  // position: relative;
  overflow: hidden;
  background-color: transparent;
  border: none;
  // padding: 0px 24px;
  border-radius: 4px;
  /* cursor: pointer; */
  transition: background-color 0.3s ease-in-out;
  border-radius: 6px;
  height: 44px;
  align-items: center;
  font-size: inherit;
  font-weight: 500;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
  letter-spacing: 0.02em;
  font-weight: bold;
  position: relative;
  left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    background-size: 200% 100%;
    animation: ${loadingAnimation} 1.5s infinite;
  }
`
const SkeletonButtonWrapper = styled.div`
  position: absolute;
  top: 120px;
  left: -25px;
`

const barCodeTypeList = [
  // { key: 'qrcode', value: 'QR code' },
  { key: 'qrcode', value: 'QR code', textLength: 2000 },
  { key: 'datamatrix', value: 'Datamatrix', textLength: 1500 },
  { key: 'code128', value: 'Code128', textLength: 20 },
  { key: 'code39', value: 'Code39', textLength: 20 },
  { key: 'aztec', value: 'Aztec', textLength: 2000 },
  { key: 'hanxin', value: 'HanXin', textLength: 500 },
  // { key: 'micropdf417', value: 'MicroPdf417' },
  // { key: 'microqrcode', value: 'MicroQRCode' },
  { key: 'pdf417', value: 'Pdf417', textLength: 150 },
]

const qrSubTypeList = [
  { key: 'text', value: 'Text', icon: textIcon },
  { key: 'contact', value: 'Contact', icon: contactIcon },
  { key: 'website', value: 'Website', icon: globeIcon },
  { key: 'email', value: 'Email', icon: emailIcon },
]

let addBarcodeTimeout = null

export default ({
  config,
  updateConfig,
  onClose,
  inputFiles,
  filesReady,
  pageSelectionType,
  customSelection,
}) => {
  //   const t = useTranslation()
  const { actionConfig, updateActionConfig } = useJobConfig()
  const [tab, setTab] = useState('barcodeType')
  // const [barcodeConfig, setBarcodeConfig] = useState({
  //   ...config,
  // })

  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const [maxContentWidth, setMaxContentWidth] = useState(0)

  const [barcodeText, setBarcodeText] = useState(config.barcodeText)
  const [pageNum, setPageNum] = useState(
    pageSelectionType === 'custom' ? (customSelection || '')[0] : 1
  )

  const setBarcodeTab = () => {
    setTab('barcodeType')
  }

  const setConfigTab = () => {
    setTab('config')
  }

  const generateBarcodeText = (settings) => {
    let _text = settings.text
    if (
      settings.barcodeType === 'qrcode' ||
      settings.barcodeType === 'qrcode2005'
    ) {
      if (settings.barcodeSubType === 'contact') {
        if (
          settings.firstName ||
          settings.lastName ||
          settings.street ||
          settings.city ||
          settings.state ||
          settings.zip ||
          settings.country ||
          settings.phone ||
          settings.contactEmail
        ) {
          _text = `BEGIN:VCARD\nVERSION:3.0`
          _text += `\nN:${settings.lastName};${settings.firstName}`
          _text += `\nFN:${settings.firstName} ${settings.lastName}`
          _text += `\nADR:;;${settings.street};${settings.city};${settings.state};${settings.zip};${settings.country}`
          _text += `\nTEL:CELL:${settings.phone}`
          _text += `\nEMAIL;WORK;INTERNET:${settings.contactEmail}`
          _text += '\nEND:VCARD'
        } else {
          _text = ''
        }
      }
      if (settings.barcodeSubType === 'website') {
        _text = settings.url
      }
      if (settings.barcodeSubType === 'email') {
        if (settings.email || settings.subject || settings.message) {
          _text = `MATMSG:TO:${settings.email};SUB:${settings.subject};BODY:${settings.message} ;;`
        } else {
          _text = ''
        }
      }
    }
    return _text
  }

  let getBarCode = null

  const validate = (form) => {
    const errors = {}
    const _text = generateBarcodeText(form)
    setBarcodeText(_text)
    if (_text && _text !== '') {
      if (addBarcodeTimeout !== null) {
        clearTimeout(addBarcodeTimeout)
      }
      addBarcodeTimeout = setTimeout(() => {
        if (getBarCode)
          getBarCode(
            form.barcodeType,
            form.barcodeColor,
            form.backgroundColor,
            _text,
            form.hideText
          )
      }, 2500)
    }
    return errors
  }

  const callback = (form) => {
    getBarCode = null
    const newConfig = {
      ...config,
      ...form,
      barcodeText,
      xalign: actionConfig.xalign,
      yalign: actionConfig.yalign,
      rotate: actionConfig.rotate,
      width: actionConfig.width,
      height: actionConfig.height,
      marginX: actionConfig.marginX,
      marginY: actionConfig.marginY,
      marginXcm: actionConfig.marginXcm,
      marginYcm: actionConfig.marginYcm,
      imgThumbnail: actionConfig.imgThumbnail,
      draggable: actionConfig.draggable,
    }

    if (actionConfig.xalign === 'center') {
      newConfig.marginX = 0
      newConfig.marginXcm = 0
    }
    if (actionConfig.xalign === 'right') {
      if (newConfig.marginX >= 0) newConfig.marginX *= -1
    }
    if (actionConfig.yalign === 'middle') {
      newConfig.marginY = 0
      newConfig.marginYcm = 0
    }
    if (actionConfig.yalign === 'top') {
      if (newConfig.marginY >= 0) newConfig.marginY *= -1
    }

    updateConfig(newConfig)
    onClose()
  }

  const {
    onChange,
    onSubmit,
    values,
    // errors,
    // isValid,
    // isPristine,
  } = useForm({
    initialValues: {
      ...actionConfig,
      // tab: 'barcodeType',
    },
    validate,
    callback,
  })

  const dispatch = useDispatch()

  const { barcodeLoading } = useSelector((state) => state.barcode)

  getBarCode = async (
    barcodeType,
    barcodeColor,
    backgroundColor,
    text,
    hideText
  ) => {
    if (!text || text === '') return
    const maxBarcodeLength = barCodeTypeList.find(
      (b) => b.key === barcodeType
    ).textLength
    if (text.length > maxBarcodeLength) return
    if (addBarcodeTimeout !== null) {
      clearTimeout(addBarcodeTimeout)
    }
    const data = await dispatch(
      barcodeActions.getBarcode(
        barcodeType,
        text,
        barcodeColor,
        backgroundColor,
        hideText
      )
    )

    const img = new Image()
    const _imgThumbnail = `data:image/png;base64,${data.barcodeImage}`
    img.onload = () => {
      updateActionConfig({
        ...actionConfig,
        barcodeType,
        imgThumbnail: _imgThumbnail,
        width: img.width / 2,
        height: img.height / 2,
      })
    }
    img.src = _imgThumbnail
  }

  const downloadImage = () => {
    const download = document.createElement('a')
    download.setAttribute('href', actionConfig.imgThumbnail)
    download.setAttribute('download', `${values.barcodeType}.png`)
    document.body.appendChild(download)
    download.click()
    window.setTimeout(() => {
      document.body.removeChild(download)
    }, 100)
  }

  const qrSubTypeMenu = qrSubTypeList.map((s) => {
    return {
      action: () => {
        const e = {
          target: {
            name: 'barcodeSubType',
            value: s.key,
            type: 'radio',
          },
        }
        onChange(e)
      },
      renderItem: () => (
        <>
          &nbsp; <Icon icon={s.icon} />
          &nbsp;&nbsp;
          {t(`title${s.value}`)}&nbsp;
        </>
      ),
    }
  })

  const padLeftZero = (n, padLength) => {
    const z = '0'
    n = n + ''
    return n.length >= padLength
      ? n
      : new Array(padLength - n.length + 1).join(z) + n
  }

  const onBarcodeColorChange = ({ hex, rgb }) => {
    // const { hex, rgb } = color
    const { a } = rgb
    const _hex =
      (hex === 'transparent' ? '#000000' : hex) +
      padLeftZero(parseInt(a * 255, 10).toString(16), 2)
    const e = {
      target: {
        name: 'barcodeColor',
        value: _hex,
        type: 'radio',
      },
    }
    onChange(e)
  }

  const onBackgroundColorChange = ({ hex, rgb }) => {
    // const { hex, rgb } = color
    const { a } = rgb
    const _hex =
      (hex === 'transparent' ? '#000000' : hex) +
      padLeftZero(parseInt(a * 255, 10).toString(16), 2)
    const e = {
      target: {
        name: 'backgroundColor',
        value: _hex,
        type: 'radio',
      },
    }
    onChange(e)
  }

  useEffect(() => {
    const updateSize = () => {
      const contentElem = document.getElementsByClassName('popup-content')[0]
      const { maxHeight } = contentElem.style
      const { width } = contentElem.getBoundingClientRect()
      let _maxHeight = parseInt(maxHeight.replace('px', ''), 10)
      if (device.tablet < width) {
        _maxHeight -= 20
      }
      setMaxContentHeight(_maxHeight)
      setMaxContentWidth(width)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  let selectForm = 'text'

  if (values.barcodeType === 'qrcode' || values.barcodeType === 'qrcode2005') {
    selectForm = values.barcodeSubType
  }

  const dispBarcodeSubType = values.barcodeSubType
    ? qrSubTypeList.find((f) => f.key === values.barcodeSubType)
    : {}

  const selectedBarcodeInfo = barCodeTypeList.find(
    (f) => f.key === values.barcodeType
  )
  const dispBarcodeType = values.barcodeType ? selectedBarcodeInfo.value : ''
  const [showSketch, setShowSketch] = useState('')

  const validLength = values.barcodeType
    ? barcodeText.length <= selectedBarcodeInfo.textLength
    : true

  const barcodeInvalid = barcodeText === '' || !validLength

  return (
    <Popup
      header={() => {
        return (
          <TabHeader
            items={[
              {
                id: 'barcodeType',
                name: t('BarCodeType'),
                action: setBarcodeTab,
              },
              {
                id: 'config',
                name: t('SizePosition'),
                action: setConfigTab,
              },
            ]}
            tab={tab}
          />
        )
      }}
      size={maxContentHeight > 550 ? 'lg' : 'md'}
      onClose={onClose}
      footer={() => {
        return (
          <ButtonRow center noWrap>
            <Button
              color="primary"
              id="btnBarcodeOK"
              onClick={onSubmit}
              disabled={barcodeInvalid}
            >
              OK
            </Button>
            <Button onClick={() => onClose()}>{t('btnCancel')}</Button>
          </ButtonRow>
        )
      }}
    >
      <form onSubmit={onSubmit}>
        {tab === 'barcodeType' && (
          <>
            <BarCodeTypeForm
              className="mbg"
              style={{ height: `${maxContentHeight}px` }}
            >
              {/* Select Barcode Type */}
              <BarcodeTypeWrapper>
                <FormRow className={['barcode_form_col']}>
                  <PreviewWrapper>
                    {actionConfig.imgThumbnail ? (
                      <ImgWrapper
                        src={actionConfig.imgThumbnail}
                        className={actionConfig.imgThumbnail ? 'visible' : ''}
                        alt=""
                      />
                    ) : (
                      <>
                        <Icon icon={imageIcon} />
                        &nbsp; {t('Preview')}
                      </>
                    )}
                  </PreviewWrapper>
                  {barcodeLoading ? (
                    <>
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                      <ImgAction>
                        <SkeletonButton>
                          <Icon icon={repeatIcon} />
                        </SkeletonButton>
                        <SkeletonButton>
                          <Icon icon={downloadIcon} />
                        </SkeletonButton>
                      </ImgAction>
                    </>
                  ) : (
                    <ImgAction
                      className={
                        barcodeLoading || !actionConfig.imgThumbnail
                          ? 'hide'
                          : ''
                      }
                    >
                      <Button
                        onClick={() => {
                          getBarCode(
                            values.barcodeType,
                            values.barcodeColor,
                            values.backgroundColor,
                            barcodeText,
                            values.hideText
                          )
                        }}
                        startIcon={repeatIcon}
                        variant="icon"
                        disabled={barcodeLoading}
                      >
                        {/* <b>{t('Refresh')}</b> */}
                      </Button>
                      <Button
                        onClick={() => {
                          downloadImage()
                        }}
                        startIcon={downloadIcon}
                        variant="icon"
                        disabled={!actionConfig?.imgThumbnail}
                      >
                        {/* <b>{t('Download')}</b> */}
                      </Button>
                    </ImgAction>
                  )}
                </FormRow>
                <BarcodeTypeDescWrapper>
                  <DisplayContact>
                    <FormRow label={t('lblBarcodeType')}>
                      <Dropdown
                        items={barCodeTypeList.map((s) => {
                          return {
                            action: () => {
                              const e = {
                                target: {
                                  name: 'barcodeType',
                                  value: s.key,
                                  type: 'radio',
                                },
                              }
                              onChange(e)
                            },
                            text: ` ${s.value} `,
                          }
                        })}
                        alignItems="left"
                      >
                        &nbsp; {dispBarcodeType}&nbsp;
                      </Dropdown>
                    </FormRow>
                    {values.barcodeType === 'code128' && (
                      <FormRow>
                        <Checkbox
                          id="chkHideText"
                          name="hideText"
                          onChange={onChange}
                          checked={values.hideText}
                          right
                        >
                          {t('lblHideText')}
                        </Checkbox>
                      </FormRow>
                    )}
                  </DisplayContact>
                  <DisplayContact>
                    <FormRow label={t('lblBarcodeColor')}>
                      <FullWidth>
                        <ToggleButton
                          onClick={() => {
                            if (showSketch !== 'barcodeColor')
                              setShowSketch('barcodeColor')
                            else setShowSketch('')
                          }}
                          pressed={showSketch === 'barcodeColor'}
                        >
                          <FlexDiv>
                            &nbsp;
                            <ColorBox color={values.barcodeColor} />
                            &nbsp;
                            {values.barcodeColor}
                          </FlexDiv>
                        </ToggleButton>

                        {showSketch === 'barcodeColor' && (
                          <SketchBarcodeColor
                            onBlur={() => {
                              setShowSketch('')
                            }}
                          >
                            <SketchPicker
                              color={values.barcodeColor}
                              onChangeComplete={onBarcodeColorChange}
                              width={230}
                            />
                          </SketchBarcodeColor>
                        )}
                      </FullWidth>
                    </FormRow>
                    <FormRow label={t('lblBackgroundColor')}>
                      <FullWidth>
                        <ToggleButton
                          onClick={() => {
                            if (showSketch !== 'backgroundColor')
                              setShowSketch('backgroundColor')
                            else setShowSketch('')
                          }}
                          pressed={showSketch === 'backgroundColor'}
                        >
                          <FlexDiv>
                            &nbsp;
                            <ColorBox color={values.backgroundColor} />
                            &nbsp;
                            {values.backgroundColor}
                          </FlexDiv>
                        </ToggleButton>

                        {showSketch === 'backgroundColor' && (
                          <SketchBackgroundColor
                            onBlur={() => {
                              setShowSketch('')
                            }}
                          >
                            <SketchPicker
                              color={values.backgroundColor}
                              onChangeComplete={onBackgroundColorChange}
                              width={230}
                            />
                          </SketchBackgroundColor>
                        )}
                      </FullWidth>
                    </FormRow>
                  </DisplayContact>
                </BarcodeTypeDescWrapper>
              </BarcodeTypeWrapper>

              {/* Select sub type when qr code is selected. if type selected is not QRcode, this remain hidden */}
              <QRcodeSubType
                className={
                  values.barcodeType === 'qrcode' ||
                  values.barcodeType === 'qrcode2005'
                    ? 'visible'
                    : ''
                }
              >
                <FormRow>
                  <Dropdown items={qrSubTypeMenu} alignItems="left">
                    <FlexDiv>
                      &nbsp; <Icon icon={dispBarcodeSubType.icon} />
                      &nbsp; &nbsp;
                      {t(`title${dispBarcodeSubType.value}`)}
                    </FlexDiv>
                  </Dropdown>
                </FormRow>
              </QRcodeSubType>
              {/* the form entering text or other parameters for barcode */}
              {!validLength && (
                <div className="formRow__errorMessage">
                  {t('lblMaxBarcodeTextLengthReached').replace(
                    '{0}',
                    barcodeText.length - selectedBarcodeInfo.textLength
                  )}
                </div>
              )}
              <BarcodeForms id="Forms">
                {selectForm === 'text' && (
                  <>
                    <InputField
                      inputType="multiline"
                      name="text"
                      label={t('lblText')}
                      value={values.text}
                      onChange={onChange}
                    />
                  </>
                )}

                {selectForm === 'contact' && (
                  <>
                    <DisplayContact>
                      <InputField
                        label={t('lblFirstName')}
                        inputType="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={onChange}
                      />
                      <InputField
                        inputType="text"
                        label={t('lblLastName')}
                        name="lastName"
                        value={values.lastName}
                        onChange={onChange}
                      />
                    </DisplayContact>
                    <DisplayContact>
                      <InputField
                        inputType="text"
                        label={t('lblPhone')}
                        name="phone"
                        value={values.phone}
                        onChange={onChange}
                      />
                      <InputField
                        inputType="email"
                        label={t('lblEmail')}
                        name="contactEmail"
                        value={values.contactEmail}
                        onChange={onChange}
                      />
                    </DisplayContact>
                    <div>
                      <InputField
                        inputType="text"
                        name="street"
                        label={t('lblStreet')}
                        value={values.street}
                        onChange={onChange}
                      />
                    </div>
                    <DisplayContact>
                      <InputField
                        inputType="text"
                        name="city"
                        label={t('lblCity')}
                        value={values.city}
                        onChange={onChange}
                      />
                      <InputField
                        inputType="text"
                        name="zip"
                        label={t('lblZip')}
                        value={values.zip}
                        onChange={onChange}
                      />
                    </DisplayContact>
                    <DisplayContact>
                      <InputField
                        inputType="text"
                        name="state"
                        label={t('lblState')}
                        value={values.state}
                        onChange={onChange}
                      />
                      <InputField
                        inputType="text"
                        name="country"
                        label={t('lblCountry')}
                        value={values.country}
                        onChange={onChange}
                      />
                    </DisplayContact>
                  </>
                )}

                {selectForm === 'website' && (
                  <>
                    <InputField
                      inputType="url"
                      name="url"
                      label={t('lblUrl')}
                      value={values.url}
                      onChange={onChange}
                    />
                  </>
                )}

                {selectForm === 'email' && (
                  <>
                    <InputField
                      inputType="email"
                      name="email"
                      label={t('lblEmail')}
                      value={values.email}
                      onChange={onChange}
                    />
                    <InputField
                      inputType="text"
                      name="subject"
                      label={t('lblSubject')}
                      value={values.subject}
                      onChange={onChange}
                    />
                    <InputField
                      inputType="multiline"
                      name="message"
                      label={t('lblMessage')}
                      value={values.message}
                      onChange={onChange}
                    />
                  </>
                )}
              </BarcodeForms>
            </BarCodeTypeForm>
          </>
        )}
        {tab === 'config' && (
          <SizeForm
            barcodeConfig={actionConfig}
            setBarcodeConfig={updateActionConfig}
            barcodeInvalid={barcodeInvalid}
            inputFiles={inputFiles}
            filesReady={filesReady}
            pageSelectionType={pageSelectionType}
            customSelection={customSelection}
            pageNum={pageNum}
            setPageNum={setPageNum}
            maxContentHeight={maxContentHeight}
            maxContentWidth={maxContentWidth}
          />
        )}
      </form>
    </Popup>
  )
}
