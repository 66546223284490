export const types = {
  CREATE_THUMBNAIL_STARTING: 'CREATE_THUMBNAIL_STARTING',
  CREATE_THUMBNAIL_STARTED: 'CREATE_THUMBNAIL_STARTED',
  CREATE_THUMBNAIL_PROGRESS: 'CREATE_THUMBNAIL_PROGRESS',
  CREATE_THUMBNAILS_PUSH_THUMBNAILS: 'CREATE_THUMBNAILS_PUSH_THUMBNAILS',
  CREATE_THUMBNAIL_COMPLETED: 'CREATE_THUMBNAIL_COMPLETED',
  CREATE_THUMBNAIL_FAILED: 'CREATE_THUMBNAIL_FAILED',
  RESET_ALL: 'RESET_ALL',
  DELETE_FILE: 'DELETE_FILE',
}

export default {
  starting: (doc) => ({
    type: types.CREATE_THUMBNAIL_STARTING,
    doc,
  }),
  started: (doc) => ({
    type: types.CREATE_THUMBNAIL_STARTED,
    doc,
  }),
  progress: (progress) => ({
    type: types.CREATE_THUMBNAIL_PROGRESS,
    progress,
  }),
  pushThumbnails: (pages) => ({
    type: types.CREATE_THUMBNAILS_PUSH_THUMBNAILS,
    pages,
  }),
  completed: (doc) => ({
    type: types.CREATE_THUMBNAIL_COMPLETED,
    doc,
  }),
  failed: (error) => ({
    type: types.CREATE_THUMBNAIL_FAILED,
    error,
  }),
}
