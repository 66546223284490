import styled from 'styled-components'

import { colors } from '../../theme'

const Tab = styled.li`
  margin: 0;

  padding: 0 16px;
  &:first-child {
    padding-left: 0;
  }

  &.selected {
    button {
      border-color: ${colors.secondary};
      color: ${colors.secondary};
    }
  }

  button {
    padding: 8px 4px 8px 4px;
    border-bottom: solid 4px transparent;
    cursor: pointer;
    color: inherit;
    font-weight: 500;
  }
`

export default function (props) {
  const { active, label, onTabClick, tabIndex } = props
  return (
    <Tab className={active ? 'selected' : ''}>
      <button
        type="button"
        onClick={() => {
          if (onTabClick) {
            onTabClick(tabIndex)
          }
        }}
      >
        {label}
      </button>
    </Tab>
  )
}
