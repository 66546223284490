import React from 'react'
import styled from 'styled-components'

import { media, device, colors } from '../../../../theme/viewer'
const Wrapper = styled.div`
  height: 40px;
  margin: 0 10px;
  width: 1px;
  align-self: center;
  background-color: ${colors.gray};
  ${media.down(device.tablet)`
    margin: 0 6px;
    height: 28px;
  `}

  ${media.down(device.mobile)`
    display: none;
  `}
`

const Separator = (props) => {
  const { children } = props
  return <Wrapper />
}

export default Separator
