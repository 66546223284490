import { useRef, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import { colors, layout } from '../../theme'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.001;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  &:hover:not(.open) {
    .tooltip {
      display: block;
      animation: ${fadeIn} 0.8s;
    }
  }
  &.open {
    .tooltip {
      display: block;
    }
  }
`

const ToolTip = styled.span`
  display: none;
  position: absolute;
  top: -12px;
  left: 50%;
  padding: 10px;
  color: ${colors.secondaryContrast};
  background-color: ${colors.grayDarker};
  transform: translateX(-50%) translateY(-100%);
  min-width: 140px;
  max-width: 220px;
  z-index: 10;
  text-align: center;
  font-size: 12px;
  box-shadow: ${layout.elevate(3)};
  border-radius: 6px;
  p {
    margin: 0;
    padding: 0;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;

    transform: translateX(-6px);

    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${colors.grayDarker};
  }
`

export default (props) => {
  const { open, children, content } = props
  const $elm = useRef()

  useEffect(() => {}, [])

  return (
    <Wrapper className={open ? 'open' : ''} ref={$elm}>
      <ToolTip className="tooltip">{content}</ToolTip>
      {children}
    </Wrapper>
  )
}
