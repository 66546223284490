import { api } from '../initFetch'
import { FetchError } from '../FetchError'

export const getDownloadUrl = async (files, asZip) => {
  try {
    const docBlobRefs = files.map((file) => {
      return {
        fileName: file.name,
        blobRefUrl: file.docBlobRef,
        storageType: file.storageType,
      }
    })

    const res = await api.post('/p4m/Document/GetDownloadUrl', {
      docBlobRefs,
      asZip: asZip ? true : false,
    })
    if (res.ok) {
      return res.json
    }
    throw new FetchError(res)
  } catch (error) {
    throw new FetchError(error.response)
  }
}

export const fileDownload = async (files, asZip) => {
  const res = await getDownloadUrl(files, asZip)
  const link = document.createElement('a')
  link.download = res.filename
  link.href = res.url
  // link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
