import { api } from '../initFetch'

export const downloadOneDriveFile = async (
  ItemId,
  Token,
  DriveId,
  TenantId
) => {
  const res = await api.post('/p4m/OneDrive/GetFileContent', {
    ItemId,
    Token,
    DriveId,
    TenantId,
  })
  return res.json
}
