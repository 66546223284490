import React, { useEffect, useMemo, useState } from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import allPagesIcon from '../../../../controls/icons/allPages'
import firstPageIcon from '../../../../controls/icons/firstPage'
import selectPagesIcon from '../../../../controls/icons/selectPages'
import { useJobConfig } from '../../../JobConfig/JobContext'
import BarcodePopup from '../../../BarcodePopup'
import {
  defaultMargin,
  defaultMarginCM,
} from '../../../BarcodePopup/Popup/common'
import PagePicker from '../../../../components/PagePicker'

import t from './translate'

const PdfToWordConfig = ({ onChange, form, inputFiles }) => {
  const { updateActionConfig, actionConfig, docIdExt } = useJobConfig()
  const [configure, setConfigure] = useState('')
  const [popupOpen, setPopupOpen] = useState(false)
  const [customSelection, setCustomSelection] = useState([])
  const [pageSelectionError, setPageSelectionError] = useState(undefined)
  const [pagePickerOpen, setPagePickerOpen] = useState(false)
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  useEffect(() => {
    const values = {
      barcodeType: 'qrcode',
      barcodeText: '',
      xalign: 'center',
      yalign: 'middle',
      width: '',
      height: '',
      rotate: 0,
      marginX: defaultMargin,
      marginY: defaultMargin,
      barcodeColor: '#000000ff',
      backgroundColor: '#ffffffff',
      hideText: true,

      imgThumbnail: '',
      barcodeSubType: 'text',
      text: '',
      firstName: '',
      lastName: '',
      phone: '',
      contactEmail: '',
      street: '',
      city: '',
      zip: '',
      country: '',
      url: '',
      email: '',
      subject: '',
      message: '',
      marginXcm: defaultMarginCM,
      marginYcm: defaultMarginCM,
      draggable: false,
      dragInitialized: false,
      pageSelectionType: 'all',
    }
    updateActionConfig(values)
    if (inputFiles.length > 0) {
      if (!popupOpen) {
        setPopupOpen(true)
      }
    }
    // setActionConfig((prev) => ({ ...prev, ...values }))
  }, [])

  return (
    <>
      <FormSection>
        <FormRow
          label={t('lblPageSequence')}
          hint={t(`pagesDesc_${form.pageSelectionType}`)}
          // errorMessage={pageSelectionError}
        >
          <ButtonGroup
            name="pageSelectionType"
            items={[
              {
                id: 'btnAllPages',
                value: 'all',
                label: t('allPages'),
                icon: allPagesIcon,
              },
              {
                id: 'btnFirstPage',
                value: 'first',
                label: t('firstPage'),
                icon: firstPageIcon,
              },
              {
                id: 'btnSelectPages',
                value: 'custom',
                label: t('customSelection'),
                icon: selectPagesIcon,
                disabled: !filesReady,
              },
            ]}
            type="button"
            value={form.pageSelectionType}
            onClick={(e) => {
              if (e.target.value === 'custom') {
                setPagePickerOpen(true)
              }
            }}
            onChange={onChange}
          />
        </FormRow>
        <FormRow>
          <ButtonGroup
            name="configure"
            items={[
              {
                id: 'btnConfigure',
                value: 'true',
                label: t('Configure'),
              },
            ]}
            value={configure}
            type="radio"
            onClick={() => {
              setPopupOpen(true)
            }}
            onChange={(e) => {
              setConfigure(e.target.value)
            }}
          />
        </FormRow>
      </FormSection>
      {popupOpen && (
        <BarcodePopup
          config={form}
          updateConfig={(updateValues) => {
            // setActionConfig(updateValues)
            updateActionConfig(updateValues)
          }}
          onClose={() => setPopupOpen(false)}
          inputFiles={inputFiles}
          filesReady={filesReady}
          pageSelectionType={form.pageSelectionType}
          customSelection={customSelection}
        />
      )}
      {pagePickerOpen && (
        <PagePicker
          selectedPages={form.customSelection || []}
          onSelect={(selection) => {
            setCustomSelection(selection.selectedPages)
            updateActionConfig({ customSelection: selection.selectedPages })
            setPagePickerOpen(false)
          }}
          inputFiles={inputFiles}
          onCancel={() => {
            setPagePickerOpen(false)
          }}
        />
      )}
    </>
  )
}

export default PdfToWordConfig
