import styled, { keyframes } from 'styled-components'

import Icon from '../../../controls/Icon'

import { colors, device, media, fontSize } from '../../../theme'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Btn = styled.button`
  position: relative;
  margin: 0;
  padding: 4px 1px;
  width: 50px;
  i {
    display: inline-block;
    margin: 8px 0;
  }
  i,
  svg {
    width: 26px;
    height: 20px;
  }
  span {
    display: block;
    font-size: ${fontSize.xs};
    color: ${colors.grayDark};
  }
  &.disabled {
    span {
      color: ${colors.gray};
    }
    svg {
      fill: ${colors.gray};
    }
  }

  ${media.down(device.tablet)`
    width: 40px;
    display: flex;
    justify-content: center;
    

    span {
      position: absolute;
      width: 110px;
      padding: 0.5rem;
      box-shadow: 0 0 12px rgba(0,0,0,.25);
      display: none;
      font-size: ${fontSize.sm};
      top: 48px;
      left: -30px;
      color: ${colors.grayDarkerContrast};
      background-color: ${colors.grayDarker};
      border-radius: 6px;
      &:before {
        content: '';
        position: absolute;
        left: 46px;
        top: -10px;
        height: 0;
        border-bottom: solid 10px ${colors.grayDarker};
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
    }
    @media (hover: hover) {
      &:hover {
        span {
          display: block;
          animation: ${fadeIn} 1s;
        }
      }
    }

  `}

  ${media.down(device.mobile)`
  
    &.sm-hide {
      display: none;
    }
  `}
`

export const IconButton = ({
  disabled,
  hideOnMobile,
  icon,
  onClick,
  children,
  id,
  toolTip,
}) => {
  const classNames = []
  if (disabled) {
    classNames.push('disabled')
  }
  if (hideOnMobile) {
    classNames.push('sm-hide')
  }
  return (
    <Btn
      id={id}
      type="button"
      className={classNames.join(' ')}
      onClick={() => {
        if (onClick && !disabled) {
          onClick()
        }
      }}
      title={toolTip}
    >
      <Icon icon={icon} />
      <span>{children}</span>
    </Btn>
  )
}
