const t = (key) => {
  const translate = {
    i6Text:
      "Now modify or update your PDF metadata over any mobile devices. PDF4me's fluid UI runs smoothly on any mobile browsers making it easy to do your document jobs over almost all mobile devices. Now update your PDF permissions even while you travel.",
    btnStartJob: 'Modify Metadata',
    lblEditDetails: 'View / Edit Properties',
    tabDescription: 'Description',
    tabSecurity: 'Security',
    tabAdvanced: 'Advanced',
    lblTitle: 'Title',
    lblAuthor: 'Author',
    lblSubject: 'Subject',
    lblKeywords: 'Keywords',
    lblPermissions: 'Permissions',
    lblAllowAssembly: 'Document Assembly',
    lblAllowCopy: 'Content Copying',
    lblAllowFillInForms: 'Filling of form fields',
    lblAllowAnnotations: 'Annotations / Commenting',
    lblAllowModifyContents: 'Changing the Document',
    lblAllowPrint: 'Printing',
    lblAllowScreenReaders: 'Content Copying for Accessibility',
    lblApplication: 'Application',
    lblPdfProducer: 'Producer',
    lblPdfVersion: 'PDF Version',
    lblCreationDate: 'Created',
    lblModDate: 'Modified',
    lblFileSize: 'File Size',
    lblPages: 'Page Count',
    lblFastWebView: 'Fast Web View',
    lblCreator: 'Creator',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
