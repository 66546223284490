const t = (key) => {
  const translate = {
    leadText:
      'Generate high-quality JPG or PNG images from PDF documents. Choose the desired size while you convert PDF to JPG or PNG.',
    errWidthRequired: 'Required',
    errWidthNaN: 'number',
    errMinWidth: 'min 100 pixel',
    errMaxWidth: 'max 2000 pixel',
    lblImgFormat: 'Image format',
    imgJPG: 'JPG',
    imgPNG: 'PNG',
    lblWidth: 'Max width or height',
    lblPageSequence: 'Pages',
    btnStartJob: 'Start creating images',
    btnMoreOptions: 'Options',
    allPages: 'All pages',
    firstPage: 'First page',
    customSelection: 'Select pages',
    changeSelection: 'Change Selection',
    onePageSelected: 'one of {totalPages} Pages selected',
    pagesSelected: '{pagesSelected} of {totalPages} Pages selected',
    errNoPagesSelected: 'At least one page must be selected',
    titleSelectPages: 'Select Pages',
    btnSelect: 'Select',
    titleOnePageSelected: 'Page selected',
    titleMultiplePagesSelected: 'Pages selected',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
