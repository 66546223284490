/* eslint-disable import/no-anonymous-default-export */
const token = localStorage.getItem("pdf4me")
const isAuthenticated = token ? true : false
export default (state = {
  isAuthenticated
}, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        ...action.data
      };
    case "LOGOUT":
      return {};

    default:
      return state;
  }
};

