export const types = {
  HUB_CONNECTING: 'HUB_CONNECTING',
  HUB_CLOSING: 'HUB_CLOSING',
  HUB_CONNECTED: 'HUB_CONNECTED',
  HUB_ERROR: 'HUB_ERROR',
  HUB_CLOSED: 'HUB_CLOSED',
  HUB_START: 'HUB_START',
  HUB_STOP: 'HUB_STOP',
}

const mutations = {
  setConnecting: () => ({
    type: types.HUB_CONNECTING,
  }),
  setClosing: () => ({
    type: types.HUB_CLOSING,
  }),
  setConnected: (connectionId) => ({
    type: types.HUB_CONNECTED,
    connectionId,
  }),
  setError: (error) => ({
    type: types.HUB_ERROR,
    error,
  }),
  setClosed: (error) => ({
    type: types.HUB_CLOSED,
    error,
  }),
}

export default mutations
