import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Icon from '../../../controls/Icon'
import caretDownIcon from '../../../controls/icons/caretDown'
import NavLink from '../../../controls/NavLink'
import { colors, device, media, layout } from '../../../theme'

const Root = styled.div`
  background-color: ${colors.bodyBackground};
  &.dropdown-disabled {
    opacity: 0.7;
  }
  min-width: 50px;
`

const ToggleButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
  justify-content: space-between;
  .dropdown-caret {
    i {
      margin-left: 2px;
      width: 12px;
      height: 12px;
      svg {
        width: 12px;
        height: 12px;
        fill: ${colors.gray};
        .dropdown-open & {
          fill: ${colors.grayDark};
        }
      }
    }
  }
`

const MenuRoot = styled.div`
  position: relative;
  height: 0;
`

const Menu = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  right: 0;
  min-width: 180px;
  padding: 0.5rem;
  background-color: ${colors.bodyBackground};
  box-shadow: ${layout.elevate(3)};
  z-index: 4;
  .dropdown-open & {
    display: block;
  }
  .dropdown-align-left & {
    left: 0;
    right: auto;
  }
  .dropdown-align-center & {
    text-align: center;
    right: 50%;
    transform: translateX(50%);
  }
  .dropdown-align-right & {
    text-align: right;
    // left: 50%;
    transform: translateX(50%);
  }
  ${media.down(device.mobile)`
  min-width: auto;
`}
`

const Items = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    margin: 0;
    padding: 0;
    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray};
    }
    > * {
      display: flex;
      align-items: center;
      padding: 13px 0;
      margin: 0 !important;
      white-space: nowrap;
      i,
      svg {
        width: 22px;
        height: 18px;
      }
      // i {
      //   margin-right: 0.5rem;
      // }
      a {
        display: flex;
        align-items: center;
      }
    }
    &.dropdown-item-disabled {
      opacity: 0.5;
    }
  }
`

export default ({
  id,
  items,
  children,
  disabled,
  alignItems,
  hideCaret,
  defaultClose = true,
  // setClose = false,
}) => {
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
    document.removeEventListener('click', closeMenu)
  }
  useEffect(() => {
    return () => {
      document.removeEventListener('click', closeMenu)
    }
  }, [])
  const toggleMenu = () => {
    if (disabled) {
      return
    }
    const addCloseListener = !open
    setOpen(!open)
    if (addCloseListener && defaultClose) {
      setTimeout(() => {
        document.addEventListener('click', closeMenu)
      }, 10)
    } else {
      document.removeEventListener('click', closeMenu)
    }
  }
  // useEffect(() => {
  //   return () => {
  //     if (setClose) {
  //       closeMenu()
  //     }
  //   }
  // })

  const classNames = ['dropdown']
  if (disabled) {
    classNames.push('dropdown-disabled')
  }
  if (open) {
    classNames.push('dropdown-open')
  }
  if (alignItems === 'center') {
    classNames.push('dropdown-align-center')
  }
  if (alignItems === 'left') {
    classNames.push('dropdown-align-left')
  }
  if (alignItems === 'right') {
    classNames.push('dropdown-align-right')
  }

  return (
    <Root className={classNames.join(' ')}>
      <ToggleButton
        id={id}
        className="dropdown-toggle"
        type="button"
        onClick={toggleMenu}
      >
        {children}
        {!hideCaret && (
          <span className="dropdown-caret">
            <Icon icon={caretDownIcon} />
          </span>
        )}
      </ToggleButton>
      <MenuRoot>
        <Menu className="dropdown-menu">
          {items && (
            <Items>
              {items.map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
            </Items>
          )}
        </Menu>
      </MenuRoot>
    </Root>
  )
}

const MenuItem = ({ item }) => {
  if (!item.disabled && item.href) {
    return (
      <li>
        <NavLink id={item.id} href={item.href}>
          {item.renderItem && item.renderItem()}
          {item.icon && <Icon icon={item.icon} />}
          {item.text}
        </NavLink>
      </li>
    )
  }
  if (!item.disabled && item.action) {
    return (
      <li>
        <button id={item.id} type="button" onClick={item.action}>
          {item.renderItem && item.renderItem()}
          {item.icon && <Icon icon={item.icon} />}
          {item.text}
        </button>
      </li>
    )
  }

  const classNames = []
  if (item.disabled) {
    classNames.push('dropdown-item-disabled')
  }

  return (
    <li className={classNames.join(' ')}>
      <div>
        {item.renderItem && item.renderItem()}
        {item.icon && <Icon icon={item.icon} />}
        {item.text}
      </div>
    </li>
  )
}
