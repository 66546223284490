import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '../../lib/config'

const pdf4me = localStorage.getItem('pdf4me') ? JSON.parse(localStorage.getItem('pdf4me')) : {}

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers) => {
    if (pdf4me.tokenType && pdf4me.accessToken) {
      // headers.set("Content-Type", "application/json");
      headers.set('Authorization', `${pdf4me.tokenType} ${pdf4me.accessToken}`)
    }
    return headers
  },
})

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ['pdf4me'],
  endpoints: (builder) => ({}),
})
