import styled from 'styled-components'

import Button from '../../controls/Button'
import Icon from '../../controls/Icon'
import Dropdown from '../../controls/Dropdown'
import okIcon from '../../controls/icons/ok'
import rotateLeftIcon from '../../controls/icons/rotateLeft'
import rotateRightIcon from '../../controls/icons/rotateRight'
import trashIcon from '../../controls/icons/trash'
import trashUndoIcon from '../../controls/icons/trashUndo'
import ellipsisIcon from '../../controls/icons/ellipsis'
import startIcon from '../../controls/icons/start'

import { colors, device, media } from '../../theme'
import { IconButton } from './IconButton'
import t from './translate'

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  user-select: none;
  color: ${colors.text};
  ${media.down(device.tablet)`
    align-items: center;
  `}
`

const Separator = styled.div`
  height: 40px;
  margin: 0 10px;
  width: 1px;
  align-self: center;
  background-color: ${colors.gray};
  ${media.down(device.tablet)`
    margin: 0 6px;
    height: 20px;
  `}

  ${media.down(device.mobile)`
    display: none;
  `}
`
const NoWrap = styled.span`
  white-space: 'nowrap';
`

export default ({
  listStatus,
  showDeletedPages,
  onToggleShowDeletedPages,
  screenSize,
  onSelectAll,
  onClearSelection,
  onToggleSelection,
  onDeletePage,
  onUndoDeletePage,
  onRotatePage,
  onStart,
  onCancel,
}) => {
  let btnSize
  if (screenSize === 'tablet') {
    btnSize = 'sm'
  } else if (screenSize === 'mobile') {
    btnSize = 'xs'
  }

  let menuItems = []
  if (screenSize === 'mobile') {
    menuItems = [
      {
        renderItem: () => (
          <>
            {t('btnShowDeletedPages')}
            &nbsp;&nbsp;&nbsp;
            {showDeletedPages && <Icon icon={okIcon} />}
          </>
        ),
        action: onToggleShowDeletedPages,
      },
      {
        text: t('btnInvertSelection'),
        disabled: (listStatus?.totalSelected || 0) < 1,
        action: onToggleSelection,
      },
      {
        text: t('btnCancel'),
        action: onCancel,
      },
    ]
  } else {
    menuItems = [
      {
        text: t('btnSelectAll'),
        action: onSelectAll,
      },
      {
        text: t('btnClearSelection'),
        disabled: (listStatus?.totalSelected || 0) < 1,
        action: onClearSelection,
      },
      {
        text: t('btnInvertSelection'),
        disabled: (listStatus?.totalSelected || 0) < 1,
        action: onToggleSelection,
      },
      {
        renderItem: () => (
          <NoWrap>
            {t('btnShowDeletedPages')}
            &nbsp;&nbsp;&nbsp;
            {showDeletedPages && <Icon icon={okIcon} />}
          </NoWrap>
        ),
        action: onToggleShowDeletedPages,
      },
      {
        text: t('btnCancel'),
        action: onCancel,
      },
    ]
  }

  return (
    <>
      <Root>
        <IconButton
          icon={rotateLeftIcon}
          disabled={listStatus?.selected < 1}
          onClick={() => {
            onRotatePage(undefined, -90)
          }}
        >
          {t('btnRotateLeft')}
        </IconButton>
        <IconButton
          icon={rotateRightIcon}
          disabled={listStatus?.selected < 1}
          onClick={() => {
            onRotatePage(undefined, 90)
          }}
        >
          {t('btnRotateRight')}
        </IconButton>
        <Separator />
        <IconButton
          id="btnDeletePages"
          icon={trashIcon}
          disabled={listStatus?.selected < 1}
          onClick={() => {
            onDeletePage()
          }}
        >
          {t('btnDeletePages')}
        </IconButton>
        {showDeletedPages && (
          <IconButton
            icon={trashUndoIcon}
            disabled={listStatus?.deletedPagesSelected < 1}
            onClick={() => {
              onUndoDeletePage()
            }}
          >
            {t('btnRestorePages')}
          </IconButton>
        )}
        <Separator />
        <Dropdown hideCaret items={menuItems} alignItems="right">
          <Icon icon={ellipsisIcon} />
        </Dropdown>
      </Root>
      <Button
        id="btnStartOrganize"
        color="primary"
        size={btnSize}
        disabled={listStatus?.pageCount < 1}
        onClick={onStart}
        icon={screenSize !== 'mobile' ? startIcon : undefined}
      >
        Start
      </Button>
    </>
  )
}
