import React from 'react'
import styled from 'styled-components'

import { colors, device, media, layout } from '../../theme'
import ResultPreview from './ResultPreview'
import ResultActions from './ResultActions'

const Layout = styled.div`
  ${media.up(device.tablet)`
    display: grid;
    grid-template-columns: 1fr 380px;
  `}
  height: calc(100vh - ${layout.headerHeight});
  ${media.down(device.mobile)`
    height: calc(100vh - ${layout.mobileHeaderHeight});
  `}
`

const ActionsWrapper = styled.div`
  ${media.up(device.tablet)`
    flex-basis: 380px;
    order: 2;
    max-height: 100%;
    overflow: auto;
    border-left: solid 1px ${colors.bgLight};
  `}
`

const PreviewWrapper = styled.div`
  background-color: ${colors.bgLighter};
  ${media.up(device.tablet)`
    flex-grow: 1;
    order: 1;
    max-height: 100%;
    overflow: auto;
  `}
`
const JobResult = () => {
  return (
    <Layout>
      <ActionsWrapper>
        <ResultActions />
      </ActionsWrapper>
      <PreviewWrapper id="pdfPreview">
        <ResultPreview />
      </PreviewWrapper>
    </Layout>
  )
}

export default JobResult
