import React from 'react'
import styled from 'styled-components'
import {colors} from '../../theme'
const Progress = styled.div`
  // position: absolute;
  // bottom: 2px;
  // left: 100px;
  // right: 4px;
  // height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: #afbfe1;

  div {
    height: 4px;
    border-radius: 3px;
    background-color: ${colors.secondary};
  }
`
const ProgressBar = ({progress, className}) => {
    return (
        <Progress className={className}>
            <div style={{ width: `${progress * 100}%` }} />
        </Progress>
    )
}

export default ProgressBar