const defaultOptions = {
  pageShadow: {
    color: 'rgba(0,0,0,0.07)',
    blur: 10,
    offsetX: 0,
    offsetY: 0,
  },
  backgroundColors: [
    'transparent',
    '#FFFFFF',
    '#FCF5E2',
    '#323232',
    '#FFEA02',
    '#D82F32',
    '#0066CC',
  ],
  strokeColors: ['#323232', '#FFFFFF', '#FFEA02', '#2ADB1A', '#0066CC', '#D82F32'],
  fillColors: ['transparent', '#FFFFFF', '#FCF5E2', '#323232', '#FFEA02', '#D82F32', '#0066CC'],
  textSelectionColor: '#0099cc',
  defaultStrokeColor: '#D82F32',
  strokeWidths: [0, 1, 2, 3, 5, 8, 13, 21],
  defaultStrokeWidth: 2,
  defaultFillColor: 'transparent',
}

export default defaultOptions
