const t = (key) => {
  const translate = {
    lblOptimize: 'Optimize for',
    optimizeNone: 'None',
    optimizeWeb: 'Web',
    optimizePrint: 'Print',
    lblMerge: 'Merge PDF output files into single PDF',
    btnStartJob: 'Start Convert',
    btnMoreOptions: 'Options',
    optimizeDesc_: 'No compression - Best quality',
    optimizeDesc_print: 'Compressed for Prints - Reduced Quality',
    optimizeDesc_web: 'Compressed to display in Web pages',
    optimizeSize: 'Size',
    optimizeDesc_max: 'Maximum compression - Small size (May affect quality)',
    lblOcr: 'Use OCR to recognize text in Images',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
