const t = (key) => {
  const translate = {
    lblOptimize: 'Optimize for',
    optimizeNone: 'None',
    optimizeWeb: 'Web',
    optimizePrint: 'Print',
    btnStartJob: 'Start merge',
    btnMoreOptions: 'Options',
    optimizeDesc_: 'No compression - Best quality PDF',
    optimizeDesc_print: 'Compressed PDFs for Prints - Reduced Quality',
    optimizeDesc_web: 'Linearized PDFs to display in Web pages',
    optimizeSize: 'Size',
    optimizeDesc_max:
      'Maximum compression - Small PDF size (May affect quality)',
    twoOrMoreFileRequired: 'Two or more files required.',
    lblOcr: 'Use OCR to recognize text in Images',
    s_toolName: 'Merge PDF',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
