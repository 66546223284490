import { useContext, useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WebViewerOptions, PdfViewerCanvas } from '@pdf-tools/four-heights-pdf-web-viewer'

import { downloadFile, getSharedDocument } from '../services/document/fileDownload'
import { fileUpload } from '../services/document/fileUpload'
import { createId } from '../utils/createId'
import saveToOneDrive from '../utils/saveToOneDrive'
import config from '../../../lib/config'

let pdfViewer = undefined

const PdfViewerContext = createContext()

function getDocumentFromUrlParameters() {
  const urlParams = new URLSearchParams(window.location.search)

  urlParams.forEach((value, key) => {
  
  })
  let doc = undefined
  // const sharedDocumentParams = urlParams.get('fileurl')
  // if (sharedDocumentParams) {
  //   doc = {
  //     type: 'shared',
  //     path: window.atob(sharedDocumentParams),
  //     expiration: new Date(urlParams.get('e')),
  //     filename: urlParams.get('n'),
  //     size: urlParams.get('s'),
  //   }
  // } else {
  const url = urlParams.get('fileurl')
  if (url) {
    doc = {
      type: 'onedrive',
      filename: urlParams.get('name'),
      url,
    }
  }

  window._doc = doc
  return doc
}

export const PdfViewerProvider = (props) => {
  const { children } = props

  // state hooks
  const [actions, setActions] = useState({})
  const [viewerCreated, setViewerCreated] = useState(false)
  const [viewerLoaded, setViewerLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [passwordReqired, setPasswordReqired] = useState(false)
  const [fileBlob, setFileBlob] = useState(false)
  const [documentOutline, setDocumentOutline] = useState()
  const [showAnnotationbar, setShowAnnotationbar] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [zoom, setZoom] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [viewerError, setViewerError] = useState(0)
  const [filename, setFilename] = useState()
  const [modules, setModules] = useState([])

  const handleResize = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  // actions
  const createPdfViewer = (viewerElement, options) => {
    if (viewerCreated) {
      throw new Error('PdfViewer is allready created')
    }
    const appLoaded = async () => {
      setViewerLoaded(true)

      setModules(pdfViewer.options.options.modules)

      const fileInfo = getDocumentFromUrlParameters()
      if (!fileInfo) {
        setViewerError('Invalid file')
        return
      } else if (fileInfo.type === 'shared' && fileInfo.expiration < new Date()) {
        setViewerError('The requested document has expired.')
        return
      }

      // loading file

      let fileBlob = undefined
      try {
 
        const resFile = await fetch(fileInfo.url)
   
        fileBlob = await resFile.blob()

        // if (fileInfo.type === 'storage') {
        //   fileBlob = await downloadFile(fileInfo.url, fileInfo.storageType)
        // } else if (fileInfo.type === 'shared') {
        //   fileBlob = await getSharedDocument(fileInfo)
        // }
        if (!fileBlob) {
          throw new Error()
        }
        fileBlob.name = 'test.pdf'

        setFilename(fileBlob.name)
      } catch (err) {
    
        setViewerError('Failed to load pdf document')
        return
      }

      const actions = {
        nextPage: () => {
          pdfViewer.nextPage()
        },
        previousPage: () => {
          pdfViewer.previousPage()
        },
        zoomIn: () => {
          pdfViewer.zoomIn()
        },
        zoomOut: () => {
          pdfViewer.zoomOut()
        },
        setZoom: (zoom) => {
          pdfViewer.setZoom(zoom)
        },
        setFitMode: (fitMode) => {
          pdfViewer.setFitMode(fitMode)
        },
        goTo: (destination) => {
          pdfViewer.goTo(destination)
        },
        addImage: (image) => {
          pdfViewer.modules.forEach((module) => {
            if (module.name === 'ImageAnnotationModule') {
              module.createImage(image)
            }
          })
        },
        addFreeTextAnnotation: () => {
          pdfViewer.modules.forEach((module) => {
            if (module.name === 'FreetextAnnotationModule') {
              pdfViewer.activateModule('FreetextAnnotationModule')
            }
          })
        },
        openFile: async (blob, password) => {
          try {
            await pdfViewer.openBlob(blob, password)
            //pdfViewer.pdfViewerApi.setBorderSize(1)
            const pageCount = pdfViewer.getPageCount()
            setPageCount(pageCount)

            const zoom = pdfViewer.getZoom()
            setZoom(zoom)

            setPasswordReqired(false)
            setFileBlob(null)
            setInitialized(true)
            setFilename(blob.name)
            return true
          } catch (err) {
     
            if (err.message === 'password required') {
              setPasswordReqired(true)
              setFileBlob(blob)
              return false
            }
            setViewerError('Failed to load pdf document')
            return false
          }
        },
        save: async () => {
          const data = await pdfViewer.saveFile(false)
          const newBlob = new Blob([data], {
            type: 'application/pdf',
            name: fileInfo.filename,
          })
          const dataUrl = window.URL.createObjectURL(newBlob)
          const link = document.createElement('a')
          link.href = dataUrl
          link.download = fileInfo.filename
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(dataUrl)
        },
        saveFile: async () => {
          const data = await pdfViewer.saveFile(false)
          const fileBlob = new Blob([data], {
            type: 'application/pdf',
            name: fileInfo.filename,
          })
          return fileBlob
        },
        print: async () => {
          const data = await pdfViewer.saveFile(false)
          const printFileBlob = new Blob([data], {
            type: 'application/pdf',
            name: fileInfo.filename,
          })
          const dataUrl = window.URL.createObjectURL(printFileBlob)

          // Open the PDF in a new window or tab
          const newWindow = window.open(
            dataUrl,
            fileInfo.filename,
            'height=800,width=600'
          )

          if (newWindow) {
            newWindow.focus()
            newWindow.print()

            // Check if the window is closed and clean up
            const intervalId = setInterval(() => {
              if (newWindow.closed) {
                clearInterval(intervalId)
                window.URL.revokeObjectURL(dataUrl)
              }
            }, 1000) // Check every second
          } else {
            console.error('Failed to open new window for printing.')
            window.URL.revokeObjectURL(dataUrl)
          }
        },
        saveToOneDrive: async () => {
          //console.log('saveToOneDrive')

          const data = await pdfViewer.saveFile(false)
          const dataFile = new Blob([data], {
            type: 'application/pdf',
          })
          dataFile.name = fileInfo.filename
    
          const fileId = createId()
          const connectionId = createId()
          const uploadRes = await fileUpload(
            'cdoc',
            dataFile,
            fileId,
            'pdfEditor',
            connectionId,
            () => {
              return {}
            }
          )
          uploadRes[0].name = fileInfo.filename
       

          const saveOneDriveRes = await saveToOneDrive(uploadRes, {})
        },
        toggleAnnotationBar: () => {
          // setShowAnnotationbar(!showAnnotationbar)
        },
        activateModule: async (moduleName) => {
   
          pdfViewer.activateModule(moduleName)
        },
      }

      const annotationBar = document.querySelector('.pwv-annotationbar')
      if (annotationBar) {
        annotationBar.addEventListener('click', actions.toggleAnnotationBar)

        const toolPicker = document.querySelector('.pwv-annotationbar-tool-picker')

        if (toolPicker) {
          let _submenuEventAttached = false
          toolPicker.addEventListener('click', (e) => {
            const target = e.currentTarget
            if (target.classList.contains('pwv-dropdown-open')) {
              if (_submenuEventAttached) {
                actions.toggleAnnotationBar()
              } else {
                e.stopPropagation()
              }
            }

            const buttons = target.querySelectorAll('button.pwv-commandbar-btn')

            ;[].forEach.call(buttons, function (btn) {
              _submenuEventAttached = true
              btn.addEventListener('click', actions.toggleAnnotationBar, {
                once: true,
              })
            })
          })
        }
      }

      await actions.openFile(fileBlob)
      setActions(actions)

      const documentOutlines = await pdfViewer.getDocumentOutline()
      if (documentOutlines.length > 0) {
        setDocumentOutline(documentOutlines)
      }
      // pdfViewer.pdfViewerApi.setBorderSize(1)
    }

    window.addEventListener('resize', handleResize)

    const viewerOptions = new WebViewerOptions(options)
    pdfViewer = new PdfViewerCanvas(viewerElement, config.VIEWER_KEY, viewerOptions)
    pdfViewer.addEventListener('appLoaded', appLoaded)
    pdfViewer.addEventListener('zoom', setZoom)
    pdfViewer.addEventListener('firstVisiblePage', setCurrentPage)
    pdfViewer.addEventListener('error', (err) => {
      setViewerError(err.toString())
    })
    setViewerCreated(true)
  }

  const state = {
    viewerLoaded,
    initialized,
    pageCount,
    zoom,
    currentPage,
    viewerError,
    documentOutline,
    showAnnotationbar,
    passwordReqired,
    fileBlob,
    modules,
  }

  const values = [state, actions, createPdfViewer]

  return <PdfViewerContext.Provider value={values}>{children}</PdfViewerContext.Provider>
}

export default PdfViewerContext
