export const formatDateTime = function (dateTimeString) {
  if (typeof dateTimeString !== 'number' && isNaN(Date.parse(dateTimeString))) {
    return ''
  }
  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}
export const formatShortDateTime = function (dateTimeString) {
  if (typeof dateTimeString !== 'number' && isNaN(Date.parse(dateTimeString))) {
    return ''
  }
  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}
export const formatDate = function (dateTimeString) {
  if (typeof dateTimeString !== 'number' && isNaN(Date.parse(dateTimeString))) {
    return ''
  }

  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}
export const formatShortDate = function (dateTimeString) {
  if (typeof dateTimeString !== 'number' && isNaN(Date.parse(dateTimeString))) {
    return ''
  }

  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}
export const formatTimeFromNow = function (dateTimeString) {
  if (typeof dateTimeString !== 'number' && isNaN(Date.parse(dateTimeString))) {
    return ''
  }
  const date = new Date(dateTimeString)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}
export const formatTimeDiff = function (from, to) {
  const f = typeof from === 'number' ? from : new Date(from).getTime()
  const t = typeof to === 'number' ? to : new Date(to).getTime()

  const diffMs = t - f
  const seconds = Math.floor(diffMs / 1000)

  let formattedTime = ''
  if (seconds < 1) {
    formattedTime = `${diffMs}ms`
  } else if (seconds < 60) {
    formattedTime = `${seconds}s`
  } else {
    const s = seconds % 60
    formattedTime = `${s}s`
    let minutes = Math.floor((seconds - s) / 60)
    if (minutes < 60) {
      formattedTime = `${minutes}m ${formattedTime}`
    } else {
      minutes %= 60
      const hours = Math.floor(seconds / 3600)
      formattedTime = `${hours}h ${minutes}m ${formattedTime}`
    }
  }
  return formattedTime
}
export const formatByteSize = function (n) {
  const size = parseInt(n, 10)
  if (size < 1024) {
    return `${size} bytes`
  }
  const kbSize = size / 1024
  if (kbSize < 1024) {
    return `${kbSize.toFixed(1)} kb`
  }
  const mbSize = kbSize / 1024
  if (mbSize < 1024) {
    return `${mbSize.toFixed(1)} mb`
  }
  const gbSize = mbSize / 1024
  return `${gbSize.toFixed(1)} gb`
}
export const formatCurrency = function (n, currency, digits = 2) {
  const value = parseFloat(n, 10)
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
}
export const formatDecimal = function (n, digits = 2) {
  const value = parseFloat(n, 10)
  return new Intl.NumberFormat(navigator.language, {
    style: 'decimal',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
}
export const formatPercent = function (n, digits = 2) {
  const value = parseFloat(n, 10)
  return new Intl.NumberFormat(navigator.language, {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
}
