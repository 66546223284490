import React, { useState, useContext } from 'react'

import {
  DocumentList,
  DocumentListHeader,
  DocumentList_list,
  Item,
  List,
  Section,
  Sidebar,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadCrumbNav,
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { withMessageBox } from '../../controls/MessageBox'

import { fetchItemsForPath } from '../../store/drive/oneDriveListItem'
import ToggleMenu from '../../components/ToggleMenu'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import JobConfig from '../../components/JobConfig'
import Table from '../../components/Table'
import OneDriveFilePicker from '../../components/OneDriveFilePicker'

const Documents = () => {
  const DocumentScreen = {
    recent: 'Recent',
    oneDrive: 'One Drive',
  }

  const { teamsUserCredential } = useContext(TeamsFxContext)
  const { history, isLoading } = useSelector((state) => state.oneDrive)
  const dispatch = useDispatch()
  const [documentScreen, setDocumentScreen] = useState(DocumentScreen.recent)
  const handleScreenChange = (currentScreen) => {
    setDocumentScreen(currentScreen)
  }
  const renderHeader = () => {
    if (DocumentScreen.recent === documentScreen)
      return (
        <Breadcrumb>
          <BreadCrumbNav>
            <BreadcrumbList>
              <BreadcrumbItem>{documentScreen}</BreadcrumbItem>
            </BreadcrumbList>
          </BreadCrumbNav>
        </Breadcrumb>
      )
  }

  return (
    <Section>
      <Sidebar>
        <List>
          {Object.keys(DocumentScreen).map((e) => (
            <Item
              className={
                DocumentScreen[e] === documentScreen ? 'is-selected' : ''
              }
              onClick={() => handleScreenChange(DocumentScreen[e])}
            >
              {DocumentScreen[e]}
            </Item>
          ))}
          {/* <Item className={'is-selected'}>OneDrive</Item> */}
        </List>
      </Sidebar>

      {DocumentScreen.recent === documentScreen ? (
        <JobConfig
          Form={() => (
            <DocumentList>
              <DocumentListHeader>
                {renderHeader()}
                {/* <UploadButton>Upload</UploadButton> */}
              </DocumentListHeader>
              <DocumentList_list>
                <Table ToggleMenu={ToggleMenu} />{' '}
              </DocumentList_list>
            </DocumentList>
          )}
        />
      ) : DocumentScreen.oneDrive === documentScreen ? (
        <OneDriveFilePicker />
      ) : (
        ''
      )}
    </Section>
  )
}

export default withMessageBox(Documents)
