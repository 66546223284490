import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg  viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35.5699 14L49.6242 36.7461H50.2255L64.4301 14H85.2484L62.0251 50L86 86H64.6555L50.2255 63.0078H49.6242L35.1942 86H14L37.8622 50L14.6013 14H35.5699Z" fill="currentColor" />
        <path d="M35.5699 14L49.6242 36.7461H50.2255L64.4301 14H85.2484L62.0251 50L86 86H64.6555L50.2255 63.0078H49.6242L35.1942 86H14L37.8622 50L14.6013 14H35.5699Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M100 100H0V0H100V100Z" stroke="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>

    </div>
  )
}
