import { getListItems } from '../../service/oneDrive/oneDriveFiles'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { errorHandler } from '../error/action'
import { app } from '@microsoft/teams-js'

export const getOneDriveFileItems = createAsyncThunk(
  'oneDrive/getFileItems',
  async (
    { teamsUserCredential, itemId, driveId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const tokenResponse = await teamsUserCredential?.getToken('')
      const token = tokenResponse?.token
      if (!token) {
        throw new Error('Failed to retrieve token')
      }
      const teamsContext = await app.getContext()
      const response = await getListItems(
        token,
        itemId,
        driveId,
        teamsContext.user.tenant.id
      ) // Make sure to await the response
      return response
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)

const oneDriveSlice = createSlice({
  name: 'onedrive',
  initialState: {
    items: [],
    isLoading: false,
    error: null,
    driveId: '', // Default drive ID, change if needed
    itemId: '', // Default item ID, change if needed
    history: [],
  },
  reducers: {
    setPath: (state, action) => {
      state.driveId = action.payload.driveId
      state.itemId = action.payload.itemId
    },
    addToHistory: (state, action) => {
      state.history.push(action.payload)
    },
    goBack: (state, action) => {
      const index = action.payload.index
      const preHistory = state.history.slice(0, index + 1)
      if (preHistory) {
        state.driveId = action.payload.driveId
        state.itemId = action.payload.itemId
        state.history = [...preHistory]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneDriveFileItems.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getOneDriveFileItems.fulfilled, (state, action) => {
        state.isLoading = false
        state.items = action.payload.items || []
      })
      .addCase(getOneDriveFileItems.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload
      })
  },
})

export const { setPath, addToHistory, goBack } = oneDriveSlice.actions

export const fetchItemsForPath =
  (teamsUserCredential, driveId, itemId, name) => async (dispatch) => {
    dispatch(addToHistory({ driveId, itemId, name }))
    dispatch(setPath({ driveId, itemId }))
    const res = await dispatch(getOneDriveFileItems({ teamsUserCredential, driveId, itemId }))
    if (res?.error) {
      throw new Error(res.payload)
    }
  }
export const breadCrumbNavigate =
  (teamsUserCredential, driveId, itemId, index) => async (dispatch) => {
    dispatch(
      goBack({
        teamsUserCredential,
        driveId,
        itemId,
        index,
      })
    )
    const res = await dispatch(
      getOneDriveFileItems({ teamsUserCredential, driveId, itemId })
    )
    if (res?.error) {
      throw new Error(res.payload)
    }
    
  }

export default oneDriveSlice.reducer
