import styled from 'styled-components'

import Icon from '../../../controls/Icon'

import { colors, device, media } from '../../../theme'

const Btn = styled.button`
  position: relative;
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  span {
    display: block;
    color: ${colors.grayDark};
  }
  &.pressed {
    background-color: ${colors.grayLight};
  }

  ${media.down(device.tablet)`
    width: 35px;
    display: flex;
    justify-content: center;
  `}
  ${media.down(device.mobile)`
    width: 25px;
  `}
`

export const ToggleButton = ({
  disabled,
  hideOnMobile,
  icon,
  onClick,
  children,
  id,
  toolTip,
  pressed,
}) => {
  const classNames = []
  if (disabled) {
    classNames.push('disabled')
  }
  if (hideOnMobile) {
    classNames.push('sm-hide')
  }
  if (pressed) {
    classNames.push('pressed')
  }

  return (
    <Btn
      id={id}
      type="button"
      className={classNames.join(' ')}
      onClick={() => {
        if (onClick && !disabled) {
          onClick()
        }
      }}
      title={toolTip}
    >
      {icon && <Icon icon={icon} />}
      <span>{children}</span>
    </Btn>
  )
}
