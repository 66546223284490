import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={['toolIcon', className ? className : ''].join(' ')}>
      <svg
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.5 20C35.5 28.5604 28.5604 35.5 20 35.5C11.4396 35.5 4.5 28.5604 4.5 20C4.5 11.4396 11.4396 4.5 20 4.5C28.5604 4.5 35.5 11.4396 35.5 20ZM76.5 20C76.5 28.5604 69.5604 35.5 61 35.5C52.4396 35.5 45.5 28.5604 45.5 20C45.5 11.4396 52.4396 4.5 61 4.5C69.5604 4.5 76.5 11.4396 76.5 20ZM35.5 60C35.5 68.5604 28.5604 75.5 20 75.5C11.4396 75.5 4.5 68.5604 4.5 60C4.5 51.4396 11.4396 44.5 20 44.5C28.5604 44.5 35.5 51.4396 35.5 60ZM75.5 60C75.5 68.5604 68.5604 75.5 60 75.5C51.4396 75.5 44.5 68.5604 44.5 60C44.5 51.4396 51.4396 44.5 60 44.5C68.5604 44.5 75.5 51.4396 75.5 60Z"
          fill="white"
          stroke="black"
        />
      </svg>
    </div>
  )
}
