import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 67 57" >
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
        <path d="M32 18L24 26L16 18"  transform="translate(7, 7)" />
        <path d="M36 42H12"  transform="translate(7, 7)" />
        <path d="M42 34H6"  transform="translate(7, 7)" />
        <path d="M24 6V26"  transform="translate(7, 7)"/>
      </g>
    </svg>
    </div>
  )
}
