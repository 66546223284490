import { types } from './mutations'

const initalState = {
  barcodeLoading: false,
  imgBarcode: null,
}

export default (state = initalState, action) => {
  switch (action.type) {
    case types.GET_BARCODE_LOADING: {
      return {
        ...state,
        barcodeLoading: true,
      }
    }
    case types.GET_BARCODE_LOADED: {
      return {
        ...state,
        barcodeLoading: false,
        imgBarcode: action.imgData.barcodeImage,
      }
    }
    case types.BARCODE_RESET: {
      return {
        ...state,
        imgBarcode: null,
      }
    }
    default:
      return state
  }
}
