import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 94 94" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M93.0882 25.3405L63.7478 1.16921C62.962 0.521596 61.896 0.157666 60.7845 0.157471H18.8697C16.647 0.159299 14.5161 0.887488 12.9445 2.18223C11.3728 3.47698 10.4889 5.2325 10.4867 7.06355V48.5H18.8697V7.06355H52.4015V27.7818C52.4037 29.6128 53.2876 31.3683 54.8593 32.6631C56.4309 33.9578 58.5619 34.686 60.7845 34.6879H85.9334V48.5H94.3163V27.7818C94.3161 26.8661 93.8743 25.9879 93.0882 25.3405ZM60.7845 27.7818V8.49311L84.1981 27.7818H60.7845Z" fill="currentColor" />
        <path d="M60.7845 66.3763V59.4701H94.3163V87.0945H85.9334V71.2589L58.3283 94.0006L52.4015 89.118L80.0066 66.3763H60.7845Z" fill="currentColor" />
        <path d="M11.95 73.48L11.902 79.504L0.429968 58.6H8.80597L16.462 74.536H13.51L21.094 58.6H29.47L18.118 79.504L18.07 73.48L29.83 94H21.43L13.39 78.448H16.558L8.51797 94H0.141968L11.95 73.48Z" fill="currentColor" />
      </svg>

    </div>
  )
}
