import styled, { keyframes } from 'styled-components'

import { colors, device, fontSize, media } from '../../theme'
import Icon from '../../controls/Icon'
import Button from '../../controls/Button'
import Icons from '../../assets/Icons'

const slideLeft = keyframes`
  0% {
    margin-right: -296px;
  }
  100% {
    margin-right: 0;
  }
`

export const TableBoardWrapper = styled.div`
  animation-duration: 0.2s;
  animation-name: ${slideLeft};
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  margin: 0;
  max-width: 280px;
  min-width: 280px;
  overflow-y: auto;
  padding: 0 0 0 8px;
  ${media.down(device.mobile)`
        background: ${colors.grayLighter};
        bottom: 0;
        box-shadow: -3px 0 6px ${colors.grayLight};
        height: auto;
        min-width: 0;
        padding: 0;
        position: fixed;
        right: 0;
        top: 64px;
        z-index: 100;
    `}
`
export const Preview = styled.div`
  align-items: center;
  background-color: ${colors.grayLight};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: 16px 16px 20px;
  min-height: 244px;
  position: relative;
  width: 240px;
`
export const PreviewMultiple = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  min-height: 65px;
`
export const PreviewCustomIcon = styled(Icon)`
  height: 32px !important;
  width: 32px !important;
  & > svg {
    height: 32px !important;
    width: 32px !important;
  }
`
export const PreviewCloseButton = styled(Button)`
  height: 18px;
  width: 18px !important;
  padding: 12px !important;
`
export const PreviewNumSelected = styled.div`
  flex: 1 0 auto;
  padding-left: 10px;
  font-size: ${fontSize.sm};
  font-weight: 500;
`
export const ListBox = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
`
export const List = styled.ul`
  list-style: none;
  width: 100%;
  align-items: flex-start;
  border: solid ${colors.grayLight};
  border-width: 1px 0 0;
  padding: 8px 10px;
`
export const Item = styled.li`
  padding: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: left;
  width: 100%;
  &:hover {
    background: ${colors.bgLight};
  }
`
export const HorizontalLine = styled.span`
  width: 100%;
  height: 1px;
`
export const ItemButton = styled(Button)`
  border: none;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: left;
  width: 100%;
  height: 30px;
  padding: 0px 12px;
`
export const FileInfoBox = styled.div`
  // flex: 0 0 auto;
  padding: 0 16px 14px;
  align-items: left;
  display: flex;
  flex-direction: column;
  tex
`
export const FileName = styled.span`
  color: ${colors.text};
  font-size: 14px;
`
export const FileDesc = styled.span`
  color: ${colors.textMuted};
  font-size: 14px;
`

export const ItemIcon = styled(Icon)`
  & > svg {
    margin: 0 8px 0 -8px;
  }
`
export const ItemToolIcon = styled(Icons)`
  width: 21px;
  height: 17px;
  margin: 0 10px 0 -5px;

  & > svg {
    width: 21px;
    height: 17px;
    color: ${(props) => props.$color} !important;
  }
`
