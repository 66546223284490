import { useState, useEffect } from 'react'
import styled from 'styled-components'

import Icon from '../Icon'
import { layout, media, device, colors, fontSize } from '../../theme/viewer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.dropdown-disabled {
    opacity: 0.7;
  }
`

const ToggleButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  .dropdown-caret {
    i {
      margin-left: 2px;
      width: 12px;
      height: 12px;
      svg {
        width: 12px;
        height: 12px;
        fill: ${colors.gray};
        .dropdown-open & {
          fill: ${colors.grayDark};
        }
      }
    }
  }
`

const MenuWrapper = styled.div`
  position: relative;
  height: 0;
  width: 100%;
`

const Menu = styled.div`
  display: none;
  position: absolute;
  top: 18px;
  right: 0;
  min-width: 180px;
  padding: 0.5rem ${layout.gap};
  background-color: ${colors.bodyBackground};
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  z-index: 4;
  .dropdown-open & {
    display: block;
  }
  .dropdown-align-center & {
    text-align: center;
    right: 50%;
    transform: translateX(50%);
  }
`

const Items = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  > li {
    margin: 0;
    padding: 0;
    /*
    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray};
    }
    */
    > button {
      width: 100%;
      &:hover {
        color: #008bd2;
        svg {
          fill: #008bd2;
        }
      }
    }
    > * {
      display: flex;
      align-items: center;
      padding: 12px 0;
      margin: 0 !important;
      white-space: nowrap;
      i,
      svg {
        width: 22px;
        height: 18px;
      }
      i {
        margin-right: 0.5rem;
      }
      a {
        display: flex;
        align-items: center;
        &:hover {
          color: #008bd2;
        }
      }
    }
    &.dropdown-item-disabled {
      opacity: 0.5;
    }
  }
`

const Dropdown = ({
  id,
  items,
  children,
  disabled,
  alignItems,
  hideCaret,
  className,
  toggleButton,
}) => {
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen(false)
    document.removeEventListener('click', closeMenu)
  }
  useEffect(() => {
    return () => {
      document.removeEventListener('click', closeMenu)
    }
  }, [])
  const toggleMenu = () => {
    if (disabled) {
      return
    }
    const addCloseListener = !open
    setOpen(!open)
    if (addCloseListener) {
      setTimeout(() => {
        document.addEventListener('click', closeMenu)
      }, 10)
    } else {
      document.removeEventListener('click', closeMenu)
    }
  }

  const classNames = ['dropdown']
  if (disabled) {
    classNames.push('dropdown-disabled')
  }

  if (open) {
    classNames.push('dropdown-open')
  }

  if (alignItems === 'center') {
    classNames.push('dropdown-align-center')
  }

  if (alignItems === 'left') {
    classNames.push('dropdown-align-left')
  }

  if (className) {
    classNames.push(className)
  }

  const CustomToggleButton = toggleButton

  return (
    <Wrapper className={classNames.join(' ')}>
      <>
        {CustomToggleButton ? (
          <CustomToggleButton onClick={toggleMenu} />
        ) : (
          <ToggleButton id={id} className="dropdown-toggle" type="button" onClick={toggleMenu}>
            {children}
            {!hideCaret && (
              <span className="dropdown-caret">
                <Icon icon="dropdownCaret" />
              </span>
            )}
          </ToggleButton>
        )}
      </>
      <MenuWrapper>
        <Menu className="dropdown-menu">
          {items && (
            <Items>
              {items.map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
            </Items>
          )}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  )
}

const MenuItem = ({ item }) => {
  if (item.renderItem) {
    const CustomItem = item.renderItem
    return (
      <li>
        <CustomItem />
      </li>
    )
  }
  if (!item.disabled && item.href) {
    return (
      <li>
        <a id={item.id} href={item.href}>
          {item.renderItem && item.renderItem()}
          {item.icon && <Icon icon={item.icon} />}
          {item.text}
        </a>
      </li>
    )
  }
  if (!item.disabled && item.action) {
    return (
      <li>
        <button id={item.id} type="button" onClick={item.action}>
          {item.renderItem && item.renderItem()}
          {item.icon && <Icon icon={item.icon} />}
          {item.text}
        </button>
      </li>
    )
  }

  const classNames = []
  if (item.disabled) {
    classNames.push('dropdown-item-disabled')
  }

  return (
    <li className={classNames.join(' ')}>
      <div>
        {item.renderItem && item.renderItem()}
        {item.icon && <Icon icon={item.icon} />}
        {item.text}
      </div>
    </li>
  )
}

export default Dropdown
