import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="m13.85 4.44l-3.28-3.3l-.35-.14H2.5l-.5.5V7h1V2h6v3.5l.5.5H13v1h1V4.8zM10 5V2l3 3zM2.5 8l-.5.5v6l.5.5h11l.5-.5v-6l-.5-.5zM13 13v1H3V9h10zm-8-1h-.32v1H4v-3h1.06c.75 0 1.13.36 1.13 1a.94.94 0 0 1-.32.72A1.33 1.33 0 0 1 5 12m-.06-1.45h-.26v.93h.26c.36 0 .54-.16.54-.47s-.18-.46-.54-.46M9 12.58a1.48 1.48 0 0 0 .44-1.12c0-1-.53-1.46-1.6-1.46H6.78v3h1.06A1.6 1.6 0 0 0 9 12.58m-1.55-.13v-1.9h.33a.94.94 0 0 1 .7.25a.9.9 0 0 1 .25.67a1 1 0 0 1-.25.72a.94.94 0 0 1-.69.26zm4.45-.61h-.97V13h-.68v-3h1.74v.55h-1.06v.74h.97z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  )
}
