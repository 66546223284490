import mutations from './mutations'
import { createThumbnails } from '../../service/document/createThumbnails'
import { errorHandler } from '../error/action'

const actions = {
  createThumbnails: (doc, pages, width) => async (dispatch, getState) => {
    try {
      const state = getState()

      if (state.thumbnails.extDocId && state.thumbnails.extDocId === doc.id) {
        return
      }

      const connectionId =
        state.hub && state.hub.connectionId ? state.hub.connectionId : undefined

      if (!connectionId) {
        window.setTimeout(() => {
          dispatch(actions.createThumbnails(doc))
        }, 750)
        return
      }
      dispatch(mutations.starting(doc))

      await createThumbnails(
        { docIdExt: doc.id, docBlobRef: doc.docBlobRef },
        connectionId,
        pages,
        width
      )

      dispatch(mutations.started(doc))
    } catch (error) {
      dispatch(errorHandler(error))
      return false
    }
  },

  // hub actions
  pushThumbnails: (data) => async (dispatch) => {
    dispatch(mutations.pushThumbnails(data))
  },
  progress: (data) => async (dispatch) => {
    dispatch(mutations.progress(data))
  },
  completed: (data) => async (dispatch) => {
    dispatch(mutations.completed(data))
  },
}
export default actions
