const t = (key) => {
  const translate = {
    lblQuality: 'Quality',
    qualityDraft: 'Normal',
    qualityHigh: 'High',
    md_convertFromPdfDesc_draft: 'Normal quality',
    md_convertFromPdfDesc_high:
      'High quality - Only for Pro users. [Upgrade to Pro now](/get-pdf4me/)',
    btnStartJob: 'Start Convert',
    md_convertFromPdfDesc_high_isPro: 'High Quality',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
