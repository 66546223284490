import { Type } from './mutation'
const initialState = []
const reducers = (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case Type.INITIATE_FILE_UPLOAD: {
      let copyState = [...state]
      const hasDocIdExt = copyState.findIndex(
        (e) => e?.docIdExt == data.docIdExt
      )
      if (hasDocIdExt != -1) {
        copyState[hasDocIdExt] = { ...copyState[hasDocIdExt], ...data }
      } else {
        copyState = [...copyState, { ...data }]
      }

      return [...copyState]
    }
    case Type.UPDATE_FILE_UPLOAD: {
      const copyState = [...state]

      return [...copyState]
    }
    case Type.ONE_DRIVE_NOTIFICATION: {
      const copyState = [...state, { ...data }]

      return [...copyState]
    }

    case Type.NOTIFICATION_TRIGGER: {
      let copyState = [...state]
      const isDocId = state.find(
        (e) => e?.jobId === data.jobId && e?.docId === data.docId
      )
      if (isDocId) {
        copyState = copyState.map((e) => {
          if (e?.jobId === data.jobId && e?.docId === data.docId) {
            return {
              ...e,
              ...data,
              isNotification: true,
              modifiedAt: Date.now(),
            }
          }
          return { ...e }
        })
      } else {
        copyState = [...copyState, { ...data, isNotification: true }]
      }
      return [...copyState]
    }
    case Type.CLEAR_JOB: {
      let copyState = [...state]
      copyState = copyState.filter((e) =>
        data.findIndex((e1) => e1?.docIdExt === e.docIdExt)
      )

      return [...copyState]
    }
    case Type.RESET_NOTIFICATION: {
      let copyState = [...state]
      copyState = copyState.filter((e) => e.isNotification)

      return [...copyState]
    }
    case Type.DELETE_NOTIFICATION: {
      let copyState = [...state]
      copyState = copyState.filter((e) => e.docIdExt !== data.docIdExt)

      return [...copyState]
    }
    default:
      return state
  }
}

export default reducers
