import { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import QRCodeStyling from 'qr-code-styling'

import PdfViewerContext from '../../pdfViewer/PdfViewerContext'
import { colors } from '../../../../theme/viewer'
import Popup from '../../../../controls/Popup'
import Button from '../../../../controls/Button'
import { cropCanvas } from './cropCanvas'

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 24px;
`

const ColorInput = styled.input`
  &[type='color'] {
    -webkit-appearance: none;
    border: none;
    width: 100%;
    height: 32px;
  }
  &[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &[type='color']::-webkit-color-swatch {
    border: none;
  }
`

const QrWrapper = styled.div`
  width: 300px;
  height: 300px;
  border: solid 1px ${colors.bgLight};
`

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 0,
  },
})

const AddQrCode = (props) => {
  const [showPopup, setShowPopup] = useState(false)
  const [data, setData] = useState('')
  const [dotStyle, setDotStyle] = useState('square')
  const [dotColor, setDotColor] = useState('#000000')
  const [state, actions] = useContext(PdfViewerContext)
  const $canvas = useRef()

  useEffect(() => {
    function openPopup() {
      setShowPopup(true)
    }
    document.body.addEventListener('openqrcodeform', openPopup)
    return () => {
      // document.body.removeEventListener('openqrcodeform', openPopup)
    }
  }, [])

  useEffect(() => {
    if (showPopup) {
      qrCode.append($canvas.current)
    }
  }, [showPopup])

  useEffect(() => {
    qrCode.update({ data })
  }, [data])

  useEffect(() => {
    qrCode.update({
      dotsOptions: {
        type: dotStyle,
      },
    })
  }, [dotStyle])

  useEffect(() => {
    qrCode.update({
      dotsOptions: {
        color: dotColor,
      },
    })
  }, [dotColor])

  function closePopup() {
    setShowPopup(false)
  }

  function handleAddQrCode() {
    const canvas = $canvas.current.querySelector('canvas')
    const img = cropCanvas(canvas)
    actions.addImage(img)
    setShowPopup(false)
  }

  if (!showPopup) {
    return null
  }

  return (
    <Popup
      title="Add QR Code"
      size="lg"
      onClose={closePopup}
      footer={() => (
        <>
          <Button color="primary" onClick={handleAddQrCode}>
            Add QR Code
          </Button>
          <Button onClick={closePopup}>Cancel</Button>
        </>
      )}
    >
      <FormGrid>
        <div>
          <div className="formRow" style={{ marginTop: 0 }}>
            <label className="formRow__label">Data</label>
            <div className="formRow__input">
              <textarea
                name="data"
                value={data}
                onChange={(e) => {
                  setData(e.target.value)
                }}
              />
            </div>
          </div>
          <div className="formRow">
            <label className="formRow__label">Dot Style</label>
            <div className="formRow__input">
              <select
                name="style"
                value={dotStyle}
                onChange={(e) => {
                  setDotStyle(e.target.value)
                }}
              >
                <option value="square">Square</option>
                <option value="dots">Dots</option>
                <option value="rounded">Rounded</option>
                <option value="extra-rounded">Extra Rounded</option>
                <option value="classy">Classy</option>
                <option value="classy-rounded">Classy Rounded</option>
              </select>
            </div>
          </div>
          <div className="formRow">
            <label className="formRow__label">Dot Color</label>

            <div className="formRow__input">
              <ColorInput
                name="data"
                type="color"
                value={dotColor}
                onChange={(e) => {
                  setDotColor(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <QrWrapper>
          <div ref={$canvas} />
        </QrWrapper>
      </FormGrid>
    </Popup>
  )
}

export default AddQrCode
