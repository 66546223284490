const t = (key) => {
  const translate = {
    lblExtract: 'Extract',
    btnStartJob: 'Start Extracting',
    extractTextImages: 'Text and Images',
    extractText: 'Only Text',
    extractImages: 'Only Images',
    extractDesc_imagetext: 'Extract both images and text from PDF Documents',
    extractDesc_image: 'Extract only images from PDF Documents',
    extractDesc_text: 'Extract only text from PDF Documents',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
