import { css } from 'styled-components'

export const device = {
  mobile: '550px',
  //tablet: '924px',
  tablet: '841px',
  labtop: '1025px',
  desktop: '1241px',
}

export const media = {
  up: breakpoint => (...args) => css`
    @media (min-width: ${breakpoint}) {
      ${css(...args)}
    }
  `,

  down: breakpoint => (...args) => css`
    @media (max-width: ${breakpoint}) {
      ${css(...args)}
    }
  `,

  between: (fromBreakpoint, toBreakpoint) => (...args) => css`
    @media (min-width: ${fromBreakpoint}) and (max-width: ${toBreakpoint}) {
      ${css(...args)}
    }
  `,
}
