import React from "react";

export default function (props) {
  const { className } = props;

  return (
    <div className={className ? className : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80">
        <path fill="#FDE5E5" d="M20 36c8.837 0 16-7.163 16-16S28.837 4 20 4 4 11.163 4 20s7.163 16 16 16Z" />
        <path fill="#185ABD" d="M61 36c8.837 0 16-7.163 16-16S69.837 4 61 4s-16 7.163-16 16 7.163 16 16 16Z" />
        <path fill="#C43E1C" d="M20 76c8.837 0 16-7.163 16-16s-7.163-16-16-16S4 51.163 4 60s7.163 16 16 16Z" />
        <path fill="#107C41" d="M60 76c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z" />
      </svg>

    </div>
  );
}
