import React from 'react'
import styled from 'styled-components'

import Icons from '../../assets/Icons'
import { colors } from '../../theme'

export const UploadBtn = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background);
  padding: 0 24px;
  font-size: inherit;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 500;
  transition: box-shadow 0.2s;
  border: 1px solid ${colors.secondary} !important;
  color: ${colors.secondary};
  min-height: 32px;
  height: auto;
  min-width: 72px;
  border-radius: 16px;
  &:hover {
    border: 1px solid ${colors.secondaryLight} !important;
  }
  & > input {
    position: absolute;
    opacity: 0;
    width: 85px;

    // z-index: -1;
  }
  & > div {
    width: 21px;
    height: 21px;
  }
`

const UploadIcon = ({
  onChange,
  className,
  multiple,
  accept,
  type = 'file',
  onClick,
  disabled
}) => {
  const classes = ['btn', 'btn--link ']
  if (className) classes.push(className)
  return (
    <UploadBtn>
      {/* <Icon icon={Upload} /> */}
      <Icons toolName="fileUpload" className={'btn__startIcon'} />
      <label htmlFor="uploadBtn">Upload</label>
      <input
        id="uploadBtn"
        className={classes.join(' ')}
        type={type}
        onChange={onChange}
        onClick={onClick}
        multiple={multiple}
        accept={accept}
      />
    </UploadBtn>
  )
}

export default UploadIcon
