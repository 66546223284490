import { api } from '../initFetch'

export const executePdfJob = async (id, feature, files, actions, connectionId, toolName, token) => {
  const res = await api.post('/p4m/Document/ExecutePdfJob', {
    connectionId,
    jobIdExt: id,
    feature,
    docs: files,
    consumerActions: actions,
    toolName,
    token,
  })
  return res.json
}

export const getUserInfo = async () => {
  const res = await api.get('/p4m/UserInfo/TestApi')
  return res.json
}