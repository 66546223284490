import { useState, useCallback } from 'react'
import styled from 'styled-components'

import Icon from '../../../../controls/Icon'
import { layout, media, device, colors } from '../../../../theme/viewer'

const Wrapper = styled.div`
  background-color: ${colors.bodyBackground};
  position: relative;
  touch-action: pan-y pan-x;

  ${media.up(device.mobile)`
    width: 300px;
    border-left: solid 2px ${colors.grayLight};
  `}
  ${media.down(device.mobile)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column-reverse;
    height: calc((var(--vh, 1vh) * 60) - ${layout.mobileHeaderHeight});
    border-top: solid 2px ${colors.grayLight};
    z-index: 15;
  `}
`

const BtnClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 4px;
  background-color: ${colors.bodyBackground};
  z-index: 2;
`

const Ct = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding: 20px 20px 20px 0;
`

const Outline = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &.root-level {
    > li {
      padding-left: 10px !important;
    }
  }
`

const OutlineItemWrapper = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  ${media.down(device.mobile)`
    padding-left: 28px;
  `}
`

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 2px 0;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    svg {
      fill: ${colors.grayDark};
    }
  }
  span {
    flex-shrink: 0;
    display: block;
    width: 28px;
    height: 28px;
  }
  a {
    line-height: 1.6;
    cursor: pointer;
    &:hover {
      color: #008bd2;
    }
  }

  ${media.down(device.mobile)`
    button {
      border: solid 1px ${colors.grayLight};
      margin-right: 10px;
    }
    span {
      margin-right: 10px;
    }
  `}
`

const OutlineItem = ({ item, onNavigate }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback((show) => {
    setIsOpen(show)
  })
  return (
    <OutlineItemWrapper>
      <ItemTitle>
        {item.descendants && item.descendants.length > 0 ? (
          <button
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <Icon icon={isOpen ? 'caretDown' : 'caretRight'} />
          </button>
        ) : (
          <span>&nbsp;</span>
        )}
        <a
          onClick={() => {
            onNavigate(item.destination)
          }}
        >
          {item.title}
        </a>
      </ItemTitle>

      {isOpen && (
        <Outline>
          {item.descendants.map((childItem, index) => (
            <OutlineItem
              key={`${index}_${childItem.id}`}
              item={childItem}
              onNavigate={onNavigate}
            />
          ))}
        </Outline>
      )}
    </OutlineItemWrapper>
  )
}

const DocumentOutline = ({ documentOutline, onClose, onNavigate }) => {
  const classNames = []
  // if (open) {
  //   classNames.push('open')
  // }

  return (
    <Wrapper className={classNames.join(' ')}>
      <BtnClose onClick={onClose}>
        <Icon icon="close" />
      </BtnClose>
      <Ct>
        <Outline className="root-level">
          {documentOutline.map((item, index) => (
            <OutlineItem key={`${index}_${item.id}`} item={item} onNavigate={onNavigate} />
          ))}
        </Outline>
      </Ct>
    </Wrapper>
  )
}

export default DocumentOutline
