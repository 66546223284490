import React, { useState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import Button from '../../controls/Button'
import ButtonRow from '../../controls/ButtonRow'

import Popup from './Popup'
import Toolbar, { fontFamilyList } from './Popup/Toolbar'
import {
  Page,
  PageLayout,
  ArrowBox,
  PageLayoutWrapper,
  VerticalLine,
  HorizontalLine,
  defaultMargin,
  // A4PageWidth,
  // A4PageHeight,
  cmToPixelRatio,
} from './Popup/common'
import {
  calculateSizeSettings,
  PreviousPage,
  NextPage,
  pageNavigate,
} from './Popup/pageNavigate'

import { ViewportContext } from '../../hooks/ViewportContext'
import { device, media, colors } from '../../theme'
import { useJobConfig } from '../JobConfig/JobContext'
import t from './translate'

const TextWrapper = styled.span`
  border: 1px solid transparent;
  input {
    border: 0px;
  }
  &:hover input {
    border: 1px solid black;
  }
  .fontFamily_Arial {
    font-family: arial;
  }
  .fontFamily_Impact {
    font-family: impact;
  }
  .fontFamily_ArialUnicodeMs {
    font-family: arial unicode ms;
  }
  .fontFamily_Verdana {
    font-family: verdana;
  }
  .fontFamily_Courier {
    font-family: courier;
  }
  .fontFamily_ComicSansMS {
    font-family: comic sans ms;
  }
  .fontFamily_TimesNewRoman {
    font-family: times-new-roman;
  }
  .fontBold {
    font-weight: bold;
  }
  .fontItalics {
    font-style: italic;
  }
  .fontUnderline {
    text-decoration: underline;
  }
  &.edit {
    cursor: text;
  }
  &.drag {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`

const Placeholder = styled.span`
  opacity: 0.75;
`

export default ({
  config,
  updateConfig,
  onClose,
  inputFiles,
  filesReady,
  pageSelectionType,
  customSelection,
}) => {
  // const t = useTranslation()
  const viewport = useContext(ViewportContext)
  const { actionConfig, updateActionConfig } = useJobConfig()

  // const [values, setTextStamp] = useState({ ...config, dragMode: true })
  const [editing, setEditing] = useState(false)
  useEffect(() => {
    updateActionConfig({ dragMode: true })
  }, [])

  const setDragMode = (_dragMode) => {
    updateActionConfig({ dragMode: _dragMode })
  }

  if (actionConfig?.dragMode) {
    if (editing) setEditing(false)
  }

  const submit = () => {
    const _values = { ...actionConfig }

    if (_values.xalign === 'center') {
      _values.marginX = 0
      _values.marginXcm = 0
    }
    if (_values.xalign === 'right') {
      if (_values.marginX >= 0) _values.marginX *= -1
    }
    if (_values.yalign === 'middle') {
      _values.marginY = 0
      _values.marginYcm = 0
    }
    if (_values.yalign === 'top') {
      if (_values.marginY >= 0) _values.marginY *= -1
    }

    updateConfig(_values)
    onClose()
  }
  const textChange = (e) => {
    const text = e.target.value
    const { offsetHeight, offsetWidth } = e.target
    updateActionConfig({
      text,
      height: offsetHeight,
      width: offsetWidth,
    })
  }

  const textOnClick = () => {
    setEditing(true)
    window.setTimeout(() => {
      const _elmInput = document.getElementsByClassName('textStamp')
      if (_elmInput[0]) {
        _elmInput[0].focus()
        _elmInput[0].select()
      }
    }, 100)
  }

  /* thumbnails and largesize popup starts */
  const dispatch = useDispatch()
  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const [maxContentWidth, setMaxContentWidth] = useState(0)

  useEffect(() => {
    const updateSize = () => {
      const contentElem = document.getElementsByClassName('popup-content')[0]
      const { maxHeight } = contentElem.style
      const { width } = contentElem.getBoundingClientRect()
      let _maxHeight = parseInt(maxHeight.replace('px', ''), 10)
      if (device.tablet < width) {
        _maxHeight -= 20
      }
      setMaxContentHeight(_maxHeight)
      setMaxContentWidth(width)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  const [pageNum, setPageNum] = useState(
    pageSelectionType === 'custom' ? (customSelection || '')[0] : 1
  )
  const { thumbnails, extDocId } = useSelector((state) => state.thumbnails)

  const { thumbnailInfo, prevDisabled, nextDisabled } = pageNavigate(
    dispatch,
    filesReady,
    inputFiles,
    thumbnails,
    pageNum,
    extDocId,
    pageSelectionType,
    customSelection,
    viewport.width < device.tablet
  )

  const settings = calculateSizeSettings(
    maxContentHeight,
    maxContentWidth,
    thumbnails,
    pageNum
  )

  const pageWrapperCss = {
    left: 'auto',
    top: 'auto',
  }

  pageWrapperCss.height = `${settings._pageHeight}px`
  pageWrapperCss.width = `${settings._pageWidth}px`
  if (settings.landscape) {
    pageWrapperCss.maxWidth = `${settings.maxPageWidth}px`
  } else {
    pageWrapperCss.maxHeight = `${settings.maxPageHeight}px`
  }

  // const dispWidth = values.width * settings._pageRatio
  // const dispHeight = values.height * settings._pageRatio

  let leftMargin = defaultMargin * settings._pageRatio
  // if (config.xalign !== 'center') {
  leftMargin =
    actionConfig.marginX < 0
      ? actionConfig.marginX * settings._pageRatio * -1
      : actionConfig.marginX * settings._pageRatio
  // }

  let topMargin = defaultMargin * settings._pageRatio
  // if (config.yalign !== 'middle') {
  topMargin =
    actionConfig.marginY < 0
      ? actionConfig.marginY * settings._pageRatio * -1
      : actionConfig.marginY * settings._pageRatio
  // }
  const rightMargin = settings._pageWidth - leftMargin
  const bottomMargin = settings._pageHeight - topMargin

  let imgWrappercss = {}
  if (actionConfig.xalign === 'right') {
    imgWrappercss.right = `${leftMargin}px`
  } else if (actionConfig.xalign === 'left') {
    imgWrappercss.left = `${leftMargin}px`
  }
  if (actionConfig.yalign === 'bottom') {
    imgWrappercss.bottom = `${topMargin}px`
  } else if (actionConfig.yalign === 'top') {
    imgWrappercss.top = `${topMargin}px`
  }

  const previousPage = () => {
    PreviousPage(
      filesReady,
      pageNum,
      pageSelectionType,
      customSelection,
      thumbnails,
      extDocId,
      setPageNum
    )
  }
  const nextPage = () => {
    NextPage(
      filesReady,
      pageNum,
      pageSelectionType,
      customSelection,
      thumbnails,
      extDocId,
      setPageNum
    )
  }

  const backgroundWrapper = {
    backgroundImage: thumbnailInfo ? `url(${thumbnailInfo.thumbnail})` : 'auto',
    backgroundPosition: 'center' /* Center the image */,
    backgroundSize: 'cover',
  }

  /* thumbnails and largesize popup ends */

  /* Draggable begin */

  const onDragMove = (x, y) => {
    const _settings = calculateSizeSettings(
      maxContentHeight,
      maxContentWidth,
      thumbnails,
      pageNum
    )
    const _marginXcm =
      Math.round(((x * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginYcm =
      Math.round(((y * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginX = Math.round(_marginXcm / cmToPixelRatio)
    const _marginY = Math.round(_marginYcm / cmToPixelRatio)
    const _values = {
      marginX: _marginX,
      marginY: _marginY,
      marginXcm: _marginXcm,
      marginYcm: _marginYcm,
      xalign: 'left',
      yalign: 'top',
    }
    if (actionConfig?.dragMode) updateActionConfig(_values)
  }

  const handlerRef = useRef(null)

  const [isMoving, setIsMoving] = useState(false)

  const move = (x, y) => {
    const handlerSize = handlerRef.current.getBoundingClientRect()
    const parentSize = handlerRef.current.parentElement.getBoundingClientRect()

    if (!isMoving) return

    let _posX = x - handlerSize.width / 2
    let _posY = y - handlerSize.height / 2
    _posX -= parentSize.left
    _posY -= parentSize.top

    if (_posX < 0) _posX = 0
    if (_posY < 0) _posY = 0

    if (parentSize.width < _posX + handlerSize.width) {
      _posX = parentSize.width - handlerSize.width
    }
    if (parentSize.height < _posY + handlerSize.height) {
      _posY = parentSize.height - handlerSize.height
    }
    _posX = Math.round(_posX)
    _posY = Math.round(_posY)
    if (onDragMove) onDragMove(_posX, _posY)
  }

  const onMouseMove = (e) => {
    const { clientX, clientY } = e
    move(clientX, clientY, e)
    if (e.buttons === 0) {
      setIsMoving(false)
    }
  }

  const onTouchMove = (e) => {
    const { clientX, clientY } = e.touches[0]
    move(clientX, clientY, e, 'touch')
    if (e.touches.length === 0) {
      setIsMoving(false)
    }
  }

  const onMouseDown = () => {
    setIsMoving(true)
  }

  const onTouchStart = () => {
    setIsMoving(true)
  }

  const onDragTargetStart = (e) => {
    if (e) {
      e.preventDefault()
    }
  }

  /* Draggable ends */

  const dblEditClick = () => {
    setDragMode(false)
    textOnClick()
  }

  const dispFontFamily = fontFamilyList.find(
    (f) => f.key === actionConfig.fontFamily
  ).className

  if (editing) imgWrappercss = {}

  return (
    <Popup
      size="md"
      onClose={onClose}
      header={() => {
        return (
          <>
            <Toolbar
              config={{ ...actionConfig }}
              updateConfig={updateActionConfig}
              stampType="text"
            />
          </>
        )
      }}
      footer={() => {
        return (
          <ButtonRow center noWrap>
            <Button
              color="primary"
              id="btnTextStampOK"
              onClick={submit}
              disabled={actionConfig.text === ''}
            >
              OK
            </Button>
            <Button onClick={onClose}>{t('btnCancel')}</Button>
          </ButtonRow>
        )
      }}
    >
      <PageLayoutWrapper style={{ height: `${maxContentHeight}px` }}>
        <PageLayout
          id="pageLayout"
          style={{ height: `${settings.pageLayoutHeight}px` }}
          $dataScale={settings._pageRatio}
        >
          <ArrowBox
            className={`left ${prevDisabled ? 'disabled' : ''}`}
            onClick={previousPage}
          >
            <div />
          </ArrowBox>
          <ArrowBox
            className={`right ${nextDisabled ? 'disabled' : ''}`}
            onClick={nextPage}
          >
            <div />
          </ArrowBox>
          <Page
            className={`overlay ${editing ? 'editing' : ''} ${
              actionConfig.xalign
            } ${actionConfig.yalign}`}
            style={{
              ...pageWrapperCss,
            }}
            onMouseMove={onMouseMove}
            onTouchMove={onTouchMove}
          >
            <TextWrapper
              className={`imgWrapper ${
                actionConfig?.dragMode ? 'drag' : 'edit'
              }`}
              style={{
                ...imgWrappercss,
                marginTop: `${Math.sin((actionConfig.rotate / 180) * Math.PI)}`,
              }}
              onDoubleClick={dblEditClick}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
              onDragStart={onDragTargetStart}
              ref={handlerRef}
            >
              <div
                onClick={() => {
                  textOnClick()
                }}
                onKeyUp={() => {}}
                role="button"
                tabIndex="0"
                className={`stamp rotate_${
                  actionConfig.rotate
                } fontFamily_${dispFontFamily} ${
                  actionConfig.fontBold ? 'fontBold' : ''
                } ${actionConfig.fontItalics ? 'fontItalics' : ''} ${
                  actionConfig.fontUnderline ? 'fontUnderline' : ''
                }`}
                style={{
                  fontSize: `${Math.round(
                    actionConfig.fontSize * settings._pageRatio
                  )}px`,
                  opacity: actionConfig.opacity,
                  color:
                    actionConfig.mode === 'fill'
                      ? actionConfig.fontColor
                      : 'white',
                  WebkitTextStroke:
                    actionConfig.mode === 'stroke'
                      ? `${actionConfig.lineWidth * settings._pageRatio}px ${
                          actionConfig.fontColor
                        }`
                      : '',
                  userSelect: 'none',
                }}
              >
                {actionConfig.text ? (
                  actionConfig.text
                ) : (
                  <Placeholder>{t('lblEnterTextHere')}</Placeholder>
                )}
              </div>
              <input
                type="text"
                name="text"
                value={actionConfig.text}
                className={`textStamp fontFamily_${dispFontFamily} ${
                  actionConfig.fontBold ? 'fontBold' : ''
                } ${actionConfig.fontItalics ? 'fontItalics' : ''} ${
                  actionConfig.fontUnderline ? 'fontUnderline' : ''
                }`}
                placeholder={t('lblEnterTextHere')}
                style={{
                  color: actionConfig.fontColor,
                }}
                onChange={textChange}
                onBlur={() => {
                  setDragMode(true)
                }}
              />
            </TextWrapper>
          </Page>
          <Page
            style={{
              ...backgroundWrapper,
              ...pageWrapperCss,
            }}
          >
            {actionConfig.xalign === 'left' && !editing && (
              <VerticalLine
                id="leftLine"
                style={{
                  transform: `translateX(${leftMargin}px)`,
                  height: `${settings._pageHeight}px`,
                }}
              />
            )}
            {actionConfig.xalign === 'right' && !editing && (
              <VerticalLine
                id="rightLine"
                style={{
                  transform: `translateX(${rightMargin}px)`,
                  height: `${settings._pageHeight}px`,
                }}
              />
            )}
            {actionConfig.yalign === 'top' && !editing && (
              <HorizontalLine
                id="topLine"
                style={{
                  transform: `translateY(${topMargin}px)`,
                  width: `${settings._pageWidth}px`,
                }}
              />
            )}
            {actionConfig.yalign === 'bottom' && !editing && (
              <HorizontalLine
                id="bottomLine"
                style={{
                  transform: `translateY(${bottomMargin}px)`,
                  width: `${settings._pageWidth}px`,
                }}
              />
            )}
          </Page>
        </PageLayout>
      </PageLayoutWrapper>
    </Popup>
  )
}
