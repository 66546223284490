export const types = {
  GET_BARCODE_LOADING: 'GET_BARCODE_LOADING',
  GET_BARCODE_LOADED: 'GET_BARCODE_LOADED',
  BARCODE_RESET: 'BARCODE_RESET',
}

export default {
  getBarcodeLoading: () => ({
    type: types.GET_BARCODE_LOADING,
  }),
  getBarcodeLoaded: data => ({
    type: types.GET_BARCODE_LOADED,
    imgData: data,
  }),
  barcodeReset: () => ({
    type: types.BARCODE_RESET,
  }),
}
