import React, { useEffect } from 'react'
import Icon from '../../assets/Icons'
import $ from 'jquery'
import styled from 'styled-components'
const LeftArrow = styled(Icon)`
  font-weight: 1000;
   position: absolute;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    cursor: pointer;
    font-size: large;
    top: calc(50% - 20px);
     transition: opacity 0.3s ease;
`
const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
`
const Carousal = ({ refresh, children }) => {
  useEffect(() => {
    // jQuery logic
    var itemsMainDiv = '.MultiCarousel'
    var itemsDiv = '.MultiCarousel-inner'
    var itemWidth = ''

    $('.leftLst, .rightLst').click(function () {
      var condition = $(this).hasClass('leftLst')
      if (condition) click(0, this)
      else click(1, this)
    })

    ResCarouselSize()

    $(window).resize(function () {
      ResCarouselSize()
    })

    function ResCarouselSize() {
      var incno = 0
      var dataItems = 'data-items'
      var itemClass = '.drop_card'
      var id = 0
      var btnParentSb = ''
      var itemsSplit = ''
      var sampwidth = $(itemsMainDiv).width()
      var bodyWidth = $('body').width()
      $(itemsDiv).each(function () {
        id = id + 1
        var itemNumbers = $(this).find(itemClass).length
        btnParentSb = $(this).parent().attr(dataItems)
        itemsSplit = btnParentSb.split(',')
        $(this)
          .parent()
          .attr('id', 'MultiCarousel' + id)

        if (bodyWidth >= 1200) {
          incno = itemsSplit[3]
          itemWidth = sampwidth / incno
        } else if (bodyWidth >= 992) {
          incno = itemsSplit[2]
          itemWidth = sampwidth / incno
        } else if (bodyWidth >= 768) {
          incno = itemsSplit[1]
          itemWidth = sampwidth / incno
        } else {
          incno = itemsSplit[0]
          itemWidth = sampwidth / incno
        }
        $(this).css({
          transform: 'translateX(0px)',
          // width: ((itemWidth+30) * itemNumbers) ,
          display: 'flex',
          'column-gap': '32px',
        })

        $('.leftLst').addClass('over')
        $('.rightLst').removeClass('over')
      })
    }

    function ResCarousel(e, el, s) {
      var leftBtn = '.leftLst'
      var rightBtn = '.rightLst'
      var translateXval = ''
      var divStyle = $(el + ' ' + itemsDiv).css('transform')
      var values = divStyle.match(/-?[\d\.]+/g)
      var xds = Math.abs(values[4])
      if (e == 0) {
        translateXval = parseInt(xds) - parseInt(itemWidth * s)
        $(el + ' ' + rightBtn).removeClass('over')

        if (translateXval <= itemWidth / 2) {
          translateXval = 0
          $(el + ' ' + leftBtn).addClass('over')
        }
      } else if (e == 1) {
        var itemsCondition = $(el).find(itemsDiv).width() - $(el).width()
        translateXval = parseInt(xds) + parseInt(itemWidth * s)
        $(el + ' ' + leftBtn).removeClass('over')

        if (translateXval >= itemsCondition - itemWidth / 2) {
          translateXval = itemsCondition
          $(el + ' ' + rightBtn).addClass('over')
        }
      }
      $(el + ' ' + itemsDiv).css(
        'transform',
        'translateX(' + -translateXval + 'px)'
      )
    }

    function click(ell, ee) {
      var Parent = '#' + $(ee).parent().attr('id')
      var slide = $(Parent).attr('data-slide')
      ResCarousel(ell, Parent, slide)
    }

    // Cleanup function
    return () => {
      // Remove event listeners or perform any necessary cleanup
    }
  }, [refresh])
  return (
    <div className="container">
      <div className="row">
        <div
          className="MultiCarousel"
          data-items="1,3,5,6"
          data-slide="1"
          id="MultiCarousel"
          data-interval="1000"
        >
          <div className="MultiCarousel-inner">{children}</div>
          {refresh.group !== 'optimize' && (
            <>
              <LeftArrow toolName="LeftArrow" className={'leftLst'} />
              <RightArrow toolName="LeftArrow" className={'rightLst'} />
            </>
          )}

          {/* <img src={RightArrow} className="rightLst"/> 
          <button className="btn btn-primary leftLst">&#60;</button>
          <button className="btn btn-primary rightLst">&#62;</button> */}
        </div>
      </div>
    </div>
  )
}

export default Carousal
