import React, { useEffect, useMemo } from 'react'

import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import imageIcon from '../../../../controls/icons/image'
import textIcon from '../../../../controls/icons/text'
import textAndImageIcon from '../../../../controls/icons/textAndImage'

import { useJobConfig } from '../../../JobConfig/JobContext'
import t from './translate'

const PdfToWordConfig = ({ onChange, form, inputFiles }) => {
  const { updateActionConfig } = useJobConfig()
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  useEffect(() => {
    if (fileReady) {
      const values = {
        extract: 'imagetext',
      }
      updateActionConfig(values)
    }
  }, [fileReady])
  return (
    <FormSection>
      <FormRow label={t('lblExtract')} hint={t(`extractDesc_${form.extract}`)}>
        <ButtonGroup
          name="extract"
          items={[
            {
              id: 'btnExtractTextImages',
              value: 'imagetext',
              label: t('extractTextImages'),
              icon: textAndImageIcon,
            },
            {
              id: 'btnExtractText',
              value: 'text',
              label: t('extractText'),
              icon: textIcon,
            },
            {
              id: 'btnExtractImages',
              value: 'image',
              label: t('extractImages'),
              icon: imageIcon,
            },
          ]}
          type="button"
          value={form.extract}
          onChange={onChange}
        />
      </FormRow>
    </FormSection>
  )
}

export default PdfToWordConfig
