import { api } from '../initFetch'

export const getRecentOneDriveFiles = async (token, driveId, TenantId) => {
  const res = await api.post('/p4m/OneDrive/GetRecentFiles', {
    token,
    driveId,
    TenantId,
  })
  return res.json
}

export const getListItems = async (token, itemId, driveId, TenantId) => {
  const res = await api.post('/p4m/OneDrive/ListItems', {
    token,
    itemId,
    driveId,
    TenantId,
  })
  return res.json
}
