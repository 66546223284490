import { api } from '../initFetch'

export const uploadFile = async (
  Name,
  ItemPath,
  DriveId,
  DocBlobRef,
  Token,
  TenantId
) => {
  const res = await api.post('/p4m/OneDrive/UploadItem', {
    Name,
    ItemPath,
    DriveId,
    DocBlobRef,
    Token,
    TenantId,
  })
  return res.json
}
