import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M53.683 50.897h3.157v-3.09h6.788V44.72h-3.683v-3.09h-3.157v6.179h-3.105v3.039h-3.157v3.09h3.105v3.088h3.157v-3.089h-3.105v-3.04Z" />
        <path fill="currentColor" d="M61.47 54.185v6.826h-3.104v-6.826h3.105Zm-1.21 11.659h2.842v10.713h-2.894V65.844h-3.105v-3.089h3.157v3.09ZM47.79 76.557h-3.157v-3.09h3.104v-3.089h3.157v3.09H54v3.089h-3.157v-3.09H47.79v3.09Zm6.209 0h3.104v.05h3.157v3.089h-3.104v3.637h-3.157v-6.776Z" />
        <path fill="currentColor" d="M44.633 73.468h-3.157v-6.229h3.157v6.228Z" />
        <path fill="currentColor" fillRule="evenodd" d="M33.32 67.638v9.716h-9.68v-9.716h9.681Zm-3 3h-3.68v3.716h3.681v-3.716Z" clipRule="evenodd" />
        <path fill="currentColor" fillRule="evenodd" d="M39.266 83.432H17.482V61.61h21.784v21.823Zm-3.21-18.634H20.692v15.446h15.364V64.798Z" clipRule="evenodd" />
        <path fill="currentColor" d="M39.056 56.876h-7.051v2.74h-2.789v-2.74h2.736v-2.69h7.104v-6.179h3.157v3.09h3.683v3.088h-6.788v2.69h2.736v2.741h-2.788v-2.74Z" />
        <path fill="currentColor" fillRule="evenodd" d="M29.216 56.876H26.48V52.59h-2.841v3.139h-6.157V44.02h3.105v3.09h3.052v2.69h5.577v7.075Zm-8.578-4.146h-.156v-2.62h.157v2.62Z" clipRule="evenodd" />
        <path fill="currentColor" d="M29.216 49.8v-2.74h2.526v-4.334h2.736V49.8h-5.262Z" />
        <path fill="currentColor" fillRule="evenodd" d="M23.691 34.206V24.49h9.682v9.716H23.69Zm3-3V27.49h3.682v3.716H26.69Z" clipRule="evenodd" />
        <path fill="currentColor" fillRule="evenodd" d="M17.482 40.284V18.461h21.784v21.823H17.482Zm3.21-3.189h15.364V21.7H20.692v15.395Z" clipRule="evenodd" />
        <path fill="currentColor" d="M42.002 18.46h7.156v5.232H46.37v-2.54h-4.367V18.46Zm8.735 6.428h3.157v3.09h2.841V41.58H45.948V35.8h3.105v2.89h4.788V27.977h-3.104v-3.089Z" />
        <path fill="currentColor" fillRule="evenodd" d="M66.785 34.206V24.49h9.682v9.716h-9.682Zm3-6.716v3.716h3.682V27.49h-3.682Z" clipRule="evenodd" />
        <path fill="currentColor" fillRule="evenodd" d="M82.413 40.284H60.629V18.461h21.784v21.823Zm-18.574-3.189h15.364V21.7H63.839v15.395Z" clipRule="evenodd" />
        <path fill="currentColor" d="M70.784 41.978v2.74h-7.103v-2.74h7.103Zm-.947 12.207h-3.105v-3.039h-3.104v-3.139h3.104v3.04h3.105v3.138Zm-6.63 5.033v-2.691h7.156v5.232h-2.789v-2.541h-4.367Zm7.156 2.541h7.156v2.69h-7.156v-2.69Zm4.42-9.816h2.736v7.026h2.789v2.74h-2.789v-2.69h-2.736v-7.076Zm2.737-5.53h2.788v5.53h-2.789v-5.53Zm0 0h-3.105v-3.14h3.104v3.14Zm-30.098 1.295v3.09h3.157v-3.09h-3.157Zm-4.578-2.989v-3.09h3.157v3.09h-3.157Zm0-8.969v-3.09h3.104v-3.088h3.157v3.089h-3.104v3.09h-3.157Zm4.578 21.325h6.261v9.716h-3.157v-6.627h-3.104v-3.089Zm26.57 23.408v-5.43h5.42v5.43h-5.42Z" />
        <path fill="currentColor" fillRule="evenodd" d="M70.571 71.714h12.103v12.157H70.57V71.715Zm1.737 1.794h8.576v8.57h-8.576v-8.57Z" clipRule="evenodd" />
        <path fill="currentColor" fillRule="evenodd" d="M7 7v86h86V7H7Zm3 3h80v80H10V10Z" clipRule="evenodd" />
      </svg>



    </div>
  )
}
