import { createDarkTheme, createLightTheme } from '@fluentui/react-components'

const myNewTheme = {
  10: '#020205',
  20: '#131623',
  30: '#1B243D',
  40: '#222F54',
  50: '#273B6B',
  60: '#2D4883',
  70: '#32559D',
  80: '#3962B4',
  90: '#516FBB',
  100: '#657DC3',
  110: '#788BCA',
  120: '#8A99D1',
  130: '#9CA8D8',
  140: '#AEB7DF',
  150: '#BFC6E6',
  160: '#D1D6ED',
}

const lightTheme = {
  ...createLightTheme(myNewTheme),
}

const darkTheme = {
  ...createDarkTheme(myNewTheme),
}

darkTheme.colorBrandForeground1 = myNewTheme[110] // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = myNewTheme[120] // use brand[120] instead of brand[110]

const Theme = {
  darkTheme,
  lightTheme,
}

export default Theme
