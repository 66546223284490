export const isEmail = (value) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}

export const minLength = (value, length) => {}

export const maxLength = (value, length) => {}

const lowerCaseExp = new RegExp('[a-z]')
const upperCaseExp = new RegExp('[A-Z]')
const numberExp = new RegExp('[0-9]')
const specialCharExp = new RegExp('[!@#$%^&*]')

export const validatePasswordPolicy = (password = '') => {
  const res = {
    tests: {
      hasLower: lowerCaseExp.test(password),
      hasUpper: upperCaseExp.test(password),
      hasNumber: numberExp.test(password),
      hasSpecialChar: specialCharExp.test(password),
      hasMinLength: password.length >= 8,
    },
    isValid: false,
    strength: 0,
  }
  res.strength += res.tests.hasLower ? 1 : 0
  res.strength += res.tests.hasUpper ? 1 : 0
  res.strength += res.tests.hasNumber ? 1 : 0
  res.strength += res.tests.hasSpecialChar ? 1 : 0
  res.strength += res.tests.hasMinLength ? 1 : 0
  res.isValid = res.strength >= 5

  return res
}
