import mutations from './mutations'
import { createBarcode } from '../../service/document/createBarcode'

export default {
  getBarcode:
    (barcodeType, text, barcodeColor, backgroundColor, hideText) =>
    async (dispatch, getState) => {
      let data = ''

      dispatch(mutations.getBarcodeLoading())
      try {
        data = await createBarcode(
          barcodeType,
          text,
          barcodeColor,
          backgroundColor,
          hideText
        )
      } catch (error) {
        const msg = error.message
      } finally {
        dispatch(mutations.getBarcodeLoaded(data))
      }
      return data
    },

  resetBarcode: () => async (dispatch) => {
    dispatch(mutations.barcodeReset())
  },
}
