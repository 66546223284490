import React from 'react'
import styled from 'styled-components'

import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import InputFormRow from '../../../../controls/InputFormRow'
import splitIcon from '../../../../controls/icons/split'
import repeateIcon from '../../../../controls/icons/repeate'
import barcodeIcon from '../../../../controls/icons/barcodeRead'
import splitTextIcon from '../../../../controls/icons/text'
import t from './translate'
const FilterText = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 5px;
`
const SplitPdfConfig = ({
  form,
  onChange,
  isPristine,
  disabled,
  inputFiles,
}) => {
  const errors = {}
  const filterType = [
    { key: 'startsWith', value: t('filterStartsWith') },
    { key: 'endsWith', value: t('filterEndsWith') },
    { key: 'contains', value: t('filterContains') },
    { key: 'exact', value: t('filterExact') },
  ]

  const barCodeType = [
    { key: 'any', value: t('barCodeTypeAny') },
    { key: 'datamatrix', value: t('barCodeTypeDatamatrix') },
    { key: 'qrcode', value: t('barCodeTypeqrcode') },
    { key: 'pdf417', value: 'PDF417' },
  ]

  const splitPageType = [
    { key: 'before', value: t('splitBefore') },
    { key: 'after', value: t('splitAfter') },
    { key: 'remove', value: t('splitRemove') },
  ]

  const splitTextPageType = [
    { key: 'before', value: t('splitBeforeText') },
    { key: 'after', value: t('splitAfterText') },
  ]


  const pageCount =
    inputFiles && inputFiles.length > 0
      ? inputFiles[0]?.docMetadata?.pageCount ?? 0
      : 0



  if (form.splitType === 'byPageNr') {
    let splitByPageNr = form.splitByPageNr.trim()

    if (splitByPageNr.indexOf('.') > -1) {
      errors.splitByPageNr = `${t('errNumberDecimal')}`
    } else if (parseInt(splitByPageNr, 10) < 1) {
      errors.splitByPageNr = `${t('errNumberZero')}`
    } else if (parseInt(splitByPageNr, 10) > 2147483647) {
      errors.splitByPageNr = `${t('errNumberHigh')} 2147483647`
    } else if (splitByPageNr === '' || isNaN(splitByPageNr)) {
      if (splitByPageNr.indexOf(',') > 0) {
        const pageNrStr = splitByPageNr.split(',')
        for (let i = 0; i < pageNrStr.length; i += 1) {
          const num = parseInt(pageNrStr[i].trim(), 10)
          if (isNaN(num) || num < 0) {
            errors.splitByPageNr = `${t('errInvalidSequence')}`
            break
          }
          if (num === 0) {
            errors.splitByPageNr = `${t('errNumberZero')}`
            break
          }
          if (pageCount && num >= pageCount) {
            errors.splitByPageNr = t('docError_InvalidSplitAfterPage')
            break
          }
        }
      } else {
        errors.splitByPageNr = t('errInvalidNumber')
      }
    } else if (pageCount && parseInt(splitByPageNr, 10) >= pageCount) {
      errors.splitByPageNr = t('docError_InvalidSplitAfterPage')
    }
  } else if (form.splitType === 'recurring') {
    let splitRecurring = form.splitRecurring.trim()
    if (splitRecurring.indexOf('.') > -1) {
      errors.splitRecurring = `${t('errNumberDecimal')}`
    } else if (parseInt(splitRecurring, 10) < 1) {
      errors.splitRecurring = `${t('errNumberZero')}`
    } else if (parseInt(splitRecurring, 10) > 2147483647) {
      errors.splitRecurring = `${t('errNumberHigh')} 2147483647`
    } else if (splitRecurring === '' || isNaN(splitRecurring)) {
      errors.splitRecurring = t('errInvalidNumber')
    } else if (pageCount && parseInt(splitRecurring, 10) >= pageCount) {
      errors.splitRecurring = t('docError_InvalidSplitAfterPage')
    }
  } else if (form.splitType === 'byBarcode') {
    // if (form.splitBarcodeText === '') {
    //   errors.splitBarcodeText = 'Text is empty'
    // }
    //  else if (form.splitBarcodeFilterType === '') {
    //   errors.splitBarcodeFilterType = 'Filter is empty'
    // } else if (form.splitBarcodeType === '') {
    //   errors.splitBarcodeType = 'Type is empty'
    // } else
    if (form.splitBarcodePage === '') {
      errors.splitBarcodePage = t('SplitTypeEmpty')
    }
    // } else if (form.splitType === 'extractPages') {
  } else if (form.splitType === 'byText') {
    if (form.splitText === '') {
      errors.splitText = t('SplitTextEmpty')
    }
  }
  return (
    <>
      <FormRow hint={t(`splitTypeDesc_${form.splitType}`)}>
        <ButtonGroup
          name="splitType"
          items={[
            {
              id: 'btnSplitByPageNr',
              value: 'byPageNr',
              label: 'By Page Nr',
              icon: splitIcon,
            },
            {
              id: 'btnSplitRecurring',
              value: 'recurring',
              label: 'Split Recurring',
              icon: repeateIcon,
            },
            {
              id: 'btnSplitByBarcode',
              value: 'byBarcode',
              label: 'By Barcode',
              icon: barcodeIcon,
            },
            {
              id: 'btnSplitByText',
              value: 'byText',
              label: 'By Text',
              icon: splitTextIcon,
            },
          ]}
          value={form.splitType}
          type="button"
          onChange={onChange}
        />
      </FormRow>
      {form.splitType === 'byPageNr' && (
        <>
          <InputFormRow
            label={`${t('lblSplitAfterPageNr')}:`}
            errorMessage={isPristine ? undefined : errors.splitByPageNr}
          >
            <input
              type="text"
              name="splitByPageNr"
              value={form.splitByPageNr}
              disabled={disabled}
              onChange={onChange}
            />
          </InputFormRow>
          {/* <Button onClick={openPagePicker} disabled={disabled}>
        {t('btnSelectPages')}
      </Button> */}
        </>
      )}
      {form.splitType === 'recurring' && (
        <InputFormRow
          label={`${t('lblSplitRecurringNr')}:`}
          errorMessage={isPristine ? undefined : errors.splitRecurring}
        >
          <input
            type="text"
            name="splitRecurring"
            value={form.splitRecurring}
            disabled={disabled}
            onChange={onChange}
          />
        </InputFormRow>
      )}
      {form.splitType === 'byBarcode' && (
        <>
          <InputFormRow
            label={`${t('lblSplitBarcodePage')}:`}
            errorMessage={isPristine ? undefined : errors.splitBarcodePage}
          >
            <select
              id="drpSplitBarcodePage"
              name="splitBarcodePage"
              value={form.splitBarcodePage}
              onChange={onChange}
              disabled={disabled}
            >
              {form.splitBarcodePage === '' && (
                <option value="">{t('splitBarcodeSelectPageType')}</option>
              )}
              {splitPageType.map((type) => (
                <option value={type.key} key={type.key}>
                  {type.value}
                </option>
              ))}
            </select>
          </InputFormRow>
          <InputFormRow label={`${t('lblSplitBarcodeType')}:`}>
            <select
              name="splitBarcodeType"
              value={form.splitBarcodeType}
              onChange={onChange}
              disabled={disabled}
            >
              {barCodeType.map((type) => (
                <option value={type.key} key={type.key}>
                  {type.value}
                </option>
              ))}
            </select>
          </InputFormRow>
          <InputFormRow label={`${t('lblSplitBarcodeFilter')}:`}>
            <FilterText>
              <select
                name="splitBarcodeFilterType"
                value={form.splitBarcodeFilterType}
                onChange={onChange}
                disabled={disabled}
              >
                {filterType.map((type) => (
                  <option value={type.key} key={type.key}>
                    {type.value}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="splitBarcodeText"
                value={form.splitBarcodeText}
                onChange={onChange}
                placeholder={t('AnyText')}
                disabled={disabled}
              />
            </FilterText>
          </InputFormRow>
        </>
      )}
      {form.splitType === 'byText' && (
        <>
          <InputFormRow
            label={`${t('lblSplitBarcodePage')}:`}
            errorMessage={isPristine ? undefined : errors.splitTextPage}
          >
            <select
              id="drpSplitTextPage"
              name="splitTextPage"
              value={form.splitTextPage}
              onChange={onChange}
              disabled={disabled}
            >
              {form.splitTextPage === '' && (
                <option value="">{t('splitTextPageType')}</option>
              )}
              {splitTextPageType.map((type) => (
                <option value={type.key} key={type.key}>
                  {type.value}
                </option>
              ))}
            </select>
          </InputFormRow>
          <InputFormRow
            label={`${t('lblSplitText')}:`}
            errorMessage={isPristine ? undefined : errors.splitText}
          >
            <input
              type="text"
              name="splitText"
              value={form.splitText}
              disabled={disabled}
              placeholder={t('anyTextOrExpression')}
              onChange={onChange}
            />
          </InputFormRow>
        </>
      )}
    </>
  )
}

export default SplitPdfConfig
