import styled from 'styled-components'
import { colors, layout } from '../../theme'

const Root = styled.li`
  position: relative;
  padding: 20px 5px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Thumbnail = styled.img`
  max-width: 100%;
  max-height: 100%;
  border: solid 2px ${colors.bgLight};
  cursor: pointer;
  .selected & {
    border-color: ${colors.secondary};
  }
  &:hover {
    box-shadow: ${layout.elevate(2)};
  }
`

const PageNumber = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
`

export const PageListItem = ({ page, thumbnails, onToggle }) => (
  <Root className={page.selected ? 'selected' : undefined}>
    <Thumbnail
      src={thumbnails[page.nr]?.thumbnail}
      onClick={() => {
        onToggle(page.nr)
      }}
    />
    <PageNumber>{page.nr}</PageNumber>
  </Root>
)
