export const icons = {
  compress: {
    w: 448,
    h: 512,
    p: 'M9.171 476.485l-5.656-5.656c-4.686-4.686-4.686-12.284 0-16.971L169.373 288H108c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h100c8.837 0 16 7.163 16 16v100c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-61.373L26.142 476.485c-4.687 4.687-12.285 4.687-16.971 0zM240 256h100c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12h-61.373L444.485 58.142c4.686-4.686 4.686-12.284 0-16.971l-5.656-5.656c-4.686-4.686-12.284-4.686-16.971 0L256 201.373V140c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v100c0 8.837 7.163 16 16 16z',
  },
  compressMax: {
    w: 512,
    h: 512,
    p: 'M312 224h112c21.4 0 32.1-25.9 17-41l-44.7-44.7L509.7 25c3.1-3.1 3.1-8.2 0-11.3L498.3 2.3c-3.1-3.1-8.2-3.1-11.3 0L373.6 115.7 329 71.1c-4.9-4.9-10.9-7.1-16.8-7.1-12.3 0-24.2 9.6-24.2 24.1v112c0 13.2 10.7 23.9 24 23.9zm8-116.7l84.7 84.7H320v-84.7zm76.3 266.3l44.7-44.7c4.9-4.9 7.1-10.9 7.1-16.8 0-12.3-9.6-24.2-24.1-24.2H312c-13.3 0-24 10.7-24 24v112c0 21.4 25.9 32.1 41 17l44.7-44.7L487 509.7c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3L396.3 373.6zM320 404.7V320h84.7L320 404.7zM200 288H88c-21.4 0-32.1 25.9-17 41l44.7 44.7L2.3 487c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l113.4-113.4 44.7 44.7c4.9 4.9 10.9 7.1 16.8 7.1 12.3 0 24.2-9.6 24.2-24.1V312c0-13.3-10.7-24-24-24zm-8 116.7L107.3 320H192v84.7zm-9-333.6l-44.7 44.7L25 2.3c-3.1-3.1-8.2-3.1-11.3 0L2.3 13.7c-3.1 3.1-3.1 8.2 0 11.3l113.4 113.4L71.1 183c-4.9 4.9-7.1 10.9-7.1 16.8 0 12.3 9.6 24.2 24.1 24.2h112c13.3 0 24-10.7 24-24V88c-.1-21.3-26-32-41.1-16.9zm9 120.9h-84.7l84.7-84.7V192z',
  },
  print: {
    w: 512,
    h: 512,
    p: 'M432 192h-16v-82.75c0-8.49-3.37-16.62-9.37-22.63L329.37 9.37c-6-6-14.14-9.37-22.63-9.37H126.48C109.64 0 96 14.33 96 32v160H80c-44.18 0-80 35.82-80 80v96c0 8.84 7.16 16 16 16h80v112c0 8.84 7.16 16 16 16h288c8.84 0 16-7.16 16-16V384h80c8.84 0 16-7.16 16-16v-96c0-44.18-35.82-80-80-80zM320 45.25L370.75 96H320V45.25zM128.12 32H288v64c0 17.67 14.33 32 32 32h64v64H128.02l.1-160zM384 480H128v-96h256v96zm96-128H32v-80c0-26.47 21.53-48 48-48h352c26.47 0 48 21.53 48 48v80zm-80-88c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24z',
  },
  previewsPage: {
    w: 448,
    h: 512,
    p: 'M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z',
  },
  nextPage: {
    w: 448,
    h: 512,
    p: 'M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z',
  },
  zoomIn: {
    w: 512,
    h: 512,
    p: 'M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z',
  },
  zoomOut: {
    w: 512,
    h: 512,
    p: 'M140 274c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v12c0 6.6-5.4 12-12 12H140zm364-18c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z',
  },
  fitHeight: {
    w: 512,
    h: 512,
    p: 'M504 64H96V8c0-4.4-3.6-8-8-8H72c-4.4 0-8 3.6-8 8v56H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h56v408c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h96v48h-16c-17.7 0-32 14.3-32 32v176c0 17.7 14.3 32 32 32h288c17.7 0 32-14.3 32-32V176c0-17.7-14.3-32-32-32h-16V96h56c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm-40 288H176V176h288v176zm-48-208H224V96h192v48z',
  },
  ellipsis: {
    w: 192,
    h: 512,
    p: 'M96 152c39.8 0 72-32.2 72-72S135.8 8 96 8 24 40.2 24 80s32.2 72 72 72zm0-112c22.1 0 40 17.9 40 40s-17.9 40-40 40-40-17.9-40-40 17.9-40 40-40zm0 144c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm0 112c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm0 64c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm0 112c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40z',
  },
  editPdf: {
    w: 576,
    h: 512,
    p: 'M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z',
  },
  fitWidth: {
    w: 512,
    h: 512,
    p: 'M399.959 170.585c-4.686 4.686-4.686 12.284 0 16.971L451.887 239H60.113l51.928-51.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-84.485 84c-4.686 4.686-4.686 12.284 0 16.971l84.485 84c4.686 4.686 12.284 4.686 16.97 0l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273h391.773l-51.928 51.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l84.485-84c4.687-4.686 4.687-12.284 0-16.971l-84.485-84c-4.686-4.686-12.284-4.686-16.97 0l-7.07 7.071z',
  },
  fitPage: {
    w: 448,
    h: 512,
    p: 'M198.829 275.515l5.656 5.656c4.686 4.686 4.686 12.284 0 16.971L54.627 448H116c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12H12c-6.627 0-12-5.373-12-12V364c0-6.627 5.373-12 12-12h8c6.627 0 12 5.373 12 12v61.373l149.858-149.858c4.687-4.687 12.285-4.687 16.971 0zM436 32H332c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h61.373L243.515 213.858c-4.686 4.686-4.686 12.284 0 16.971l5.656 5.656c4.686 4.686 12.284 4.686 16.971 0L416 86.627V148c0 6.627 5.373 12 12 12h8c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12z',
  },
  outline: {
    w: 512,
    h: 512,
    p: 'M88 56H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16V72a16 16 0 0 0-16-16zm0 160H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16zm0 160H40a16 16 0 0 0-16 16v48a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16zm416 24H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-320H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8zm0 160H168a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h336a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z',
  },
  upload: {
    w: 512,
    h: 512,
    p: 'M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h124v-99.3h-52.7c-35.6 0-53.4-43.1-28.3-68.3L227.7 11.7c15.6-15.6 40.9-15.6 56.6 0L425 152.4c25.2 25.2 7.3 68.3-28.3 68.3H344V320h124c24.3 0 44 19.7 44 44zM200 188.7V376c0 4.4 3.6 8 8 8h96c4.4 0 8-3.6 8-8V188.7h84.7c7.1 0 10.7-8.6 5.7-13.7L261.7 34.3c-3.1-3.1-8.2-3.1-11.3 0L109.7 175c-5 5-1.5 13.7 5.7 13.7H200zM480 364c0-6.6-5.4-12-12-12H344v24c0 22.1-17.9 40-40 40h-96c-22.1 0-40-17.9-40-40v-24H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z',
  },
  // outline: {
  //   w: 512,
  //   h: 512,
  //   p:
  //     'M464 64c8.823 0 16 7.178 16 16v352c0 8.822-7.177 16-16 16H48c-8.823 0-16-7.178-16-16V80c0-8.822 7.177-16 16-16h416m0-32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm-336 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm0 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm288-148v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6zm0 96v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6zm0 96v-24a6 6 0 0 0-6-6H198a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h212a6 6 0 0 0 6-6z',
  // },
  close: {
    w: 320,
    h: 512,
    p: 'M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z',
  },
  dropdownCaret: {
    w: 320,
    h: 512,
    p: 'M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z',
  },
  download: {
    w: 512,
    h: 512,
    p: 'M452 432c0 11-9 20-20 20s-20-9-20-20 9-20 20-20 20 9 20 20zm-84-20c-11 0-20 9-20 20s9 20 20 20 20-9 20-20-9-20-20-20zm144-48v104c0 24.3-19.7 44-44 44H44c-24.3 0-44-19.7-44-44V364c0-24.3 19.7-44 44-44h99.4L87 263.6c-25.2-25.2-7.3-68.3 28.3-68.3H168V40c0-22.1 17.9-40 40-40h96c22.1 0 40 17.9 40 40v155.3h52.7c35.6 0 53.4 43.1 28.3 68.3L368.6 320H468c24.3 0 44 19.7 44 44zm-261.7 17.7c3.1 3.1 8.2 3.1 11.3 0L402.3 241c5-5 1.5-13.7-5.7-13.7H312V40c0-4.4-3.6-8-8-8h-96c-4.4 0-8 3.6-8 8v187.3h-84.7c-7.1 0-10.7 8.6-5.7 13.7l140.7 140.7zM480 364c0-6.6-5.4-12-12-12H336.6l-52.3 52.3c-15.6 15.6-41 15.6-56.6 0L175.4 352H44c-6.6 0-12 5.4-12 12v104c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12V364z',
  },
  caretDown: {
    w: 320,
    h: 512,
    p: 'M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z',
  },
  caretRight: {
    w: 192,
    h: 512,
    p: 'M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z',
  },
  text: {
    w: 448,
    h: 512,
    p: 'M448 48v72a8 8 0 0 1-8 8h-16a8 8 0 0 1-8-8V64H240v384h72a8 8 0 0 1 8 8v16a8 8 0 0 1-8 8H136a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h72V64H32v56a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V48a16 16 0 0 1 16-16h416a16 16 0 0 1 16 16z',
  },
  signature: {
    w: 640,
    h: 512,
    p: 'M630.1 206.8c-32.7 8.4-77.1 30.8-108.7 49.6-14.7 8.8-29.9 17.9-41.5 23.4-31.3 15.2-58.3 28.2-84.1 28.2-12.9 0-23-4.3-29.9-12.7-11.3-13.8-11.3-35.3-7.9-63.3 3.3-26.9.6-47.8-7.6-57.3-4.4-5-10-6.9-18.7-6.3C307 170 257 210.8 169.6 300.9l-54.4 56 70-187.6c11.8-31.6 3.6-66.1-20.9-87.8C145 64.3 112 54.3 77.3 78L3.5 127.8c-3.5 2.3-4.5 7-2.5 10.6l9.2 16.2c2.3 4.1 7.6 5.3 11.5 2.7L97.5 106c6.6-4.5 14-6.8 21.4-6.8 9.1 0 17.6 3.4 24.8 9.8 13.2 11.7 17.6 30.2 11.3 47.1L55.2 423.7c-1.9 5.2-1 12.6 2.2 17.7 2.4 3.7 6.6 6.1 11.3 6.6 4.9.3 9.7-1.4 13-4.9C125 396.8 239.5 278.4 298 228.4l20.4-17.4c3.4-2.9 8.5-.3 8.2 4.1l-2.1 27.9c-2 27.3-2.4 55.9 16.8 78.6 12.4 14.5 30.7 21.9 54.6 21.9 32.7 0 64.1-15.1 97.3-31.1 10.2-4.9 24.9-14.1 39.2-23 30.9-19.3 72.3-40.5 101.8-47.7 3.5-.9 5.9-4 5.9-7.6v-17.3c-.1-7.4-5-11.2-10-10z',
  },
  trash: {
    w: 448,
    h: 512,
    p: 'M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z',
  },
  plus: {
    w: 384,
    h: 512,
    p: 'M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z',
  },
  ok: {
    w: 448,
    h: 512,
    p: 'M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z',
  },
  stickyNote: {
    w: 512,
    h: 512,
    p: 'M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm32 352c0 17.6-14.4 32-32 32H293.3l-8.5 6.4L192 460v-76H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32v288zM280 240H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h144c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm96-96H136c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z',
  },

  highlighter: {
    w: 544,
    h: 512,
    p: 'M528.61 75.91l-60.49-60.52C457.91 5.16 444.45 0 430.98 0a52.38 52.38 0 0 0-34.75 13.15L110.59 261.8c-10.29 9.08-14.33 23.35-10.33 36.49l12.49 41.02-36.54 36.56c-6.74 6.75-6.74 17.68 0 24.43l.25.26L0 479.98 99.88 512l43.99-44.01.02.02c6.75 6.75 17.69 6.75 24.44 0l36.46-36.47 40.91 12.53c18.01 5.51 31.41-4.54 36.51-10.32l248.65-285.9c18.35-20.82 17.37-52.32-2.25-71.94zM91.05 475.55l-32.21-10.33 40.26-42.03 22.14 22.15-30.19 30.21zm167.16-62.99c-.63.72-1.4.94-2.32.94-.26 0-.54-.02-.83-.05l-40.91-12.53-18.39-5.63-39.65 39.67-46.85-46.88 39.71-39.72-5.6-18.38-12.49-41.02c-.34-1.13.01-2.36.73-3l44.97-39.15 120.74 120.8-39.11 44.95zm248.51-285.73L318.36 343.4l-117.6-117.66L417.4 37.15c4.5-3.97 17.55-9.68 28.1.88l60.48 60.52c7.65 7.65 8.04 20 .74 28.28z',
  },
  pencil: {
    w: 512,
    h: 512,
    p: 'M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zm-95.196 140.45L174 420.745V386h-48v-48H91.255l224.059-224.059 82.745 82.745zM126.147 468.598l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z',
  },
  freeText: {
    w: 512,
    h: 512,
    p: 'm 460,81 v 72 c 0,4.42 -3.58,8 -8,8 h -16 c -4.42,0 -8,-3.58 -8,-8 V 97 H 272 v 338 h 72 c 4.42,0 8,3.58 8,8 v 16 c 0,4.42 -3.58,8 -8,8 H 168 c -4.42,0 -8,-3.58 -8,-8 v -16 c 0,-4.42 3.58,-8 8,-8 h 72 V 97 H 84 v 56 c 0,4.42 -3.582,8 -8,8 H 60 c -4.418,0 -8,-3.58 -8,-8 V 81 c 0,-8.837 7.163,-16 16,-16 h 376 c 8.84,0 16,7.163 16,16 z',
  },
  stamp: {
    w: 508,
    h: 512,
    p: 'm 333.71644,332.74589 h 121.26625 l 52.08896,87.08726 H 508.009 V 512.0005 H 0 V 419.83315 H 0.80598227 L 54.686486,332.74589 H 178.89175 V 241.68498 C 139.83419,229.2037 120.83357,188.0785 120.83357,122.28502 120.83357,50.03435 163.8049,8.54414 246.15327,0.3862 V 0 h 4.2226 14.81043 v 0.26345 c 83.1756,7.85642 126.58831,49.40078 126.58831,122.02157 0,65.79348 -19.00062,106.91868 -58.05817,119.39996 z m 0,26.85191 v 11.42093 l -0.57524,1.90136 c -7.77664,25.70458 -26.80014,40.76443 -54.91507,44.75071 -3.13022,0.44382 -6.37313,0.75038 -9.72577,0.91907 2e-5,0.0515 5e-5,0.10303 8e-5,0.15469 h -18.12457 -4.2226 c 0,-0.0231 0,-0.0462 0,-0.0693 -34.59286,-1.15044 -57.8689,-16.6105 -66.68629,-45.75514 l -0.57523,-1.90136 V 359.5978 H 70.109423 L 33.186454,419.83315 H 474.69705 l -35.1373,-60.23535 z m -72.20597,-332.75872 -5.41897,-0.0189 -4.95803,0.0158 C 180.53972,32.90225 147.68548,63.8059 147.68548,122.285 c 0,60.26373 16.08094,90.41551 46.87034,95.62098 l 11.18782,1.8915 v 147.12358 c 5.83066,16.85951 19.64352,24.97158 44.63223,24.97158 h 5.92822 5.92822 c 24.9887,0 38.80158,-8.11207 44.63222,-24.97158 V 219.79748 l 11.18782,-1.8915 c 30.7894,-5.20547 46.87035,-35.35725 46.87035,-95.62098 0,-58.46923 -32.84314,-89.37232 -103.41223,-95.44594 z M 26.96748,485.1486 H 481.04152 V 446.68506 H 26.96748 Z',
  },
  image: {
    w: 512,
    h: 512,
    p: 'M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm16 336c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V112c0-8.822 7.178-16 16-16h416c8.822 0 16 7.178 16 16v288zM112 232c30.928 0 56-25.072 56-56s-25.072-56-56-56-56 25.072-56 56 25.072 56 56 56zm0-80c13.234 0 24 10.766 24 24s-10.766 24-24 24-24-10.766-24-24 10.766-24 24-24zm207.029 23.029L224 270.059l-31.029-31.029c-9.373-9.373-24.569-9.373-33.941 0l-88 88A23.998 23.998 0 0 0 64 344v28c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-92c0-6.365-2.529-12.47-7.029-16.971l-88-88c-9.373-9.372-24.569-9.372-33.942 0zM416 352H96v-4.686l80-80 48 48 112-112 80 80V352z',
  },
  shapes: {
    w: 512,
    h: 512,
    p: 'M480 288H320c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32V320c0-17.67-14.33-32-32-32zm0 192H320V320h160v160zM128 256C57.31 256 0 313.31 0 384s57.31 128 128 128 128-57.31 128-128-57.31-128-128-128zm0 224c-52.93 0-96-43.07-96-96 0-52.94 43.07-96 96-96 52.94 0 96 43.06 96 96 0 52.93-43.06 96-96 96zm378.98-278.86L400.07 18.29C392.95 6.1 380.47 0 368 0s-24.95 6.1-32.07 18.29L229.02 201.14c-14.26 24.38 3.56 54.86 32.07 54.86h213.82c28.51 0 46.33-30.48 32.07-54.86zm-27.6 20.39c-.94 1.64-2.45 2.47-4.47 2.47H261.09c-2.02 0-3.53-.83-4.47-2.47-1.21-2.12-.35-3.6.02-4.23L363.55 34.44c.95-1.62 2.44-2.44 4.45-2.44s3.5.82 4.45 2.44L479.36 217.3c.37.63 1.24 2.11.02 4.23z',
  },
  imageCapture: {
    w: 512,
    h: 512,
    p: 'M324.3 64c3.3 0 6.3 2.1 7.5 5.2l22.1 58.8H464c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h110.2l20.1-53.6c2.3-6.2 8.3-10.4 15-10.4h131m0-32h-131c-20 0-37.9 12.4-44.9 31.1L136 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26zM256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-208c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88z',
  },
  addQrCode: {
    w: 448,
    h: 512,
    p: 'M0 224h192V32H0v192zM32 64h128v128H32V64zm224-32v192h192V32H256zm160 160H288V64h128v128zM0 480h192V288H0v192zm32-160h128v128H32V320zM64 96h64v64H64V96zm320 64h-64V96h64v64zM64 352h64v64H64v-64zm352-64h32v128H320v-32h-32v96h-32V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z',
  },
  dropbox: {
    w: 512,
    h: 512,
    p: 'M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132 84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z',
  },
  googleDrive: {
    w: 512,
    h: 512,
    p: 'M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z',
  },
  oneDrive: {
    w: 640,
    h: 512,
    p: 'M219.274 517.046c-37.678-9.402-58.642-39.367-58.725-83.8 0-14.197 1.004-21 4.57-30.166 8.682-22.406 31.714-39.272 61.998-45.52 15.07-3.083 19.712-6.39 19.712-14.114 0-2.41 1.808-9.603 4.016-15.97 9.957-28.972 28.406-53.078 48.072-62.917 20.61-10.335 31.04-12.638 55.961-12.52 35.445.189 53.091 7.878 77.8 33.827l13.607 14.292 12.153-4.205c58.926-20.398 117.686 14.315 122.446 72.355l1.288 15.874 11.598 4.158c33.154 11.882 48.722 36.803 45.91 73.477-1.842 23.965-13.05 43.11-30.791 52.595l-8.363 4.477-185.518.366c-142.56.283-187.88-.248-195.723-2.209h-.011zM64.512 490.53c-22.607-5.693-46.571-26.811-57.804-50.918-6.366-13.69-6.72-15.732-6.72-39.934 0-23.043.555-26.716 5.586-38.161 10.678-24.107 31.205-41.505 56.871-48.39 5.445-1.43 10.524-3.757 11.327-5.127.803-1.358 1.724-8.835 1.996-16.595 1.795-48.248 31.76-90.757 73.572-104.41 22.595-7.359 52.229-8.068 76.796 2.314 7.795 3.284 6.921 4.004 23.398-18.838 9.768-13.512 28.205-27.804 44.327-36.355 17.41-9.237 35.493-13.489 57.119-13.441 60.497.165 112.608 40.122 131.884 101.15 6.154 19.489 5.835 24.97-1.406 25.135-3.165.07-12.248 1.913-20.197 4.075l-14.445 3.968-13.146-13.925c-37.122-39.201-97.642-47.682-149.128-20.882-20.563 10.724-37.087 26.079-49.56 46.122-8.881 14.292-20.244 40.961-20.244 47.564 0 4.689-3.602 7.051-18.956 12.366-47.564 16.477-75.32 54.568-75.273 103.29 0 17.716 4.323 39.449 10.323 51.838 2.28 4.69 3.52 9.119 2.8 9.886-1.843 1.973-61.005 1.288-69.202-.756l.082.024z',
  },
}
