import styled from 'styled-components'
import { TableCellLayout } from '@fluentui/react-components'

import { colors, fontSize } from '../../theme'
import FileIcon from '../File/FileIcon'
import Icons from '../../assets/Icons'
import Button from '../../controls/Button'

export const CustomFileIcon = styled(FileIcon)`
  width: 32px;
  height: 32px;
`
export const TheeDotsCellLayout = styled(TableCellLayout)`
  justify-content: flex-end;
`
export const CustomTableTd = styled.td`
  text-align: center;
  margin: 20px 0;
`
export const NoDataText = styled.span`
  color: ${colors.textMuted};
  font-size: ${fontSize.sm};
  font-weight: 500;
`
export const FolderButtonWrapper = styled(TableCellLayout)`
  div {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: #eeeff2;
      width: 32px;
      height: 32px;
    }
  }
`
export const FolderButton = styled(Icons)`
  border: none;
  svg {
    color: #91afdb;
    width: 32px;
    height: 32px;
  }
`
export const FooterBtn = styled(Button)`
    border-width:  2px;
    border-style: solid;
    border-radius: 16px;
    min-height: 32px;
    height: auto;
    min-width: 72px;
    padding: 3px 14px 5px;
    font-size": 14px;
    font-weight: 700;
`
export const FooterBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
`
export const CustomButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`