import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg  viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8319 19.8225H27.7876L34.7788 65L43.4808 19.8225H56.8732L65.0737 65.2663L73.2448 19.8225H85.5457L71.2684 85.503H58.1711L49.292 40.0888L40.2065 85.503H27.2566L12.8319 19.8225Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
      </svg>


    </div>
  )
}
