const INITIATE_FILE_UPLOAD = 'INITIATE_FILE_UPLOAD'
const UPDATE_FILE_UPLOAD = 'UPDATE_FILE_UPLOAD'
const NOTIFICATION_TRIGGER = 'NOTIFICATION_TRIGGER'
const NOTIFICATION_UPDATE = 'NOTIFICATION_UPDATE'
const CLEAR_JOB = 'CLEAR_JOB'
const RESET_NOTIFICATION = 'RESET_NOTIFICATION'
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
const ONE_DRIVE_NOTIFICATION = 'ONE_DRIVE_NOTIFICATION'

export const Type = {
  INITIATE_FILE_UPLOAD,
  UPDATE_FILE_UPLOAD,
  NOTIFICATION_TRIGGER,
  NOTIFICATION_UPDATE,
  CLEAR_JOB,
  RESET_NOTIFICATION,
  DELETE_NOTIFICATION,
  ONE_DRIVE_NOTIFICATION,
}
