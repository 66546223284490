import { useState, useEffect } from 'react'
import styled from 'styled-components'

import Popup from '../../../../controls/Popup'
import Button from '../../../../controls/Button'
import ButtonRow from '../../../../controls/ButtonRow'
import { colors, fontSize } from '../../../../theme/viewer'

const IconImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem 0;
  svg {
    width: 100px;
    height: 60px;
    fill: ${colors.grayDark};
  }
`

const Form = styled.form`
  text-align: center;
  p {
    /*font-size: ${fontSize.lg};*/
  }
`

const FormRow = styled.div`
  margin-bottom: 2rem;
`

const ErrorMessage = styled.div`
  margin: 0.5rem 0;
  font-size: ${fontSize.sm};
  color: ${colors.error};
`

const Input = styled.input`
  text-align: center;
  width: 100%;
  border: none;
  border: solid 1px ${colors.grayLight};
  border-radius: 4px;
  height: 40px;
  margin: 20px 0;
  &.error {
    border-color: ${colors.error};
  }
`

const UnlockPdf = ({ passwordAttempts, onPassword }) => {
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    if (passwordAttempts > 1) {
      setHasError(true)
      setIsValid(false)
    }
  }, [passwordAttempts])

  const handlePasswordChange = (e) => {
    const { value } = e.target
    setIsValid(value && value.length)
    setPassword(value)
    setHasError(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isValid) {
      const pwRes = await onPassword(password)
      if (pwRes === false) {
        setHasError(true)
      } else {
        setHasError(false)
      }
    }
  }

  return (
    <Popup size="sm">
      <IconImage>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M224 420c-11 0-20-9-20-20v-64c0-11 9-20 20-20s20 9 20 20v64c0 11-9 20-20 20zm224-148v192c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48h16v-64C64 71.6 136-.3 224.5 0 312.9.3 384 73.1 384 161.5V224h16c26.5 0 48 21.5 48 48zM96 224h256v-64c0-70.6-57.4-128-128-128S96 89.4 96 160v64zm320 240V272c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16v192c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16z" />
        </svg>
      </IconImage>
      <Form onSubmit={handleSubmit}>
        <h3>This document is protected</h3>
        <p>Please enter the password</p>
        <FormRow>
          <Input
            type="password"
            placeholder=""
            onChange={handlePasswordChange}
            value={password}
            className={hasError ? 'error' : ''}
          />
          {hasError && <ErrorMessage>invalid password</ErrorMessage>}
        </FormRow>

        <ButtonRow center>
          <Button color="primary" type="submit" disabled={!isValid}>
            OK
          </Button>
          <Button href="/">Cancel</Button>
        </ButtonRow>
      </Form>
    </Popup>
  )
}

export default UnlockPdf
