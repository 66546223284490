import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  > div {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(99, 99, 99, 0.1);
    border-top-color: rgba(227, 27, 35, 0.6);
    border-radius: 100%;
    animation: ${rotate} 0.9s infinite linear;
  }
  > span {
    display: block;
    padding: 1rem;
  }
`

const fadeInBg = keyframes`
  from {
    opacity: 0.001;
  }
  to {
    opacity: 1;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
    animation: ${fadeInBg} ease-in 0.25s;
  }
`

const Loader = () => {
  return (
    <Wrapper>
      <div>
        <span />
        <span />
        <span />
      </div>
    </Wrapper>
  )
}

export const OverlayLoader = ({ children }) => {
  return (
    <Overlay>
      <Wrapper>
        <div>
          <span />
          <span />
          <span />
        </div>
        <span>{children}</span>
      </Wrapper>
    </Overlay>
  )
}

export default Loader
