import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { ApiClient } from '../../service/apiClient'
// import config from '../../lib/config'
import { getRecentOneDriveFiles } from '../../service/oneDrive/oneDriveFiles'
import { errorHandler } from '../error/action'
import { app } from '@microsoft/teams-js'
// Define an async thunk for fetching recent drive files
export const fetchRecentDriveFiles = createAsyncThunk(
  'drive/fetchRecentDriveFiles',
  async (teamsUserCredential, { dispatch, rejectWithValue, getState }) => {
    // const apiClient = ApiClient(teamsUserCredential);
    // const functionName = config.apiName || "myFunc";
    // const response = await apiClient.get(functionName);
    // return response.data.value;
    const { auth } = getState()
    try {
      var tokenRes = await teamsUserCredential.getToken('')
      const teamsContext = await app.getContext()
      const res = await getRecentOneDriveFiles(
        tokenRes.token,
        '',
        teamsContext.user.tenant.id
      )

      const _files =
        res.items?.map((f) => {
          const { itemId: id, ...res } = f
          return { id, ...res }
        }) || []
      return _files
    } catch (error) {
      error?.message &&
        !error?.message.includes('Azure.Iden') &&
        dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)

// Create a slice to manage drive state including loading
const driveSlice = createSlice({
  name: 'drive',
  initialState: {
    recentDriveFiles: [],
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle loading state when the async thunk is pending
    builder.addCase(fetchRecentDriveFiles.pending, (state) => {
      state.isLoading = true
    })
    // Handle loading state when the async thunk is fulfilled or rejected
    builder.addCase(fetchRecentDriveFiles.fulfilled, (state, action) => {
      state.isLoading = false
      //console.log('action.payload', action.payload)
      state.recentDriveFiles = action.payload || []
    })
    builder.addCase(fetchRecentDriveFiles.rejected, (state) => {
      state.isLoading = false
      state.recentDriveFiles = []
    })
  },
})

export default driveSlice.reducer
