const MB10 = 10000000
const MB20 = 20000000
const MB50 = 50000000
// const MB75 = 75000000
const MB100 = 100000000
const MB200 = 200000000
const MB500 = 500000000
const GB1 = 1000000000

const jobConfig = {
  defaults: {
    inputFileTypes: 'pdf',
    outputFileTypes: 'pdf',
    minInputFiles: 1,
    maxInputFiles: 20,
    maxBasicInputFiles: 5,
    maxFreeInputFiles: 1,
    maxFileSize: MB500,
    maxBasicFileSize: MB100,
    maxFreeFileSize: MB50,
    maxTotalFileSize: GB1,
    maxBasicTotalFileSize: MB200,
    maxFreeTotalFileSize: MB100,
    storageType: 'cdoc',
    fileTypeSizeLimits: {
      doc: MB500,
      docx: MB500,
      ppt: MB500,
      pptx: MB500,
      xls: MB500,
      xlsx: MB500,
      png: MB200,
      jpg: MB200,
      jpeg: MB200,
      gif: MB200,
      bmp: MB200,
    },
    inputFilesSortable: false,
    paidOnlyFeature: false,
    warnBeforeUnload: true,
  },
  wordToPdf: {
    jobConfig: {
      convertToPdf: {},
    },
    inputFileTypes: 'convertable',
  },
  powerpointToPdf: {
    jobConfig: {
      convertToPdf: {},
    },
    inputFileTypes: 'convertable',
  },
  excelToPdf: {
    jobConfig: {
      convertToPdf: {},
    },
    inputFileTypes: 'convertable',
  },
  pdfConverter: {
    jobConfig: {
      convertToPdf: {},
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'convertable',
  },
  imageToPdf: {
    jobConfig: {
      merge: {},
      convertToPdf: {},
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'images',
    maxFreeInputFiles: 5,
    maxBasicInputFiles: 50,
    maxInputFiles: 150,
  },
  organizePages: {
    jobConfig: {
      produce: {
        docs: [],
      },
    },
    maxFreeInputFiles: 1,
    maxBasicInputFiles: 1,
    maxInputFiles: 1,
  },
  signPdf: {
    jobConfig: {
      optimize: {
        profile: 'size',
      },
    },
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    inputFileTypes: 'all',
    warnBeforeUnload: false,
  },
  mergePdf: {
    jobConfig: {
      merge: {},
      optimize: {
        profile: '',
      },
    },

    createThumbnailOnUpload: true,
    minInputFiles: 2,
    maxFreeInputFiles: 3,
    maxInputFiles: 50,
    inputFilesSortable: true,
    inputFileTypes: 'all',
  },
  splitPdf: {
    jobConfig: {
      split: {
        splitType: 'byPageNr',
        splitByPageNr: '',
        splitRecurring: '',
        splitBarcodeText: '',
        splitBarcodeFilterType: 'contains',
        splitBarcodeType: 'any',
        splitBarcodePage: 'before',
        splitText: '',
        splitTextPage: 'before',
      },
    },
    createThumbnailOnUpload: true,
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    inputFileTypes: 'all',
  },
  optimizeForWeb: {
    jobConfig: {
      optimize: {
        profile: 'web',
      },
    },
    createThumbnailOnUpload: true,
  },
  prepareForPrint: {
    jobConfig: {
      optimize: {
        prepareForPrint: 'print',
      },
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'all',
  },
  compressPdf: {
    jobConfig: {
      optimize: {
        profile: 'compress',
      },
    },
    createThumbnailOnUpload: true,
  },
  pdfToImage: {
    jobConfig: {
      image: {
        imageFormat: 'png',
        width: 700,
        pageSequence: 'all',
      },
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'documentTypes',
    outputFileTypes: 'image',
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  protectPdf: {
    jobConfig: {
      protect: {
        UserPassword: '',
      },
    },
    createThumbnailOnUpload: true,
    outputFileTypes: 'protectedPdf',
    inputFileTypes: 'all',
  },
  unlockPdf: {
    jobConfig: {
      protect: {
        Unlock: true,
        UserPassword: '',
      },
    },
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  repairPdf: {
    jobConfig: {
      repair: {},
    },
  },
  pdfToWord: {
    jobConfig: {
      convertFromPdf: {
        outputFormat: 'docx',
        quality: 'draft',
      },
    },
    outputFileTypes: 'docx',
    createThumbnailOnUpload: true,
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    maxFreeFileSize: MB20,
  },
  pdfToExcel: {
    jobConfig: {
      convertFromPdf: {
        outputFormat: 'excel',
        quality: 'draft',
      },
    },
    outputFileTypes: 'excel',
    createThumbnailOnUpload: true,
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  pdfToPowerpoint: {
    jobConfig: {
      convertFromPdf: {
        outputFormat: 'pptx',
        quality: 'draft',
      },
    },
    outputFileTypes: 'pptx',
    createThumbnailOnUpload: true,
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  pdfToEpub: {
    jobConfig: {
      convertFromPdf: {
        outputFormat: 'epub',
        quality: 'draft',
      },
    },
    outputFileTypes: 'ebook',
    createThumbnailOnUpload: true,
    inputFileTypes: 'nonEbook',
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  pdfOcr: {
    jobConfig: {
      convertFromPdf: {
        outputFormat: 'pdfocr',
        quality: 'high',
      },
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'pdfImage',
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
  },
  pdfEditor: {
    jobConfig: {},
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    inputFileTypes: 'all',
    warnBeforeUnload: false,
  },
  extractResources: {
    jobConfig: {
      extractResources: {
        extract: '',
      },
    },
    outputFileTypes: 'zip',
    createThumbnailOnUpload: true,
    inputFileTypes: 'pdfOfficeZip',
  },
  addBarcode: {
    jobConfig: {
      addBarcode: {},
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'all',
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    maxFreeInputFiles: 1,
  },
  addWatermark: {
    jobConfig: {
      stamp: {},
    },
    createThumbnailOnUpload: true,
    inputFileTypes: 'all',
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    maxFreeInputFiles: 1,
  },
  pdfMetadata: {
    jobConfig: {
      editMetadata: {},
    },
    createThumbnailOnUpload: true,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    maxFreeInputFiles: 1,
  },
  workflow: {
    jobConfig: {},
    createThumbnailOnUpload: false,
    maxFreeInputFiles: 1,
    maxInputFiles: 1,
    maxBasicInputFiles: 1,
    inputFileTypes: 'all',
    storageType: 'wfin',
  },
  // findAndReplace: {
  //   jobConfig: {
  //     findAndReplace: {
  //       pageSequence: 'all',
  //     },
  //   },
  //   createThumbnailOnUpload: true,
  // },
}

export default jobConfig
