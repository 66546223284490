import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
        <path
          fill="currentColor"
          d="M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.172a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 10.828 2H18a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2Z"
        />
      </svg>
    </div>
  )
}
