import { types } from './mutations'

const initalState = {
  extDocId: undefined,
  thumbnails: {},
}

export default (state = initalState, action) => {
  switch (action.type) {
    case types.DELETE_FILE: {
      window.p4mthb = {}
      return { ...initalState }
    }

    case types.RESET_ALL: {
      window.p4mthb = {}
      return { ...initalState }
    }

    case types.CREATE_THUMBNAIL_STARTING: {
      window.p4mthb = {}
      const { doc } = action
      const thumbnails = []
      let count = doc.pageCount || doc?.docMetadata?.pageCount
      for (let i = 1; i <= count; i++) {
        thumbnails[i] = {
          nr: i,
          thumbnail: '/images/blind-page.png',
          pageHeight: 400,
          pageWidth: 282,
        }
      }

      return {
        ...initalState,
        thumbnails,
        extDocId: action.doc.id || action?.doc?.docIdExt,
      }
    }

    case types.CREATE_THUMBNAIL_STARTED: {
      return {
        ...state,
      }
    }

    case types.CREATE_THUMBNAIL_PROGRESS: {
      // const pageCount = action.progress.docs
      // const s = Object.keys(state.thumbnails).length + 1
      // const thumbnails = { ...state.thumbnails }
      // for (let i = s; i <= pageCount; i++) {
      //   thumbnails[i] = {
      //     nr: i,
      //     thumbnail: '/images/blind-page.png',
      //     pageHeight: 400,
      //     pageWidth: 282,
      //   }
      // }
      // return {
      //   ...state,
      //   thumbnails,
      // }
      return {
        ...state,
      }
    }

    case types.CREATE_THUMBNAILS_PUSH_THUMBNAILS: {
      const { pages } = action
      const thumbnails = { ...state.thumbnails }
      pages.forEach((page) => {
        thumbnails[page.pageNr] = {
          thumbnail: `data:image/jpg;base64,${page.thumbnail}`,
          nr: page.pageNr,
          pageHeight: page.pageHeight,
          pageWidth: page.pageWidth,
        }
      })
      return {
        ...state,
        thumbnails,
      }
    }

    default:
      return state
  }
}
