const t = (key) => {
  const translate = {
    i6Text:
      'Harness the power of mobility by creating Barcodes or QR codes from your mobile devices. This makes it easy for you to add barcodes to your documents even while you travel.',
    btnStartJob: 'Start adding Barcodes',
    left: 'Left',
    lblPageSequence: 'Pages',
    pagesDesc_custom: 'Add barcode to selected pages of PDF',
    Configure: 'Configure Barcode',
    pagesDesc_first: 'Add Barcode to the First page of the PDF',
    pagesDesc_all: 'Add Barcode to all the pages of the PDF',
    titleSelectPages: 'Select Pages',
    center: 'Center',
    btnSelect: 'Done',
    titleOnePageSelected: 'page selected',
    titleMultiplePagesSelected: 'pages selected',
    right: 'Right',
    top: 'Top',
    middle: 'Middle',
    bottom: 'Bottom',
    allPages: 'All pages',
    firstPage: 'First page',
    customSelection: 'Select pages',
    BarCodeType: 'Barcode Type',
    SizePosition: 'Size and Position',
    GeneratePreview: 'Generate Preview',
    titleText: 'Text',
    titleContact: 'Contact',
    titleWebsite: 'Website',
    titleEmail: 'Email',
    lblText: 'Text',
    lblFirstName: 'Firstname',
    lblLastName: 'Lastname',
    lblPhone: 'Phone',
    lblStreet: 'Street',
    lblCity: 'City',
    lblZip: 'ZIP',
    lblState: 'State',
    lblCountry: 'Country',
    lblEmail: 'Email',
    lblSubject: 'Subject',
    lblMessage: 'Message',
    MarginInCm: 'Margin (in cm)',
    lblUrl: 'URL',
    aztec_desc:
      'Aztec Code can encode from small to large amounts of data with user-selected percentages of error correction.',
    datamatrix_desc:
      'Data Matrix is used for encoding large amounts of data and is ideal for marking small objects.',
    pdf417_desc:
      'Symbol Technologies invented this (stacked) 2D symbology. It is used to encode large quantities of data. It is the de-facto 2D standard symbology in the automotive industry.',
    qrcode_desc:
      'This 2D symbology is used to encode large quantities of data and was developed for fast readability (QR = Quick Response Code) by Denso.',
    hanxin_desc:
      'Han Xin Code is a 2D matrix symbology, which is used for encoding large amounts of data and provides special support for encoding Chinese characters',
    code128_desc:
      'Code 128 is heavily used in all areas. It is a modern high-density symbology',
    errNoPagesSelected: 'No page selected',
    Preview: 'Preview',
    Refresh: 'Refresh',
    Download: 'Download',
    lblBarcodeType: 'Barcode Type',
    lblBarcodeColor: 'Barcode Color',
    lblBackgroundColor: 'Background Color',
    lblMaxBarcodeTextLengthReached:
      'Max Barcode Text length Reached. Characters exceeded : {0}',
    lblHideText: 'Hide Text',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
