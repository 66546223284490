const mimeMap = {
    pdf: ['application/pdf'],
    png: ['image/png'],
    jpg: ['image/jpeg'],
    jpeg: ['image/jpeg'],
    jp2: ['image/jp2'],
    gif: ['image/gif'],
    tif: ['image/tif'],
    bmp: ['image/bmp'],
    svg: ['image/svg+xml'],
    doc: ['application/msword'],
    docx: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
    rtf: ['application/rtf'],
    txt: ['text/plain'],
    ppt: ['application/vnd.ms-powerpoint'],
    pptx: [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    pps: [
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    ],
    ppsx: [
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    ],
    xls: ['application/vnd.ms-excel'],
    xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    zip: ['application/zip', 'application/x-zip-compressed'],
    ai: ['application/postscript'],
    eps: ['application/postscript'],
    psd: ['application/octet-stream'],
    vsd: ['application/vnd.ms-visio.viewer'],
    epub: ['application/epub+zip'],
    mobi: ['application/octet-stream'],
    csv: ['text/csv'],
    eml: ['message/rfc822'],
    msg: ['']
  }
  
  const mimeTypesMap = {
    none: [],
    pdf: ['pdf', 'zip'],
    images: ['png', 'jpg', 'jpeg', 'gif', 'tif', 'bmp'],
    pdfImage: ['pdf', 'zip', 'png', 'jpg', 'jpeg', 'gif', 'tif', 'bmp'],
    convertable: [
      'png',
      'jpg',
      'jpeg',
      'jp2',
      'gif',
      'tif',
      'bmp',
      'svg',
      'doc',
      'docx',
      'rtf',
      'txt',
      'ppt',
      'pptx',
      'pps',
      'ppsx',
      'xls',
      'xlsx',
      'zip',
      'ai',
      // 'eps',
      'vsd',
      'epub',
      'eml',
      'msg',
      'csv',
      'mobi',
    ],
    pdfOffice: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx'],
    pdfOfficeZip: ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'zip'],
    doc: ['doc', 'docx'],
    ppt: ['ppt', 'pptx'],
    xls: ['xls', 'xlsx'],
    zip: ['zip'],
    documentTypes: [
      'pdf',
      'tif',
      'doc',
      'docx',
      'rtf',
      'txt',
      'ppt',
      'pptx',
      'pps',
      'ppsx',
      'xls',
      'xlsx',
      'zip',
      'vsd',
    ],
    all: [
      'pdf',
      'png',
      'jpg',
      'jpeg',
      'jp2',
      'gif',
      'tif',
      'bmp',
      'svg',
      'doc',
      'docx',
      'rtf',
      'txt',
      'ppt',
      'pptx',
      'pps',
      'ppsx',
      'xls',
      'xlsx',
      'zip',
      'ai',
      // 'eps',
      'vsd',
      'epub',
      'mobi',
    ],
    nonEbook: [
      'pdf',
      'png',
      'jpg',
      'jpeg',
      'jp2',
      'gif',
      'tif',
      'bmp',
      'svg',
      'doc',
      'docx',
      'rtf',
      'txt',
      'ppt',
      'pptx',
      'pps',
      'ppsx',
      'xls',
      'xlsx',
      'zip',
      'ai',
      // 'eps',
      'vsd',
    ],
  }
  
  export const isMimeTypeSupported = (filename, type) => {
    const match = /\.([a-z0-9]+)$/i.exec(filename)
    if (!match || match.length < 2) {
      return false
    }
    return (
      mimeTypesMap[type] && mimeTypesMap[type].includes(match[1].toLowerCase())
    )
  }
  
  export const getMimeTypes = (types) => {
    let fileExtensions = []
    let mimeTypes = []
  
    types.split(',').forEach((type) => {
      fileExtensions = [...fileExtensions, ...mimeTypesMap[type]]
    })
  
    fileExtensions.forEach((fileExtension) => {
      mimeTypes = mimeTypes.concat(mimeMap[fileExtension])
    })
  
    return {
      fileExtensions,
      mimeTypes,
    }
  }
  