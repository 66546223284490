import { configureStore as createReduxToolkitStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { apiSlice } from './api/apiSlice'

export const configureStore = (rootReducer, additionalMiddleware) => {
  const store = createReduxToolkitStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['SHOW_ERROR', 'REMOVE_ERROR', 'HUB_ERROR','persist/PERSIST', 'persist/REHYDRATE'],
        },
      })
      middleware.push(apiSlice.middleware)
      if (typeof additionalMiddleware === 'function') {
        middleware.push(additionalMiddleware)
      } else if (
        typeof additionalMiddleware === 'object' &&
        typeof additionalMiddleware.forEach === 'function'
      ) {
        additionalMiddleware.forEach((mw) => {
          middleware.push(mw)
        })
      }
      if (process.env.NODE_ENV !== 'production') {
        middleware.push(logger)
        console.info('logger enabled')
      }
      return middleware
    },

    devTools: process.env.NODE_ENV !== 'production',
  })

  return store
}
