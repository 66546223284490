import { apiSlice } from "./apiSlice";

export const userInfoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query({
      query: () => ({
        url: "/userInfo/getUserInfo",
      }),
    }),
    executePdfJob: builder.query({
      query: (body) => ({
        url: "/document/executePdfJob",
        method: "post",
        body,
        headers: { "content-type": "application/json" },
      }),
    }),
  }),
});

// Export the generated hook for the `getUserInfo` endpoint
export const { useGetUserInfoQuery, useExecutePdfJobQuery } = userInfoApiSlice;
