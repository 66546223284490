import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M73.778 90c1.362 0 2.458-1.423 2.458-3.191h-.018v-2.64c0-1.768-1.096-3.191-2.457-3.191H26.095c-1.361 0-2.458 1.423-2.458 3.191v2.64c0 1.768 1.096 3.191 2.458 3.191h47.683ZM22.152 77.535l55.905.183c.99 0 2.157-4.178 1.91-6.703l-7.62-19.904c-.372-1.147-1.627-2.043-2.812-2.043l-13.79-.045V35.96c.424-.184.83-.39 1.237-.62 6.56-3.742 8.876-12.029 5.198-18.526-3.695-6.496-11.987-8.746-18.529-5.004-6.559 3.742-8.875 12.03-5.198 18.526a13.414 13.414 0 0 0 6.365 5.693v12.947l-14.957-.045c-1.185 0-2.422.895-2.758 2.02l-7.09 20.04c-.16 2.136 1.184 6.59 2.14 6.544Z" />
      </svg>

    </div>
  )
}
