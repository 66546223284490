import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useJobConfig } from '../../../JobConfig/JobContext'

const PdfEditor = ({ toolName, inputFiles }) => {
  const navigate = useNavigate()
  const { tool, docIdExt, closeToolPopup } = useJobConfig()
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )

  useEffect(() => {
    ;(async () => {
      try {
        if (fileReady) {
          navigate('/OrganizePdf', {
            state: { tool, docIdExt },
          })
        }
      } catch (error) {
        closeToolPopup && closeToolPopup()
      }
    })()
  }, [fileReady])

  return ''
}

export default PdfEditor
