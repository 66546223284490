import { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { sortableHandle } from 'react-sortable-hoc'

import { colors, device, media } from '../../theme'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Root = styled.div`
  position: relative;
  padding: 40px 10px 20px 10px;
  margin: 0;
  max-height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: pan-y pan-x;
  user-select: none;
  &.hide {
    display: none;
  }

  @media (hover: hover) {
    &:hover {
      .dragmove-inactive & {
        ${media.up(device.tablet)`
          .page-actions {
            display: flex;
            animation: ${fadeIn} 0.5s;
          }
        `}
      }
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    border: solid 2px ${colors.gray};
    cursor: pointer;
    box-shadow: 0 0 12px rgba(5, 10, 18, 0.2);
    &:hover {
      .dragmove-inactive & {
        border-color: ${colors.gray};
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
        transition: box-shadow 0.25s;
      }
    }
    &.sortable {
      cursor: pointer;
    }
    &.rotate-0 {
      transform: rotate(0);
    }
    &.rotate-360 {
      transform: rotate(360deg);
    }
    &.rotate-90 {
      transform: rotate(90deg);
    }
    &.rotate-180 {
      transform: rotate(180deg);
    }
    &.rotate-270 {
      transform: rotate(270deg);
    }
  }

  &.deleted {
    img {
      opacity: 0.3;
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
  }
  &.selected {
    img {
      border-color: ${colors.primary} !important;
    }
  }
`

const Actions = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  display: none;
  button {
    margin: 0;
    padding: 2px 6px;
    width: 42px;
    i {
      display: inline-block;
    }
    i,
    svg {
      width: 24px;
      height: 20px;
    }
    svg {
      fill: ${colors.grayDarker};
    }
  }
`

const PageNumber = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  text-align: center;

  .deleted & {
    opacity: 0.4;
  }
`

const Thumbnail = sortableHandle(({ src, className }) => {
  return <img src={src} className={className} alt="" />
})

export const PageListItem = ({
  page,
  pageSize,
  isMobile,
  thumbnails,
  showDeletedPages,
  onDeletePage,
  onUndoDeletePage,
  onRotatePage,
  onSelectPage,
}) => {
  const rootElm = useRef(null)
  useEffect(() => {
    const onMouseDown = (e) => {
      if (e.srcElement.nodeName.toLowerCase() !== 'img') {
        return
      }

      const onMouseUp = (ev) => {
        window.removeEventListener('mouseup', onMouseUp)
        if (onSelectPage) {
          onSelectPage(page.nr, ev.ctrlKey, ev.shiftKey)
        }
      }

      window.addEventListener('mouseup', onMouseUp)
      window.setTimeout(() => {
        window.removeEventListener('mouseup', onMouseUp)
      }, 200)
    }

    const onTouchStart = (e) => {
      if (
        e.touches.length !== 1 ||
        e.srcElement.nodeName.toLowerCase() !== 'img'
      ) {
        return
      }

      const startX = e.touches[0].clientX
      const startY = e.touches[0].clientY
      let endX = e.touches[0].clientX
      let endY = e.touches[0].clientY

      const onTouchMove = (me) => {
        if (onSelectPage && me.touches.length > 0) {
          endX = me.touches[0].clientX
          endY = me.touches[0].clientY
        }
      }

      const onTouchEnd = (ee) => {
        ee.preventDefault()
        document.body.click()

        if (onSelectPage) {
          const dist = Math.abs(endX - startX) + Math.abs(endY - startY)
          if (dist < 4) {
            onSelectPage(page.nr, true, false)
          }
        }
      }

      window.addEventListener('touchmove', onTouchMove)
      window.addEventListener('touchend', onTouchEnd)
      window.setTimeout(() => {
        window.removeEventListener('touchmove', onTouchMove)
        window.removeEventListener('touchend', onTouchEnd)
      }, 180)
    }

    rootElm.current.addEventListener('mousedown', onMouseDown)
    rootElm.current.addEventListener('touchstart', onTouchStart)
    return () => {
      rootElm.current?.removeEventListener('mousedown', onMouseDown)
      rootElm.current?.removeEventListener('touchstart', onTouchStart)
    }
  }, [])

  const classNames = []
  if (page.selected) {
    classNames.push('selected')
  }

  if (!showDeletedPages && page.deleted) {
    classNames.push('hide')
  }

  if (page.deleted) {
    classNames.push('deleted')
  }

  const skipContent =
    (!page.sorting && page.skip) || (page.deleted && !showDeletedPages)

  return (
    <Root
      ref={rootElm}
      className={classNames.join(' ')}
      style={{
        width: `${pageSize}px`,
        height: `${pageSize + 40}px`,
      }}
    >
      {!skipContent && (
        <>
          {thumbnails && thumbnails[page.nr] && (
            <Thumbnail
              src={thumbnails[page.nr].thumbnail}
              className={`rotate-${page.rotation} sortable`}
            />
          )}
          <PageNumber>{page.nr}</PageNumber>
        </>
      )}
    </Root>
  )
}
