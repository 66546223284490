import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M89.853 10.15H57.147v33.297h32.706V10.15Z" />
        <path
          fill="currentColor"
          d="M89.707 10.299v33.036h-32.45V10.3h32.45ZM90 10H57v33.597h33V10ZM43.975 56.418h-32.87v33.246h32.87V56.418Z"
        />
        <path
          fill="currentColor"
          d="M43.828 56.567v32.986H11.216V56.567h32.612Zm.295-.298H10.958v33.545h33.165V56.269Zm40.744 5.02V84.71H62.133V61.29h22.734ZM90 56H57v34h33V56ZM38.867 15.246v23.232H16.133V15.246h22.734ZM44 10H11v33.723h33V10Z"
        />
      </svg>
    </div>
  )
}
