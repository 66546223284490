export default (props) => {
  const { title, children } = props

  const classNames = ['formSection']

  return (
    <div className={classNames.join(' ')}>
      {title && <div className="h3">{title}</div>}
      {children}
    </div>
  )
}
