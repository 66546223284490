import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import uploadIcon from '../../controls/icons/upload'
import Button from '../../controls/Button'
import ButtonRow from '../../controls/ButtonRow'
import Icon from '../../controls/Icon'

import Popup from './Popup'
import {
  Page,
  PageLayout,
  ArrowBox,
  PageLayoutWrapper,
  VerticalLine,
  HorizontalLine,
  defaultMargin,
  cmToPixelRatio,
} from './Popup/common'
import {
  calculateSizeSettings,
  PreviousPage,
  NextPage,
  pageNavigate,
} from './Popup/pageNavigate'
import Toolbar from './Popup/Toolbar'

import { ViewportContext } from '../../hooks/ViewportContext'
import { getMimeTypes } from '../../utils/mimeTypes'
import { device } from '../../theme'
import stampActions from '../../store/stamp/actions'
import { useJobConfig } from '../JobConfig/JobContext'
import t from './translate'

const DropImage = styled.div`
  display: grid;
  justify-content: center;
  position: absolute;
  text-align: center;
  // z-index: 1;
  // height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  // width: 100%;
`

const IconWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const ImgStamp = styled.img`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

const ChangeFileWrapper = styled.div`
  position: absolute;
  z-index: 1;
  align-items: center;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  // &:hover {
  //   opacity: 0.95;
  // }
  min-width: 140px;
  span {
    user-select: none;
  }
`

const FileInput = styled.input`
  display: none;
`

const DragWrapper = styled.div`
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

let allowDropTimer = null

export default ({
  config,
  updateConfig,
  onClose,
  inputFiles,
  filesReady,
  pageSelectionType,
  customSelection,
}) => {
  // const t = useTranslation()
  const { actionConfig, updateActionConfig } = useJobConfig()
  const dispatch = useDispatch()
  const viewport = useContext(ViewportContext)

  const { mimeTypes } = getMimeTypes('images')

  // const [values, setImageStamp] = useState({ ...config })
  const [localChanges, setLocalChanges] = useState({
    isDragActive: false,
    acceptedFiles: [],
  })

  const { imgLoading } = useSelector((state) => state.stamp)

  const UploadImage = async (imageFile) => {
    const data = await dispatch(stampActions.addLocalFile(imageFile))
    updateActionConfig({ docBlobRef: data[0]?.docBlobRef })
    // setImageStamp({
    //   ...values,
    //   docBlobRef: data[0].docBlobRef,
    // })
    const fr = new FileReader()
    fr.onload = () => {
      const _imgThumbnail = fr.result
      const img = new Image()
      img.onload = () => {
        updateActionConfig({
          imgThumbnail: _imgThumbnail,
          docBlobRef: data[0]?.docBlobRef,
          width: img.width,
          height: img.height,
        })
        // setImageStamp({
        //   ...values,
        //   imgThumbnail: _imgThumbnail,
        //   docBlobRef: data[0].docBlobRef,
        //   width: img.width,
        //   height: img.height,
        // })
      }
      img.src = _imgThumbnail
    }
    fr.readAsDataURL(imageFile)
  }

  const onDragover = useCallback((e) => {
    e.preventDefault()
    const { isDragActive } = localChanges

    clearTimeout(allowDropTimer)
    allowDropTimer = window.setTimeout(() => {
      setLocalChanges((prevLocalChanges) => ({
        ...prevLocalChanges,
        isDragActive: false,
        acceptedFiles: [],
      }))
    }, 500)
    const _acceptedFiles = []

    if (!isDragActive) {
      if (e.dataTransfer.items) {
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
          const item = e.dataTransfer.items[i]
          if (item.kind === 'file' && mimeTypes.includes(item.type)) {
            _acceptedFiles.push({
              type: item.type,
            })
          }
        }
      }
      setLocalChanges((prevLocalChanges) => ({
        ...prevLocalChanges,
        isDragActive: true,
        acceptedFiles: _acceptedFiles,
      }))
    }
  }, [])

  const onDrop = useCallback((e) => {
    e.preventDefault()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        const file = e.dataTransfer.files[i]
        if (mimeTypes.includes(file.type)) {
          UploadImage(file)
          break
        }
      }
    }
  }, [])

  useEffect(() => {
    document.body.addEventListener('dragover', onDragover)
    document.body.addEventListener('drop', onDrop)
    return () => {
      document.body.removeEventListener('dragover', onDragover)
      document.body.removeEventListener('drop', onDrop)
    }
  }, [onDragover, onDrop])

  let elmInput = null

  const callback = () => {
    const _values = { ...actionConfig }

    if (_values.xalign === 'center') {
      _values.marginX = 0
      _values.marginXcm = 0
    }
    if (_values.xalign === 'right') {
      if (_values.marginX >= 0) _values.marginX *= -1
    }
    if (_values.yalign === 'middle') {
      _values.marginY = 0
      _values.marginYcm = 0
    }
    if (_values.yalign === 'top') {
      if (_values.marginY >= 0) _values.marginY *= -1
    }

    updateConfig(_values)
    onClose()
  }

  const openFile = () => {
    if (elmInput) elmInput.click()
  }

  const onFilesSelected = async (e) => {
    const imageFile = e.target.files[0]
    await UploadImage(imageFile)
  }

  /* thumbnails and largesize popup starts */
  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const [maxContentWidth, setMaxContentWidth] = useState(0)

  useEffect(() => {
    const updateSize = () => {
      const contentElem = document.getElementsByClassName('popup-content')[0]
      const { maxHeight } = contentElem.style
      const { width } = contentElem.getBoundingClientRect()
      let _maxHeight = parseInt(maxHeight.replace('px', ''), 10)
      if (device.tablet < width) {
        _maxHeight -= 20
      }
      setMaxContentHeight(_maxHeight)
      setMaxContentWidth(width)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  const [pageNum, setPageNum] = useState(
    pageSelectionType === 'custom' ? (customSelection || '')[0] : 1
  )
  const { thumbnails, extDocId } = useSelector((state) => state.thumbnails)

  const { thumbnailInfo, prevDisabled, nextDisabled } = pageNavigate(
    dispatch,
    filesReady,
    inputFiles,
    thumbnails,
    pageNum,
    extDocId,
    pageSelectionType,
    customSelection,
    viewport.width < device.tablet
  )

  const settings = calculateSizeSettings(
    maxContentHeight,
    maxContentWidth,
    thumbnails,
    pageNum
  )

  const pageWrapperCss = {
    left: 'auto',
    top: 'auto',
  }

  pageWrapperCss.height = `${settings._pageHeight}px`
  pageWrapperCss.width = `${settings._pageWidth}px`
  if (settings.landscape) {
    pageWrapperCss.maxWidth = `${settings.maxPageWidth}px`
  } else {
    pageWrapperCss.maxHeight = `${settings.maxPageHeight}px`
  }

  const dispWidth = actionConfig.width * settings._pageRatio
  const dispHeight = actionConfig.height * settings._pageRatio

  let leftMargin = defaultMargin * settings._pageRatio
  // if (config.xalign !== 'center') {
  leftMargin =
    actionConfig.marginX < 0
      ? actionConfig.marginX * settings._pageRatio * -1
      : actionConfig.marginX * settings._pageRatio
  // }

  let topMargin = defaultMargin * settings._pageRatio
  // if (config.yalign !== 'middle') {
  topMargin =
    actionConfig.marginY < 0
      ? actionConfig.marginY * settings._pageRatio * -1
      : actionConfig.marginY * settings._pageRatio
  // }
  const rightMargin = settings._pageWidth - leftMargin
  const bottomMargin = settings._pageHeight - topMargin

  const imgWrappercss = {}
  if (actionConfig.xalign === 'right') {
    imgWrappercss.right = `${leftMargin}px`
  } else if (actionConfig.xalign === 'left') {
    imgWrappercss.left = `${leftMargin}px`
  }
  if (actionConfig.yalign === 'bottom') {
    imgWrappercss.bottom = `${topMargin}px`
  } else if (actionConfig.yalign === 'top') {
    imgWrappercss.top = `${topMargin}px`
  }

  const previousPage = () => {
    PreviousPage(
      filesReady,
      pageNum,
      pageSelectionType,
      customSelection,
      thumbnails,
      extDocId,
      setPageNum
    )
  }
  const nextPage = () => {
    NextPage(
      filesReady,
      pageNum,
      pageSelectionType,
      customSelection,
      thumbnails,
      extDocId,
      setPageNum
    )
  }

  const backgroundWrapper = {
    backgroundImage: thumbnailInfo ? `url(${thumbnailInfo.thumbnail})` : 'auto',
    backgroundPosition: 'center' /* Center the image */,
    backgroundSize: 'cover',
  }

  /* thumbnails and largesize popup ends */

  /* Draggable begin */

  const onDragMove = (x, y) => {
    const _settings = calculateSizeSettings(
      maxContentHeight,
      maxContentWidth,
      thumbnails,
      pageNum
    )
    const _marginXcm =
      Math.round(((x * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginYcm =
      Math.round(((y * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginX = Math.round(_marginXcm / cmToPixelRatio)
    const _marginY = Math.round(_marginYcm / cmToPixelRatio)
    const _values = {
      marginX: _marginX,
      marginY: _marginY,
      marginXcm: _marginXcm,
      marginYcm: _marginYcm,
      xalign: 'left',
      yalign: 'top',
    }

    updateActionConfig(_values)
  }

  const handlerRef = useRef(null)

  const [isMoving, setIsMoving] = useState(false)

  const move = (x, y) => {
    const handlerSize = handlerRef.current.getBoundingClientRect()
    const parentSize = handlerRef.current.parentElement.getBoundingClientRect()

    if (!isMoving) return

    let _posX = x - handlerSize.width / 2
    let _posY = y - handlerSize.height / 2
    // if (type) {
    _posX -= parentSize.left
    _posY -= parentSize.top
    // }

    if (_posX < 0) _posX = 0
    if (_posY < 0) _posY = 0

    if (parentSize.width < _posX + handlerSize.width) {
      _posX = parentSize.width - handlerSize.width
    }
    if (parentSize.height < _posY + handlerSize.height) {
      _posY = parentSize.height - handlerSize.height
    }
    _posX = Math.round(_posX)
    _posY = Math.round(_posY)
    if (onDragMove) onDragMove(_posX, _posY)
  }

  const onMouseMove = (e) => {
    const { clientX, clientY } = e

    move(clientX, clientY, e)
    if (e.buttons === 0) {
      setIsMoving(false)
    }
  }

  const onTouchMove = (e) => {
    const { clientX, clientY } = e.touches[0]
    move(clientX, clientY, e, 'touch')
    if (e.touches.length === 0) {
      setIsMoving(false)
    }
  }

  const onMouseDown = () => {
    setIsMoving(true)
  }

  const onTouchStart = () => {
    setIsMoving(true)
  }

  const onDragTargetStart = (e) => {
    if (e) {
      e.preventDefault()
    }
  }

  /* Draggable ends */

  // const dispHeight = values.height * 0.457
  // const dispWidth = values.width * 0.457

  return (
    <Popup
      header={() => {
        return (
          <Toolbar
            config={{ ...actionConfig }}
            updateConfig={updateActionConfig}
            stampType="image"
            disabled={actionConfig.docBlobRef === ''}
          />
        )
      }}
      size="md"
      onClose={onClose}
      config={{ ...actionConfig }}
      updateConfig={updateActionConfig}
      stampType="image"
      footer={() => {
        return (
          <ButtonRow center noWrap>
            <Button
              color="primary"
              id="btnTextStampOK"
              onClick={callback}
              disabled={actionConfig.docBlobRef === ''}
            >
              OK
            </Button>
            <Button onClick={() => onClose()}>{t('btnCancel')}</Button>
          </ButtonRow>
        )
      }}
    >
      <PageLayoutWrapper style={{ height: `${maxContentHeight}px` }}>
        <PageLayout
          id="pageLayout"
          style={{ height: `${settings.pageLayoutHeight}px` }}
          $dataScale={settings._pageRatio}
        >
          <ArrowBox
            className={`left ${prevDisabled ? 'disabled' : ''}`}
            onClick={previousPage}
          >
            <div />
          </ArrowBox>
          <ArrowBox
            className={`right ${nextDisabled ? 'disabled' : ''}`}
            onClick={nextPage}
          >
            <div />
          </ArrowBox>
          {!actionConfig.docBlobRef ? (
            <Page
              id="watermarkPage"
              className="center middle"
              style={{
                ...backgroundWrapper,
                ...pageWrapperCss,
              }}
            >
              <DropImage id="stampImageDrop">
                {!imgLoading && (
                  <>
                    <IconWrapper>
                      <Icon icon={uploadIcon} />
                    </IconWrapper>
                    <span>{t('lblDropImageHere')}</span>
                  </>
                )}
                <Button
                  color="primary"
                  type="submit"
                  id="btnDropImage"
                  onClick={openFile}
                  loading={imgLoading}
                >
                  {imgLoading ? t('lblUploadingImage') : t('lblSelectHere')}
                </Button>
                <FileInput
                  ref={(elm) => {
                    elmInput = elm
                  }}
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  onChange={onFilesSelected}
                />
              </DropImage>
            </Page>
          ) : (
            <>
              <Page
                id="watermarkPage"
                className={`overlay ${actionConfig.xalign} ${actionConfig.yalign}`}
                style={{
                  ...pageWrapperCss,
                }}
                onMouseMove={onMouseMove}
                onTouchMove={onTouchMove}
              >
                <DragWrapper
                  className="imgWrapper"
                  style={{
                    ...imgWrappercss,
                    height: dispHeight ? `${dispHeight}px` : 'auto',
                    width: dispWidth ? `${dispWidth}px` : 'auto',
                    opacity: actionConfig.opacity,
                  }}
                  onMouseDown={onMouseDown}
                  onTouchStart={onTouchStart}
                  onDragStart={onDragTargetStart}
                  ref={handlerRef}
                >
                  <ImgStamp
                    src={actionConfig.imgThumbnail}
                    className={`stamp rotate_${actionConfig.rotate}`}
                    style={{
                      height: dispHeight ? `${dispHeight}px` : 'auto',
                      width: dispWidth ? `${dispWidth}px` : 'auto',
                      opacity: actionConfig.opacity,
                    }}
                    alt="img"
                  />
                  <ChangeFileWrapper
                    style={{
                      height: dispHeight ? `${dispHeight}px` : 'auto',
                      width: dispWidth ? `${dispWidth}px` : 'auto',
                    }}
                    className="changeImage"
                  >
                    <Button
                      color="primary"
                      type="submit"
                      id="btnDropImage"
                      onClick={openFile}
                      loading={imgLoading}
                      size="small"
                    >
                      {t('lblChangeImage')}
                    </Button>
                    <FileInput
                      ref={(elm) => {
                        elmInput = elm
                      }}
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={onFilesSelected}
                    />
                  </ChangeFileWrapper>
                </DragWrapper>
              </Page>
              <Page
                style={{
                  ...backgroundWrapper,
                  ...pageWrapperCss,
                }}
              >
                {actionConfig.xalign === 'left' && (
                  <VerticalLine
                    id="leftLine"
                    style={{
                      transform: `translateX(${leftMargin}px)`,
                      height: `${settings._pageHeight}px`,
                    }}
                  />
                )}
                {actionConfig.xalign === 'right' && (
                  <VerticalLine
                    id="rightLine"
                    style={{
                      transform: `translateX(${rightMargin}px)`,
                      height: `${settings._pageHeight}px`,
                    }}
                  />
                )}
                {actionConfig.yalign === 'top' && (
                  <HorizontalLine
                    id="topLine"
                    style={{
                      transform: `translateY(${topMargin}px)`,
                      width: `${settings._pageWidth}px`,
                    }}
                  />
                )}
                {actionConfig.yalign === 'bottom' && (
                  <HorizontalLine
                    id="bottomLine"
                    style={{
                      transform: `translateY(${bottomMargin}px)`,
                      width: `${settings._pageWidth}px`,
                    }}
                  />
                )}
              </Page>
            </>
          )}
        </PageLayout>
      </PageLayoutWrapper>
    </Popup>
  )
}
