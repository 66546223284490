const t = (key) => {
  const translate = {
    lblPages: 'Pages',
    btnStartJob: 'Apply Changes',
    btnMoreOptions: 'Options',
    btnRotateLeft: 'Rotate Left',
    btnRotateRight: 'Rotate Right',
    btnDeletePages: 'Delete PDF Pages',
    btnRestorePages: 'Restore Pages',
    btnShowDeletedPages: 'Show deleted Pages',
    btnInvertSelection: 'Invert Selection',
    btnSelectAll: 'Select All',
    btnClearSelection: 'Clear Selection',
    btnStart: 'Start',
    pageTitle: 'Sort, Rotate or Delete pages of a PDF',
    btnCancel: 'Cancel',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
