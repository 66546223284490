import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 92 94" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M91.0882 25.3405L61.7478 1.16921C60.9619 0.521596 59.896 0.157666 58.7844 0.157471H16.8696C14.647 0.159299 12.5161 0.887488 10.9444 2.18223C9.3728 3.47698 8.48889 5.2325 8.48667 7.06355V48.5H16.8696V7.06355H50.4015V27.7818C50.4037 29.6128 51.2876 31.3683 52.8592 32.6631C54.4309 33.9578 56.5618 34.686 58.7844 34.6879H83.9333V48.5H92.3163V27.7818C92.3161 26.8661 91.8743 25.9879 91.0882 25.3405ZM58.7844 27.7818V8.49311L82.1981 27.7818H58.7844Z" fill="currentColor" />
        <path d="M58.7844 66.3763V59.4701H92.3163V87.0945H83.9333V71.2589L56.3282 94.0006L50.4015 89.118L78.0066 66.3763H58.7844Z" fill="currentColor" />
        <path d="M8.14865 94H0.840149V59.3375H12.7076C15.3553 59.3375 17.6192 59.8545 19.4992 60.8885C21.3948 61.9069 22.8361 63.2934 23.8231 65.048C24.8258 66.787 25.3272 68.7532 25.3272 70.9465C25.3272 73.1242 24.818 75.1217 23.7997 76.939C22.797 78.7407 21.3556 80.1899 19.4756 81.2865C17.5956 82.3675 15.3396 82.908 12.7076 82.908H8.14865V94ZM8.14865 80.652L4.85865 77.268H11.9321C13.1542 77.268 14.2195 77.0252 15.1281 76.5395C16.0368 76.0382 16.7418 75.3175 17.2432 74.3775C17.7445 73.4219 17.9951 72.2782 17.9951 70.9465C17.9951 69.5992 17.7445 68.4869 17.2432 67.6095C16.7575 66.7165 16.0603 66.0585 15.1516 65.6355C14.2586 65.1969 13.1855 64.9775 11.9321 64.9775H4.85865L8.14865 61.4995V80.652Z" fill="currentColor" />
      </svg>
    </div>
  )
}
