export const createId = (size = 2) => {
  let id = Math.random()
    .toString(36)
    .substring(2, 8)

  for (let i = 1; i < size; i++) {
    id += Math.random()
      .toString(36)
      .substring(2, 8)
  }
  return id
}
