import { Component } from 'react'
import styled from 'styled-components'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { PageListItem } from './PageListItem'

import { device, media, layout } from '../../theme'

const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0

export const ScrollWrapper = styled.div`
  position: absolute;
  top: ${layout.headerHeight};
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-top: 24px;
  margin-bottom: 80px;
  ${media.down(device.mobile)`
    top: ${layout.mobileHeaderHeight};
  `}
`

export const List = styled.div`
  touch-action: pan-y;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
`

const SortableItem = SortableElement(
  ({
    page,
    pageSize,
    isMobile,
    showDeletedPages,
    thumbnails,
    onDeletePage,
    onUndoDeletePage,
    onRotatePage,
    onSelectPage,
  }) => (
    <PageListItem
      pageSize={pageSize}
      thumbnails={thumbnails}
      page={page}
      isMobile={isMobile}
      showDeletedPages={showDeletedPages}
      onDeletePage={onDeletePage}
      onUndoDeletePage={onUndoDeletePage}
      onRotatePage={onRotatePage}
      onSelectPage={onSelectPage}
    />
  )
)

class VirtualList extends Component {
  pageListElm = undefined

  state = {
    pageSize: 0,
    skipPagesTop: 0,
    skipPagesBottom: 0,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.pageSize !== nextProps.pageSize) {
      return { pageSize: nextProps.pageSize }
    }
    return null
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    document
      .querySelector('#opScrollContainer')
      .addEventListener('scroll', this.handleScroll)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { pageSize, showDeletedPages } = this.setState
    const { pages } = this.props
    if (
      pages.length !== nextProps.pages.length ||
      pageSize !== nextState.pageSize ||
      showDeletedPages !== nextState.showDeletedPages
    ) {
      this.calcVisiblePages()
    }
    return true
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    document
      .querySelector('#opScrollContainer')
      .removeEventListener('scroll', this.handleScroll)
  }

  handleResize = () => {
    this.calcVisiblePages()
  }

  handleScroll = () => {
    this.calcVisiblePages()
  }

  calcVisiblePages = () => {
    const { skipPagesTop, skipPagesBottom } = this.state
    const { pageSize, pages } = this.props

    if (this.pageListElm && pages.length > 20) {
      const listElmRect = this.pageListElm.getBoundingClientRect()

      const scrollTop = Math.abs(listElmRect.top)
      const height = window.innerHeight
      const pageWidth = pageSize
      const pageHeight = pageSize + 40

      const pagesPerRow = Math.floor(listElmRect.width / pageWidth)
      const skipTopRows = Math.floor(scrollTop / pageHeight)

      const skipBottomRows = Math.floor(
        (listElmRect.height - scrollTop - height) / pageHeight
      )

      const newSkipPagesTop = skipTopRows * pagesPerRow
      const newSkipPagesBottom =
        skipBottomRows > 0 ? skipBottomRows * pagesPerRow : 0

      if (
        skipPagesTop !== newSkipPagesTop ||
        skipPagesBottom !== newSkipPagesBottom
      ) {
        // const iTop = newSkipPagesTop
        // const iBottom = pageList.length - newSkipPagesBottom - 1
        // const newPageList = pageList.map((p, index) => ({
        //   ...p,
        //   skip: index < iTop || index > iBottom,
        // }))

        this.setState({
          // pageList: newPageList,
          skipPagesTop: newSkipPagesTop,
          skipPagesBottom: newSkipPagesBottom,
        })
      }
    }
  }

  render() {
    const {
      showDeletedPages,
      thumbnails,
      pages,
      pageSize,
      isMobile,
      isSorting,
      onDeletePage,
      onUndoDeletePage,
      onRotatePage,
      onSelectPage,
    } = this.props

    const { skipPagesTop, skipPagesBottom } = this.state

    const iTop = skipPagesTop
    const iBottom = pages.length - skipPagesBottom - 1
    const pageList = pages
      .filter((p) => showDeletedPages || !p.deleted)
      .map((p, index) => ({
        ...p,
        skip: index < iTop || index > iBottom,
      }))

    const classNames = ['sortable-list']
    if (isSorting) {
      classNames.push('dragmove-active')
    } else {
      classNames.push('dragmove-inactive')
    }
    return (
      <ScrollWrapper id="opScrollContainer">
        <List
          id="PageList"
          className={classNames.join(' ')}
          ref={(elm) => {
            this.pageListElm = elm
          }}
        >
          {pageList.map((page, index) => (
            <SortableItem
              key={page.nr}
              index={index}
              page={page}
              pageSize={pageSize}
              isMobile={isMobile}
              showDeletedPages={showDeletedPages}
              thumbnails={thumbnails}
              onDeletePage={onDeletePage}
              onUndoDeletePage={onUndoDeletePage}
              onRotatePage={onRotatePage}
              onSelectPage={onSelectPage}
            />
          ))}
        </List>
      </ScrollWrapper>
    )
  }
}

const SortableList = SortableContainer(VirtualList)

export const PageList = ({
  pages,
  showDeletedPages,
  thumbnails,
  pageSize,
  isMobile,
  onSortEnd,
  onSortStart,
  onDeletePage,
  onUndoDeletePage,
  onRotatePage,
  onSelectPage,
}) => {
  const pressDelay = isTouchDevice ? 150 : 0
  return (
    <SortableList
      helperClass="sortable-item-helper"
      axis="xy"
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      useWindowAsScrollContainer
      pressDelay={pressDelay}
      pages={pages}
      pageSize={pageSize}
      isMobile={isMobile}
      showDeletedPages={showDeletedPages}
      thumbnails={thumbnails}
      onDeletePage={onDeletePage}
      onUndoDeletePage={onUndoDeletePage}
      onRotatePage={onRotatePage}
      onSelectPage={onSelectPage}
    />
  )
}
