import styled from 'styled-components'
import Button from '../../controls/Button'
import { colors } from '../../theme'
export const Section = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: var(--gray-100);
`
export const DocumentList = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  padding: 8px 20px 62px 16px;
  width: auto;
  // height: 88vh;
`
export const Sidebar = styled.aside`
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 100%;
  padding: 20px 20px 0 20px;
`
export const DocumentListHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  justify-content: space-between;
`
export const Breadcrumb = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
`
export const BreadCrumbNav = styled.nav`
  aria-label: breadcrumb;
`
export const BreadcrumbList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
`
export const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  & + &::before {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '\\00a0\\002F';
  }
`

export const DocumentList_list = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: scroll;
  position: relative;
  border-radius: 5px;
  background: ${colors.bodyBackground};
  /* WebKit browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.bgLighter};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary};
    border-radius: 4px;
    border: 3px solid ${colors.bgLighter};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primaryDark};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.secondary} ${colors.bgLighter};
`

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 190px;
  min-width: 190px;
  & > .is-selected {
    color: rgb(44, 44, 44);
    background-color: rgba(44, 44, 44, 0.06);
  }
`
export const Item = styled.li`
  list-style-type: none;
  margin: 4px 0;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: left;
  justify-content: left;
  box-sizing: border-box;
  width: 100%;
  min-height: 32px;
  padding: 5px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  cursor: pointer;
  transition: background-color 130ms ease-out, color 130ms ease-out;
`
export const UploadButton = styled(Button)``
