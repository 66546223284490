import React, { useEffect, useMemo, useState } from 'react'

import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import textIcon from '../../../../controls/icons/text'
import imageIcon from '../../../../controls/icons/image'

import PagePicker from '../../../../components/PagePicker'

import ImageStamp from '../../../WaterMarkPopup/Image'
import TextStamp from '../../../WaterMarkPopup/Text'
import { pageRatio } from '../../../WaterMarkPopup/Popup/common'

import { useJobConfig } from '../../../JobConfig/JobContext'

import t from './translate'

const PdfToWordConfig = ({ onChange, form, inputFiles }) => {
  const { updateActionConfig, actionConfig, docIdExt } = useJobConfig()
  const [customSelection, setCustomSelection] = useState([])
  const [pageSelectionError, setPageSelectionError] = useState(undefined)
  const [pagePickerOpen, setPagePickerOpen] = useState(false)
  const [stampOpen, setStampOpen] = useState('')
  useEffect(() => {
    const values = {
      text: '',
      fontFamily: 'Arial',
      fontBold: false,
      fontItalics: false,
      fontUnderline: false,
      fontSize: 12 / pageRatio,
      fontColor: '#000000',
      opacity: 0.75,
      xalign: 'center',
      yalign: 'middle',
      rotate: 0,
      stampPosition: 'foreground',
      mode: 'fill',
      lineWidth: 1.0,
      marginX: 0, // defaultMargin,
      marginY: 0, // defaultMargin,
      showOnlyInPrint: false,

      marginXcm: 0, // defaultMarginCM,
      marginYcm: 0, // defaultMarginCM,
      pageSelectionType: 'all',
      stampType: '',
    }
    updateActionConfig(values)
  }, [])

  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  return (
    <>
      <FormSection>
        <FormRow>
          <ButtonGroup
            name="stampType"
            items={[
              {
                id: 'btnTextStamp',
                value: 'text',
                label: t('lblText'),
                icon: textIcon,
              },
              {
                id: 'btnImageStamp',
                value: 'image',
                label: t('lblImage'),
                icon: imageIcon,
              },
            ]}
            value={form.stampType}
            type="button"
            onChange={() => {}}
            onClick={(e) => {
              const { value } = e.target
              setStampOpen(value)
              onChange(e)
            }}
          />
        </FormRow>
        {form.stampType !== '' && (
          <p className="action-description">
            {t(`stampTypeDesc_${form.stampType}`)}
          </p>
        )}
        {form.stampType === '' && (
          <p className="action-description">{t('lblSelectStamp')}</p>
        )}
        <FormRow label={t('lblPageSelection')}>
          <ButtonGroup
            name="pageSelectionType"
            items={[
              {
                id: 'btnAllPages',
                value: 'all',
                label: t('allPages'),
              },
              {
                id: 'btnFirstPage',
                value: 'first',
                label: t('firstPage'),
              },
              {
                id: 'btnSelectPages',
                value: 'custom',
                label: t('customSelection'),
                disabled: !filesReady,
              },
            ]}
            value={form.pageSelectionType}
            type="radio"
            onClick={(e) => {
              if (e.target.value === 'custom') {
                setPagePickerOpen(true)
              }
            }}
            onChange={onChange}
          />
        </FormRow>
      </FormSection>

      {stampOpen === 'text' && (
        <TextStamp
          config={form}
          updateConfig={(updateValues) => {
            updateActionConfig(updateValues)
          }}
          onClose={() => setStampOpen('')}
          filesReady={filesReady}
          inputFiles={inputFiles}
          pageSelectionType={form.pageSelectionType}
          // customSelection={form.customSelection}
        />
      )}
      {stampOpen === 'image' && (
        <ImageStamp
          config={form}
          updateConfig={(updateValues) => {
            updateActionConfig(updateValues)
          }}
          onClose={() => setStampOpen('')}
          filesReady={filesReady}
          inputFiles={inputFiles}
          pageSelectionType={form.pageSelectionType}
          customSelection={form.customSelection}
        />
      )}
      {pagePickerOpen && (
        <PagePicker
          selectedPages={form.customSelection || []}
          onSelect={(selection) => {
            setCustomSelection(selection.selectedPages)
            updateActionConfig({ customSelection: selection.selectedPages })
            setPagePickerOpen(false)
          }}
          inputFiles={inputFiles}
          onCancel={() => {
            setPagePickerOpen(false)
          }}
        />
      )}
    </>
  )
}

export default PdfToWordConfig
