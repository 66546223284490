export function toPascalCase(text) {
  return text
    ? text
        .replace(/\s(.)/g, ($1) => {
          return $1.toUpperCase()
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, ($1) => {
          return $1.toUpperCase()
        })
        .replace(/\W/g, '')
    : ''
}
