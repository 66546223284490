import styled from 'styled-components'
import Icons from '../../assets/Icons'
import { Button as FluentButton } from '@fluentui/react-components'

export const ThreeDotsIcon = styled(Icons)`
  width: 20px;
`
export const ToggleButton = styled(FluentButton)`
  background: transparent;
  border: none;
  &:hover,
  &:active {
    background: transparent;
    border: none;
  }
`
