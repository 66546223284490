import React from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import compressIcon from '../../../../controls/icons/compress'
import compressMaxIcon from '../../../../controls/icons/compressMax'
import t from './translate'

const CompressPdfConfig = ({ form, onChange }) => {
  return (
    <FormRow label={t('lblOptimize')} hint={t(`optimizeDesc_${form.profile}`)}>
      <ButtonGroup
        name="profile"
        items={[
          {
            id: 'btnOptimizeDefault',
            value: 'compress',
            label: 'Default',
            icon: compressIcon,
          },
          {
            id: 'btnOptimizeMax',
            value: 'compressMax',
            label: 'Max',
            icon: compressMaxIcon,
          },
        ]}
        type="button"
        value={form.profile}
        onChange={onChange}
      />
    </FormRow>
  )
}

export default CompressPdfConfig
