export const fontSize = {
  normal: '15px',
  sm: '14px',
  xs: '12px',
  lg: '17px',
  xl: '18px',
  h1: '40px',
  h2: '26px',
  h3: '22px',
  h4: '18px',
  h5: '15px',
  display1: '56px',
  display2: '28px',
  display3: '22px',
}
