import React from 'react'

const Icons = ({ toolName, className }) => {
  const Icon = require(`./${toolName}`).default
  return (
    <Icon {...(className && { className })} />
  )
}

export default Icons

