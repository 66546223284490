import styled from 'styled-components'
export default (props) => {
  const { className, icon } = props
  const classes = ['icon']
  if (className) {
    classes.push(className)
  }
  const Span = styled.span`
  padding: 0;
  margin: 0;
  width: 26px;
  height: 18px;
  text-align: center;
  display: inline-block;
  svg {
    width: 21px;
    height: 17px;
  }
`
  return (
    <Span className={classes.join(' ')}>
      <svg viewBox={`0 0 ${icon.w} 512`}>
        <path d={icon.p} />
      </svg>
    </Span>
  )
}
