import { CanvasModule } from '@pdf-tools/four-heights-pdf-web-viewer'

export class AddQrCodeModule extends CanvasModule {
  static moduleInfo = {
    name: 'AddQrCodeModule',
    moduleType: 0,
    allowInViewOnly: false,
    translationKey: 'annotQrCode.add',
    icon: 'addQrCode',
    requiredFeatures: { annotate: true, fillFormFields: false },
  }

  constructor() {
    super()
    this.annotationbarElement = null
    this.toolbarElement = null
    this.annotationbar = null
    this.name = 'AddQrCodeModule'
  }
  onRegister() {
    this.toolbarElement = document.createElement('div')

    return {
      toolbar: this.toolbarElement,
    }
  }

  activate() {
  
    const event = new Event('openqrcodeform')
    document.body.dispatchEvent(event)
  }
}
