const Translation = (location) => {
  const t = require(`./${location}.json`)

  return (name) => {
    if (t[name]) return t[name]
    return ''
  }
}

export default Translation
