import React from 'react'
import ResultInfo from './ResultInfo'
import Download from './Download'

const ResultActions = () => {
  return (<>
   <ResultInfo />
   <Download />
   </>
  )
}

export default ResultActions