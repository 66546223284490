import config from '../../lib/config'
//import { logError } from '../../services/logger/logGlobalErrors'
import mutations from './mutations'
//import docProcessing from '../docProcessing/actions'
import thumbnails from '../thumbnails/actions'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { createNotification } from '../notification/action'

const options = {}

const logError = () => {}

function createHub(store, reConnect) {
  const connection = new HubConnectionBuilder()
    .withUrl(config.SIGNALR_HUB_URL, options)
    .build()

  let thumbnailsQueue = []
  let thumbnailsQueueTimer

  const pushThumbnailToQueue = (doc) => {
    thumbnailsQueue.push(doc)
    if (!thumbnailsQueueTimer) {
      thumbnailsQueueTimer = setTimeout(() => {
        store.dispatch(thumbnails.pushThumbnails([...thumbnailsQueue]))
        thumbnailsQueue = []
        thumbnailsQueueTimer = undefined
      }, 200)
    }
  }

  connection.onclose(async (error) => {
    if (error) {
      store.dispatch(mutations.setClosed(error))

      const { hub } = store.getState()
      const { connectionId } = hub

      logError(error, {
        message: 'signalR connection closed, try to reconnect',
        connectionId: connectionId || 'no connectionId',
      })

      await reConnect(connectionId)
    }
  })

  // upload zip
  connection.on('DocUnzipMessage', () => {})

  // upload storage files
  connection.on('addStorageFileStatusChange', (data) => {
    //store.dispatch(docProcessing.addStorageFileStarted(data.jobId, data.docs))
  })
  connection.on('addStorageFileProgress', (data) => {
    //store.dispatch(docProcessing.addStorageFileProgress(data.jobId, data.docs))
  })
  connection.on('addStorageFileError', (data) => {
    //store.dispatch(docProcessing.addStorageFileFailed(data.jobId, data.docs))
  })

  // doc processing
  connection.on('docStatusChange', (data) => {
    // store.dispatch(
    //   docProcessing.processingDocStatusChanged({
    //     ...data,
    //     size: data.fileSize,
    //     status: data.docStatus,
    //   })
    // )

    if (data.docStatus === 'inProgress') {
  
      store.dispatch(createNotification(data))
    }
    if (data.docStatus === 'finished') {

      store.dispatch(createNotification(data))
    }
  })

  // thumbnails
  connection.on('onlineImageStatusChange', (data) => {

    store.dispatch(thumbnails.progress(data))
  })
  connection.on('onlineImageThumbnail', (data) => {

    pushThumbnailToQueue(data)
  })
  connection.on('onlineImageCompleted', (data) => {
  
    store.dispatch(thumbnails.completed(data))
  })

  return connection
}

export function hubMiddleware(store) {
  let connection

  const reConnect = async (connectionId) => {
    try {
      store.dispatch(mutations.setConnecting())

      connection = createHub(store, reConnect)
      await connection.start()
      await connection.invoke('ReConnect', connectionId)
    } catch (error) {
      store.dispatch(mutations.setError(error))
      logError(error, {
        message: 'failed to reconnect to signalR',
      })
    }
  }

  const start = async () => {
    try {
      store.dispatch(mutations.setConnecting())

      connection = createHub(store, reConnect)
      await connection.start()

      const connectionId = await connection.invoke('CreateChannel')
      store.dispatch(mutations.setConnected(connectionId))

      // try {
      // } catch (error) {
      //   store.dispatch(mutations.setError(error))
      //   logError(error, {
      //     message: 'failed to create signalR channel',
      //   })
      // }
    } catch (error) {
      store.dispatch(mutations.setError(error))
      logError(error, {
        message: 'failed to start signalR connection',
      })
    }
  }

  const stop = async () => {
    try {
      store.dispatch(mutations.setClosing())
      await connection.stop()
      store.dispatch(mutations.setClosed())
    } catch (error) {
      store.dispatch(mutations.setError(error))
      logError(error, {
        message: 'failed to stop signalR connection',
      })
    }
  }

  return (next) => (action) => {
    switch (action?.type) {
      case 'HUB_START':
        start()
        break

      case 'HUB_STOP':
        stop()
        break

      case 'INITIALIZE_APP_COMPLETED':
        // start()
        // store.dispatch(mutations.setConnecting())
        // connection
        //   .start()
        //   .then(() => {
        //     connection
        //       .invoke('CreateChannel')
        //       .then(connectionId => {
        //         store.dispatch(mutations.setConnected(connectionId))
        //       })
        //       .catch(error => {
        //         store.dispatch(mutations.setError(error))
        //         logError(error, {
        //           message: 'failed to create signalR channel',
        //         })
        //       })
        //   })
        //   .catch(error => {
        //     store.dispatch(mutations.setError(error))
        //     logError(error, {
        //       message: 'failed to start signalR connection',
        //     })
        //   })

        break

      default:
        break
    }
    return next(action)
  }
}
