import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  fontSize,
  layout,
  colors,
  formControl,
  device,
  media,
} from '../../theme'
// import { useTranslation } from '../../hooks/TranslationContext'
import t from './translate'
import { validatePasswordPolicy } from '../../utils/validators'
import { toPascalCase } from '../../utils/toPascalCase'
import Icon from '../Icon'
import OkIcon from '../icons/ok'
import CloseIcon from '../icons/close'

const PasswordPolicy = styled.div`
  position: relative;
`

const Tooltip = styled.div`
  position: absolute;
  top: -22px;
  right: 0;
  background-color: ${colors.bgLight};
  border-radius: 4px;
  transform: translateY(-100%);
  padding: 10px;
  box-shadow: ${layout.elevate(2)};
  min-width: 33%;

  &:after {
    transform: translateX(-50%);
    bottom: -10px;
    left: 50%;
    height: 0;
    position: absolute;
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 10px solid ${colors.bgLight};
  }
`

const Title = styled.h4`
  margin: 0;
  padding: 10px 0;
`

const List = styled.ul`
  width: 260px;
  margin: 0;
  padding: 0;
`
const Item = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: ${fontSize.sm};
  height: 22px;
  .icon {
    width: 24px;
    margin-right: 8px;
  }
  svg {
    fill: ${colors.errorLight};
  }
  &.valid {
    color: ${colors.successLighter};
    svg {
      fill: ${colors.successLighter};
    }
  }
`

export default (props) => {
  const { password, showHint } = props
  // const t = useTranslation()
  const [passwordStrength, setPasswordStrength] = useState([])
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const res = validatePasswordPolicy(password)
    const items = Object.keys(res.tests).map((k) => ({
      name: k,
      valid: res.tests[k],
    }))
    setPasswordStrength(items)
    setIsValid(res.isValid)
  }, [password])

  if (isValid) {
    return null
  }

  return (
    <PasswordPolicy>
      {isValid && <Icon icon={OkIcon} />}

      {showHint && (
        <Tooltip>
          <List>
            {passwordStrength.map((item) => (
              <Item
                key={item.name}
                className={item.valid ? 'valid' : 'invalid'}
              >
                <Icon icon={item.valid ? OkIcon : CloseIcon} />
                <span>{t(`pwPolicy${toPascalCase(item.name)}`)}</span>
              </Item>
            ))}
          </List>
        </Tooltip>
      )}
    </PasswordPolicy>
  )
}
