import styled from 'styled-components'
import { colors, media, device } from '../../../theme'

const Parent = styled.div`
  display: flex;
  height: 38px;
  cursor: default;
  margin-top: 20px;
`

const Tab = styled.div`
  // margin: 5px;
  background-color: ${colors.grayLighter};
  color: ${colors.gray};
  border-width: 1px 1px 0px 1px;
  border-color: ${colors.gray};
  border-style: solid;
  padding: 10px 35px 5px;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
  ${media.down(device.mobile)`
  padding: 10px 16px;
  `}
  &.active {
    background-color: ${colors.bodyBackground};
    color: ${colors.text};
    transform: scale(1.1, 1.1);
    cursor: default;
    transition: all 0.5s;
  }
`

export default ({ items, tab }) => {
  return (
    <>
      <div>
        <Parent>
          {items.map((f) => {
            return (
              <Tab
                key={f.id}
                id={f.id}
                onClick={f.action}
                className={tab === f.id ? 'active' : ''}
              >
                <span>{f.name}</span>
              </Tab>
            )
          })}
        </Parent>
      </div>
    </>
  )
}
