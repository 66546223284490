export const colors = {
  text: '#252525',
  textMuted: '#999999',
  textContrast: '#fff',
  bodyBackground: '#fff',

  primaryLight: '#dd7174',
  primary: '#c71217',
  primaryDark: '#770b0e',
  primaryContrast: '#fff',

  secondary: '#3660b3',
  secondaryLight: '#86a0d1',
  secondaryContrast: '#fff',

  bgLight: '#eeeff2',
  bgLighter: '#f9f8f9',

  grayLighter: '#f9f8f9',
  grayLighterContrast: '#252525',
  grayLight: '#eeeff2',
  grayLightContrast: '#252525',
  gray: '#b1b1b1',
  grayContrast: '#fff',
  grayDark: '#203a6b',
  grayDarkContrast: '#fff',
  grayDarker: '#162648',
  grayDarkerContrast: '#fff',

  success: '#007E33',
  successContrast: '#fff',
  successLight: '#00C851',
  successLighter: '#00C851',
  info: '#0099CC',
  infoLight: '#99BFCC',
  infoLighter: '#BAEDFF',
  infoContrast: '#fff',
  warning: '#FF8800',
  warningContrast: '#fff',
  warningLight: '#FFC27C',
  warningLightContrast: '#fff',
  warningLighter: '#FFF3E5',
  warningLighterContrast: '#000',
  errorDark: '#880000',
  error: '#cc0000',
  errorContrast: '#fff',
  errorLight: '#ff4444',
  errorLighter: '#ffeeee',
}
