import React from 'react'

import {
  PopupModule,
  HighlightAnnotationModule,
  TextAnnotationModule,
  InkAnnotationModule,
  FreetextAnnotationModule,
  StampAnnotationModule,
  ShapeAnnotationModule,
  ImageAnnotationModule,
} from '@pdf-tools/four-heights-pdf-web-viewer'

// import { ImageCaptureModule } from '../modules/ImageCapture/ImageCaptureModule'
import { AddQrCodeModule } from '../modules/AddQrCode/AddQrCodeModule'

import Viewer from '../components/Viewer'
import AddQrCode from '../components/AddQrCode'

import '../../../theme/viewer/scss/styles.scss'

const App = (props) => {
  return (
    <>
      <Viewer
        options={{
          modules: [
            PopupModule,
            TextAnnotationModule,
            HighlightAnnotationModule,
            FreetextAnnotationModule,
            InkAnnotationModule,
            StampAnnotationModule,
            ShapeAnnotationModule,
            ImageAnnotationModule,
            AddQrCodeModule,
            // ImageCaptureModule,
          ],
        }}
        toolbar={() => <></>}
      />
      <AddQrCode />
    </>
  )
}

export default App
