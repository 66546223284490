import Icon from '../Icon'

const Button = ({
  variant,
  color,
  size,
  type = 'button',
  startIcon,
  endIcon,
  href,
  id,
  loading,
  disabled,
  children,
  onClick,
  className
}) => {
  const label = children || null

  const classes = ['btn']

  if (variant) {
    classes.push(`btn--${variant}`)
  }

  if (color) {
    classes.push(`btn--${color}`)
  }

  if (size) {
    classes.push(`btn--${size}`)
  }

  if (disabled) {
    classes.push(`btn--${disabled}`)
  }

  if(className){
    classes.push(className)
  }

  function renderContent() {
    if (variant === 'round' || variant === 'icon') {
      const icon = startIcon ? <Icon icon={startIcon} /> : null
      return loading ? <div className="btnLoader" /> : icon
    }

    const startIconElm = startIcon ? <Icon icon={startIcon} className="btn__startIcon" /> : null
    const endIconElm = endIcon ? <Icon icon={endIcon} className="btn__endIcon" /> : null
    return (
      <>
        {loading ? <div className="btnLoader btn__startIcon" /> : startIconElm}
        {label}
        {endIconElm && endIconElm}
      </>
    )
  }

  if (href) {
    const aHref = href

    return (
      <a
        href={aHref}
        id={id}
        onClick={(e) => {
          if (loading || disabled) {
            e.preventDefault()
            return
          }
          if (onClick) {
            onClick(e)
          }
        }}
        className={classes.join(' ')}
        disabled={disabled}
      >
        {renderContent()}
      </a>
    )
  }

  return (
    <button
      id={id}
      className={classes.join(' ')}
      disabled={disabled}
      type={type}
      onClick={(e) => {
        if (onClick && !loading && !disabled) {
          onClick(e)
        }
      }}
    >
      {renderContent()}
    </button>
  )
}

export default Button
