import { useState } from 'react'
import styled from 'styled-components'

import Button from '../../../controls/Button'
import Icon from '../../../controls/Icon'
import Dropdown from './DropDown'
import FormRow from '../../../controls/FormRow'
import rotateLeftIcon from '../../../controls/icons/rotateLeft'
import rotateRightIcon from '../../../controls/icons/rotateRight'
import longArrowUpIcon from '../../../controls/icons/longArrowUp'
import imageSizeDownIcon from '../../../controls/icons/imageSizeDown'
import imageSizeUpIcon from '../../../controls/icons/imageSizeUp'
import arrowsHIcon from '../../../controls/icons/arrowsH'
import arrowsVIcon from '../../../controls/icons/arrowsV'
import t from '../translate'
import {
  A4PageWidth,
  A4PageHeight,
  defaultMarginCM,
  A4PageWidthCM,
  A4PageHeightCM,
} from './common'
import { colors, device, media } from '../../../theme'

const ToolBar = styled.div`
  // margin-top: 12px;
  // ${media.down(device.tablet)`
  //   margin-top: 8px;
  // `};
`

const Root = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0px auto;
  user-select: none;
  ${media.down(device.tablet)`
    align-items: center;
  `};
`

const Separator = styled.div`
  height: 30px;
  margin: 0 5px;
  width: 1px;
  align-self: center;
  background-color: ${colors.gray};
  ${media.down(device.tablet)`
    margin: 0 6px;
    height: 20px;
  `}

  ${media.down(device.mobile)`
    display: none;
  `}
`

const AlignGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 40px 40px;
  grid-template-rows: 40px 40px 40px;
  grid-gap: 2px;
  height: 120px;
  width: 120px;
`

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${colors.grayLight};
  color: ${colors.grayLightContrast};
  display: flex;
  align-items: center;
  justify-content: center;
  &.red {
    background-color: ${colors.primary};
    fill: ${colors.primaryContrast};
  }
  .center-top {
    transform: rotate(0);
  }
  .left-top {
    transform: rotate(315deg);
  }
  .left-middle {
    transform: rotate(270deg);
  }
  .left-bottom {
    transform: rotate(225deg);
  }
  .center-bottom {
    transform: rotate(180deg);
  }
  .right-bottom {
    transform: rotate(135deg);
  }
  .right-middle {
    transform: rotate(90deg);
  }
  .right-top {
    transform: rotate(45deg);
  }
  &.small {
    width: 30px;
    height: 30px;
  }
`

const MarginBox = styled.div`
  width: 30px;
  height: 30px;
  // background-color: ${colors.grayLight};
  color: ${colors.grayLightContrast};
  display: flex;
  align-items: center;
  justify-content: center;

  .bottom {
    transform: rotate(0);
  }
  .right {
    transform: rotate(270deg);
  }
  .top {
    transform: rotate(180deg);
  }
  .left {
    transform: rotate(90deg);
  }
  &.disabled {
    opacity: 0.7;
  }
  &.hide {
    visibility: hidden;
  }
`

const NumberInput = styled.input`
  width: 49px;
  &.hide {
    visibility: hidden;
  }
  ${media.down(device.mobile)`
    width: 35px;
  `}
`

const DropDownWrapper = styled.div`
  width: 122px;
  ${media.down(device.mobile)`
    width: auto;
  `}
`

const ArrowBox = ({ align, disabled, dispMargin, onChange, hide }) => {
  return (
    <>
      <MarginBox className={hide ? 'hide' : ''}>
        {!disabled &&
          (align === 'top' || align === 'middle' || align === 'bottom') && (
            <Icon icon={arrowsVIcon} />
          )}
        {!disabled &&
          (align === 'center' || align === 'left' || align === 'right') && (
            <Icon icon={arrowsHIcon} />
          )}
      </MarginBox>
      <NumberInput
        type="number"
        step="0.1"
        min="0"
        max="100"
        value={dispMargin}
        onChange={onChange}
        disabled={disabled}
        className={hide ? 'hide' : ''}
      />
    </>
  )
}

export default ({ config, updateConfig, disabled }) => {
  // const t = useTranslation()

  const xalignList = [
    { key: 'left', value: t('left') },
    { key: 'center', value: t('center') },
    { key: 'right', value: t('right') },
  ]

  const yalignList = [
    { key: 'top', value: t('top') },
    { key: 'middle', value: t('middle') },
    { key: 'bottom', value: t('bottom') },
  ]

  const {
    xalign,
    yalign,
    rotate,
    width,
    height,
    // marginX,
    // marginY,
    marginXcm,
    marginYcm,
  } = config

  const setAlign = (x, y) => {
    const draggable = x === 'left' && y === 'top'
    const newConfig = { ...config, xalign: x, yalign: y, draggable }
    updateConfig(newConfig)
  }

  const setStampRotate = (angle) => {
    const newConfig = { ...config, rotate: angle }
    updateConfig(newConfig)
  }

  const setImageSize = (h, w) => {
    const newConfig = { ...config, width: w, height: h }
    updateConfig(newConfig)
  }

  const setMarginX = (xCM) => {
    let _margin = (xCM / A4PageWidthCM) * A4PageWidth
    // if (xalign === 'right') _margin *= -1
    // if (xalign === 'center') _margin = 0
    const newConfig = { ...config, marginX: _margin, marginXcm: xCM }
    updateConfig(newConfig)
  }

  const setMarginY = (yCM) => {
    let _margin = (yCM / A4PageHeightCM) * A4PageHeight
    // if (yalign === 'top') _margin *= -1
    // if (yalign === 'middle') _margin = 0
    const newConfig = { ...config, marginY: _margin, marginYcm: yCM }
    updateConfig(newConfig)
  }

  const [rotateLeftDisable, setRotateLeftDisable] = useState(false)
  const [rotateRightDisable, setRotateRightDisable] = useState(true)

  const onRotateLeft = () => {
    const _rotate = rotate + 45
    if (_rotate > 90) {
      return
    }

    if (rotateLeftDisable) setRotateLeftDisable(false)
    if (rotateRightDisable) setRotateRightDisable(false)

    if (_rotate < 360) {
      setStampRotate(_rotate)
    } else {
      setStampRotate(0)
    }

    if (_rotate >= 90) {
      if (!rotateLeftDisable) setRotateLeftDisable(true)
    }
  }

  const onRotateRight = () => {
    const _rotate = rotate - 45
    if (_rotate < 0) {
      return
    }

    if (rotateLeftDisable) setRotateLeftDisable(false)
    if (rotateRightDisable) setRotateRightDisable(false)

    if (_rotate >= 0) {
      setStampRotate(_rotate)
    } else {
      setStampRotate(315)
    }

    if (_rotate <= 0) {
      if (!rotateRightDisable) setRotateRightDisable(true)
    }
  }

  const increaseSize = () => {
    const _w = width * 1.1
    const _h = height * 1.1
    setImageSize(_h, _w)
  }

  const decreaseSize = () => {
    const _w = width * 0.9
    const _h = height * 0.9
    setImageSize(_h, _w)
  }

  const alignMenus = [
    {
      renderItem: () => (
        <>
          <AlignGrid>
            {yalignList.map((y) =>
              xalignList.map((x) => (
                <Box
                  key={`${x.key}-${y.key}`}
                  className={xalign === x.key && yalign === y.key ? 'red' : ''}
                  onClick={() => {
                    setAlign(x.key, y.key)
                  }}
                  onKeyUp={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  {(x.key !== 'center' || y.key !== 'middle') && (
                    <Icon
                      icon={longArrowUpIcon}
                      className={`${x.key}-${y.key}`}
                    />
                  )}
                </Box>
              ))
            )}
          </AlignGrid>
        </>
      ),
      disabled,
    },
  ]

  const dispXAlign = xalign
    ? xalignList.find((f) => f.key === xalign).value
    : ''

  const dispYAlign = yalign
    ? yalignList.find((f) => f.key === yalign).value
    : ''
  const marginHide = xalign === 'center' && yalign === 'middle'
  return (
    <>
      <ToolBar>
        <Root>
          <Button
            startIcon={imageSizeDownIcon}
            variant="icon"
            onClick={decreaseSize}
            disabled={disabled}
          />
          <Button
            startIcon={imageSizeUpIcon}
            variant="icon"
            onClick={increaseSize}
            disabled={disabled}
          />
          <Separator />

          {false && (
            <>
              <Button
                startIcon={rotateLeftIcon}
                variant="icon"
                onClick={onRotateLeft}
                disabled={disabled || rotateLeftDisable}
              />
              <Button
                startIcon={rotateRightIcon}
                variant="icon"
                onClick={onRotateRight}
                disabled={disabled || rotateRightDisable}
              />
              <Separator />
            </>
          )}
          <DropDownWrapper>
            <Dropdown items={alignMenus} disabled={disabled}>
              {dispXAlign} {dispYAlign}
            </Dropdown>
          </DropDownWrapper>
          <Separator />
          <span style={{ visibility: marginHide ? 'hidden' : 'visible' }}>
            {t('MarginInCm')}:
          </span>
          <FormRow>
            <ArrowBox
              align={xalign}
              // disabled={xalign === 'center'}
              dispMargin={marginXcm}
              onChange={(e) => {
                setMarginX(e.target.value)
              }}
              hide={marginHide}
            />
          </FormRow>
          <FormRow>
            <ArrowBox
              align={yalign}
              // disabled={yalign === 'middle'}
              dispMargin={marginYcm}
              onChange={(e) => {
                setMarginY(e.target.value)
              }}
              hide={marginHide}
            />
          </FormRow>
        </Root>
      </ToolBar>
    </>
  )
}
