import React from 'react'
import styled from 'styled-components'

import Popup from '../../../../controls/Popup'
import Button from '../../../../controls/Button'
import ButtonRow from '../../../../controls/ButtonRow'
import { colors, fontSize } from '../../../../theme/viewer'

const IconImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 2rem 0;
  svg {
    width: 100px;
    height: 60px;
    fill: ${colors.grayDark};
  }
`

const Form = styled.form`
  text-align: center;
  p {
    /*font-size: ${fontSize.lg};*/
  }
`

// const FormRow = styled.div`
//   margin-bottom: 2rem;
// `

// const ErrorMessage = styled.div`
//   margin: 0.5rem 0;
//   font-size: ${fontSize.sm};
//   color: ${colors.error};
// `

// const Input = styled.input`
//   text-align: center;
//   width: 100%;
//   border: none;
//   border-bottom: solid 1px ${colors.grayDarker};
//   height: 30px;
//   &.error {
//     border-color: ${colors.error};
//   }
// `

const ErrorInfo = ({ error }) => {
  return (
    <Popup size="sm">
      <IconImage>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M368 256h-73.8l25-74.9c1.6-4.9.8-10.2-2.2-14.4-3-4.2-7.8-6.6-13-6.6H192c-7.7 0-14.3 5.5-15.8 13.1l-32 176c-.8 4.7.4 9.5 3.5 13.1s7.5 5.8 12.3 5.8h80v128c0 7.4 5.1 13.9 12.3 15.6 1.2.3 2.5.4 3.7.4 6 0 11.6-3.3 14.3-8.8l112-224c2.5-5 2.2-10.8-.7-15.6-2.9-4.8-8.1-7.7-13.6-7.7zm-96 172.2V352c0-8.8-7.2-16-16-16h-76.8l26.2-144h76.5l-25 74.9c-1.6 4.9-.8 10.2 2.2 14.4 3 4.2 7.8 6.6 13 6.6h70.1L272 428.2zm143.7-315.9C411.8 67.4 373.9 32 328 32c-17.8 0-34.8 5.3-49.2 15.2C256.3 17.7 221.5 0 184 0 117.8 0 64 53.8 64 120v.4c-38.3 16-64 53.5-64 95.6 0 57.3 46.7 104 104 104h13l5.8-32H104c-39.7 0-72-32.3-72-72 0-32.3 21.9-60.7 53.3-69.2l13.3-3.6-2-17.2c-.3-2-.6-4-.6-6 0-48.5 39.5-88 88-88 32.2 0 61.8 17.9 77.2 46.8l10.6 19.8L287 82.1C297.9 70.4 312.4 64 328 64c30.9 0 56 25.1 56 56 0 1.6-.3 3.1-.8 6.9l-2.5 20 23.5-2.4c1.2-.2 2.5-.4 3.8-.4 39.7 0 72 32.3 72 72 0 38-29.7 68.9-67 71.5-.7 2-1.1 4-2.1 6L397.7 320H408c57.3 0 104-46.7 104-104 0-54.8-42.6-99.8-96.3-103.7z" />
        </svg>
      </IconImage>
      <Form>
        <h3>Something went wrong</h3>
        <p>{error}</p>
        <ButtonRow center>
          <Button href="/">Close</Button>
        </ButtonRow>
      </Form>
    </Popup>
  )
}

export default ErrorInfo
