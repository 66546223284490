import React from "react";
import {Link} from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
      <div class="ct ct--content ct-404">
        <div class="row">
          <h1>Page Not Found</h1>
          <h2 class="h3">
            We're sorry, the page you are requested could not be found
          </h2>
          <Link to="/home" class="btn btn--primary btn--large">
            Visit Homepage
          </Link>
        </div>
        <div class="bg404"></div>
      </div>
    </>
  );
};

export default PageNotFound;
