const config = {
  ...(window?.p4mConfig || {}),
  login_url: `${window.location.origin}${window?.p4mConfig?.serverHost}`,
  redirect_url: `${window.location.origin}${window?.p4mConfig?.redirectEndpoint}`,
  reactAppClientId: process.env.REACT_APP_CLIENT_ID,
  tokenUrl: `${window?.p4mConfig.serverHost2}/connect/token`,
}

// window.p4m_config = config

export default config
