import React from 'react'

export default function (props) {
  const { className } = props


  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="#000" d="M88.281 84.375a3.918 3.918 0 0 1-3.906 3.906 3.918 3.918 0 0 1-3.906-3.906 3.918 3.918 0 0 1 3.906-3.906 3.918 3.918 0 0 1 3.906 3.906Zm-16.406-3.906a3.918 3.918 0 0 0-3.906 3.906 3.918 3.918 0 0 0 3.906 3.906 3.918 3.918 0 0 0 3.906-3.906 3.918 3.918 0 0 0-3.906-3.906ZM100 71.094v20.312A8.594 8.594 0 0 1 91.406 100H8.594A8.594 8.594 0 0 1 0 91.406V71.094A8.594 8.594 0 0 1 8.594 62.5h19.414L16.992 51.484c-4.922-4.922-1.426-13.34 5.527-13.34h10.294V7.814A7.81 7.81 0 0 1 40.624 0h18.75a7.81 7.81 0 0 1 7.813 7.813v30.332H77.48c6.953 0 10.43 8.417 5.527 13.34L71.992 62.5h19.414A8.594 8.594 0 0 1 100 71.094ZM48.887 74.55a1.568 1.568 0 0 0 2.207 0l27.48-27.48c.977-.977.293-2.676-1.113-2.676H60.938V7.812c0-.859-.704-1.562-1.563-1.562h-18.75c-.86 0-1.563.703-1.563 1.563v36.581H22.52c-1.387 0-2.09 1.68-1.114 2.676l27.48 27.48Zm44.863-3.457a2.35 2.35 0 0 0-2.344-2.344H65.742L55.527 78.965c-3.047 3.047-8.008 3.047-11.054 0L34.258 68.75H8.594a2.35 2.35 0 0 0-2.344 2.344v20.312a2.35 2.35 0 0 0 2.344 2.344h82.812a2.35 2.35 0 0 0 2.344-2.344V71.094Z" />
      </svg>
    </div>
  )
}
