import React, { useContext } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import Button from '../../controls/Button'
import FormRow from '../../controls/FormRow'
import ButtonRow from '../../controls/ButtonRow'
import { useDispatch } from 'react-redux'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import { updateUserInfo } from '../../store/teamsAuth/teamsAuthSlice'
import { colors, device, media } from '../../theme'
import Icons from '../../assets/Icons'

export const CustomButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`
const AuthorizationPage = styled.div`
  background-color: ${({ $bg }) => $bg};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const AuthorizationContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
}
`

const Pdf4meLogoIcon = styled(Icons)`
  display: flex;
  align-items: center;
  width: 200px;
  height: 100px;
  ${media.up(device.tablet)`
      width: 30%;
      height: 20%;
    `}
`

const TeamsAuthorize = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { teamsUserCredential, themeString } = useContext(TeamsFxContext)
  const authorize = async () => {
    if (!teamsUserCredential) {
      throw new Error('TeamsFx SDK is not initialized.')
    }
    try {
      const res = await teamsUserCredential.login([
        'User.Read',
        'Files.Read.All',
        // 'Sites.Read.All'
      ])
      const userInfo = await teamsUserCredential.getUserInfo()
      dispatch(updateUserInfo({ ...userInfo, isReadFiles: true }))
      navigate('/home')
    } catch (err) {}
  }
  return (
    <AuthorizationPage $bg={colors.bodyBackground}>
      <AuthorizationContent>
        <Pdf4meLogoIcon toolName={'pdf4melogo'} />

        <div>
          <p>
            PDF4me lets you view, convert, edit, and optimize PDF files,
            collaborate with others, and complete all your essential PDF tasks.
          </p>
          <p>
            Please accept the permissions on the next screen to start using the
            app.
          </p>
        </div>
        <ButtonRow center noWrap>
          <CustomButton color={'secondary'} onClick={authorize}>
            Authorize
          </CustomButton>
        </ButtonRow>
      </AuthorizationContent>
    </AuthorizationPage>
  )
}

export default TeamsAuthorize
