export const fontSize = {
  normal: '14px',
  sm: '12px',
  xs: '10px',
  lg: '16px',
  xl: '18px',
  h1: '34px',
  h2: '26px',
  h3: '20px',
  h4: '16px',
  h5: '14px',
  display1: '42px',
  display2: '34px',
  display3: '26px',
}
