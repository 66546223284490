import React from 'react'

export default function (props, context) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg width='24' height='24' viewBox="0 0 71 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path  fill="currentColor" d="M21 0C18.3478 0 15.8043 1.05357 13.9289 2.92893C12.0536 4.8043 11 7.34784 11 10V37.005C12.5454 36.1102 14.2389 35.5005 16 35.205V10C16 8.67392 16.5268 7.40215 17.4645 6.46447C18.4022 5.52678 19.6739 5 21 5H41V22.5C41 24.4891 41.7902 26.3968 43.1967 27.8033C44.6032 29.2098 46.5109 30 48.5 30H66V70C66 71.3261 65.4732 72.5979 64.5355 73.5355C63.5979 74.4732 62.3261 75 61 75H41V80H61C63.6522 80 66.1957 78.9464 68.0711 77.0711C69.9464 75.1957 71 72.6522 71 70V27.07C70.9983 25.0815 70.2069 23.1752 68.8 21.77L49.23 2.195C47.824 0.789908 45.9177 0.000424407 43.93 0H21ZM64.965 25H48.5C47.837 25 47.2011 24.7366 46.7322 24.2678C46.2634 23.7989 46 23.163 46 22.5V6.035L64.965 25Z" fill="white" />
        <path  fill="currentColor" d="M32.75 32.25C27.115 32.25 22.5417 37.29 22.5417 43.5V48H4.16668C3.08371 48 2.0451 48.4741 1.27932 49.318C0.513551 50.1619 0.0833435 51.3065 0.0833435 52.5V75C0.0833435 76.1935 0.513551 77.3381 1.27932 78.182C2.0451 79.0259 3.08371 79.5 4.16668 79.5H28.6667C30.9329 79.5 32.75 77.4975 32.75 75V52.5C32.75 51.3065 32.3198 50.1619 31.554 49.318C30.7883 48.4741 29.7496 48 28.6667 48H26.625V43.5C26.625 39.765 29.3608 36.75 32.75 36.75C36.1392 36.75 38.875 39.765 38.875 43.5V48H42.9583V43.5C42.9583 37.29 38.385 32.25 32.75 32.25ZM16.4167 59.25C17.4996 59.25 18.5383 59.7241 19.304 60.568C20.0698 61.4119 20.5 62.5565 20.5 63.75C20.5 66.2475 18.6829 68.25 16.4167 68.25C15.3337 68.25 14.2951 67.7759 13.5293 66.932C12.7636 66.0881 12.3333 64.9435 12.3333 63.75C12.3333 62.5565 12.7636 61.4119 13.5293 60.568C14.2951 59.7241 15.3337 59.25 16.4167 59.25Z" fill="white" />
      </svg>

    </div>
  )
}
