import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { downloadFile } from '../../../../store/notification/action'
import { useJobConfig } from '../../../JobConfig/JobContext'
import { redirectToViewer } from '../../../../utils/helper'

const PdfEditor = ({ toolName, inputFiles }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { closeToolPopup } = useJobConfig()
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )

  useEffect(() => {
    ;(async () => {
      try {
        if (fileReady) {
          const file = await dispatch(
            downloadFile({
              name: inputFiles[0].fileName,
              docBlobRef: inputFiles[0].docBlobRef,
              storageType: 'cdoc',
            })
          )
          if (file.error) {
            closeToolPopup && closeToolPopup()
            return
          }
          redirectToViewer(navigate, file.payload?.url, toolName)
        }
      } catch (error) {
        closeToolPopup && closeToolPopup()
      }
    })()
  }, [fileReady])

  return ''
}

export default PdfEditor
