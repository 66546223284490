import { FetchError } from './FetchError'
// import { logError } from './logger/logGlobalErrors'
import config from '../lib/config'

//const BASE_URL = 'https://localhost:7257'

export const initFetch = (method, data) => {
  const init = {
    method,
    credentials: 'include',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
      Accept: 'application/json',
      'Accept-Language': 'en-US,en',
      credentials: 'include',
    },
  }
  const pdf4me = localStorage.getItem('pdf4me')
    ? JSON.parse(localStorage.getItem('pdf4me'))
    : {}

  if (pdf4me.tokenType && pdf4me.accessToken) {
    init.headers['Authorization'] = `${pdf4me.tokenType} ${pdf4me.accessToken}`
  }
  if (data) {
    init.headers['Content-Type'] = 'application/json'
    init.body = JSON.stringify(data)
  }

  return init
}

const handleApiCall = async (method, url, data) => {
  try {
    const response = await window.fetch(url, initFetch(method, data))

    if (response.status === 200) {
      const resData = await response.json()
      return {
        ok: true,
        json: resData,
      }
    }

    if (response.status === 204) {
      return null // No content
    }

    if (response.status === 401) {
      const event = new CustomEvent('sessiontimeout', {
        detail: { method, url, data },
      })
      window.dispatchEvent(event)
    }
    const val = response.text
    const error = await response.json()
    throw new FetchError(response, error.message)
  } catch (error) {
    throw new FetchError(error?.response, error?.message)
  }
}

export const api = {
  get: async (url) => {
    return handleApiCall('GET', url)
  },
  post: async (url, data) => {
    return handleApiCall('POST', url, data)
  },
}
