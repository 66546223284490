import React, { useState } from 'react'
import { MessageType, PopupScreen } from '../../utils/enums'
import { useMessage } from '../../controls/MessageBox'
import { useJobConfig } from '../JobConfig/JobContext'

const MyComputer = ({ width, height, multiple }) => {
  const { closeToolPopup, accept, tool, onFileSelect, index } = useJobConfig()
  const { showMessage } = useMessage()
  const [isDragOver, setIsDragOver] = useState(false)
  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragEnter = (event) => {
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = () => {
    setIsDragOver(false)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setIsDragOver(false)
    try {
      event.preventDefault()
      setIsDragOver(false)
      const files = []
      const items = event.dataTransfer.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          const file = items[i].getAsFile()
          if (file) {
            files.push(file)
          }
        }
      }
      if (!files?.length) throw new Error('File not found')
      onFileSelect(tool, files, true, PopupScreen.StartJob, index)
    } catch (error) {
      closeToolPopup()
      showMessage(error.message, MessageType.Error)
    }
  }
  return (
    <section
      className="dropBox"
      style={{ width: width || 400, height: height || 250 }}
    >
      <div
        className={`dropBox__content ${isDragOver ? 'dragOver' : ''}`}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        accept={accept}
      >
        <div className="dropBox__input">
          <input
            className="dropBox__file "
            type="file"
            name="files[]"
            id="file"
            data-multiple-caption="{count} files selected"
            accept={accept}
            multiple={multiple}
            onChange={(e) =>
              onFileSelect &&
              onFileSelect(tool, e.target.files, true, PopupScreen.StartJob)
            }
          />
          <label htmlFor="file" className="btn btn--primary">
            Select File
          </label>
          <span>or Drop file here</span>
        </div>
        <div className="dropBox__uploading">Uploading…</div>
        <div className="dropBox__success">Done!</div>
        <div className="dropBox__error">
          Error! <span></span>.
        </div>
      </div>
    </section>
  )
}

export default MyComputer
