import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M82.966 43.476 52.512 76.48 24.019 47.982l24.88-25.75L62.44 35.23 45.287 51.498l6.292 6.264 23.684-23.497L49.163 10 10 48.477 52.297 90 90 49.988l-7.034-6.512Z" />
      </svg>



    </div>
  )
}
