import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 537 112">
        <path
          fill="#2E3137"
          d="M45.98 6.37 41.48 0C31.33 8 20.59 16.44 16.62 19.58l8.68 19 .78-.48c8.29-4.82 15.22-10.39 20.11-20.73 1.75-3.92 1.65-8.35-.21-11Z"
        />
        <path
          fill="url(#a)"
          d="M48.31 20.29 31.63 71.64s-2.84 7.92-9.41 10.29c-6.1 2.21-14.21 5.32-14.21 5.32l-8-28.81 27.23-16.07c8.79-4.94 16.09-10.76 21.07-22.11v.03Z"
        />
        <path
          fill="#2E3137"
          d="m35.55 99.37 4.79 12.6c13.78-3.1 32.92-7.52 41.66-9.54L61.17 65.65l-1.2.48c-11.27 4.18-19.17 8.9-24.31 19.31a19.1 19.1 0 0 0-.11 14v-.07Z"
        />
        <path
          fill="url(#b)"
          d="M33.66 82.06 50.74 29.5a28.71 28.71 0 0 1 11.21-13.29c9-5.51 23.37-14.59 23.37-14.59l33.31 34.11-60.46 25.42c-11.51 4.56-19.46 9.61-24.51 20.91Z"
        />
        <path
          fill="#2E3137"
          d="M195.62 41.68H157.7c-2.73 0-3.37 1.38-3.37 2.89v19.24h37.58c2 0 3.71-1 3.71-3.3V41.68Zm-48.84 0a7.315 7.315 0 0 1 7.55-7.54h48.83v29.67a7.345 7.345 0 0 1-4.617 7.04 7.346 7.346 0 0 1-2.923.52h-41.29v9.93h-7.55V41.68Zm71.11 22.22h37.92c2.72 0 3.37-1.38 3.37-2.89V41.78H221.6c-2 0-3.71 1-3.71 3.29V63.9Zm48.83 0a7.331 7.331 0 0 1-2.141 5.4 7.312 7.312 0 0 1-5.399 2.14h-48.83V41.78a7.36 7.36 0 0 1 2.147-5.396 7.336 7.336 0 0 1 5.393-2.154h41.29V24.3h7.54v39.6Zm258.86-23.93h-37.56v8.4h41.28v-5.26c0-2.28-1.68-3.14-3.72-3.14Zm11.27 15.93h-48.83v3.24c0 1.81 1.41 3 3.52 3h45.31v7.55h-48.83c-4.62 0-7.55-3.08-7.55-7.55V32.42h48.83c4.56 0 7.55 2.64 7.55 7.55V55.9ZM401.17 39.56a7.404 7.404 0 0 1 2.166-5.38 7.397 7.397 0 0 1 5.384-2.16h56.76a7.439 7.439 0 0 1 7.54 7.54v29.75h-7.54V43.08c0-2-1-3.52-3.37-3.52h-50.07a3.268 3.268 0 0 0-3.32 3.37v26.38h-7.55V39.56Z"
        />
        <path fill="#2E3137" d="M440.86 34.62v35.1h-7.55v-35.1h7.55Z" />
        <path
          fill="url(#c)"
          d="M394.14 33.99v35.73h-7.54v-9.93h-41.29a7.341 7.341 0 0 1-7.54-7.54V33.99h7.54v15c0 2.26 1.7 3.3 3.71 3.3h37.58v-18.3h7.54Z"
        />
        <path
          fill="#2E3137"
          d="M275.04 48.61v-6.83a7.36 7.36 0 0 1 2.147-5.396 7.336 7.336 0 0 1 5.393-2.154h47.47v7.55h-43.76c-2 0-3.71 1-3.71 3.29v3.54h-7.54Zm7.54 22.83h-7.54V56.16h7.54v15.28Z"
        />
        <path fill="#2E3137" d="M270.43 59.79v-7.55h45.31v7.55h-45.31Z" />
        <defs>
          <linearGradient
            id="a"
            x1="-.101"
            x2="536.85"
            y1="56.01"
            y2="56.01"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#ED1C24" />
            <stop offset=".158" stop-color="#DE181F" />
            <stop offset="1" stop-color="#8B0304" />
          </linearGradient>
          <linearGradient
            id="b"
            x1="9.613"
            x2="546.453"
            y1="62.43"
            y2="62.43"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#ED1C24" />
            <stop offset="1" stop-color="#8B0304" />
          </linearGradient>
          <linearGradient
            id="c"
            x1=".037"
            x2="536.85"
            y1="55.969"
            y2="55.969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#8B0304" />
            <stop offset="1" stop-color="#ED1C24" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
