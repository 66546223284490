import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M58.7925 31.7205L39.7247 69.2455L30.1354 51.6462L12 87.3618L49.3141 86.8448L88.4928 86.3278L58.7925 31.7205Z"
          fill="currentColor"
        />
        <path
          d="M48.8701 24.7195C48.9589 31.2681 44.2087 36.6534 38.2153 36.7396C32.222 36.8258 27.3163 31.5697 27.2275 25.0211C27.1387 18.4725 31.889 13.0872 37.8824 13.001C43.8535 12.9149 48.7592 18.1494 48.8701 24.7195Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
