import React from 'react'

export default function (props) {
  const { className } = props


  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
        <path fill="currentColor" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m10.45 10.63L15.31 25.76L7.55 18a1.4 1.4 0 0 1 2-2l5.78 5.78l11.14-11.13a1.4 1.4 0 1 1 2 2Z" className="clr-i-solid clr-i-solid-path-1" />
        <path fill="none" d="M0 0h36v36H0z" />
      </svg>
    </div>
  )
}
