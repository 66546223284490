import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.55068 60.1976L9.19574 61.2019L10.1951 60.8334L31.5171 52.9706V62.0858V63.3777L32.3868 62.4225L43.7491 49.9432L44.0727 49.5877L43.7303 49.2504L32.368 38.0575L31.5171 37.2193V38.4137V45.8307L10.1298 40.0829L9.14038 39.817L9.5395 40.7605L13.2882 49.6228L9.55068 60.1976ZM61.5283 90.4755L62.6382 90.836L62.1336 89.7838L52.2006 69.0689L61.6793 69.1349L62.8955 69.1433L62.0369 68.2819L49.702 55.9063L49.3271 55.5302L48.9743 55.927L38.032 68.2311L37.2999 69.0543L38.4016 69.0633L46.4381 69.1288L37.9431 89.81L37.512 90.8596L38.5775 90.4695L49.3577 86.5229L61.5283 90.4755ZM38.3266 9.5277L37.2426 9.15103L37.7048 10.2014L46.5258 30.2452H38.1625H37.003L37.7989 31.0884L48.9843 42.9393L49.334 43.3099L49.6979 42.9532L61.7896 31.1022L62.6552 30.2539L61.4432 30.2452L52.3062 30.1793L61.8904 10.2164L62.3936 9.16815L61.2866 9.52399L49.3541 13.3596L38.3266 9.5277ZM90.4229 39.7494L91.1633 38.5754L89.8443 39.0076L67.7865 46.236V37.3307V36.1622L66.9413 36.9691L54.7169 48.6388L54.3434 48.9954L54.7119 49.3573L66.9362 61.3596L67.7759 62.1841L67.7865 61.0074L67.8686 51.9016L89.8317 59.7521L91.1233 60.2138L90.4318 59.0292L84.5831 49.0105L90.4229 39.7494Z"
          fill="currentColor"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}
