import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, CounterBadge } from '@fluentui/react-components'
import { Outlet, Link, useLocation, redirect } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { HomeRegular } from '@fluentui/react-icons'
import { HeaderActiveLink } from '../utils/enums'
import Notification from '../components/Notification'
import ButtonRow from '../controls/ButtonRow'
import { login, logout } from '../store/auth/action'

const useStyles = makeStyles({
  icon: {
    height: '18px',
    width: '18px',
  },
})

const Header = () => {
  const styles = useStyles()
  const location = useLocation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  

  const pdf4meOauth = async () => {
    if (!isAuthenticated) {
      dispatch(login())
    } else {
      dispatch(logout())
    }
  }



  const url = 'https://teamsappapi.pdf4me-dev.net/'
  return (
    <div className="main_wrapper">
      <div className="main">
        <nav className="header">
          <Link to={'/home'} className="btn nav_tool nav_icon_tool">
            <HomeRegular className={styles.icon} />
          </Link>
          <div className="vertical_line" />
          <div className="btn nav_tool nav_icon_tool">
            {HeaderActiveLink[location.pathname]
              ? HeaderActiveLink[location.pathname]
              : ''}
          </div>
          <div className="spacer" />
          <ButtonRow noWrap>
            {/* <button
              onClick={testUserInfo}
              className="btn nav_tool nav_icon_tool"
            >
              TestUserInfo
            </button> */}
            <Notification />
            {/* <button onClick={loginTest} className="btn nav_tool nav_icon_tool">
              Cookie
            </button> */}
            <button
              onClick={pdf4meOauth}
              className="btn nav_tool nav_icon_tool"
            >
              {isAuthenticated ? 'logout' : 'Sign In'}
            </button>
          </ButtonRow>
        </nav>
        <Outlet />
      </div>
    </div>
  )
}

export default Header
