import styled from 'styled-components'
import Button from '../../controls/Button'

export const Spacer = styled.div`
  width: 100%;
`
export const BannerBtn = styled(Button)`
    border-width:  2px;
    border-style: solid;
    border-radius: 16px;
    min-height: 32px;
    height: auto;
    min-width: 72px;
    padding: 3px 14px 5px;
    font-size": 14px;
    font-weight: 700;
`
