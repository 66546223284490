const InputFormRow = (props) => {
  const { label, hint, errorMessage, required, children } = props

  const classNames = ['formRow']

  // if (inline) {
  //   classNames.push('inline')
  // }

  if (required) {
    classNames.push('formRow--required')
  }
  if (errorMessage) {
    classNames.push('formRow--error')
  }

  return (
    <div className={classNames.join(' ')}>
      {label && <label className="formRow__label">{label}</label>}
      <div className="formRow__input">{children}</div>
      {hint && <div className="formRow__hint">{hint}</div>}
      {errorMessage && (
        <div className="formRow__errorMessage">{errorMessage}</div>
      )}
    </div>
  )
}

export default InputFormRow
