import styled from 'styled-components'

import { media, device } from '../../theme/viewer'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  &.btn-row-center {
    justify-content: center;
  }

  > *:not(:first-child) {
    margin-left: 1rem;
  }

  &.btn-row-wrap {
    ${media.down(device.tablet)`
      flex-direction: column;
      > * {
        width: 100%;
        margin: 0.75rem 0;
        display: flex;
        justify-content: center;
      }
      > * {
        margin-left: 0;
      }
    `}
  }
`

const ButtonRow = ({ center, noWrap, children }) => {
  const classNames = []
  if (center) {
    classNames.push('btn-row-center')
  }
  if (!noWrap) {
    classNames.push('btn-row-wrap')
  }
  return <Wrapper className={classNames.join(' ')}>{children}</Wrapper>
}
export default ButtonRow
