import { api } from '../initFetch'

export const createThumbnails = async (
  doc,
  connectionId,
  pages = '',
  width
) => {
  const res = await api.post('/p4m/Document/GetOnlineImages', {
    connectionId,
    width: width || 400,
    doc,
    pages,
  })
  return res.json
}
