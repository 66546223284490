import React, { useState } from 'react'
import styled from 'styled-components'

// import { useTranslation } from '../../../hooks/TranslationContext'
// import { createDocumentLink } from '../../../services/document/createDocumentLink'
// import { fileDownload } from '../../../services/document/fileDownload'
// import { saveToMyDoc } from '../../../services/document/saveToMyDoc'
// import { openFileInPdfViewer } from '../../../services/ui/openFileInPdfViewer'
// import saveToDropbox from '../../../services/ui/saveToDropbox'
// import saveToGoogleDrive from '../../../services/ui/saveToGoogleDrive'
// import saveToOneDrive from '../../../services/ui/saveToOneDrive'
// import { getMyDocStatus } from '../../../services/document/getMyDocStatus'

// import { gaEvt } from '../../../utils/seo'
// import { copyTextToClipboard } from '../../../utils/copyTextToClipboard'
// import SendDocumentByEmail from '../../../components/SendDocumentByEmail'
import Button from '../../../controls/Button'
// import Icon from '../../../controls/Icon'
// import Dropdown from '../../../controls/Dropdown'
// import CaretDownIcon from '../../../controls/icons/caretDown'
// import DownloadIcon from '../../../controls/icons/download'
// import DropboxIcon from '../../../controls/icons/dropbox'
// import GoogleDriveIcon from '../../../controls/icons/googleDrive'
// import OneDriveIcon from '../../../controls/icons/oneDrive'
// import StartNewIcon from '../../../controls/icons/startNew'
// import SendAsEmailIcon from '../../../controls/icons/email'
// import LinkIcon from '../../../controls/icons/link'
// import FolderIcon from '../../../controls/icons/folder'
import { colors, device, media } from '../../../theme'

// popup
import Popup from '../../../controls/Popup'
import ButtonRow from '../../../controls/ButtonRow'

const Wrapper = styled.div`
  padding: 8px 24px;
  ${media.down(device.mobile)`
    padding: 8px 16px;
  `}
  display: flex;
  > .btn {
    flex-grow: 1;
    padding: 0 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dropdown {
    border-left: 1px solid #e9a0a2;
    > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0 12px;
    }
  }
`

const DownloadButton = styled.div`
  margin-bottom: 24px;
  .btn {
    width: 100%;
  }
`

const Actions = styled.div`
  .btn {
    ${media.up(device.mobile)`
      padding: 0;
      width: 44px;
      .btn__startIcon {
        margin: 0;
      }
      .label {
        display: none;
      }
      &:hover, &:active, &:focus {
        color: ${colors.text};
      }
    `}
  }
`

export default (props) => {
  const { user, outputFiles, toolName, toolTitle, jobType, wfTriggerId, jobId, openFileDeleted } =
    props
  // const t = useTranslation()

  // const [mailPopupOpen, setMailPopupOpen] = useState(false)
  // const [documentLink, setDocumentLink] = useState()
  // const [actionPending, setActionPending] = useState()
  // const [myDocSaved, setMyDocSaved] = useState()
  // const [showNoStorageLeft, setShowNoStorageLeft] = useState(false)

  // function handleSaveToDropbox() {
  //   saveToDropbox(outputFiles, {
  //     onFolderSelected: () => {
  //       setActionPending('saveToDropBox')
  //     },
  //     onCompleted: () => {
  //       setActionPending(undefined)
  //     },
  //     azZip: false,
  //   })
  // }

  // function handleSaveToGoogleDrive() {
  //   saveToGoogleDrive(outputFiles, {
  //     onFolderSelected: () => {
  //       setActionPending('saveToGoogleDrive')
  //     },
  //     onCompleted: () => {
  //       setActionPending(undefined)
  //     },
  //     azZip: false,
  //   })
  // }

  // function handleSaveToOneDrive() {
  //   saveToOneDrive(outputFiles, {
  //     onFolderSelected: () => {
  //       setActionPending('saveToOneDrive')
  //     },
  //     onCompleted: () => {
  //       setActionPending(undefined)
  //     },
  //     azZip: false,
  //   })
  // }

  // function handleReset() {
  //   const toolUrl = window.p4mr[toolName]
  //   if (toolName === 'wfTrigger') {
  //     document.location.href = `${toolUrl}?wfid=${wfTriggerId}&name=${toolTitle}`
  //   } else {
  //     document.location.href = toolUrl
  //   }
  // }

  // function handleOpenInEditor() {
  //   openFileInPdfViewer(outputFiles[0])
  // }

  // async function handleDownload() {
  //   setActionPending('download')
  //   try {
  //     await fileDownload(outputFiles)
  //   } catch (e) {
  //     if (e.response && e.response.status === 404) {
  //       openFileDeleted()
  //     }
  //   } finally {
  //     setActionPending(undefined)
  //   }
  // }

  // async function handleDownloadAsZip() {
  //   const zipFileName = 'all-files'
  //   setActionPending('download')
  //   try {
  //     await fileDownload(outputFiles, true, zipFileName || 'all-files')
  //   } catch (e) {
  //     if (e.response && e.response.status === 404) {
  //       openFileDeleted()
  //     }
  //   } finally {
  //     setActionPending(undefined)
  //   }
  // }

  // function handleSendAsEmail() {
  //   setMailPopupOpen(true)
  // }

  // function handleCloseMailPopup() {
  //   setMailPopupOpen(false)
  // }

  // function showLinkCopied() {
  //   setActionPending('linkCreated')
  //   window.setTimeout(() => {
  //     setActionPending(undefined)
  //   }, 1000)
  // }

  // async function handleCreateDocumentLink() {
  //   if (!documentLink) {
  //     setActionPending('createLink')
  //     const uiLanguage = localStorage.getItem('lang')
  //     const docLink = await createDocumentLink(
  //       outputFiles,
  //       uiLanguage,
  //       toolName
  //     )
  //     setDocumentLink(docLink.url)
  //     copyTextToClipboard(docLink.url)
  //     showLinkCopied()
  //   } else {
  //     copyTextToClipboard(documentLink)
  //     showLinkCopied()
  //   }
  // }

  // function showSaveMyDocCopied() {
  //   setActionPending('fileSavedToMyDoc')
  //   window.setTimeout(() => {
  //     setActionPending(undefined)
  //   }, 1000)
  // }

  // const handleShowNoStorageLeft = () => {
  //   setShowNoStorageLeft(true)
  // }
  // const handleCloseNoStorageLeft = () => {
  //   setShowNoStorageLeft(false)
  // }

  // async function handleSaveToMyDoc() {
  //   if (myDocSaved) {
  //     showSaveMyDocCopied()
  //   } else {
  //     setActionPending('savingToMyDoc')

  //     if (await handleSizeCheck(outputFiles)) {
  //       window.setTimeout(() => {
  //         setActionPending(undefined)
  //       }, 1000)
  //       return false
  //     }

  //     const files = outputFiles.map((f) => {
  //       return {
  //         name: f.name,
  //         docBlobRef: f.docBlobRef,
  //         storageType: f.storageType,
  //       }
  //     })
  //     const res = await saveToMyDoc(files)
  //     setMyDocSaved(true)
  //     showSaveMyDocCopied()
  //   }
  // }

  // const handleSizeCheck = async (files) => {
  //   let totalSize = 0
  //   for (const file of files) {
  //     totalSize = totalSize + file.size
  //   }

  //   const myDocStatusRes = await getMyDocStatus()

  //   const storageLeft = myDocStatusRes.usageLimit - myDocStatusRes.usedSize

  //   if (totalSize > storageLeft) {
  //     handleShowNoStorageLeft()
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // const dropdownActions = [
  //   {
  //     text: t('btnSaveToDropbox'),
  //     icon: DropboxIcon,
  //     action: handleSaveToDropbox,
  //   },
  //   {
  //     text: t('btnSaveToGoogleDrive'),
  //     icon: GoogleDriveIcon,
  //     action: handleSaveToGoogleDrive,
  //   },
  //   {
  //     text: t('btnSaveToOneDrive'),
  //     icon: OneDriveIcon,
  //     action: handleSaveToOneDrive,
  //   },
  //   {
  //     text: t('btnSendAsEmail'),
  //     icon: SendAsEmailIcon,
  //     action: handleSendAsEmail,
  //   },
  //   {
  //     text: t('btnCopyDownloadLink'),
  //     icon: LinkIcon,
  //     action: handleCreateDocumentLink,
  //   },
  // ]

  // if (user.isAuthenticated && user.paid) {
  //   dropdownActions.push({
  //     text: t('btnSaveToMyDoc'),
  //     icon: FolderIcon,
  //     action: handleSaveToMyDoc,
  //   })
  // }
  // dropdownActions.push({
  //   text: t('btnStartNewJob'),
  //   icon: StartNewIcon,
  //   action: handleReset,
  // })
  return (
    <>
      <Wrapper>
        {/* {outputFiles.length > 1 ? (
          <Button
            id="btnDownloadAsZip"
            color="primary"
            size="large"
            loading={actionPending}
            disabled={actionPending}
            startIcon={DownloadIcon}
            onClick={handleDownloadAsZip}
          >
            {actionPending
              ? t(`action_${actionPending}`)
              : t('btnDownloadAsZip')}
          </Button>
        ) : ( */}
        <Button
          id="btnDownload"
          size="large"
          color="primary"
          // loading={actionPending}
          // disabled={actionPending}
          // startIcon={DownloadIcon}
          // onClick={handleDownload}
        >
          Download
          {/* {actionPending ? t(`action_${actionPending}`) : t('btnDownload')} */}
        </Button>
        {/* )} */}
        {/* <Dropdown
          color="primary"
          useButton={{
            color: 'primary',
            size: 'large',
          }}
          disabled={actionPending}
          alignItems="right"
          items={dropdownActions}
        >
          <Icon icon={CaretDownIcon} />
        </Dropdown> */}
        {/* {mailPopupOpen && (
          <SendDocumentByEmail
            user={user}
            files={outputFiles}
            toolName={toolName}
            onClose={handleCloseMailPopup}
          />
        )} */}
      </Wrapper>
      {/* {showNoStorageLeft && (
        <NoStorageLeft t={t} onClose={handleCloseNoStorageLeft} />
      )} */}
    </>
  )
}

// const NoStorageLeft = ({ t, onClose }) => {
//   return (
//     <Popup
//       title={t('titleStorage')}
//       onClose={onClose}
//       footer={() => (
//         <ButtonRow>
//           <Button onClick={onClose}>{t('btnCancel')}</Button>
//         </ButtonRow>
//       )}
//       size="sm"
//     >
//       {t('messageMoreThanAvailable')}
//     </Popup>
//   )
// }
