import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab, TabList } from '@fluentui/react-components'
import { useDispatch, useSelector } from 'react-redux'

import ToolTab from '../../components/ToolTab'
import Table from '../../components/Table'

import { withMessageBox } from '../../controls/MessageBox'
import ButtonRow from '../../controls/ButtonRow'
import ParseDocument from '../../controls/icons/parseDocument'
import Upload from '../../controls/icons/upload'
import Icons from '../../assets/Icons'
import Icon from '../../controls/Icon'
import JobConfig from '../../components/JobConfig'
import { BannerButton, BannerTitle, BannerSubTitle, Content } from './styles'
import UploadButton from '../../controls/UploadButton'

import '../../theme/scss/base.scss'
import ToggleMenu from '../../components/ToggleMenu'
import TableBoard from '../../components/TableBoard'
import styled, { keyframes } from 'styled-components'
import { media, device, colors } from '../../theme'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import Popup from '../../controls/Popup'
import OneDriveFilePicker from '../../components/OneDriveFilePicker'
import { login, logout } from '../../store/auth/action'
import BannerPdfConverter from '../../components/BannerPdfConverter'
import Button from '../../controls/Button'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
`
export const DocumentList_list = styled.div`
  background: ${colors.bodyBackground};
  border-radius: 5px;
`

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  // const { recentDriveFiles } = useSelector((state) => state.recentOneDrive)
  const openPopup = () => {
    setIsPopupOpen(true)
  }
  const closePopup = () => {
    setIsPopupOpen(false)
  }
  const pdf4meOauth = async () => {
    if (!isAuthenticated) {
      dispatch(login())
    } else {
      dispatch(logout())
    }
  }

  return (
    <>
      {isPopupOpen && (
        <Popup title={'File Upload'} onClose={closePopup} isCustom={true}>
          <Content>
            <OneDriveFilePicker className="file__picker" />
          </Content>
        </Popup>
      )}

      <div className="homeTab">
        <div id="home-tab-content" className="homeTab_content">
          <div className="homeTab_banneer">
            <div className="homeTab_banneer_content">
              <div className="homeTab_banneer_title">
                <BannerTitle>Welcome to PDF4me</BannerTitle>
                <p>PDF done simple and online!</p>
                {!isAuthenticated && (
                  <BannerButton color={'secondary'} onClick={pdf4meOauth}>
                    Sign In
                  </BannerButton>
                )}
              </div>
              <div className="homeTab_actionBlock">
                <div className="banner_thumbnail">
                  <Icons toolName={'logo'} className={'logo_pdf4me'} />
                </div>
                <div className="homeTab_actionBlock_message">
                  <BannerSubTitle className={'homeTab_actionBlock_tiltle'}>
                    Convert to PDF
                  </BannerSubTitle>
                  <p className="homeTab_actionBlock_message">
                    Select any documents or images and get it to convert to PDF
                  </p>
                  <JobConfig Form={() => <BannerPdfConverter />} />
                </div>
              </div>
            </div>
          </div>
          <div className="toolTab_wrapper">
            <ToolTab />
          </div>
          <div className="table_wrapper">
            <TabList defaultSelectedValue={'recent-tab'}>
              <Tab value="recent-tab">Recent</Tab>
              <div className="spacer"></div>
              <ButtonRow noWrap>
                <UploadButton type="button" onClick={openPopup} />
                <Button
                  className={'custom__btn'}
                  // icon={<DocumentFilled />}
                  variant={'link'}
                  // value="tab4"
                  onClick={() => navigate('/document')}
                >
                  <Icons toolName="pdf" className={'btn__startIcon'} />
                  Document
                </Button>
              </ButtonRow>
            </TabList>
            <JobConfig
              Form={() => (
                <TableWrapper>
                  <DocumentList_list>
                    <Table ToggleMenu={ToggleMenu} isMultiple={true} />
                  </DocumentList_list>
                  <TableBoard />
                </TableWrapper>
              )}
            ></JobConfig>
          </div>

          <div className="homeTab_fileContent"></div>
        </div>
      </div>
    </>
  )
}

export default withMessageBox(Home)
