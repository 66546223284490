import ErrorItem from './ErrorItem'

export default (props) => {
  const { id, name, onDeleteFile, isShowDelete } = props
  //   const t = useTranslation()

  return (
    <ErrorItem id={id} name={name} onDeleteFile={onDeleteFile} isShowDelete={isShowDelete}>
      <p>Password protected file</p>
    </ErrorItem>
  )
}
