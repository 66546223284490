import { useContext } from 'react'
// import { TranslationContext } from '../../hooks/TranslationContext'

export default (props) => {
  const { href, children, className, activeClassName } = props
  // const translationContext = useContext(TranslationContext)

  const lnkHref = window.p4mr[href] || '/'

  const classes = []
  if (className) {
    classes.push(className)
  }
  // if (activeClassName && translationContext.path === lnkHref) {
  //   classes.push(activeClassName)
  // }
  return (
    <a href={lnkHref} className={classes.join(' ')}>
      {children}
    </a>
  )
}
