import { api } from '../initFetch'
import { asyncForEach } from '../../utils/asyncForEach'

const s256KB = 1024 * 256
const s512KB = 1024 * 512
const s1MB = 1024 * 1024
const s2MB = s1MB * 2
const s4MB = s1MB * 4
const s8MB = s1MB * 8
// const s20MB = s1MB * 20
const s50MB = s1MB * 50
// const s100MB = s1MB * 100
const s200MB = s1MB * 100
const s500MB = s1MB * 500

const startFileUpload = async (storageType, fileId, fileName, connectionId) => {
  const res = await api.post(
    `/p4m/Document/StartFileUpload?fileId=${encodeURIComponent(fileId)}&fileName=${encodeURIComponent(
      fileName
    )}&connectionId=${encodeURIComponent(
      connectionId
    )}&rawZip=true&storageType=${encodeURIComponent(storageType)}`,
    {
      fileId,
      fileName,
      connectionId,
    }
  )
  return res.json
}

const cancelFileUpload = async (docBlobRef, storageType) => {
  const res = await api.post(
    `/p4m/Document/CancelFileUpload?docBlobRef=${encodeURIComponent(
      docBlobRef
    )}&storageType=${encodeURIComponent(storageType)}`,
    {}
  )
}

const commitUpload = async (storageType, docBlobRef, blockCount, toolName, thumbnail, file) => {
  const res = await api.post(
    `/p4m/Document/CommitUpload?docBlobRef=${encodeURIComponent(
      docBlobRef
    )}&blockCount=${encodeURIComponent(blockCount)}&jobType=${toolName}${
      thumbnail ? `&thumbnail=${thumbnail}` : ''
    }&storageType=${encodeURIComponent(storageType)}&fileSize=${encodeURIComponent(file.size)}`,
    {}
  )
  const files = []

  if (res.json.result === 'failed') {
    throw new Error('CommitUpload failed')
  }

  try {
    await asyncForEach(res.json, async (uploadedFile) => {
      if (uploadedFile.docMetadata && uploadedFile.docMetadata.thumbnail) {
        if (!uploadedFile.docMetadata.thumbnail.startsWith('data:image')) {
          uploadedFile.docMetadata.thumbnail =
            'data:image/png;base64,' + uploadedFile.docMetadata.thumbnail
        }
      }
      files.push(uploadedFile)
    })
    return files
  } catch (err) {
    return res.json
  }
}

const uploadChunk = async (
  storageType,
  uploadUrl,
  docBlobRef,
  index,
  chunkCount,
  chunk,
  chunkSize
) => {
  return new Promise((resolve, reject) => {
    const xhr = new window.XMLHttpRequest()

    // upload completed successfully
    xhr.onload = () => {
      // onprogress(1)
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.response))
      } else {
        reject(xhr.status)
      }
    }

    xhr.open(
      'POST',
      `${uploadUrl}?docBlobRef=${encodeURIComponent(docBlobRef)}&id=${encodeURIComponent(
        index
      )}&blockCount=${chunkCount}&blockSize=${encodeURIComponent(
        chunk.size
      )}&storageType=${encodeURIComponent(storageType)}&splitSize=${encodeURIComponent(chunkSize)}`
    )
    const fp = localStorage.getItem('p4mfp')
    if (fp) {
      xhr.setRequestHeader('p4m-fp', fp)
    }
    xhr.send(chunk)
  })
}

const getChunk = (file, start, end) => {
  const slice = file.mozSlice
    ? file.mozSlice
    : file.webkitSlice
    ? file.webkitSlice
    : file.slice
    ? file.slice
    : () => {}

  return slice.bind(file)(start, end)
}

const sendChunk = async (
  storageType,
  uploadUrl,
  docBlobRef,
  start,
  index,
  chunkCount,
  chunkSize,
  file,
  toolName,
  onprogress,
  thumbnail
) => {
  const end = start + chunkSize
  const chunk = getChunk(file, start, end)

  const uploadRes = await uploadChunk(
    storageType,
    uploadUrl,
    docBlobRef,
    index,
    chunkCount,
    chunk,
    chunkSize
  )

  if (uploadRes.error) {
    throw new Error(`failed to upload chunk: ${uploadRes.message}`)
  }

  const cancelToken = onprogress(index / chunkCount)

  if (cancelToken && cancelToken.cancel) {
    // todo: call cancel upload
    cancelFileUpload(docBlobRef, storageType)
    return []
  }

  if (end < file.size) {
    const data = await sendChunk(
      storageType,
      uploadUrl,
      docBlobRef,
      end,
      index + 1,
      chunkCount,
      chunkSize,
      file,
      toolName,
      onprogress,
      thumbnail
    )
    return data
  }
  const data = await commitUpload(storageType, docBlobRef, chunkCount, toolName, thumbnail, file)
  return data
}

export const fileUpload = async (
  storageType,
  file,
  fileId,
  toolName,
  connectionId,
  onprogress,
  thumbnail
) => {
  onprogress(0)
  const start = 0
  const index = 1
  const fileSize = file.size

  let chunkSize = s8MB // s8MB

  if (fileSize < s2MB) {
    chunkSize = s256KB
  } else if (fileSize < s8MB) {
    chunkSize = s512KB
  } else if (fileSize < s50MB) {
    chunkSize = s1MB
  } else if (fileSize < s200MB) {
    chunkSize = s2MB
  } else if (fileSize < s500MB) {
    chunkSize = s4MB
  }

  const chunkCount = Math.ceil(fileSize / chunkSize)

  let docBlobRef
  let uploadUrl
  try {
    const c = await startFileUpload(storageType, fileId, file.name, connectionId)
    docBlobRef = c.docBlobRef
    uploadUrl = c.url
  } catch (err) {
    console.error(err)
    throw new Error('start file upload failed')
  }
  if (!docBlobRef) {
    throw new Error('start file upload failed. missing docBlobRef')
  }

  try {
    const result = await sendChunk(
      storageType,
      uploadUrl,
      docBlobRef,
      start,
      index,
      chunkCount,
      chunkSize,
      file,
      toolName,
      onprogress,
      thumbnail
    )

    return result
  } catch (err) {
    console.error(err)
    throw new Error('file upload send chunks failed')
  }
}
