import { api } from '../initFetch'

export const createBarcode = async (
  barcodeType,
  text,
  barcodeColor,
  backgroundColor,
  hideText
) => {
  const res = await api.post('/p4m/Document/CreateBarcode', {
    barcodeType,
    text,
    barcodeColor,
    backgroundColor,
    hideText,
  })
  return res.json
}
