import React from 'react'

import { PopupScreen } from '../../utils/enums'
import { ActonPopup, StartJobPopup } from '../JobPopup'

const RenderPopup = ({ screen = 'ACTION', tab, updateTab }) => {
  const renderScreen = (currentScreen) => {
    switch (currentScreen) {
      case PopupScreen.Action:
        return <ActonPopup tab={tab} updateTab={updateTab} />
      case PopupScreen.StartJob:
        return <StartJobPopup />

      default:
        return <></>
    }
  }
  return screen && renderScreen(screen)
}

export default RenderPopup
