import { useState } from 'react'
import styled from 'styled-components'

// import FormattedText from '../../../controls/FormattedText'
import { colors, device, media } from '../../../theme'

const ResultInfo = styled.div`
  padding: 24px 24px 16px 24px;
  ${media.down(device.mobile)`
    padding: 24px 16px 16px 16px;
  `}
  h2 {
    margin: 0;
    /* color: #fff; */
  }
`

export default (props) => {
  const { toolName, toolTitle, user } = props

  return (
    <ResultInfo>
      <h2>{toolTitle||"PDF Converter"}</h2>
      {/* <FormattedText resKey="jobCompletedText" /> */}
    </ResultInfo>
  )
}
