import React from 'react'

export default function  (props, context) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M56.165 10.027C56.088 10 56.011 10 55.908 10a1.31 1.31 0 0 0-1.285 1.048l-4.139 16.039-4.139-16.04a1.153 1.153 0 0 0-.36-.617 1.335 1.335 0 0 0-1.208-.403c-2.03.376-3.367 2.418-3.007 4.54l4.858 27.511-3.342 12.977-1.542 4.46c-1.774 5.05-5.81 8.839-10.771 10.209-3.033.86-5.887 2.875-6.812 8.06-.977 5.642 2.596 11.042 7.995 12.063.205.053.437.08.642.107 5.296.51 10.077-3.304 10.977-8.785l4.395-25.2.103-.592c.206-1.182 1.106-2.068 2.237-2.257 1.13.162 2.03 1.075 2.236 2.23l.103.591 4.396 25.201c.9 5.48 5.681 9.296 10.95 8.785 5.45-.644 9.358-5.776 8.74-11.472a6.541 6.541 0 0 0-.102-.698c-.925-5.212-3.779-7.2-6.812-8.06-4.961-1.37-8.997-5.185-10.771-10.21l-1.542-4.459-3.342-12.976 4.807-27.512c.36-2.095-.977-4.11-3.008-4.513Zm-17.66 68.617c.488 2.74-1.26 5.373-3.882 5.883-2.622.51-5.141-1.316-5.604-4.057-.488-2.74 1.26-5.373 3.882-5.856 2.596-.538 5.115 1.29 5.604 4.03Zm33.444 0c.488 2.74-1.26 5.373-3.882 5.883-2.622.51-5.141-1.316-5.63-4.057-.488-2.74 1.26-5.373 3.882-5.883 2.622-.484 5.141 1.316 5.63 4.056Z" />
      </svg>


    </div>
  )
}
