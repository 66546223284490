const t = (key) => {
  const translate = {
    noInviteFormTitle: 'No Invite found',
    md_noInviteLoggedOut:
      'No invite found. Please Login or [Upgrade to Pro](/get-pdf4me/) for using our Pro features',
    md_noInviteLoggedInNotPaid:
      'No invite found. [Upgrade to Pro](/get-pdf4me/) for using our Pro features',
    md_noInviteLoggedInPaid:
      'No invite found. Instead, Please try our [features](/)',
    signUpTitle: 'Sign Up Invite',
    signUpLeadText: 'Sign up Invite',
    emailMismatchFormTitle: 'Email Mismatch',
    emailMismatchFormContent:
      'The signed in user does not match invited user. Try login with the correct email id',
    signUpFormLoginExisting: 'Already have an Account?',
    lblPassword: 'Password',
    continueWith: 'Continue With',
    signUpFormTitle: 'Sign up',
    signUpFormBtnCreateAccount: 'Create Account',
    md_signUpFormLegalInfo:
      'By creating an account, you agree to our [Terms and Conditions](/terms-and-conditions/) and [Privacy Policy](/privacy-policy/)',
    signUpFormSpacer: 'or sign up with',
    linkExpiredFormTitle: 'Link expired',
    linkExpiredFormContent:
      'The invite link has expired. Please ask to resend the link.',
    signUpErrEmailAlreadyExists: 'User with this email already exists.',
    paidPlanExistsTitle: 'Paid Plan Exists',
    paidPlanExistsFormContent:
      'Your are on a Paid/Trial plan. Please cancel your current plan and then accept invite.',
    btnOpenMyAccount: 'Open My Account',
    accountInfoTitle: 'Accept Invite to register',
    accountInfoLeadText: 'Accept Invite and start using our Pro features',
    accountInfoFormLblFirstName: 'Firstname',
    accountInfoFormErrFirstNameRequired: 'Required',
    accountInfoFormErrFirstNameMaxLength: 'Max 50 characters in length',
    accountInfoFormLblLastName: 'Lastname',
    accountInfoFormErrLastNameRequired: 'Required',
    accountInfoFormErrLastNameMaxLength: 'Max 50 characters in length',
    signUpLblAcceptingInvite: 'Accepting Invite',
    signUpLblAcceptInvite: 'Accept Invite',
    completedTitle: 'Invitation Accepted',
    completedFormContent: 'For a start, try our Word to PDF feature ...',
    completedLeadText: 'Thank you for accepting the invite',
    registeredFormTitle: 'Registered',
    registeredFormContent: 'This invitation has already been accepted',
    inviteAcceptFailedTitle: 'Invitation Accept Failed',
    inviteAcceptFailedLeadText: 'Sorry, failed to accept the invite.',
    inviteAcceptFailedFormContent: 'Please try again or contact administrator',
    errEmailRequired: 'Required',
    errPasswordRequired: 'Required',
    errInvalidEmail: 'Invalid email',
    errPasswordPolicy: 'Password policy',
    pwPolicyHasMinLength: 'At least 8 characters in length',
    pwPolicyHasLower: 'Lower case letters',
    pwPolicyHasUpper: 'Upper case letters',
    pwPolicyHasNumber: 'Numbers',
    pwPolicyHasSpecialChar: 'Special characters',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
