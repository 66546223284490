const t = (key) => {
  const translate = {
    lblPleaseSelect: 'Please Select',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
