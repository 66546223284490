import { useState } from 'react'

export const useForm = ({
  initialValues = {},
  initialErrors = {},
  callback,
  validate,
}) => {
  const hasInitialErrors = Object.entries(initialErrors).length > 0
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState(initialErrors)
  const [isValid, setIsValid] = useState(!hasInitialErrors)
  const [isPristine, setIsPristine] = useState(!hasInitialErrors)

  const doValidate = (form, submit) => {
    const validationResult = validate(form, submit)

    setErrors(validationResult)

    if (Object.keys(validationResult).length === 0) {
      setIsValid(true)
      return true
    }

    setIsValid(false)
    return false
  }

  const onChange = (e) => {
    const { name, value, type, checked } = e.target
    const newValues = {
      ...values,
      [name]: type === 'checkbox' ? checked : value,
    }
    setValues(newValues)
    doValidate(newValues)
  }

  const updateValues = (updatedValues) => {
    const newValues = {
      ...values,
      ...updatedValues,
    }
    setValues(newValues)
    doValidate(newValues)
  }

  const onSubmit = (e) => {
    if (e) {
      e.stopPropagation()
    }

    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault()
    }

    if (isPristine) {
      setIsPristine(false)
    }

    if (doValidate(values, true)) {
      callback(values)
    }
  }

  const resetForm = () => {
    setValues(initialValues)
    setErrors(initialErrors)
    setIsValid(!hasInitialErrors)
    setIsPristine(!hasInitialErrors)
  }

  const bindInput = (name) => {
    return {
      name,
      onChange,
      value: values[name],
    }
  }

  const bindError = (name) => {
    return { errorMessage: !isPristine && errors[name] }
  }

  const setValidationErrors = (validationErrors) => {
    const newErrors = { ...errors, ...validationErrors }
    setErrors(newErrors)

    if (Object.keys(newErrors).length === 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }

  return {
    onChange,
    onSubmit,
    setValidationErrors,
    bindError,
    resetForm,
    bindInput,
    updateValues,
    values,
    errors,
    isValid,
    isPristine,
  }
}
