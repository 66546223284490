import { useState, useRef, useEffect } from 'react'
import React from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

import t from '../translate'
import Icon from '../../../controls/Icon'
import closeIcon from '../../../controls/icons/close'
import { colors, media, device, layout } from '../../../theme'

const fadeInBg = keyframes`
  from {
    opacity: 0.001;
  }
  to {
    opacity: 1;
  }
`

const slideIn = keyframes`
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(0);
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    animation: ${fadeInBg} ease-in 0.5s;
  }
`

const PopupElm = styled.aside`
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  animation: ${slideIn} 0.75s;
  z-index: 2;

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }

  ${media.down(device.mobile)`
    &.size-sm,
    &.size-md {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      > div {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
      }
    }
  `}

  ${media.up(device.mobile)`
    &.size-sm,
    &.size-md {
      max-height: calc(var(--vh, 1vh) * 100);
      border-radius: ${layout.gap};
      // border-top-left-radius: 35px;
    }
    &.size-sm {
      width: 320px;
    }
    &.size-md {
      width: 510px;
    }
  `}

  ${media.down(device.tablet)`
    &.size-lg {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      > div {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
      }
    }
  `}

  ${media.up(device.tablet)`
    &.size-lg {
      width: ${device.tablet}px;
      min-height: 80vh;
      max-height: calc(var(--vh, 1vh) * 100);
      border-radius: ${layout.gap};
      // border-top-left-radius: 35px;
      > div {
        min-height: 80vh;
        max-height: calc(var(--vh, 1vh) * 100);
      }
    }
  `}

  ${media.down(device.desktop)`
    &.size-xl {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      > div {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
      }
    }
  `}

  ${media.up(device.desktop)`
    &.size-xl {
      width: ${device.desktop}px;
      min-height: 80vh;
      max-height: calc(var(--vh, 1vh) * 100);
      border-radius: ${layout.gap};
      // border-top-left-radius: 35px;
      > div {
        min-height: 80vh;
        max-height: calc(var(--vh, 1vh) * 100);
      }
    }
  `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  /* height: 62px;*/
  // min-height: 62px;
  padding: 3px 1.5rem 0;
  border-bottom: solid 2px ${colors.grayLight};

  ${media.down(device.mobile)`
    /* height: 52px; */
    // min-height: 52px;
    padding: 3px 0.5rem 0;
  `}

  h3, h4 {
    margin: 0;
    padding: 0;
    /* text-align: center; */
  }
`
const Content = styled.div`
  position: relative;
  // padding: 1.5rem 1.5rem;
  flex: 1 1;
  // background-image: url('/images/main-bg-transparent-dark.png');
  // background-repeat: repeat-x;
  background-color: ${colors.bodyBackground};
  overflow: auto;
  ${media.down(device.mobile)`
  overflow: auto;
  `}
`
const Footer = styled.div`
  border-top: solid 2px ${colors.grayLight};
  padding: 0px 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    margin: 0 10px;
  }
  ${media.down(device.mobile)`
    padding: 5px 1.5rem;
  `}
`

const BtnClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 54px;
  position: absolute;
  right: 18px;
  top: 0px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  ${media.down(device.mobile)`
    top: 0;
  `}

  i,
  svg {
    width: 30px;
    height: 24px;
  }
  svg {
    fill: ${colors.grayDarker};
  }
`

const PopupComponent = ({
  title,
  header,
  footer,
  size,
  maxHeight,
  children,
  onClose,
}) => {
  // const t = useTranslation()
  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const overlay = useRef()
  const container = useRef()
  const headerElm = useRef()
  const contentElm = useRef()
  const footerElm = useRef()

  useEffect(() => {
    const handleClose = (e) => {
      if (onClose && e.target === e.currentTarget) {
        onClose()
      }
    }
    overlay.current?.addEventListener('click', handleClose)
    return () => {
      overlay.current?.removeEventListener('click', handleClose)
    }
  })

  useEffect(() => {
    const sbWidth = window.innerWidth - document.body.clientWidth
    const updateSize = () => {
      // update content width
      const width = window.innerWidth - sbWidth
      const $header = document.querySelector('.app-header')
      if ($header) {
        $header.style.width = `${width}px`
      }
      document.body.style.width = `${width}px`
      document.body.style.overflow = 'hidden'
      document.body.classList.add('modal')

      // calculate max content height
      const windowHeight = window.innerHeight

      const headerHeight = headerElm.current
        ? headerElm.current.getBoundingClientRect().height
        : 0

      const footerHeight = footerElm.current
        ? footerElm.current.getBoundingClientRect().height
        : 0

      const calculatedMaxHeight = windowHeight - (headerHeight + footerHeight)

      if (
        maxHeight &&
        maxHeight < calculatedMaxHeight &&
        window.innerWidth > parseInt(device.mobile, 10)
      ) {
        const ctHeight = maxHeight - (headerHeight + footerHeight)
        contentElm.current.style.maxHeight = `${ctHeight}px`
        if (maxHeight) {
          contentElm.current.style.minHeight = `${ctHeight}px`
        }
      } else {
        setMaxContentHeight(calculatedMaxHeight)

        contentElm.current.style.maxHeight = `${calculatedMaxHeight}px`
        if (maxHeight) {
          contentElm.current.style.minHeight = `${calculatedMaxHeight}px`
        }
      }
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => {
      window.removeEventListener('resize', updateSize)
      document.body.style.overflow = null
      document.body.style.width = null
      const $header = document.querySelector('.app-header')
      if ($header) {
        $header.style.width = null
      }
      document.body.classList.remove('modal')
    }
  }, [])

  return (
    <Overlay ref={overlay}>
      <PopupElm ref={container} className={size ? `size-${size}` : 'size-md'}>
        <div>
          <Header ref={headerElm}>
            {title && <h3>{t(title)}</h3>}
            {header && header()}
          </Header>
          {onClose && (
            <BtnClose
              onClick={() => {
                onClose()
              }}
            >
              <Icon icon={closeIcon} />
            </BtnClose>
          )}
          <Content className="popup-content" ref={contentElm}>
            {children}
          </Content>
          <Footer ref={footerElm}>{footer && footer()}</Footer>
        </div>
      </PopupElm>
    </Overlay>
  )
}

export default (props) => {
  let elm = document.querySelector('#modal')
  if (!elm) {
    elm = document.createElement('div')
    elm.setAttribute('id', 'modal')
    document.body.appendChild(elm)
  }
  return ReactDOM.createPortal(
    <PopupComponent {...props} />,
    document.querySelector('#modal')
  )
}
