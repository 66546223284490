import React, { useState } from 'react'
import styled from 'styled-components'

import ToolTip from '../../controls/ToolTip'

import Button from '../Button'
import Icons from '../../assets/Icons'

export const AddFolderBtn = styled(Button)`
  border: none;
  height: fit-content;
  width: fit-content;
  & > div {
    width: 18px;
    height: 18px;
  }
`
const CreateFolder = ({ onClick, className = '' }) => {
  return (
    <ToolTip content={'Create Folder'}>
      <AddFolderBtn onClick={onClick} variant="link">
        <Icons toolName="addSolid" />
      </AddFolderBtn>
    </ToolTip>
  )
}

export default CreateFolder
