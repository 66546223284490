const t = (key) => {
  const translate = {
    btnStartJob: 'Start Converting',
    md_pdfToEpubDesc:
      '**eBooks - Future of online reading**\n\nPDF4me makes eReading easy with our eBook online converter. Turn any document into ebook format and enjoy Kindle or Nook like reading experience. PDF4me ensures the conversion of eBook or ePub read-only files back to an editable format.\n\nJust click on the *Start Converting* button and enjoy a seamless reading experience.',
    lblTitle: 'Book title',
    lblAuthor: 'Author name',
    lblEbookFormat: 'eBook Format',
    outputEpub: 'ePub',
    outputMobi: 'Mobi',
    lblCover: 'Select Cover Type',
    FirstPageCover: 'Set first page as cover',
    CustomCover: 'Add a custom cover',
    lblDropImageHere: 'Drop Image for cover',
    lblUploadingImage: 'Uploading cover image',
    lblSelectHere: 'or Click to Upload',
    lblChangeImage: 'Change cover image',
    SelectPredefined: 'Select Predefined Covers',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
