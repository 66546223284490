import React from 'react'

export default function  (props) {
  const { className  } = props
  

  return (
    <div className={className ? className : ''}>
      <svg  viewBox="0 0 100 94" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M99.0882 25.3405L69.7478 1.16921C68.9619 0.521596 67.896 0.157666 66.7844 0.157471H24.8696C22.647 0.159299 20.5161 0.887488 18.9444 2.18223C17.3728 3.47698 16.4889 5.2325 16.4867 7.06355V48.5H24.8696V7.06355H58.4015V27.7818C58.4037 29.6128 59.2876 31.3683 60.8592 32.6631C62.4309 33.9578 64.5618 34.686 66.7844 34.6879H91.9333V48.5H100.316V27.7818C100.316 26.8661 99.8743 25.9879 99.0882 25.3405ZM66.7844 27.7818V8.49311L90.1981 27.7818H66.7844Z" fill="currentColor" />
        <path d="M66.7844 66.3763V59.4701H100.316V87.0945H91.9333V71.2589L64.3282 94.0006L58.4015 89.118L86.0066 66.3763H66.7844Z" fill="currentColor" />
        <path d="M30.9792 89.2043L34.7893 59.4701H41.9148L36.2144 94.0006H26.6578L20.9574 65.0848L15.257 94.0006H5.70042L0 59.4701H7.12552L10.9356 89.2043L16.7659 59.4701H25.1489L30.9792 89.2043Z" fill="currentColor" />
      </svg>



    </div>
  )
}
