import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { uploadFile } from '../../service/oneDrive/uploadFile'
import { createFolder } from '../../service/oneDrive/createFolder'
import { fileUpload } from '../../service/document/fileUpload'
import { errorHandler } from '../error/action'
import { createId } from '../../utils/createId'
import { oneDriveNotification } from '../notification/action'
import { app } from '@microsoft/teams-js'

export const uploadFileToOneDrive = createAsyncThunk(
  'oneDrive/uploadFile',
  async (
    { teamsUserCredential, name, itemPath, file },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { oneDrive, hub } = getState()
      const { connectionId } = hub
      const { items, driveId } = oneDrive
      const tokenResponse = await teamsUserCredential?.getToken('')
      const teamsContext = await app.getContext()
      const token = tokenResponse?.token
      if (!token) {
        throw new Error('Failed to retrieve token')
      }
      const onProgress = (progress) => {
        return {}
      }
      const data = await fileUpload(
        'cdoc',
        file,
        createId(),
        'oneDrive',
        connectionId,
        onProgress
      )
      const response = await uploadFile(
        name,
        itemPath,
        driveId || items[0]?.driveId,
        data[0].docBlobRef,
        token,
        teamsContext.user.tenant.id
      )
      dispatch(
        oneDriveNotification({
          name: file.name,
          size: file.size,
          itemId: response.itemId,
          isNotification: true,
          isOneDrive: true,
        })
      )
      return response
    } catch (error) {
      dispatch(errorHandler(error))
      
      return rejectWithValue(error.message)
    }
  }
)
export const createFolderInOneDrive = createAsyncThunk(
  'oneDrive/createFolder',
  async (
    { teamsUserCredential, folderName, absolutePath },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { oneDrive, hub } = getState()
      // const { connectionId } = hub
      const { items, driveId, history } = oneDrive
      const tokenResponse = await teamsUserCredential?.getToken('')
      const teamsContext = await app.getContext()

      const token = tokenResponse?.token
      if (!token) {
        throw new Error('Failed to retrieve token')
      }

      const lastHistory = history[history.length ? history.length - 1 : 0]
      const response = await createFolder(
        folderName,
        absolutePath,
        lastHistory?.driveId,
        lastHistory?.itemId,
        token,
        teamsContext.user.tenant.id
      )

      return true
    } catch (error) {
      dispatch(errorHandler(error))
      rejectWithValue(error.message)
      return false
    }
  }
)
