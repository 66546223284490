import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const ToolbarItem = (props) => {
  const { children } = props
  return <Wrapper>{children}</Wrapper>
}

export default ToolbarItem
