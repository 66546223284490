import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import SignaturePad from 'signature_pad'

import Popup from '../../controls/Popup'
import Button from '../../controls/Button'

import Icon from '../../controls/Icon'
import { cropCanvas } from './cropCanvas'
import { colors, media, device } from '../../theme'

const Container = styled.div`
  position: relative;
  min-height: 300px;
  max-height: 300px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border: dashed 2px ${colors.gray};

  ${media.down(device.tablet)`
    min-height: 150px;
  `}
`

const BtnClear = styled.button`
  border: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
`

const Canvas = styled.canvas`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

const SignHere = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: ${colors.grayLight};
  font-size: 32px;
  font-weight: 600;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const FileButton = styled.label`
  ${media.up(device.tablet)`
    margin-right: auto;
  `}
  svg {
    fill: #ffffff;
  }
`

export default (props) => {
  const { onClose, onChange } = props
  const [signaturePad, setSignaturePad] = useState()
  const [padIsEmpty, setPadIsEmpty] = useState(true)
  const [isWriting, setIsWriting] = useState(false)
  const $container = useRef()
  const $canvas = useRef()

  const handleResize = useCallback(() => {
    const canvas = $canvas.current
    const container = $container.current
    const containerRect = container.getBoundingClientRect()
    canvas.style.width = `${containerRect.width - 4}px`
    canvas.style.height = `${containerRect.height - 4}px`
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
    signaturePad.clear() // otherwise isEmpty() might return incorrect value
  }, [signaturePad])
  useEffect(() => {
    const signaturePad = new SignaturePad($canvas.current, {
      dotSize: 1,
      minWidth: 1,
      maxWidth: 3,
      onEnd: (e, a) => {
        setPadIsEmpty(signaturePad.isEmpty())
        setIsWriting(false)
      },
      onBegin: (e, a) => {
        setPadIsEmpty(signaturePad.isEmpty())
        setIsWriting(true)
      },
    })
    setSignaturePad(signaturePad)
    setPadIsEmpty(signaturePad.isEmpty())
    setIsWriting(false)
  }, [])

  useEffect(() => {
    if (signaturePad) {
      window.addEventListener('resize', handleResize)
      handleResize()
    }
    return () => {
      if (signaturePad) window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    const beginStroke = () => {
      setPadIsEmpty(false)
    }
    if (signaturePad) {
      signaturePad.addEventListener('beginStroke', beginStroke)
    }
    return () => {
      if (signaturePad)
        signaturePad.removeEventListener('beginStroke', beginStroke)
    }
  }, [signaturePad])

  const handleClear = () => {
    signaturePad.clear()
    setPadIsEmpty(true)
  }

  const handleSave = () => {
    const data = signaturePad.toData()
    const img = cropCanvas($canvas.current)
    onChange({ data, img })
  }

  const handleFileUpload = (e) => {
    const fileTypes = ['image/bmp', 'image/gif', 'image/jpeg', 'image/png']

    const files = e.target.files

    if (files.length > 0 && fileTypes.includes(files[0].type)) {
      const reader = new FileReader()
      reader.onload = function () {
        const data = []
        const img = reader.result
        onChange({ data, img })
      }
      reader.onerror = function () {
        console.log(reader.error)
      }
      reader.readAsDataURL(files[0])
    }
  }

  return (
    <Popup
      onClose={onClose}
      size="lg"
      title="Add Signature"
      footer={() => (
        <>
          <FileButton className="btn btn--file btn--secondary">
            <Icon className="btn__startIcon" icon="upload" />
            Upload Image
            <input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={handleFileUpload}
            />
          </FileButton>

          <Button disabled={padIsEmpty} color="primary" onClick={handleSave}>
            Add Signature
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      )}
    >
      <Container ref={$container}>
        {!isWriting && !padIsEmpty && (
          <BtnClear onClick={handleClear}>
            <Icon icon="trash" />
            <span>Clear</span>
          </BtnClear>
        )}
        <Canvas ref={$canvas} />
        {padIsEmpty && <SignHere>Sign here</SignHere>}
      </Container>
      {/*           
      <Tabs>
        <Tab label="Draw">
        </Tab>
        <Tab label="Upload">
          <FileDrop
            accept="image/x-png,image/gif,image/jpeg"
            onChange={handleFileUpload}
          />
           <FileButton className="btn">
            Upload Image
            <input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={handleFileUpload}
            />
          </FileButton>
        </Tab>
      </Tabs> */}
    </Popup>
  )
}
