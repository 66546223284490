import styled from 'styled-components'

import { layout, colors, fontSize } from '../../theme'
import { PageListItem } from './PageListItem'

export const List = styled.ul`
  list-style: none;
  margin: 0 -20px;
  padding: 0;
  display: grid;
  touch-action: pan-y;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${(props) => `${props.pageSize}px`}, 1fr)
  );
  grid-auto-rows: 1fr;

  &::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`

export const PageList = ({ pages, thumbnails, pageSize, onToggle }) => (
  <List id="PageList" pageSize={pageSize}>
    {pages.map((page) => (
      <PageListItem
        key={page.nr}
        page={page}
        onToggle={onToggle}
        thumbnails={thumbnails}
      />
    ))}
  </List>
)
