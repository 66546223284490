import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" d="M53.5 30.002a8.5 8.5 0 1 0-8 0V38.5h4.154l-9.146 9.913 9.1 9.97 9.146-9.913-9.1-9.97H53.5v-8.498ZM23 72l.395.034C20.92 72.38 19 74.688 19 77.473c0 3.032 2.256 5.484 5.04 5.484h6.533c2.784.01 5.04-2.452 5.04-5.484 0-2.893-2.054-5.258-4.661-5.469L31 72v-6h15v6l.095.012c-2.623.187-4.708 2.571-4.708 5.472 0 3.032 2.256 5.484 5.04 5.484h6.533c2.785.01 5.04-2.452 5.04-5.484 0-2.787-1.906-5.084-4.376-5.437L54 72v-6.5h16v6.674c-2.15.625-3.741 2.765-3.741 5.299 0 3.032 2.255 5.484 5.04 5.484h6.533c2.784.01 5.04-2.452 5.04-5.484 0-2.97-2.166-5.385-4.872-5.481V58.5H23V72Z" />
      </svg>
    </div>
  )
}
