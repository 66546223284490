const t = (key) => {
  const translate = {
    lblOptimize: 'Select compression type',

    optimizeDesc_compress: 'Moderate compression. Slight reduction in quality.',
    optimizeDesc_compressMax: 'Maximum compression. Reduced quality.',

    splitTypeDesc_byPageNr:
      'Split after specific pages. Give a single page number or sequence of page numbers separated by commas. i.e, 5 for splitting after 5th or 4,7,11 to split after 4th, 7th, and 11th page.',
    splitTypeDesc_recurring:
      'Split periodically. For E.g.: Give 2 to split PDF after every 2 pages i.e. 2nd, 4th, 6th page,.. etc.',
    splitTypeDesc_byBarcode: 'Split by Barcode',
    splitTypeDesc_byText: 'Split by Text. Give any text or expression',
    errInvalidNumber: 'Please provide a valid number',
    errNumberZero: 'Please provide a valid number greater than 0',
    errNumberHigh: 'Please provide a valid number lesser than',
    errNumberDecimal: 'Please provide a non-decimal number',
    byPageNr: 'By Page Nr',
    byBarCode: 'By Barcode',
    splitRecurring: 'Split Recurring',
    lblSplitAfterPageNr: 'Split after Page',
    lblSplitRecurringNr: 'Split after every Page',
    lblSplitBarcodeFilter: 'Filter by Barcode that',
    lblSplitBarcodeType: 'Barcode type',
    splitBarcodeSelectType: 'Select Barcode Type',
    splitBarcodeSelectFilterType: 'Select Filter Type',
    lblSplitBarcodePage: 'Split Type',
    splitBarcodeSelectPageType: 'Select Split Type',
    lblSplitByBarcodeTitle: 'Split by Barcode',
    SplitTypeEmpty: 'Split Type is empty',
    SplitTextEmpty: 'Split Text is empty',
    filterStartsWith: 'Starts With',
    filterEndsWith: 'Ends With',
    filterContains: 'Contains',
    filterExact: 'is Equal to',
    barCodeTypeAny: 'Any',
    barCodeTypeDatamatrix: 'Datamatrix',
    barCodeTypeqrcode: 'QR code',
    splitBefore: 'Split Before Barcode',
    splitAfter: 'Split After Barcode',
    splitRemove: 'Remove Barcode',
    moreOptions: 'More options',
    AnyText: 'Any Text',
    errInvalidSequence: 'Invalid Sequence of Number',
    docError_InvalidSplitAfterPage:
      'Please give a value less than the total page number',
    splitBeforeText: 'Split Before Text',
    splitAfterText: 'Split After Text',
    lblSplitText: 'Split Text',
    anyTextOrExpression: 'Any Text or Expression',
    lblQuality: 'Page Options',
    qualityHigh: 'All Pages',
    qualityDraft: 'First Page',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
