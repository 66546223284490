const t = (key) => {
  const translate = {
    btnStartJob: 'Start adding Watermark',
    stampTypeDesc_text: 'Apply Text stamp',
    lblPageSelection: 'Pages',
    lblTextStampTitle: 'Text Stamp',
    titleSelectPages: 'Select Pages',
    btnSelect: 'Select',
    stampTypeDesc_image: 'Apply Image stamp',
    lblImageStampTitle: 'Image Stamp',
    btnSetForeground: 'Set to Foreground',
    btnSetBackground: 'Set to Background',
    lblText: 'Text',
    lblImage: 'Image',
    allPages: 'All pages',
    firstPage: 'First page',
    customSelection: 'Select pages',
    left: 'Left',
    center: 'Center',
    right: 'Right',
    top: 'Top',
    middle: 'Middle',
    bottom: 'Bottom',
    lblSelectStamp: 'Choose the Stamp type',
    lblTransparency: 'Opacity',
    lblNoTransparency: 'No Transparency',
    titleOnePageSelected: 'Page selected',
    titleMultiplePagesSelected: 'Pages selected',
    lblEnterTextHere: 'Enter text here',
    modeFill: 'Fill',
    modeStroke: 'Stroke',
    lblDropImageHere: 'Drop Image here',
    lblSelectHere: 'or Select Here',
    lblUploadingImage: 'Uploading Image',
    lblChangeImage: 'Change Image',
    lblLineWidth: 'Line width',
    btnCancel: 'Cancel',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
