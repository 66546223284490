import { forwardRef, useState } from 'react'

import Icon from '../Icon'
import EyeIcon from '../icons/eye'
import EyeSlashIcon from '../icons/eyeSlash'
import PasswordPolicy from './PasswordPolicy'

export default forwardRef((props, ref) => {
  const { value, passwordPolicy, name, placeholder, disabled, onChange } = props
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordMeter, setShowPasswordMeter] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  function handleToggleViewPassword() {
    setShowPassword(!showPassword)
  }

  function handleFocus() {
    setHasFocus(true)
  }
  function handleBlur() {
    setHasFocus(false)
  }

  return (
    <>
      {showPassword ? (
        <input
          ref={ref}
          value={value}
          name={name}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <input
          ref={ref}
          value={value}
          name={name}
          type="password"
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {passwordPolicy && (
        <PasswordPolicy password={value} showHint={hasFocus} />
      )}
      <button type="button" onClick={handleToggleViewPassword} tabIndex={-1}>
        <Icon icon={showPassword ? EyeIcon : EyeSlashIcon} />
      </button>
    </>
  )
})
