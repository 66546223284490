import { useState, useRef, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { ViewportContext } from '../../hooks/ViewportContext'
import { device, media, colors } from '../../theme'
import imageIcon from '../../controls/icons/image'
import Icon from '../../controls/Icon'
import thumbnailsActions from '../../store/thumbnails/actions'
import Toolbar from './Popup/Toolbar'
import {
  Page,
  PageLayout,
  defaultMargin,
  A4PageHeight,
  A4PageWidth,
  cmToPixelRatio,
} from './Popup/common'

const IconWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const VerticalLine = styled.div`
  width: 0px;
  position: absolute;
  border-left: 1px dashed ${colors.gray};
`

const HorizontalLine = styled.div`
  height: 0;
  position: absolute;
  border-top: 1px dashed ${colors.gray};
`

const SizePositionForm = styled.div`
  height: 485px;
  ${media.down(device.mobile)`
  height:auto;
  `}
`

const ArrowBox = styled.div`
  z-index: 2;
  cursor: pointer;
  position: absolute;
  align-self: center;
  div {
    border: 17px solid transparent;
    margin: 10px;
  }
  &.left {
    left: 0px;
    div {
      border-left: 0px;
      border-right: 25px solid ${colors.grayLight};
    }
    &.disabled {
      div {
        border-right-color: #ebebeb40;
      }
    }
  }
  &.right {
    right: 0px;
    div {
      border-right: 0px;
      border-left: 25px solid ${colors.grayLight};
    }
    &.disabled {
      div {
        border-left-color: #ebebeb40;
      }
    }
  }
`

const ImgStamp = styled.img`
  user-select: none;
  cursor: grab;
  border: 1px dashed #426fef;
  &:active {
    cursor: grabbing;
  }
`

const DragWrapper = styled.div``
const _boxSize = 15
const ResizerPointer = styled.div`
  width: ${_boxSize}px;
  height: ${_boxSize}px;
  background-color: ${colors.primary};
  position: absolute;
  &.top {
    top: 0px;
    &.left {
      cursor: nw-resize;
    }
    &.right {
      cursor: ne-resize;
    }
  }
  &.bottom {
    bottom: 0px;
    &.left {
      cursor: sw-resize;
    }
    &.right {
      cursor: se-resize;
    }
  }
  &.left {
    left: 0px;
  }
  &.right {
    right: 0px;
  }
  ${media.down(device.mobile)`
  width: ${_boxSize + 10}px;
  height: ${_boxSize + 10}px;
  `}
`

export default ({
  barcodeConfig,
  setBarcodeConfig,
  barcodeInvalid,
  inputFiles,
  filesReady,
  pageSelectionType,
  customSelection,
  pageNum,
  setPageNum,
  maxContentHeight,
  maxContentWidth,
}) => {
  const { thumbnails, extDocId } = useSelector((state) => state.thumbnails)
  const viewport = useContext(ViewportContext)
  let thumbnailInfo = null
  const dispatch = useDispatch()

  const getThumbnails = async () => {
    if (filesReady) {
      const thumbmnailWidth = viewport.width > device.tablet ? 700 : 400
      await dispatch(
        thumbnailsActions.createThumbnails(inputFiles[0], '', thumbmnailWidth)
      )
    }
  }

  const boxSize = viewport.width < device.tablet ? _boxSize + 10 : _boxSize
  let prevDisabled = true
  let nextDisabled = true


  if (filesReady) {
    if (thumbnails[pageNum]) {
      thumbnailInfo = thumbnails[pageNum]
    } else if (!extDocId) {
      getThumbnails()
    }
    if (pageSelectionType === 'custom') {
      if (customSelection?.indexOf(pageNum) - 1 >= 0) {
        prevDisabled = false
      }
      if (customSelection?.indexOf(pageNum) + 1 < customSelection?.length) {
        nextDisabled = false
      }
    } else if (pageSelectionType === 'all') {
      if (thumbnails[pageNum - 1]) {
        prevDisabled = false
      }
      if (thumbnails[pageNum + 1]) {
        nextDisabled = false
      }
    }
  }

  const calculateSizeSettings = () => {
    const pageLayoutHeight = maxContentHeight - 65
    let maxPageHeight = pageLayoutHeight - 20
    const maxPageWidth = maxContentWidth - 30
    if (maxPageHeight < 350) maxPageHeight = 350
    else if (maxPageHeight > 1000) maxPageHeight = 1000
    const pageHeight = maxPageHeight
    const pageWidth = pageHeight * (A4PageWidth / A4PageHeight)
    let _pageWidth = pageWidth
    let _pageHeight = pageHeight
    let _pageRatio = pageHeight / A4PageHeight
    let landscape = false
    if (thumbnails[pageNum]) {
      landscape = thumbnails[pageNum]
        ? thumbnails[pageNum].pageHeight < thumbnails[pageNum].pageWidth
        : false

      if (landscape) {
        _pageWidth = maxPageWidth
        _pageRatio = _pageWidth / thumbnails[pageNum].pageWidth
        _pageHeight = thumbnails[pageNum].pageHeight * _pageRatio
        if (_pageHeight > maxPageHeight) {
          _pageHeight = maxPageHeight
          _pageRatio = _pageHeight / thumbnails[pageNum].pageHeight
          _pageWidth = thumbnails[pageNum].pageWidth * _pageRatio
          landscape = false
        }
      } else {
        _pageHeight = maxPageHeight
        _pageRatio = _pageHeight / thumbnails[pageNum].pageHeight
        _pageWidth = thumbnails[pageNum].pageWidth * _pageRatio
        if (_pageWidth > maxPageWidth) {
          _pageWidth = maxPageWidth
          _pageRatio = _pageWidth / thumbnails[pageNum].pageWidth
          _pageHeight = thumbnails[pageNum].pageHeight * _pageRatio
          landscape = true
        }
      }
    }

    return {
      _pageWidth,
      _pageHeight,
      _pageRatio,
      maxPageHeight,
      maxPageWidth,
      pageHeight,
      pageWidth,
      landscape,
      pageLayoutHeight,
    }
  }

  const settings = calculateSizeSettings()

  const pageWrapperCss = {
    left: 'auto',
    top: 'auto',
  }

  pageWrapperCss.height = `${settings._pageHeight}px`
  pageWrapperCss.width = `${settings._pageWidth}px`
  if (settings.landscape) {
    pageWrapperCss.maxWidth = `${settings.maxPageWidth}px`
  } else {
    pageWrapperCss.maxHeight = `${settings.maxPageHeight}px`
  }

  const dispBarcodeWidth = barcodeConfig.width * settings._pageRatio
  const dispBarcodeHeight = barcodeConfig.height * settings._pageRatio

  let leftMargin = defaultMargin * settings._pageRatio
  // if (barcodeConfig.xalign !== 'center') {
  leftMargin =
    barcodeConfig.marginX < 0
      ? barcodeConfig.marginX * settings._pageRatio * -1
      : barcodeConfig.marginX * settings._pageRatio
  // }

  let topMargin = defaultMargin * settings._pageRatio
  // if (barcodeConfig.yalign !== 'middle') {
  topMargin =
    barcodeConfig.marginY < 0
      ? barcodeConfig.marginY * settings._pageRatio * -1
      : barcodeConfig.marginY * settings._pageRatio
  // }
  const rightMargin = settings._pageWidth - leftMargin
  const bottomMargin = settings._pageHeight - topMargin

  const imgWrappercss = {}
  if (barcodeConfig.xalign === 'right') {
    imgWrappercss.right = `${leftMargin}px`
  } else if (barcodeConfig.xalign === 'left') {
    imgWrappercss.left = `${leftMargin}px`
  }
  if (barcodeConfig.yalign === 'bottom') {
    imgWrappercss.bottom = `${topMargin}px`
  } else if (barcodeConfig.yalign === 'top') {
    imgWrappercss.top = `${topMargin}px`
  }

  const PrevPage = () => {
    if (filesReady) {
      let prevPage = pageNum
      if (pageSelectionType === 'custom') {
        if (customSelection?.indexOf(pageNum) - 1 >= 0) {
          prevPage = parseInt(
            customSelection[customSelection?.indexOf(pageNum) - 1],
            10
          )
        }
      } else if (pageSelectionType === 'all') {
        if (thumbnails[prevPage - 1]) {
          prevPage -= 1
        }
      } else if (!extDocId) {
        return
      }
      setPageNum(prevPage)
    }
  }
  const Nextpage = () => {
    if (filesReady) {
      let nextPage = pageNum
      if (pageSelectionType === 'custom') {
        if (customSelection?.indexOf(pageNum) + 1 < customSelection?.length) {
          nextPage = parseInt(
            customSelection[customSelection?.indexOf(pageNum) + 1],
            10
          )
        }
      } else if (pageSelectionType === 'all') {
        if (thumbnails[nextPage + 1]) {
          nextPage += 1
        }
      } else if (!extDocId) {
        return
      }
      setPageNum(nextPage)
    }
  }

  const backgroundWrapper = {
    backgroundImage: thumbnailInfo ? `url(${thumbnailInfo.thumbnail})` : 'auto',
    backgroundPosition: 'center' /* Center the image */,
    backgroundSize: 'cover',
  }

  /* Draggable begin */

  const onDragMove = (x, y) => {
    const _settings = calculateSizeSettings()
    const _marginXcm =
      Math.round(((x * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginYcm =
      Math.round(((y * cmToPixelRatio) / _settings._pageRatio) * 10) / 10
    const _marginX = Math.round(_marginXcm / cmToPixelRatio)
    const _marginY = Math.round(_marginYcm / cmToPixelRatio)
    const _barcodeConfig = {
      ...barcodeConfig,
      marginX: _marginX,
      marginY: _marginY,
      marginXcm: _marginXcm,
      marginYcm: _marginYcm,
      xalign: 'left',
      yalign: 'top',
      draggable: true,
      dragInitialized: true,
    }

    setBarcodeConfig(_barcodeConfig)
  }

  const onResize = (width, height) => {
    const _settings = calculateSizeSettings()

    let _width = width / _settings._pageRatio
    let _height = height / _settings._pageRatio
    if (
      barcodeConfig.barcodeType !== 'code39' &&
      barcodeConfig.barcodeType !== 'code128'
    ) {
      const widthRatio = _width / barcodeConfig.width
      const heightRatio = _height / barcodeConfig.height
      const minRatio = widthRatio >= heightRatio ? heightRatio : widthRatio

      _width = minRatio * barcodeConfig.width
      _height = minRatio * barcodeConfig.height
    }

    const _barcodeConfig = {
      ...barcodeConfig,
      width: _width,
      height: _height,
    }

    setBarcodeConfig(_barcodeConfig)
  }

  const handlerRef = useRef(null)

  const [mode, setMode] = useState('') // move- drag the barcode , resize-${id} - resizing the barcode

  const move = (x, y, e, type) => {
    const handlerSize = handlerRef.current.getBoundingClientRect()
    const parentSize = handlerRef.current.parentElement.getBoundingClientRect()

    if (mode === 'move') {
      let _posX = x - handlerSize.width / 2 - parentSize.left + boxSize
      let _posY = y - handlerSize.height / 2 - parentSize.top + boxSize

      if (_posX < 0) _posX = 0
      if (_posY < 0) _posY = 0

      if (parentSize.width + boxSize * 2 < _posX + handlerSize.width) {
        _posX = parentSize.width - handlerSize.width + boxSize * 2
      }
      if (parentSize.height + boxSize * 2 < _posY + handlerSize.height) {
        _posY = parentSize.height - handlerSize.height + boxSize * 2
      }
      _posX = Math.round(_posX)
      _posY = Math.round(_posY)
      if (onDragMove) onDragMove(_posX, _posY)
    }

    if (mode?.indexOf('resize') > -1) {
      let _width = barcodeConfig.width
      let _height = barcodeConfig.height
      let _x = x
      let _y = y

      const boxType = mode.split('-')[1].toLowerCase()

      if (boxType?.indexOf('top') > -1) {
        if (parentSize.top > _y) {
          _x = parentSize.top
        }
        _height = handlerSize.bottom - (_y + boxSize * 1.5)
      } else if (boxType?.indexOf('bottom') > -1) {
        if (parentSize.bottom < _y) {
          _y = parentSize.bottom
        }
        _height = _y - (handlerSize.top + boxSize * 1.5)
      }

      if (boxType?.indexOf('left') > -1) {
        if (parentSize.left > _x) {
          _x = parentSize.left
        }
        _width = handlerSize.right - (_x + boxSize * 1.5)
      } else if (boxType?.indexOf('right') > -1) {
        if (parentSize.right < _x) {
          _x = parentSize.right
        }
        _width = _x - (handlerSize.left + boxSize * 1.5)
      }

      if (_width <= 0) _width = 1
      if (_height <= 0) _height = 1

      if (onResize) onResize(_width, _height)
    }
  }

  const onMouseMove = (e /* : MouseEvent */) => {
    const { clientX, clientY, pageX, pageY, screenX, screenY } = e
    move(clientX, clientY, e)
    if (e.buttons === 0) {
      setMode('')
    }
  }

  const onTouchMove = (e /*: TouchEvent */) => {
    const { clientX, clientY } = e.touches[0]
    move(clientX, clientY, e, 'touch')
    if (e.touches?.length === 0) {
      setMode('')
    }
  }

  const onMouseDown = (e /* :MouseEvent */) => {
    setMode('move')
  }

  const onTouchStart = (e /* : TouchEvent */) => {
    setMode('move')
  }

  const onDragTargetStart = (e) => {
    if (e) {
      e.preventDefault()
    }
  }

  /* Draggable ends */

  /* Resizer Starts */
  if (imgWrappercss.left) imgWrappercss.left = `${leftMargin - boxSize}px`
  if (imgWrappercss.right) imgWrappercss.right = `${leftMargin - boxSize}px`
  if (imgWrappercss.top) imgWrappercss.top = `${topMargin - boxSize}px`
  if (imgWrappercss.bottom) imgWrappercss.bottom = `${topMargin - boxSize}px`

  const onResizerMouseDown = (e) => {
    const { id } = e.target
    setMode(`resize-${id}`)
  }
  const onResizerTouchStart = (e) => {
    const { id } = e.target
    setMode(`resize-${id}`)
  }

  /* Reszier end */

  return (
    <>
      <SizePositionForm style={{ height: `${maxContentHeight}px` }}>
        <Toolbar
          config={{ ...barcodeConfig }}
          updateConfig={setBarcodeConfig}
          disabled={barcodeInvalid}
        />

        <PageLayout
          style={{ height: `${settings.pageLayoutHeight}px` }}
          data-scale={settings._pageRatio}
        >
          <ArrowBox
            className={`left ${prevDisabled ? 'disabled' : ''}`}
            onClick={PrevPage}
          >
            <div />
          </ArrowBox>
          <ArrowBox
            className={`right ${nextDisabled ? 'disabled' : ''}`}
            onClick={Nextpage}
          >
            <div />
          </ArrowBox>

          <Page
            className={`overlay ${barcodeConfig.xalign} ${barcodeConfig.yalign}`}
            style={pageWrapperCss}
            onMouseMove={onMouseMove}
            onTouchMove={onTouchMove}
          >
            <DragWrapper
              id="dragWrapper"
              className="imgWrapper"
              style={{
                ...imgWrappercss,
                height: dispBarcodeHeight
                  ? `${dispBarcodeHeight + boxSize * 2}px`
                  : 'auto',
                width: dispBarcodeWidth
                  ? `${dispBarcodeWidth + boxSize * 2}px`
                  : 'auto',
              }}
              onDragStart={onDragTargetStart}
              ref={handlerRef}
            >
              {barcodeConfig.imgThumbnail ? (
                <>
                  {!(
                    barcodeConfig.yalign === 'top' ||
                    barcodeConfig.xalign === 'left'
                  ) && (
                    <ResizerPointer
                      id="boxTopLeft"
                      className="top left resizer"
                      onMouseDown={onResizerMouseDown}
                      onTouchStart={onResizerTouchStart}
                    />
                  )}
                  {!(
                    barcodeConfig.yalign === 'top' ||
                    barcodeConfig.xalign === 'right'
                  ) && (
                    <ResizerPointer
                      id="boxTopRight"
                      className="top right resizer"
                      onMouseDown={onResizerMouseDown}
                      onTouchStart={onResizerTouchStart}
                    />
                  )}
                  {!(
                    barcodeConfig.yalign === 'bottom' ||
                    barcodeConfig.xalign === 'left'
                  ) && (
                    <ResizerPointer
                      id="boxBottomLeft"
                      className="bottom left resizer"
                      onMouseDown={onResizerMouseDown}
                      onTouchStart={onResizerTouchStart}
                    />
                  )}
                  {!(
                    barcodeConfig.yalign === 'bottom' ||
                    barcodeConfig.xalign === 'right'
                  ) && (
                    <ResizerPointer
                      id="boxBottomRight"
                      className="bottom right resizer"
                      onMouseDown={onResizerMouseDown}
                      onTouchStart={onResizerTouchStart}
                    />
                  )}
                  <ImgStamp
                    src={barcodeConfig.imgThumbnail}
                    className={`stamp rotate_${barcodeConfig.rotate}`}
                    style={{
                      height: dispBarcodeHeight
                        ? `${dispBarcodeHeight}px`
                        : 'auto',
                      width: dispBarcodeWidth
                        ? `${dispBarcodeWidth}px`
                        : 'auto',
                      margin: `${boxSize}px`,
                    }}
                    alt="img"
                    onMouseDown={onMouseDown}
                    onTouchStart={onTouchStart}
                  />
                </>
              ) : (
                <IconWrapper>
                  <Icon icon={imageIcon} />
                </IconWrapper>
              )}
            </DragWrapper>
          </Page>
          <Page
            id="marginPage"
            style={{
              ...backgroundWrapper,
              ...pageWrapperCss,
            }}
          >
            {barcodeConfig.xalign === 'left' && (
              <VerticalLine
                id="leftLine"
                style={{
                  // left: `${leftMargin}px`,
                  transform: `translateX(${leftMargin}px)`,
                  height: `${settings._pageHeight}px`,
                }}
              />
            )}
            {barcodeConfig.xalign === 'right' && (
              <VerticalLine
                id="rightLine"
                style={{
                  // right : `${rightMargin}px`,
                  transform: `translateX(${rightMargin}px)`,
                  height: `${settings._pageHeight}px`,
                }}
              />
            )}
            {barcodeConfig.yalign === 'top' && (
              <HorizontalLine
                id="topLine"
                style={{
                  // top: `${topMargin}px`,
                  transform: `translateY(${topMargin}px)`,
                  width: `${settings._pageWidth}px`,
                }}
              />
            )}
            {barcodeConfig.yalign === 'bottom' && (
              <HorizontalLine
                id="bottomLine"
                style={{
                  // top: `${bottomMargin}px`,
                  transform: `translateY(${bottomMargin}px)`,
                  width: `${settings._pageWidth}px`,
                }}
              />
            )}
          </Page>
        </PageLayout>
      </SizePositionForm>
    </>
  )
}
