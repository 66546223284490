// import { saveAs } from 'file-saver'
import mutations from './mutations'
import { fileUpload } from '../../service/document/fileUpload'
import { createId } from '../../utils/createId'
import { ToolName } from '../../utils/enums'

export default {
  addLocalFile: (file) => async (dispatch, getState) => {
    const { hub } = getState()
    const connectionId = hub && hub.connectionId ? hub.connectionId : undefined
    let data = ''
    const onProgress = (progress) => {
      dispatch(mutations.setImageStampProgress(progress))
      return {}
    }
    const docProcessing = {
      storageType: 'cdoc',
      toolName: ToolName.ADD_BARCODE,
    }
    dispatch(mutations.setImgLoading())
    try {
      data = await fileUpload(
        docProcessing.storageType,
        file,
        createId(),
        docProcessing.toolName,
        connectionId,
        onProgress
      )
    } catch (error) {
      const msg = error.message
    } finally {
      dispatch(mutations.setImgLoaded())
    }
    return data
  },
}
