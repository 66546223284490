import { useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  PopupModule,
  FreetextAnnotationModule,
  HighlightAnnotationModule,
  ImageAnnotationModule,
} from '@pdf-tools/four-heights-pdf-web-viewer'

import Viewer from '../components/Viewer'
import IconButton from '../../../controls/IconButton'
import Dropdown from '../../../controls/Dropdown'
import SignaturePad from '../../../components/SignaturePad'
import PdfViewerContext from '../pdfViewer/PdfViewerContext'

import '../../../theme/viewer/scss/styles.scss'

const SigButton = styled.button`
  border: bone;
  background-color: none;
  margin: 0;
  padding: 0;
  img {
    max-height: 80px;
    max-width: 200px;
  }
`

export default (props) => {
  const [showSignaturePad, setShowSignaturePad] = useState(false)
  const [signatures, setSignatures] = useState([])
  const [state, actions] = useContext(PdfViewerContext)

  const openSignaturePad = () => {
    setShowSignaturePad(true)
  }

  const closeSignaturePad = () => {
    setShowSignaturePad(false)
  }

  const addSignature = (signature) => {
    setSignatures([...signatures, signature.img])
    setShowSignaturePad(false)
    actions.addImage(signature.img)
  }

  const addText = () => {
    actions.addFreeTextAnnotation()
  }

  const signatureDropdownItems = signatures.map((sig) => ({
    renderItem: () => (
      <SigButton
        onClick={() => {
          actions.addImage(sig)
        }}
      >
        <img src={sig} />
      </SigButton>
    ),
  }))
  signatureDropdownItems.unshift({
    text: 'Add new signature',
    icon: 'plus',
    action: () => {
      openSignaturePad()
    },
  })

  return (
    <>
      <Viewer
        options={{
          modules: [
            PopupModule,
            FreetextAnnotationModule,
            ImageAnnotationModule,
          ],
          defaultBorderSize: 0,
          defaultBackgroundColor: '#ffffff00',
        }}
        hidePageNavigation={true}
        hidePageZoom={true}
        hideAnnotationBar={true}
        toolbar={() => (
          <>
            {signatures.length === 0 ? (
              <IconButton
                text="Place Signature"
                icon="signature"
                onClick={openSignaturePad}
              />
            ) : (
              <Dropdown
                toggleButton={(props) => (
                  <IconButton
                    text="Place Signature"
                    icon="signature"
                    {...props}
                  />
                )}
                items={signatureDropdownItems}
              />
            )}

            <IconButton text="Add Text" icon="text" onClick={addText} />
          </>
        )}
      />
      {showSignaturePad && (
        <SignaturePad onClose={closeSignaturePad} onChange={addSignature} />
      )}
    </>
  )
}
