const t = (key) => {
  const translate = {
    titleSelectPages: 'Select Pages',
    btnSelect: 'Done',
    btnCancel: 'Cancel',
  }
  if (translate[key]) return translate[key]
  // throw new Error(key + ' not found')
  return ''
}

export default t
