import React from 'react'

export default function  (props) {
  const { className } = props
  

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 100 100">
        <path fill="currentColor" fillRule="evenodd" d="M37.222 65.562c-1.93 3.645-4.742 5.5-8.366 5.5-3.624 0-6.436-1.855-8.366-5.517-1.931-3.61-2.896-8.873-2.896-15.635 0-6.761.982-11.99 2.896-15.566 1.947-3.611 4.776-5.433 8.4-5.433 3.64 0 6.452 1.839 8.366 5.484 1.897 3.61 2.862 8.839 2.862 15.583 0 6.728-.982 11.956-2.896 15.584Zm-4.098-25.82c-.949-2.23-2.354-3.32-4.251-3.32-1.897 0-3.303 1.09-4.285 3.338-1 2.282-1.49 5.705-1.49 10.201s.49 7.92 1.473 10.185c.965 2.248 2.354 3.338 4.268 3.338 1.914 0 3.32-1.09 4.285-3.321.965-2.248 1.456-5.688 1.456-10.202 0-4.53-.491-7.97-1.456-10.218Z" clipRule="evenodd" />
        <path fill="currentColor" d="M60.238 68.951c-1.931 1.397-4.116 2.112-6.504 2.112-3.455 0-6.114-1.84-7.943-5.467-1.795-3.594-2.71-8.822-2.71-15.516 0-4.206.441-7.97 1.322-11.138.88-3.185 2.184-5.671 3.844-7.375 1.677-1.72 3.675-2.588 5.928-2.588 2.286 0 4.623.97 6.926 2.895l.085.068-.017.102-1.592 7-.068.273-.203-.17a22.598 22.598 0 0 0-2.608-1.874c-.847-.528-1.694-.784-2.506-.784-1.779 0-3.184 1.193-4.167 3.543-1.016 2.401-1.524 5.808-1.524 10.117 0 4.479.491 7.885 1.457 10.116.965 2.214 2.337 3.287 4.234 3.287 1.608 0 3.573-.715 5.86-2.112l.253-.153V68.9l-.067.051Z" />
        <path fill="currentColor" fillRule="evenodd" d="M69.79 70.5h-5.285V29.54h6.961c3.2 0 5.623 1.04 7.164 3.083 1.54 2.044 2.32 5.16 2.32 9.265 0 2.402-.39 4.565-1.152 6.438-.745 1.84-1.795 3.321-3.15 4.377 3.472 9.027 5.758 14.937 6.808 17.576l.085.239h-5.86l-.034-.12-5.52-15.48h-2.338V70.5Zm4.724-32.376c-.728-.783-1.863-1.175-3.404-1.175h-1.32v10.645h1.422c1.49 0 2.608-.426 3.32-1.294.728-.87 1.083-2.283 1.083-4.173 0-1.874-.372-3.22-1.1-4.003Z" clipRule="evenodd" />
        <path fill="currentColor" fillRule="evenodd" d="M90 15H10v70h80V15Zm-3.648 66.742V19.288H13.648v62.454h72.704Z" clipRule="evenodd" />
      </svg>


    </div>
  )
}
