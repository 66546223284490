import t from '../../components/JobPopup/ActionConfigGenerator/SplitPdfConfig/translate'

export default (props) => {
  const { resKey, content } = props
  // const t = useTranslation()
  if (resKey) {
    return (
      <div
        className="md"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t(resKey),
        }}
      />
    )
  }
  return (
    <div
      className="md"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}
